import * as React from 'react';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import UserNotesTable from './UserNotesTable';

function UserViewNotesModal() {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <StandardModal
      headerText={`User Notes ${params.id}`}
      open={true}
      handleClose={() => navigate(-1)}
    >
      <Box sx={{ padding: 5 }}>
        <UserNotesTable />
      </Box>
    </StandardModal>
  );
}

export default UserViewNotesModal;
