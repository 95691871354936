import React from 'react';
import UserTable from './UserTable/UserTable';
import Section from '../../Templates/Section/Section';
import '../Pages.css';

function UsersPage(): JSX.Element {
  return (
    <div className="whole-width">
      <Section title="Users">{<UserTable />}</Section>
    </div>
  );
}

export default UsersPage;
