/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ScheduleInputs from './ScheduleInputs';
import { set } from 'lodash';
import ExitModal from '../../../../components/Templates/ExitModal/ExitModal';

interface Props {
  handleClose: Function;
  schedulesToEdit?: GridSelectionModel;
  userId: string | undefined;
}

interface ScheduleInArrayI {
  id: string;
  uuid: string;
}

const generateEmptySchedule = () => ({
  id: 'new',
  uuid: uuidv4()
});

export default function SchedulesForm(props: Props): JSX.Element {
  const [schedules, setSchedules] = useState<ScheduleInArrayI[]>([
    generateEmptySchedule()
  ]);
  const { handleClose, userId, schedulesToEdit } = props;
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const [exitModal, setExitModal] = useState(false);
  // const permissionCacheContext = useContext(PermissionCacheContext);

  const handleRemoveSchedule = (index: number) => {
    const newSchedules = schedules.filter((schedule, i) => i !== index);
    setSchedules(newSchedules);
  };

  const handleScheduleUpdate = (index: number, id: string) => {
    const newSchedules = [...schedules];
    const scheduleAtIndex = newSchedules[index];
    scheduleAtIndex.id = id;
    setSchedules(newSchedules);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        className="no-scroll-bar"
        sx={{ overflowY: 'scroll', height: '90%', marginTop: 3 }}
      >
        <Stack spacing={5}>
          {schedules.map((schedule, i) => {
            return (
              <Box
                key={schedule.uuid}
                sx={{ minHeight: 50, display: 'flex', alignItems: 'center' }}
              >
                <ScheduleInputs
                  scheduleId={schedule.id as string}
                  handleRemove={handleRemoveSchedule}
                  handleIdUpdate={handleScheduleUpdate}
                  index={i}
                />
              </Box>
            );
          })}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              sx={{
                width: 250,
                backgroundColor: 'primary.light',
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={() => {
                setSchedules([...schedules, generateEmptySchedule()]);
              }}
            >
              Add Schedule
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10%'
        }}
      >
        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            size="medium"
            sx={{ height: 45 }}
            onClick={() => setExitModal(true)}
          >
            Close
          </Button>
        </Stack>
        {exitModal && (
          <ExitModal
            handleCloseAll={() => handleClose()}
            handleClose={() => setExitModal(false)}
            isOpen
          />
        )}
      </Box>
    </Box>
  );
}
