/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { query } from './SeqApi';

export * as SysLogApi from "./SysLogApi"

const PROCESS_NAME = 'sys_log'

// TODO: add filters
export interface SysLogRequest {
    start_date: string;
    end_date: string;
}

// s.subuser_id, s.reason, s.reason_user_assessment_id, s.result_user_assessment_id, s.timestamp,
// result.expire_date AS result_expire_date, result_ass.friendlyName as result_name, reason_ass.friendlyName AS reason_name,
// reason.expire_date AS reason_expire_date, sub.name AS assigned_to_name, record.score AS reason_score
export type SysLogEntry = {
    subuser_id: string;
    assigned_to_name: string;
    reason_user_assessment_id: string;
    reason_name: string;
    reason_expire_date: string;
    result_user_assessment_id: string;
    result_name: string;
    result_expire_date: string;
    timestamp: string;
} & (
    | {
          reason: 'COMPLETED';
          reason_score: number;
          reason_completion_date: string;
      }
    | {
          reason: 'EXPIRED';
          reason_score: null;
          reason_completion_date: null;
      }
);

export const get = query<SysLogEntry[]>()({
    variables: (req: SysLogRequest) => ({
        action: 'get_sys_log',
        ...req
    }),
    queryKey: (req) => ['sys_log', req],
    processName: PROCESS_NAME,
    transform: (r) => r
})
