import {
  GroupSummary,
  GroupSummaryResponse
} from '../structures/organizationInterfaces';

export const mapGroupSummariesToFrontEnd = (
  groups: GroupSummaryResponse[]
): GroupSummary[] => {
  return groups.map((group: GroupSummaryResponse) => {
    return {
      id: group.group_id,
      name: group.name,
      category: group.category,
      organizationId: group.org_id,
      liaisonName: group.liaison.name ?? "N/A",
      liaisonEmail: group.liaison.email ?? "N/A",
      liaisonPhone: group.liaison.phone ?? "N/A",
      liaisonTitle: group.liaison.title ?? "N/A",
    };
  });
};
