import { faker } from '@faker-js/faker';
import {
  BaseOrganization,
  OrgSummaryResponse
} from '../../structures/organizationInterfaces';
import { OrgStatus, OrgType } from '../../structures/organizationEnums';

export const generateSummaryRows = (numRows: number): OrgSummaryResponse[] => {
  const orgSummaries: OrgSummaryResponse[] = [];

  for (let i = 0; i < numRows; i++) {
    const orgSummary: OrgSummaryResponse = {
      org_id: faker.string.uuid(),
      status: faker.helpers.arrayElement(Object.values(OrgStatus)),
      name: faker.company.name(),
      type: faker.helpers.arrayElement(Object.values(OrgType)),
      address1: faker.location.streetAddress(),
      address2: faker.location.secondaryAddress(),
      groups: [faker.lorem.word(), faker.lorem.word(), faker.lorem.word()],
      pLName: faker.person.fullName(),
      pLPhone: faker.phone.number() as string,
      pLEmail: faker.internet.email(),
      created_on: faker.date.past().toISOString(),
      updated_on: faker.date.recent().toISOString(),
      created_by: faker.string.uuid(),
      updated_by: faker.string.uuid(),
      delete_status: '0',
      city: faker.location.city(),
      state: faker.location.state(),
      zip: faker.location.zipCode(),
      country: faker.location.country()
    };

    orgSummaries.push(orgSummary);
  }

  return orgSummaries;
};

export const generateBaseOrganizations = (
  numRows: number
): BaseOrganization[] => {
  const baseOrganizations: BaseOrganization[] = [];

  for (let i = 0; i < numRows; i++) {
    const baseOrganization: BaseOrganization = {
      org_id: faker.string.uuid(),
      status: faker.helpers.arrayElement(Object.values(OrgStatus)),
      name: faker.company.name(),
      type: faker.helpers.arrayElement(Object.values(OrgType)),
      address1: faker.location.streetAddress(),
      address2: faker.location.secondaryAddress(),
      primaryLiaison: {
        subuser_id: faker.string.uuid(),
        title: faker.person.jobTitle(),
        name: faker.person.fullName(),
        email: faker.internet.email(),
        phone: faker.phone.number(),
        associated_user_id: faker.string.uuid()
      },
      created_on: faker.date.past().toISOString(),
      updated_on: faker.date.recent().toISOString(),
      city: faker.location.city(),
      state: faker.location.state(),
      zip: faker.location.zipCode(),
      country: faker.location.country()
    };

    baseOrganizations.push(baseOrganization);
  }

  return baseOrganizations;
};
