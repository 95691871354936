/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useEntityFilter from '../../../helpers/useEntityFilter';
import { FilterCriteria } from '../../../structures/interfaces';
import DateFilter from '../../Templates/EntityTable/DateFilter';
import MultiSelectFilter from '../../Templates/EntityTable/MultiSelectFilter';
import ScalarFilter from '../../Templates/EntityTable/ScalarFilter';
import TableFilterMenu from '../../Templates/EntityTable/TableFilterMenu';
import * as React from 'react';

interface Props {
  filters: FilterCriteria;
  handleClose: Function;
  setFilters: Function;
}

function PermissionTableFilterMenu(props: Props): JSX.Element {
  const { filters, handleClose, setFilters } = props;

  const { displayedFilterState, handleCriterionChange } =
    useEntityFilter(filters);

  return (
    <TableFilterMenu
      displayedFilterState={displayedFilterState}
      handleClose={handleClose}
      setFilters={setFilters}
    >
      <DateFilter
        displayedCriterion={displayedFilterState.effectiveDate}
        fieldName={'effectiveDate'}
        fieldTitle={'Effective Date'}
        handleCriterionChange={handleCriterionChange}
      />
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.role}
        fieldName={'role'}
        fieldTitle={'Role'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={['Employee', 'Admin', 'Psychologist']}
      />
    </TableFilterMenu>
  );
}

export default PermissionTableFilterMenu;
