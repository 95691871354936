/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Modal, Paper } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactNode, useEffect, useState } from 'react';
import {UserApi} from '../../../api/UserApi';
import { TabI, Entity } from '../../../structures/interfaces';
import './EntityDetailModal.css';
import EntityDetailsModalHeader from './EnityDetailsModalHeader/EntityDetailsModalHeader';
import { GridSelectionModel } from '@mui/x-data-grid';
import DetailedEntityInfoPanel from './DetailedEntityInfoPanel/DetailedEntityInfoPanel';
import EntityApi from '../../../api/EntityApi';
import { Navigate, Route, Routes, useParams } from 'react-router';
import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';

interface Props {
    handleClose: Function;
    isOpen: boolean;
    pageTabs: TabI[];
    renderEntityDetails: () => JSX.Element;
}

const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.default',
    border: 'none',
    boxShadow: 24,
    borderRadius: 2,
    padding: 5
};

export default function EntityDetailModal({
    pageTabs,
    handleClose,
    isOpen,
    renderEntityDetails
}: Props): JSX.Element {
    const params = useParams();

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                handleClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ zIndex: 1200 }}
        >
            <Box sx={modalStyle}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateRows: 'auto 100%',
                        height: '90%'
                    }}
                >
                    {
                        <>
                            <EntityDetailsModalHeader
                                handleClose={handleClose}
                                tabs={pageTabs}
                            />
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '30% 70%',
                                    position: 'relative'
                                }}
                            >
                                <DetailedEntityInfoPanel>
                                    {renderEntityDetails()}
                                </DetailedEntityInfoPanel>
                                <Paper sx={{ padding: 2 }}>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <Navigate
                                                    to={pageTabs[0].link}
                                                />
                                            }
                                        ></Route>
                                        {pageTabs.map((tab, i) => {
                                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                                            return (
                                                <Route
                                                    key={i}
                                                    path={
                                                        (tab.link as string) +
                                                        '/*'
                                                    }
                                                    element={tab.renderPage?.(
                                                        params.id
                                                    )}
                                                ></Route>
                                            ); // this is messed up as heck, fix
                                        })}
                                    </Routes>
                                </Paper>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </Modal>
    );
}
