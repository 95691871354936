import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import UserNotesTable from '../UserTable/UserNotesTable';
import { FilterOperator } from '../../../../structures/enums';
import { AssessmentNoteModal } from '../UserTable/AssessmentNoteModal';
import { Info } from '@mui/icons-material';

function AssessmentNotesModal() {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <StandardModal
      headerText={`Assessment Notes: ${params.assessmentRecordId}`}
      open={true}
      handleClose={() => navigate(-1)}
    >
      
      <Box sx={{ padding: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Info sx={{ marginRight: 1 }} />
          <Typography variant="subtitle1">Click on the Note ID to expand the note</Typography>
        </Box>
        <UserNotesTable
          permaFilters={{
            assessment_record_id: {
              operator: FilterOperator.EQUALS,
              arguments: [params.assessmentRecordId as string]
            }
          }}
        />
        <Routes>
          <Route path="viewNote/:noteId" element={<AssessmentNoteModal />} />
        </Routes>
      </Box>
    </StandardModal>
  );
}

export default AssessmentNotesModal;
