/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import TableFilterMenu from '../../../Templates/EntityTable/TableFilterMenu';
import DateFilter from '../../../Templates/EntityTable/DateFilter';
import { FilterCriteria } from '../../../../structures/interfaces';
import {
  FilterOperator,
  UserSeverity,
  UserStatus
} from '../../../../structures/enums';
import useEntityFilter from '../../../../helpers/useEntityFilter';
import MultiSelectFilter from '../../../Templates/EntityTable/MultiSelectFilter';
import ScalarFilter from '../../../Templates/EntityTable/ScalarFilter';

interface Props {
  filters: FilterCriteria;
  handleClose: Function;
  setFilters: Function;
}
function OrganizationUsersFilterMenu(props: Props): JSX.Element {
  const { filters, handleClose, setFilters } = props;

  const { displayedFilterState, handleCriterionChange } =
    useEntityFilter(filters);

  return (
    <TableFilterMenu
      displayedFilterState={displayedFilterState}
      handleClose={handleClose}
      setFilters={setFilters}
    >
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.severity}
        fieldName={'severity'}
        fieldTitle={'Severity'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={Object.values(UserSeverity)}
      />
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.group}
        fieldName={'group'}
        fieldTitle={'Group'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={[
          'Basketball - Women',
          'Golf - Men',
          'Climbing - Women'
        ]}
      />
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.status}
        fieldName={'status'}
        fieldTitle={'Status'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={Object.values(UserStatus)}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.created_on}
        fieldName={'created_on'}
        fieldTitle={'Date Created'}
        handleCriterionChange={handleCriterionChange}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.updated_on}
        fieldName={'updated_on'}
        fieldTitle={'Date Updated'}
        handleCriterionChange={handleCriterionChange}
      />
    </TableFilterMenu>
  );
}

export default OrganizationUsersFilterMenu;
