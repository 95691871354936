import React from 'react';
import { FilterOperator } from '../structures/enums';
import { FilterCriteria } from '../structures/interfaces';

// write a hook that exposes a thing to handle a filter change event and the validated filters

interface RetType {
  displayedFilterState: FilterCriteria;
  handleCriterionChange: (
    field: string,
    operator: FilterOperator | undefined,
    args: string[] | undefined
  ) => void;
}

function useEntityFilter(filters: FilterCriteria): RetType {
  const [displayedFilterState, setDisplayedFilterState] =
    React.useState<FilterCriteria>(filters);

  function handleCriterionChange(
    field: string,
    operator: FilterOperator | undefined,
    args: string[] | undefined
  ): void {
    const newDisplayedFilterState = { ...displayedFilterState };
    //map over args, if one is a valid filter via dayjs, convert it to sql format
    newDisplayedFilterState[field] = {
      operator,
      arguments: args
    };

    setDisplayedFilterState(newDisplayedFilterState);
  }

  return {
    displayedFilterState,
    handleCriterionChange
  };
}

export default useEntityFilter;
