/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel,
    useGridApiRef
} from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import * as React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AssessmentRecordApi } from '../../../../api/AssessmentRecordApi';
import { FilterCriteria } from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import AssessmentNotesModal from './AssessmentNotesModal';
import AssessmentRecordViewer from './AssessmentRecordViewer';
import UserAssessmentRecordsFilterMenu from './UserAssessmentsFilterMenu';
import './UserAssessmentsViewer.css';
import { FilterOperator } from '../../../../structures/enums';
import { AssessmentReviewedCell } from './AssessmentReviewedCell';
import { UserAssessmentsTableActions } from './UserAssessmentsTableActions';
import { MarkEmailRead } from '@mui/icons-material';
import AddAssessmentNotesModal from './AddAssessmentNotesModal';
import { SeverityChip } from '../../../Templates/EntityTable/SeverityChip';
import { FlagChip } from '../../../Templates/EntityTable/FlagChip';
import FlagIcon from '@mui/icons-material/Flag';

interface Props {
    userId: string;
}

export const UserAsessmentsFilterContext =
    React.createContext<FilterCriteria | null>(null);

function UserAssessmentsViewer(props: Props): JSX.Element {
    const { userId } = props;
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef<GridApiPro>();
    const navigate = useNavigate();

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'last_reviewed',
            headerName: 'REVIEWED',
            width: 75,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams) => (
                <AssessmentReviewedCell params={params} apiRef={apiRef} />
            )
        },
        {
            field: 'flagged',
            headerName: 'Flagged',
            width: 60,
            headerClassName: 'table-column-title',
            renderCell: FlaggedCell,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.idCellRenderer,
            sortable: false
        },

        {
            field: 'friendly_name',
            headerName: 'FRIENDLY NAME',
            width: 100,
            headerClassName: 'table-column-title'
        },
        {
            field: 'code_name',
            headerName: 'CODE NAME',
            width: 150,
            headerClassName: 'table-column-title'
        },
        {
            field: 'has_notes',
            headerName: 'VIEW NOTES',
            width: 80,
            headerClassName: 'table-column-title',
            renderCell: renderViewNotesButton
        },
        {
            field: 'severity',
            headerName: 'SEVERITY',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams) => (
                <SeverityChip severity={params.value} />
            )
        },

        {
            field: 'score',
            headerName: 'SCORE',
            width: 75,
            headerClassName: 'table-column-title',
            renderCell: renderResult
        },
        {
            field: 'start_date',
            headerName: 'STARTED ON',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },
        {
            field: 'completion_date',
            headerName: 'COMPLETED ON',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },

        {
            field: 'action',
            headerName: 'ACTION',
            width: 65,
            headerClassName: 'table-column-title',
            renderCell: renderAddNotesButton
        }
    ];

    //TODO: this belongs in a distinct component
    function renderResult(params: GridRenderCellParams): JSX.Element {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box>
                    <Typography> {params.row.score}</Typography>
                </Box>
                {params.row.hasNotes && (
                    <DescriptionOutlinedIcon
                        color="secondary"
                        onClick={() => navigate(`notes/${params.id}`)}
                        sx={{ cursor: 'pointer' }}
                    />
                )}
            </Box>
        );
    }

    function generateEditAssessmentsModal(): JSX.Element {
        return <></>;
    }

    function generateAddAssessmentsModal(): JSX.Element {
        return <></>;
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen,
        handleClose,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <UserAssessmentRecordsFilterMenu
                        filters={filterCriteria}
                        handleClose={handleClose}
                        setFilters={setFilterCriteria}
                    />
                }
            />
        );
    };

    const generateTableActions = (
        selectionModel: GridSelectionModel
    ): JSX.Element => {
        const userType = localStorage.getItem('USER_TYPE');
        return userType === 'psychologist' ? (
            <UserAssessmentsTableActions userId={userId as unknown as string} />
        ) : (
            <></>
        );
    };

    const renderAddNotesButton = (
        params: GridRenderCellParams
    ): JSX.Element => {
        return (
            <>
                <IconButton
                    onClick={() => navigate(`${params.row.id}/addNote/`)}
                >
                    <NoteAddOutlinedIcon color="primary" />
                </IconButton>
            </>
        );
    };

    const renderViewNotesButton = (
        params: GridRenderCellParams
    ): JSX.Element => {
        return params.value ? (
            <>
                <IconButton onClick={() => navigate(`notes/${params.row.id}`)}>
                    <MarkEmailRead color="primary" />
                </IconButton>
            </>
        ) : (
            <></>
        );
    };

    return (
        <Box sx={{ height: '100%' }}>
            <EntityTable
                pinnedColumns={{ right: ['action'] }}
                tableKey={['assessments', userId]}
                generateColumns={generateColumns}
                api={AssessmentRecordApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                generateEditEntityModal={generateEditAssessmentsModal}
                generateAddEntityModal={generateAddAssessmentsModal}
                entityDetailModal={<AssessmentRecordViewer />}
                generateEntityFilterForm={generateFilterForm}
                generateTableActions={generateTableActions}
                apiRef={apiRef}
                selectionMode="none"
                notesModal={<AssessmentNotesModal />}
                initialState={{
                    permaFilters: {
                        subuser_id: {
                            operator: FilterOperator.EQUALS,
                            arguments: [userId as unknown as string]
                        }
                    }
                }}
            />
            <Routes>
                <Route
                    path=":entityId/addNote/"
                    element={<AddAssessmentNotesModal />}
                />
            </Routes>
        </Box>
    );
}

export default UserAssessmentsViewer;

function FlaggedCell(params: GridRenderCellParams) {
    const style = {
        // transparent background
        background: 'rgba(0,0,0,0)',
        height: 25
    };

    return params.value ? <FlagIcon color="secondary" sx={style} /> : <></>;
}

