import {
    Alert,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssessmentRecordApi } from '../../../../api/AssessmentRecordApi';
import { UserSeverity } from '../../../../structures/enums';
import { SeverityChipPlain } from '../../../Templates/SeverityChip/SeverityChip';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import { LoadingButton } from '@mui/lab';
import { mapUserSeverityToNumber } from '../../../../api/userMappers';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';

function AddAssessmentNotesModal() {
    const navigate = useNavigate();
    const params = useParams();
    const recordId = params.entityId as string;

    const [severity, setSeverity] = React.useState(UserSeverity.NORMAL);
    const [noteText, setNoteText] = React.useState('');

    const addNote = AssessmentRecordApi.addNote.useMutation();

    const record = AssessmentRecordApi.get.useQuery(recordId);

    useEffect(() => {
        if (!record.data) return
        setSeverity(record.data.severity)
    }, [record.data])


    async function handleSubmit() {
        // console.log('submitting note: ', severity, noteText);
        try {
            await addNote.mutateAsync({
                    recordId,
                    severity: mapUserSeverityToNumber(severity),
                    note: noteText
                });
            if (addNote.isError) {
                enqueueSnackbar('Add note error', {
                    variant: 'error'
                });

                throw addNote.error;
            }
            enqueueSnackbar('Note added successfully', { variant: 'success' });
            navigate(-1);
        } catch (err) {
            console.log('error submitting note: ', err);
        }
    }

    return (
        <StandardModal
            headerText={`Add Note ${params.entityId}`}
            open={true}
            handleClose={() => navigate(-1)}
        >
            <Box sx={{ boxSizing: 'border-box', height: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'row'
                    }}
                >
                    <Typography variant="body1" marginBottom={5}>
                        {dayjs().format('MMM DD YYYY h:mm A')}
                    </Typography>
                </Box>

                {record.isError && (
                    <Alert sx={{ marginBottom: 5 }} severity="error">
                        Error fetching severity:{' '}
                        {record.error?.message}
                    </Alert>
                )}
                <FormControl fullWidth sx={{ height: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            gap: 5
                        }}
                    >
                        {record.isLoading ||
                        record.isError ||
                        !record.data ? (
                            <Skeleton
                                variant="rectangular"
                                height={50}
                                width={150}
                            />
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Box>
                                        <InputLabel id="demo-simple-select-label">
                                            Severity
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={severity}
                                            label="Severity"
                                            onChange={(event) =>
                                                setSeverity(
                                                    event.target
                                                        .value as UserSeverity
                                                )
                                            }
                                        >
                                            <MenuItem
                                                value={UserSeverity.NORMAL}
                                            >
                                                <SeverityChipPlain
                                                    severity={
                                                        UserSeverity.NORMAL
                                                    }
                                                />
                                            </MenuItem>
                                            <MenuItem value={UserSeverity.LOW}>
                                                <SeverityChipPlain
                                                    severity={UserSeverity.LOW}
                                                />
                                            </MenuItem>
                                            <MenuItem
                                                value={UserSeverity.MEDIUM}
                                            >
                                                <SeverityChipPlain
                                                    severity={
                                                        UserSeverity.MEDIUM
                                                    }
                                                />
                                            </MenuItem>
                                            <MenuItem
                                                value={UserSeverity.CRITICAL}
                                            >
                                                <SeverityChipPlain
                                                    severity={
                                                        UserSeverity.CRITICAL
                                                    }
                                                />
                                            </MenuItem>
                                        </Select>
                                    </Box>
                                </Box>
                            </>
                        )}

                        <TextField
                            id="outlined-multiline-static"
                            label="Note"
                            multiline
                            rows={10}
                            placeholder="Type note"
                            value={noteText}
                            onChange={(event) =>
                                setNoteText(event.target.value)
                            }
                            fullWidth
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '10%'
                        }}
                    >
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="outlined"
                                size="medium"
                                sx={{ height: 45 }}
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                loading={addNote.isPending}
                                variant="contained"
                                disabled={noteText.length === 0}
                                size="medium"
                                sx={{ height: 45, fontColor: 'white' }}
                                color="secondary"
                                onClick={() => handleSubmit()}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box>
                </FormControl>
            </Box>
        </StandardModal>
    );
}

export default AddAssessmentNotesModal;
