/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel
} from '@mui/x-data-grid-pro';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CheckCircleFilledIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {UserApi} from '../../../../api/UserApi';
import {
    Status,
} from '../../../../structures/enums';
import { CacheContextI } from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import { SeverityChip } from '../../../Templates/EntityTable/SeverityChip';
import AddUsersModal from '../AddUserModal/AddUserModal';
import EditUserModal from '../EditUserModal/EditUserModal';
import UserDetailsModal from '../UserDetailsModal/UserDetailsModal';
import { ScheduleStatusChip } from '../UserDetailsModal/ScheduleStatusChip';
import './UserTable.css';
import UserTableFilterMenu from './UserTableFilterMenu';
import UserViewNotesModal from './UserViewNotesModal';
import { UserSummary } from '../../../../structures/userInterfaces';
import { useGridApiRef } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import FlagIcon from '@mui/icons-material/Flag';
import UserTableActions from './TableActions';
import AssignAssessmentModal from './AssignModal';

// try to implement filtering at this level.
// goal: make filter a context controlled from here
// UserTable should pass in Function that renders the filter form
// When submit is clicked on form, the UserFilterContext should be updated

const style = {
    // transparent background
    background: 'rgba(0,0,0,0)',
    height: 25
};

const tableKey = ['users'];

export default function UserTable(): JSX.Element {
    const [outOfDate, setOutOfDate] = useState(true);

    const navigate = useNavigate();

    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef<UserSummary>[] = (
        standardCellsRenderers: StandardCellRenderers
    ) => [
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: true,
            renderCell: standardCellsRenderers.entityDetailsRenderer
        },
        {
            field: 'last.status',
            valueGetter: ({ row }) => row.last.status,
            description: 'The status of the last completed assessment',
            headerName: 'STATUS',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams) => (
                <StatusCell status={params.value} />
            )
        },
        {
            field: 'last.flagged',
            valueGetter: ({ row }) => row.last.flagged,
            description: 'Whether the last completed assessment was flagged',
            headerName: 'FLAGGED',
            width: 75,
            headerClassName: 'table-column-title',
            renderCell: FlaggedCell,
            sortable: false
        },
        {
            field: 'last.severity',
            valueGetter: ({ row }) => row.last.severity,
            description: 'The severity of the last completed assessment',
            headerName: 'SEVERITY',
            renderCell: (params: GridRenderCellParams): JSX.Element => {
                return <SeverityChip severity={params.value} />;
            },
            width: 150,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'last.reviewed',
            valueGetter: ({ row }) => row.last.reviewed,
            description: 'Whether the last completed assessment has been reviewed',
            headerName: 'REVIEWED',
            width: 75,
            headerClassName: 'table-column-title',
            renderCell: ReviewedCell,
            sortable: false
        },
        {
            field: 'email',
            headerName: 'EMAIL',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'groups',
            headerName: 'GROUPS',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'organizationNames',
            headerName: 'ORGANIZATIONS',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        }
    ];

    function generateEditUserModal(
        selectedEntities: GridSelectionModel
    ): JSX.Element {
        return (
            <EditUserModal
                open={true}
                handleClose={() => navigate('/users/')}
                selectionModel={selectedEntities}
            />
        );
    }

    function generateAddOrganizationsModal(): JSX.Element {
        return (
            <AddUsersModal
                open={true}
                handleClose={() => navigate('/users/')}
            />
        );
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen: boolean,
        handleClose: Function,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <UserTableFilterMenu
                        setFilters={setFilterCriteria}
                        filters={filterCriteria}
                        handleClose={handleClose}
                    />
                }
            />
        );
    };

    function generateTableActions(
        selectionModel: GridSelectionModel
    ): JSX.Element {
        return (
            <UserTableActions
                selectionModel={selectionModel}
            />
        );
    }


    const getSortedSummaries = UserApi.summaries.fetchQuery()

    const api = {
        getSortedSummaries
    }

    return (
        <>
        <Routes>
            <Route path="assign" element={<AssignAssessmentModal open={true} handleClose={() => navigate(-1)} />} />
        </Routes>
        <EntityTable
            pinnedColumns={{ right: ['action'] }}
            tableKey={tableKey}
            generateColumns={generateColumns}
            generateEditEntityModal={generateEditUserModal}
            generateAddEntityModal={generateAddOrganizationsModal}
            entityDetailModal={<UserDetailsModal />}
            generateEntityFilterForm={generateFilterForm}
            api={api}
            outOfDate={outOfDate}
            setOutOfDate={setOutOfDate}
            generateTableActions={generateTableActions}
            notesModal={<UserViewNotesModal />}
            apiRef={apiRef}
        />
        </>
    );
}

// NOTE:
// explicit empty node (<></>) required instead of null | undefined
// for following cell renderers.
// MUI renders result of valueGetter if result value is null or undefined

function StatusCell({ status }: { status?: Status }) {
    return status ? <ScheduleStatusChip status={status} /> : <></>;
}

function FlaggedCell(params: GridRenderCellParams) {
    return params.value ? <FlagIcon color="secondary" sx={style} /> : <></>;
}

function ReviewedCell(params: GridRenderCellParams) {
    let elem = <></>;
    switch (params.value) {
        case false:
            // explicit false: not reviewed yet
            elem = <CircleOutlinedIcon />;
            break;
        case true:
            // explicit true: reviewed
            elem = <CheckCircleFilledIcon color="success" />;
            break;
        case null:
            // null: no assessments to review (no assessments completed or last assessment not completed)
            elem = <></>;
            break;
    }

    return elem;
}


