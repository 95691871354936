import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, List, ListItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import ProfileApi from '../../../api/ProfileApi';
import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';
YupPassword(yup); // extend yup

interface CodeFormI {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const loginSchema: yup.SchemaOf<CodeFormI> = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup.string().password().required(),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), ''], 'does not match password')
    .required()
});
interface Props {
  handleProfileUpdate: () => void;
}

export function EditPasswordForm({ handleProfileUpdate }: Props) {
  const { loading, error, execute } = useAsyncRequest(
    ProfileApi.singleton.editPassword,
    ProfileApi.singleton
  );

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CodeFormI>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  });

  const formSubmitHandler: SubmitHandler<CodeFormI> = async (
    data: CodeFormI
  ) => {
    try {
      const result = await execute(
        data.currentPassword,
        data.newPassword,
        data.confirmNewPassword
      );
      if (result.error == null) {
        handleProfileUpdate();
      } else {
        throw result.error;
      }
    } catch (e) {
      console.log('could not change password', e);
    }
  };

  const onInvalid = (errors: unknown): void =>
    console.error('houston we got em', errors);

  return (
    <Box>
      <Typography variant="body1">Requirements</Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="body1">Minimum 8 characters</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant="body1">
            Must include at least one: uppercase letter, number, and symbol{' '}
          </Typography>
        </ListItem>
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(formSubmitHandler, onInvalid)}
          noValidate
          sx={{ mt: 1 }}
          width={'100%'}
        >
          <TextField
            margin="normal"
            fullWidth
            id="currentPassword"
            label="Current password"
            autoFocus
            autoComplete="current-password"
            type="password"
            error={errors.currentPassword != null}
            helperText={errors.currentPassword?.message ?? ''}
            required
            {...register('currentPassword')}
          />
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="New password"
            autoFocus
            autoComplete="current-password"
            type="password"
            error={errors.newPassword != null}
            helperText={errors.newPassword?.message ?? ''}
            required
            {...register('newPassword')}
          />
          <TextField
            margin="normal"
            fullWidth
            id="confirmPassword"
            label="Confirm new password"
            autoFocus
            autoComplete="current-password"
            type="password"
            required
            error={errors.confirmNewPassword != null}
            helperText={errors.confirmNewPassword?.message ?? ''}
            {...register('confirmNewPassword')}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Confirm
          </LoadingButton>
          {error && <Alert severity="error">{error.message}</Alert>}
        </Box>
      </Box>
    </Box>
  );
}
