import React from 'react';
import './PageTabs.css';
import { Link, useLocation } from 'react-router-dom';

import { TabI } from '../../../structures/interfaces';
import { Box, Tab, Tabs } from '@mui/material';

interface Props {
  tabs: TabI[];
}

function PageTabs(props: Props): JSX.Element {
  const { tabs } = props;
  const [value, setValue] = React.useState(0);
  const location = useLocation();

    // FIXME: find a better way to do this (set tab value onClick?) | use react-router matching
  React.useEffect(() => {
    tabs.forEach((tab, i) => {
      const splitRoute = location.pathname.split('/');
      const temp = tab.link?.split('/');
      const resolvedTabLink = temp?.[temp?.length - 2];
      if (splitRoute[splitRoute.length - 2] === resolvedTabLink) {
        setValue(i);
      }
    });
  }, [location, setValue]);

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        height: 'inherit',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
      }}
    >
      <Tabs
        value={value}
        indicatorColor="secondary"
        aria-label="disabled tabs example"
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />
        }}
        sx={{
          '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'secondary.main'
          },
          height: 'inherit'
        }}
      >
        {tabs.map((obj, i) => {
          return (
            <Tab
              key={i}
              label={obj.name}
              value={i}
              to={obj.link}
              icon={<obj.icon />}
              iconPosition="start"
              component={Link}
              sx={{
                '&.Mui-selected': {
                  color: 'background.default'
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'rgba(100, 95, 228, 0.32)'
                }
              }}
            />
          );
        })}
      </Tabs>
    </Box>

    // theme.palette.background.paper

    // <Box className="tabs-cont">
    //   {tabs.map((obj, i) => {
    //     return (
    //       <Box sx={{width: "250px"}} key={i}>
    //         <PageTab
    //           tab={obj}
    //           Icon={obj.icon}
    //           active={activeTab === i}
    //         ></PageTab>
    //       </Box>
    //     );
    //   })}
    // </Box>
  );
}

export default PageTabs;
