/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
    GridColDef,
    GridColumnGroupingModel,
    GridRenderCellParams,
    GridSelectionModel,
    GridSortModel
} from '@mui/x-data-grid';
import {
    DataGridPro,
    GridFetchRowsParams,
    GridPinnedColumns
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import { TableRows } from '../../../api/EntityApi';
import { ActivationStatus, TaskStatus } from '../../../structures/enums';
import {
    EntityTableRequest,
    FilterCriteria
} from '../../../structures/interfaces';

import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import dayjs from 'dayjs';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import GreenRedPill from '../GreenRedPill/GreenRedPill';
import EntityTableControls from './EntityTableControls';
import { ResponsibleDate } from './ResponsibleDate';
import {
    OrgStatus,
    OrganizationStatus
} from '../../../structures/organizationEnums';
import { UserIdToUser } from './userIdToUser/UserIdToUser';
import { useParams } from 'react-router-dom';
import {
    QueryKey,
    useInfiniteQuery,
    useQueryClient,
    hashKey
} from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { create } from 'zustand';
import _, { debounce } from 'lodash';
import { resolveFilters } from '../../../helpers/useResolveFilters';

type TableState = {
    searchValue: string;
    filters: FilterCriteria;
    permaFilters: FilterCriteria;
    columnReorders: Map<number, number>;
    sortModel: GridSortModel;
    selectionModel: GridSelectionModel;
    _initialState?: Partial<TableState>;
};

type TableActions = {
    setSearchValue: (value: string) => void;
    setFilters: (value: FilterCriteria) => void;
    clearFilters: () => void;
    updateInitialState: (value: Partial<TableState>) => void;
    addColumnReoder: (oldIndex: number, newIndex: number) => void;
    applyColumnReorders: (columns: GridColDef[]) => GridColDef[];
    setSortModel: (value: GridSortModel) => void;
    setSelectionModel: (value: GridSelectionModel) => void;
};

const createTableStore = (initialState?: Partial<TableState>) =>
    create<TableState & TableActions>()((set, get) => ({
        searchValue: '',
        filters: {},
        permaFilters: {},
        columnReorders: new Map(),
        sortModel: [],
        selectionModel: [],
        ...initialState,
        _initialState: initialState,
        setSearchValue: (value: string) => set({ searchValue: value }),
        setFilters: (value: FilterCriteria) =>
            set({
                filters: resolveFilters(value)
            }),
        clearFilters: () => set({ filters: {} }),
        updateInitialState: (value: Partial<TableState>) =>
            set({ ...value, _initialState: value }),
        addColumnReoder: (oldIndex: number, newIndex: number) =>
            set({
                columnReorders: new Map(get().columnReorders).set(
                    oldIndex,
                    newIndex
                )
            }),
        applyColumnReorders: (cols) => {
            const reorders = get().columnReorders;
            reorders.forEach((newIndex, oldIndex) => {
                const col = cols[oldIndex];
                cols.splice(oldIndex, 1);
                cols.splice(newIndex, 0, col);
            });
            return cols;
        },
        setSortModel: (value: GridSortModel) => set({ sortModel: value }),
        setSelectionModel: (value: GridSelectionModel) =>
            set({ selectionModel: value })
    }));

const useKeyedTableStores = create<Record<string, UseTableStore | undefined>>(
    () => ({})
);

function getStoreFromKey(key_: Key) {
    const key = hashKey(key_);
    const store = useKeyedTableStores((state) => state[key]);
    return store;
}

function useKeyedTableStore(keyArr: Key, initialState?: Partial<TableState>) {
    let store = getStoreFromKey(keyArr);
    if (!store) {
        store = createTableStore(initialState);
        useKeyedTableStores.setState({ [hashKey(keyArr)]: store });
    }
    return store;
}

export function useTableSelection(key: Key) {
    const store = getStoreFromKey(key);
    const selection = store?.((s) => s.selectionModel);
    return selection;
}

type UseTableStore = ReturnType<typeof createTableStore>;

export interface StandardCellRenderers {
    idCellRenderer: (params: GridRenderCellParams) => JSX.Element;
    entityDetailsRenderer: (params: GridRenderCellParams) => JSX.Element;
    renderError: (params: GridRenderCellParams) => JSX.Element;
    renderDate: (params: GridRenderCellParams) => JSX.Element;
    renderStatusCell: (params: GridRenderCellParams) => JSX.Element;
    renderResponsibleDate: (params: GridRenderCellParams) => JSX.Element;
    renderUser: (params: GridRenderCellParams) => JSX.Element;
}

const PAGE_SIZE = 100;

export type GenerateEntityFilterForm = (
    isOpen: boolean,
    handleClose: () => void,
    filters: FilterCriteria,
    setFilters: (value: FilterCriteria) => void
) => JSX.Element;

type Key = readonly unknown[];

type Api = {
    getSortedSummaries(req: EntityTableRequest): Promise<TableRows<Object>>;
};

interface Props {
    tableKey: Key;
    generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[];
    generateEditEntityModal?: (
        selectedEntities: GridSelectionModel
    ) => JSX.Element;
    generateAddEntityModal?: () => JSX.Element;
    entityDetailModal?: React.ReactNode;
    generateEntityFilterForm?: GenerateEntityFilterForm;
    api: Api;
    outOfDate: boolean;
    setOutOfDate: Function;
    generateTableActions: (selectionModel: GridSelectionModel) => JSX.Element;
    selectionMode?: string;
    notesModal?: JSX.Element;
    apiRef: React.MutableRefObject<GridApiPro>;
    pinnedColumns?: GridPinnedColumns;
    initialState?: Partial<TableState>;
    columnGroupingModel?: GridColumnGroupingModel;
}

// TODO: create useLazyPagination hook to extract pagination logic
// with: requestRows request,
export default function EntityTable(props: Props): JSX.Element {
    const {
        generateColumns,
        generateAddEntityModal,
        generateEditEntityModal,
        entityDetailModal,
        api,
        generateEntityFilterForm,
        selectionMode,
        generateTableActions,
        notesModal,
        apiRef,
        pinnedColumns,
        initialState: _initialState,
        columnGroupingModel,
        tableKey: key
    } = props;

    const useTableStore = useKeyedTableStore(key, _initialState);

    const sortModel = useTableStore((s) => s.sortModel);
    const setSortModel = useTableStore((s) => s.setSortModel);

    const searchValue = useTableStore((state) => state.searchValue);
    const setSearchValue = useTableStore((state) => state.setSearchValue);

    const filters = useTableStore((state) => state.filters);
    const setFilters = useTableStore((s) => s.setFilters);
    const permaFilters = useTableStore((state) => state.permaFilters);

    const totalFilters = useMemo(() => {
        return { ...permaFilters, ...filters };
    }, [filters, permaFilters]);

    const clearFilters = useTableStore((state) => state.clearFilters);

    // we only fetch summaries here
    const queryKey = [...key, 'summaries'];

    const applyColumnReorders = useTableStore((s) => s.applyColumnReorders);

    const columns = useMemo(
        () =>
            applyColumnReorders(
                generateColumns({
                    idCellRenderer,
                    entityDetailsRenderer,
                    renderError,
                    renderDate,
                    renderStatusCell,
                    renderResponsibleDate,
                    renderUser
                })
            ),
        // purposefully don't rerender when applyColumnReorders changes
        // applyColumnReorders intended to only be used to restore column
        // ordering, not handle column reordering
        [generateColumns]
    );

    const addColumnReoder = useTableStore((s) => s.addColumnReoder);

    const handleColumnReoder = useCallback(
        (oldIndex: number, newIndex: number) => {
            if (selectionMode !== 'none') {
                oldIndex -= 1;
                newIndex -= 1;
            }
            addColumnReoder(oldIndex, newIndex);
        },
        [addColumnReoder, selectionMode]
    );

    const [selectionModel, setSelectionModel] = useTableStore((state) => [
        state.selectionModel,
        state.setSelectionModel
    ]);

    // set to page size to have datagrid render skeleton rows while loading
    const [rowCount, setRowCount] = useState(PAGE_SIZE);
    const MAX_PAGE = pageOfRow(rowCount);
    // not actually used to store rows,
    // just to trigger data-grid to rerender
    // (except when #rows is less than PAGE_SIZE, then it is used to remove excess skeleton rows)
    const [rowsRef, setRowsRef] = useState<any[]>([]);
    const resetRows = useCallback(() => {
        setRowsRef(() => []);
    }, [setRowsRef]);

    const pageQueue = usePageQueue([1]);

    const updateCacheEntries = useListCache(queryKey);

    // used to clear component cache when filters/searchValue/sorModel etc change
    const clearState = function clearState() {
        void pageQueue.clear();
        resetRows();
        tryScrollToTop();
    }

    function tryScrollToTop() {
        try {
            void apiRef?.current?.scrollToIndexes({ rowIndex: 0 });
            return true;
        } catch (_e) {
            return false;
        }
    }

    const onRowOrderChanged = useCallback(debounce(() => clearState(), 200), [clearState])
    const rowOrderChanged = useAnyChanged([sortModel, searchValue, filters]);
    if (rowOrderChanged) {
        onRowOrderChanged();
    }

    const query = useInfiniteQuery({
        queryFn: (ctx) => fetchPage(ctx.pageParam),
        queryKey: [...queryKey, totalFilters, sortModel, searchValue],
        initialPageParam: 0,
        placeholderData: (data) => data,
        getNextPageParam: (): number | undefined => {
            const next = pageQueue.peek();
            if (next && next > MAX_PAGE) {
                void pageQueue.pop(next);
                return;
            }
            return next;
        }
    });

    const updateRows = useCallback(
        (start: number, rows: any[]) => {
            if (!apiRef || !apiRef.current) return;
            apiRef.current.unstable_replaceRows(start, rows);
        },
        [apiRef]
    );

    async function fetchPage(page: number) {
        const firstRow = page * PAGE_SIZE;
        const res = await api.getSortedSummaries({
            filters: totalFilters,
            firstRow,
            lastRow: firstRow + PAGE_SIZE,
            sortModel: sortModel,
            searchQuery: searchValue
        });

        if (res.totalRows !== rowCount) {
            if (res.totalRows < PAGE_SIZE) {
                setRowsRef(() => res.rows);
            } else if (res.totalRows < rowCount) {
                // api always returns the same total rows for the same query
                // so this should only happen on the first page load
                console.assert(page === 0, `page = ${page} not 0`);
                clearState();
                void (await query.refetch({ cancelRefetch: true }));
            }
            setRowCount(() => res.totalRows);
        }
        updateRows(firstRow, res.rows);
        updateCacheEntries(res.rows as { id: string | number }[]);
        void pageQueue.pop(page);
        return Object.assign(res, { page });
    }

    function handleSelectionChange(
        newSelectionModel: GridSelectionModel
    ) {
        let selected = newSelectionModel;
        if (selectionMode === 'single') {
            // slice containing last selected item
            selected = newSelectionModel.slice(-1)
        }
        setSelectionModel(selected);
    }

    function handleRowsRequest(params: GridFetchRowsParams) {
        const page = pageOfRow(params.firstRowToRender);
        const lastPage = pageOfRow(params.lastRowToRender);
        // NOTE: case where request spans more than two pages (page +1 < lastPage) is not handled
        // if PAGE_SIZE is decreased or table displays more than PAGE_SIZE rows this will be a problem
        // but it's not a problem now :)

        if (lastPage !== page) {
            pageQueue.push([lastPage, page]);
            return;
        }
        pageQueue.push(page);
    }

    const shouldFetchNextPage = query.hasNextPage && !query.isRefetching;
    if (shouldFetchNextPage) {
        void query.fetchNextPage();
    }

    const sx = useEntityTableSx(selectionMode);

    const wrappedGenerateEntityFilterForm = generateEntityFilterForm && (
        (isOpen: boolean, handleClose: () => void) => generateEntityFilterForm(isOpen, handleClose, filters, setFilters)
    )

    return (
        <Box
            sx={{
                height: '100%'
            }}
        >
            <EntityTableControls
                generateEntityFilterForm={
                    wrappedGenerateEntityFilterForm
                }
                appliedFilters={filters}
                clearFilters={clearFilters}
                tableActions={generateTableActions(selectionModel)}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
            />
            <EntityTableRoutes
                notesModal={notesModal}
                generateAddEntityModal={generateAddEntityModal}
                generateEditEntityModal={generateEditEntityModal}
                selectionModel={selectionModel as string[]}
                entityDetailModal={entityDetailModal}
            />
            <DataGridPro
                pinnedColumns={pinnedColumns}
                apiRef={apiRef}
                rows={rowsRef}
                hideFooterPagination
                rowCount={rowCount}
                columns={columns}
                onSortModelChange={setSortModel}
                sortingMode="server"
                filterMode="server"
                rowsLoadingMode="server"
                checkboxSelection={selectionMode !== 'none'}
                experimentalFeatures={{
                    lazyLoading: true,
                    columnGrouping: !!columnGroupingModel
                }}
                onFetchRows={handleRowsRequest}
                onSelectionModelChange={handleSelectionChange}
                getRowId={(row) => row.id}
                selectionModel={selectionModel}
                onColumnOrderChange={(params) =>
                    handleColumnReoder(params.oldIndex, params.targetIndex)
                }
                columnGroupingModel={columnGroupingModel}
                sx={sx}
                disableColumnMenu
                disableSelectionOnClick
                disableMultipleSelection
            />
            {query.isError && (
                <Alert severity="error">{query.error.message}</Alert>
            )}
        </Box>
    );
}

function usePageQueue(initial: number[]) {
    const [data, set] = useState<number[]>(initial);

    const push = useCallback(
        (_pages: number | number[]) => {
            const pages = Array.isArray(_pages) ? _pages : [_pages];
            const updated = data.filter((p) => !pages.includes(p));
            updated.push(...pages);
            set(updated);
        },
        [data]
    );

    const pop = useCallback(
        (value: number) => {
            const nextIdx = data.lastIndexOf(value);
            if (nextIdx === -1) return;
            const next = data[nextIdx];
            set(data.filter((_, i) => i !== nextIdx));
            return next;
        },
        [data]
    );

    const clear = useCallback(() => {
        set([]);
    }, [data]);

    const peek = useCallback(() => data.at(-1), [data]);

    return {
        push,
        pop,
        clear,
        peek,
        data
    };
}

function pageOfRow(row: number) {
    if (row < PAGE_SIZE) {
        return 0;
    }
    const page = Math.floor(row / PAGE_SIZE);

    if (row % PAGE_SIZE === 0) {
        return page - 1;
    }
    return page;
}

function useListCache(key: QueryKey) {
    const client = useQueryClient();

    const update = useCallback(
        <Item extends { id: string | number }>(items: Item[]) => {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                client.setQueryData([...key, item.id], item);
            }
        },
        [client, client.setQueryData]
    );

    return update;
}

function EditEntityRoute(props: {
    generateModal: (id: string[]) => JSX.Element;
}): JSX.Element {
    const params = useParams();
    const entityId = params.entityId;
    if (!entityId) {
        const navigate = useNavigate();
        navigate(-1);
        return <></>;
    }
    return props.generateModal([entityId]);
}

function renderResponsibleDate(params: GridRenderCellParams): JSX.Element {
    return (
        <ResponsibleDate
            date={params.value.date as string}
            authorId={params.value.authorId}
        />
    );
}

function renderUser(params: GridRenderCellParams): JSX.Element {
    return <UserIdToUser userId={params.value} />;
}

function renderStatusCell(params: GridRenderCellParams): JSX.Element {
    const goodStatuses = [
        TaskStatus.COMPLETE,
        ActivationStatus.ACTIVE,
        OrganizationStatus.ACTIVE,
        OrgStatus.OPEN
    ];
    const isGood = goodStatuses.includes(params.value);
    return <GreenRedPill good={isGood} text={params.value} />;
}

function renderError(): JSX.Element {
    console.error('entity table cell render error');
    return <div>RENDER ERROR</div>;
}

function idCellRenderer(params: GridRenderCellParams): JSX.Element {
    return (
        <Button component={Link} to={`${params.value as string}/details/`}>
            {params.value.slice(0, 8)}
        </Button>
        // <Link to={`${params.value as string}`}>{params.value}</Link>
    );
}

function renderDate(params: GridRenderCellParams): JSX.Element {
    return (
        <Box>
            <Typography variant="body1">
                {params.value
                    ? dayjs(params.value).format('MM/DD/YYYY')
                    : 'N/A'}
            </Typography>
        </Box>
    );
}

function entityDetailsRenderer(params: GridRenderCellParams): JSX.Element {
    const navigate = useNavigate();
    function handleClick() {
        navigate(`${params.row.id}/details`);
    }
    return (
        <Button sx={{ textTransform: 'none' }} onClick={() => handleClick()}>
            {params.formattedValue}
        </Button>
    );
}

type EntityTableRoutesProps = {
    entityDetailModal: React.ReactNode;
    generateEditEntityModal?: (id: string[]) => JSX.Element;
    generateAddEntityModal?: () => JSX.Element;
    selectionModel: string[];
    notesModal?: JSX.Element;
};

function EntityTableRoutes(props: EntityTableRoutesProps) {
    return (
        <Routes>
            <Route
                path="/:entityId/details/*"
                element={props.entityDetailModal}
            />
            {!!props.generateEditEntityModal && (
                <>
                    <Route
                        path="/:entityId/edit/*"
                        element={
                            <EditEntityRoute
                                generateModal={props.generateEditEntityModal}
                            />
                        }
                    />
                    <Route
                        path="edit/*"
                        element={props.generateEditEntityModal(
                            props.selectionModel
                        )}
                    />
                </>
            )}
            {!!props.generateAddEntityModal && (
                <Route path="add/*" element={props.generateAddEntityModal()} />
            )}

            {!!props.notesModal && (
                <Route
                    path="/notes/:assessmentRecordId/*"
                    element={props.notesModal}
                />
            )}
        </Routes>
    );
}

function useEntityTableSx(selectionMode?: string) {
    const columnHeaderCss = '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer';
    const sx: Record<string, string | {display: string}> = {
        height: '90%',
        minHeight: '600px',
        width: '100%',
    }
    if (selectionMode === 'single') {
        sx[columnHeaderCss] = {
            display: 'none'
        }
    }
    return sx;
}

function useAnyChanged<TDeps extends readonly any[]>(deps: TDeps) {
    const depsRef = React.useRef(deps);
    const changed = depsRef.current.some((d, i) => d !== deps[i]);
    if (changed) {
        depsRef.current = deps;
    }
    return changed
}

