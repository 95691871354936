/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mutation, query, sendRequest, SendRequestOptions } from './SeqApi';
import {
    EntityTableRequest,
} from '../structures/interfaces';
import { TableRows } from './EntityApi';
import { GridRowId } from '@mui/x-data-grid';
import {
    Assessment,
    AssessmentSummaryResponse,
    AssessmentSummary,
    Question
} from '../structures/assessmentInterfaces';
import { RequestResponse } from '../structures/responses';
import { mapAssessmentsToAssessmentSummaries } from './assessmentMappers';
import { EntityTypes } from '../structures/enums';

export interface UserAssessmentsRequest {
    userId: number;
}

export interface AssessmentReviewedStatus {
    id: number;
    reviewed: boolean;
}

export * as AssessmentApi from './AssessmentApi';

const PROCESS_NAME = 'assessment';

export async function getSortedSummaries(
    pageReq: EntityTableRequest
): Promise<TableRows<AssessmentSummary>> {
    const variables: { [key: string]: any } = {
        action: 'get_assessment_summaries',
        ...pageReq
    };
    try {
        const response = await sendRequest(variables, PROCESS_NAME, undefined, {
            mockRequest: false
        } as SendRequestOptions);
        const data = response.data as unknown as RequestResponse<
            TableRows<AssessmentSummaryResponse>
        >;
        if (data.status !== 'success') throw new Error(data.message);
        const rows = mapAssessmentsToAssessmentSummaries(
            data.resp_variables.rows
        );
        return {
            rows: rows,
            totalRows: data.resp_variables.totalRows
        };
    } catch (err) {
        console.log('could not retrieve assessments');
        console.log(err);
        throw err;
    }
}

export const summaries = query<TableRows<AssessmentSummaryResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
        action: 'get_assessment_summaries',
        ...pageReq
    }),
    processName: PROCESS_NAME,
    queryKey: (pageReq) => [PROCESS_NAME, 'summaries', pageReq],
    transform: (r) => ({
        rows: mapAssessmentsToAssessmentSummaries(r.rows),
        totalRows: r.totalRows
    })
});

// NOTE: unused
export const questions = query<Question[]>()({
    variables: (assessmentId: string) => ({
        action: 'get_assessment_questions',
        assessment_id: assessmentId
    }),
    processName: PROCESS_NAME,
    queryKey: (assessmentId) => ['assessment', assessmentId, 'questions'],
    transform: (r) => r
});

type AssessmentWithoutQuestions = Assessment & {questions: never[]};

export const update = mutation<{ assessment_id: string }>()({
    variables: (attributes: AssessmentWithoutQuestions) => ({
        action: 'update_assessment',
        assessment: attributes
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

// TODO: verify status in response is AssessmentStatus type
export const changeStatus = mutation<{assessment_id: string, status: string}>()({
    variables: (params: {id: GridRowId, retired: number}) => ({
        action: 'change_assessment_status',
        assessment_id: params.id,
        retired: params.retired
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

// TODO: figure out response type
export const attachQuestions = mutation<any>()({
    variables: (params: {assessment_id: string, questions: Question[]}) => ({

            action: 'attach_questions_to_assessment',
            ...params
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
})

export const get = query<Assessment>()({
    variables: (id: GridRowId) => ({
        action: 'get_assessment',
        assessment_id: id
    }),
    processName: PROCESS_NAME,
    queryKey: (id) => ['assessment', id],
    transform: (r) => r
});

export const filterOptions = query<{options: string[]}>()({
    variables: (filterField: string) => ({
        action: 'get_filter_options',
        entityType: EntityTypes.ASSESSMENTS,
        filterField: filterField
    }),
    processName: 'data',
    queryKey: (filterField) => ['filterOptions', EntityTypes.ASSESSMENTS, filterField],
    transform: (r) => r.options
});

// NOTE: not used
export const getBulk = query<AssessmentSummaryResponse[]>()({
    variables: (assessment_ids: string[]) => ({
        action: 'get_bulk_assessments',
        assessment_ids
    }),
    processName: PROCESS_NAME,
    queryKey: (assessment_ids) => [PROCESS_NAME, ...assessment_ids],
    transform: (r) => mapAssessmentsToAssessmentSummaries(r)
});
