/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel,
    useGridApiRef
} from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import * as React from 'react';
import { FilterCriteria } from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import './UserAssignedAssessmentsViewer.css';
import { FilterOperator } from '../../../../structures/enums';
import UserAssignedAssessmentsFilterMenu from './UserAssignedAssessmentsFilterMenu';
import { UserAssignedAssessmentsTableActions } from './UserAssignedAssessmentsTableActions';
import { UserAssessmentApi } from '../../../../api/UserAssessmentApi';
import { ScheduleStatusChip } from './ScheduleStatusChip';

interface Props {
    userId: number | null;
}

export const UserAsessmentsFilterContext =
    React.createContext<FilterCriteria | null>(null);

function UserAssignedAssessmentsViewer(props: Props): JSX.Element {
    const { userId } = props;
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.idCellRenderer,
            sortable: false
        },
        {
            field: 'friendlyName',
            headerName: 'FRIENDLY NAME',
            width: 140,
            headerClassName: 'table-column-title'
        },
        {
            field: 'codeName',
            headerName: 'CODE NAME',
            width: 250,
            headerClassName: 'table-column-title'
        },
        {
            field: 'status',
            headerName: 'STATUS',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams) => (
                <ScheduleStatusChip status={params.value} />
            )
        },
        {
            field: 'assignment_date',
            headerName: 'ASSIGNED ON',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },
        {
            field: 'assigned_by',
            headerName: 'ASSIGNED BY',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderUser,
            sortable: false
        },
        {
            field: 'start_date',
            headerName: 'STARTED ON',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },
        {
            field: 'end_date',
            headerName: 'ENDED ON',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },
        {
            field: 'expire_date',
            headerName: 'EXPIRES ON',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        }
    ];

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen,
        handleClose,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <UserAssignedAssessmentsFilterMenu
                        filters={filterCriteria}
                        handleClose={handleClose}
                        setFilters={setFilterCriteria}
                    />
                }
            />
        );
    };

    const generateTableActions = (
        selectionModel: GridSelectionModel
    ): JSX.Element => {
        const userType = localStorage.getItem('USER_TYPE');
        return userType === 'psychologist' ? (
            <UserAssignedAssessmentsTableActions />
        ) : (
            <></>
        );
    };

    return (
        <Box sx={{ height: '100%' }}>
            <EntityTable
                tableKey={['assigned-assessments', userId]}
                generateColumns={generateColumns}
                api={UserAssessmentApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                generateEditEntityModal={() => <></>}
                generateAddEntityModal={() => <></>}
                entityDetailModal={<></>}
                generateEntityFilterForm={generateFilterForm}
                generateTableActions={generateTableActions}
                apiRef={apiRef}
                selectionMode="none"
                initialState={{
                    permaFilters: {
                        subuser_id: {
                            operator: FilterOperator.EQUALS,
                            arguments: [userId as unknown as string]
                        }
                    }
                }}
            />
        </Box>
    );
}

export default UserAssignedAssessmentsViewer;
