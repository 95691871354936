/* eslint-disable @typescript-eslint/no-empty-interface */
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { Liaison } from '../../../../structures/userInterfaces';
import { AddLiaisonForm } from './AddLiaisonForm';
import { AddedPrimaryLiaisonInfo } from './AddedPrimaryLiaisonInfo';
import { LiaisonPicker } from './LiaisonPicker';

interface Props {
  updateAddedLiaisons: (liaisons: Liaison[]) => void;
  currentAddedLiaisons: Liaison[];
}
export const AddLiaison = (props: Props): JSX.Element => {
  const { updateAddedLiaisons, currentAddedLiaisons } = props;
  const [value, setValue] = useState(0);

  const handleAddLiaison = (addedLiaison: Liaison) => {
    // Check for duplicate IDs
    // console.log('added liaison', addedLiaison);
    // console.log(
    //   ' current Added liaison ids',
    //   currentAddedLiaisonIds,
    //   typeof currentAddedLiaisonIds
    // );

    const existingLiaisonId = currentAddedLiaisons?.find((liaison) => {
      if (liaison?.subuser_id === 'new') return false;
      return liaison?.subuser_id === addedLiaison?.subuser_id;
    });

    // const duplicateLiaison = currentAddedLiaisons.find((liaison) => {
    //   if (addedLiaison?.email === liaison.email) return true;
    //   return false;
    // });

    if (existingLiaisonId) {
      console.error(
        `Liaison with id ${addedLiaison?.subuser_id} already exists.`
      );
      return;
    }

    const updatedLiaisons = [...currentAddedLiaisons, addedLiaison];
    //update the org form
    updateAddedLiaisons(updatedLiaisons);
    //update the local state
  };

  const handleDeleteLiaison = (liaisonId: string) => {
    //remove from the organization form context
    const updatedLiaisons = currentAddedLiaisons.filter(
      (currentLiaison) => currentLiaison?.subuser_id !== liaisonId
    );
    //update the org form
    updateAddedLiaisons(updatedLiaisons);
    //update the local state
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Existing Liaison" />
        <Tab label="Add Liaison" />
      </Tabs>
      {value === 0 && <LiaisonPicker handleAddLiaison={handleAddLiaison} />}
      {value === 1 && <AddLiaisonForm handleAddLiaison={handleAddLiaison} />}
      <Box>
        <Stack spacing={2} marginTop={2}>
          {currentAddedLiaisons.map((addedLiaison, i) => (
            <AddedPrimaryLiaisonInfo
              key={addedLiaison.subuser_id + i}
              liaison={addedLiaison}
              handleDeleteLiaison={handleDeleteLiaison}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
