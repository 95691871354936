import { Chip, useTheme } from '@mui/material';
import { UserSeverity } from '../../../../structures/enums';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { ValueChangeWithAuthor } from '../../../../structures/interfaces';

interface Props {
  severityData: ValueChangeWithAuthor<UserSeverity>;
}
export const AuthoredSeverityChangeCell = (props: Props): JSX.Element => {
  const { severityData } = props;
  const severity = severityData.after;
  const author =
    severityData.before === severityData.after
      ? severityData.authorId
      : undefined;
  const theme = useTheme();
  let backgroundColor: string;

  switch (severity) {
    case UserSeverity.LOW:
      backgroundColor = theme.palette.warning.light;
      break;
    case UserSeverity.NORMAL:
      backgroundColor = theme.palette.success.main;
      break;
    case UserSeverity.MEDIUM:
      backgroundColor = theme.palette.warning.main;
      break;
    case UserSeverity.CRITICAL:
      backgroundColor = theme.palette.error.light;
      break;
    default:
      backgroundColor = theme.palette.error.light;
  }
  const style = {
    background: backgroundColor,
    height: 25
  };
  return (
    <Chip
      icon={author ? <AccountCircleOutlinedIcon /> : <></>}
      label={severity}
      sx={style}
    />
  );
};
