import { EntityTableRequest } from '../structures/interfaces';
import {
    AssignAssessmentToSubuserRequest,
    SubUserBulkResponse,
    SubUserResponse,
    UpdateSubUserRequest,
    UserSummary,
    UserSummaryResponse
} from '../structures/userInterfaces';

import { TableRows } from './EntityApi';
import {
    SendRequestOptions,
    formatDateForBackend,
    sendRequest,
    query,
    mutation
} from './SeqApi';
import { UserNameResponse } from '../structures/userInterfaces';
import {
    mapSubUserBulkResponseToSubUsers,
    mapUserSummaryResponseToFrontEnd
} from './userMappers';
import {
    BaseOrganization,
    UserGroupsResponse
} from '../structures/organizationInterfaces';
import { isProd } from 'src/helpers/is-prod';
import z from 'zod';

export * as UserApi from './UserApi';

const PROCESS_NAME = 'subuser';

export const groups = query<UserGroupsResponse>()({
    variables: (subuser_id: string) => ({
        action: 'get_subuser_groups',
        subuser_id
    }),
    queryKey: (subuser_id) => [PROCESS_NAME, subuser_id, 'groups'],
    processName: PROCESS_NAME,
    transform: (r) =>
        r.groups.map((g) => ({
            organization: {
                id: g.org_id,
                name: g.org_name
            },
            group: { name: g.group_name, id: g.group_id, category: g.category }
        }))
});

export async function getSortedSummaries(
    pageReq: EntityTableRequest
): Promise<TableRows<UserSummary>> {
    const variables = {
        action: 'get_subuser_summary',
        ...pageReq
    };
    try {
        const response = await sendRequest<TableRows<UserSummaryResponse>>(
            variables,
            'subuser',
            undefined,
            {
                mockRequest: false
            } as SendRequestOptions
        );

        const data = response.data;

        return {
            rows: mapUserSummaryResponseToFrontEnd(data.resp_variables.rows),
            totalRows: data.resp_variables.totalRows
        };
    } catch (err) {
        console.log('could not get sorted user summaries');
        throw err;
    }
}

export const summaries = query<TableRows<UserSummaryResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
        action: 'get_subuser_summary',
        ...pageReq
    }),
    processName: PROCESS_NAME,
    queryKey: (pageReq) => [PROCESS_NAME, 'summaries', pageReq],
    transform: (r) => ({rows: mapUserSummaryResponseToFrontEnd(r.rows), totalRows: r.totalRows}),
    postUpdate(data, _key, client) {
        for (let i = 0; i < data.rows.length; i++) {
            const row = data.rows[i]
            const rowKey = getSubuserSummaryKey(row.id);
            client.setQueryData(rowKey, row);
        }
    }
})

export function getSubuserSummaryKey(subuserID: string) {
    return [PROCESS_NAME, 'summary', subuserID];
}

// FIXME: separate into UserApi and SubuserApi
// FIXME: UserNameResponse may not be accurate for bulkNames (wasn't for single name)
export const userNamesBulk = query<UserNameResponse[]>()({
    variables: (userIds: string[]) => ({
        action: 'get_user_names_bulk',
        user_ids: userIds
    }),
    processName: 'user',
    queryKey: (userIds) => ['user', ...userIds, 'names'],
    transform: (r) => r
});

export const userName = query<{
    name: string;
    user_id: string;
    email: string;
}>()({
    variables: (userId: string) => ({
        action: 'get_user_name',
        user_id: userId
    }),
    processName: 'user',
    queryKey: (userId) => ['user', userId, 'name'],
    transform: (r) => {
        if (!r.user_id) {
            throw new Error("Could not get user's name");
        }
        return r;
    }
});

type GetSubuserReportRequest = {
    userId: string;
    includeHistory: boolean;
    includeRecords: string[];
};

const zReportResponseBody = z
    .string()
    .transform((s) => JSON.parse(s))
    .pipe(
        z.object({
            url: z.string().url(`Could not parse report url`)
        })
    );

export const getReport = query<{ body: string }>()({
    variables: (params: GetSubuserReportRequest) => (
        {
            action: 'get_subuser_report',
            ...params,
            isProd: isProd()
        }
    ),
    processName: PROCESS_NAME,
    queryKey: (params) => [PROCESS_NAME, params.userId, 'report'],
    transform: (r) => zReportResponseBody.parse(r.body).url
});

export const update = mutation<{ subuser_id: string }>()({
    variables: (userToUpdate: UpdateSubUserRequest) => ({
        action: 'update_subuser',
        subuser: {
            ...userToUpdate
        }
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

export const assignAssessment = mutation<{ subuser_id: string }>()({
    variables: (assignment_data: AssignAssessmentToSubuserRequest) => ({
        action: 'assign_assessment_to_subuser',
        ...assignment_data,
        expire_date: formatDateForBackend(assignment_data.expireDate)
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

export const bulkByQuery = query<TableRows<SubUserResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
        action: 'get_sub_users_by_query',
        ...pageReq
    }),
    processName: PROCESS_NAME,
    queryKey: (pageReq) => [PROCESS_NAME, pageReq],
    transform: (r) => r
});

export const getBulk = query<SubUserBulkResponse>()({
    variables: (subuser_ids: string[]) => ({
        action: 'get_subusers_bulk',
        subuser_ids
    }),
    processName: PROCESS_NAME,
    queryKey: (subuser_ids) => [PROCESS_NAME, ...subuser_ids],
    transform: mapSubUserBulkResponseToSubUsers
});

export const get = query<SubUserResponse>()({
    variables: (subuser_id: string) => ({
        action: 'get_subuser_basic',
        subuser_id
    }),
    processName: PROCESS_NAME,
    queryKey: (subuser_id) => [PROCESS_NAME, subuser_id],
    transform: (r) => r
});

export function isPrimaryLiaison(title: string): number {
    return title !== 'SUBJECT' && title !== '' ? 1 : 0;
}

type AddSubuserToGroupRequest = {
    subuser_id: string;
    group_id: string;
    title: string;
    is_liaison?: boolean;
};

export const addToGroup = mutation<{ org_id: string; group_id: string }>()({
    variables: (vars: AddSubuserToGroupRequest) => ({
        action: 'attach_subuser_to_group',
        ...vars,
        is_liason: vars.is_liaison ? 1 : 0,
        title: vars.title || 'SUBJECT'
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

type RemoveGroupResponse = {
    group_id: string;
    org_id: string;
};

export const removeFromGroup = mutation<RemoveGroupResponse>()({
    variables: ({
        subuserId: subuser_id,
        groupId: group_id
    }: {
        subuserId: string;
        groupId: string;
    }) => ({
        action: 'remove_subuser_from_group',
        subuser_id,
        group_id
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

// FIXME: use get_subuser_info wherever subuser groups/orgs required (it has way more than just orgs!)
export const subuserOrgs = query<{ organizations: BaseOrganization[] }>()({
    variables: (subuser_id: string) => ({
        action: 'get_subuser_info',
        subuser_id
    }),
    processName: PROCESS_NAME,
    queryKey: (subuser_id) => [PROCESS_NAME, subuser_id, 'organizations'],
    transform: (r) => r.organizations
});
