/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { GridSelectionModel } from '@mui/x-data-grid';
import PermissionsForm from './PermissionsForm';

/* abstract this component into a UsersForm which is capable of displaying rows of user inputs and doing validation. 
AddUsersForm should be parent of UsersForm should specify text of submit button and whether or not rows are addable. also passes in initial formData
EditUsersForm should act same as AddUsers form but pass in relevant users as the starting state depending on what was selected. QUESTION: what happens if an obsurd number of users are selected to be edited, like 1000000?
*/

interface Props {
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  selectionModel: GridSelectionModel;
}

export default function EditPermissionsForm({
  handleClose,
  selectionModel
}: Props): JSX.Element {
  return (
    <PermissionsForm
      handleClose={handleClose}
      permissionsToEdit={selectionModel}
    />
  );
}
