import { ProfileData } from '../../../structures/profile';
import PhoneForm from './PhoneForm';
interface Props {
  phone: number | undefined;
  updateProfile: (attributesToUpdate: Partial<ProfileData>) => Promise<unknown>;
  setModalOpen: (open: boolean) => void;
}
export const EditPhoneForm = (props: Props) => {
  // const [step, setStep] = useState(0);
  return (
    <PhoneForm
      phone={props.phone}
      updateProfile={props.updateProfile}
      setModalOpen={props.setModalOpen}
    />
  );
};

//multistep phone validation

// return (
//   <>
//     {step == 0 ? (
//       <PhoneForm phone={props.phone} setStep={setStep} />
//     ) : (
//       <PhoneCodeForm
//         setStep={setStep}
//         handleFullPhoneChange={props.handleProfileUpdate}
//       />
//     )}
//   </>
// );
