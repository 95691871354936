/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AssessmentAssignmentInputs from './AssessmentAssignmentInputs';

interface Props {
  handleClose: Function;
  usersToEdit?: GridSelectionModel;
  assessmentIds: string[];
}

interface UserInArrayI {
  id: string;
  uuid: string;
}

const generateEmptyUser = () => ({
  id: 'new',
  uuid: uuidv4()
});

export default function AssignAssessmentsForm(props: Props): JSX.Element {
  const [users, setUsers] = useState<UserInArrayI[]>([generateEmptyUser()]);
  const { handleClose, usersToEdit } = props;
  const bottomRef = useRef<null | HTMLDivElement>(null);
  // const permissionCacheContext = useContext(PermissionCacheContext);

  const handleRemoveUser = (index: number) => {
    const newUsers = users.filter((user, i) => i !== index);
    setUsers(newUsers);
  };

  const handleUserUpdate = (index: number, id: string) => {
    const newUsers = [...users];
    const userAtIndex = newUsers[index];
    userAtIndex.id = id;
    setUsers(newUsers);
  };

  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <Box
        className="no-scroll-bar"
        sx={{ overflowY: 'scroll', height: '90%', marginTop: 3 }}
      >
        <Stack spacing={5}>
          {users.map((user, i) => {
            return (
              <Box
                key={user.uuid}
                sx={{
                  minHeight: 50,
                  display: 'flex',
                  alignItems: 'center',
                  borderStyle: 'solid',
                  borderRadius: 5,
                  borderColor: 'primary.main',
                  borderWidth: 2,
                  padding: 4
                }}
              >
                <AssessmentAssignmentInputs
                  userId={user.id as string}
                  handleRemove={handleRemoveUser}
                  handleIdUpdate={handleUserUpdate}
                  index={i}
                  assessmentIds={props.assessmentIds}
                />
              </Box>
            );
          })}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              variant="contained"
              sx={{ width: 250, backgroundColor: 'primary.light' }}
              onClick={() => {
                setUsers([...users, generateEmptyUser()]);
              }}
            >
              Add User
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10%'
        }}
      >
        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            size="medium"
            sx={{ height: 45 }}
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
