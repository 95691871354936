/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { OrgApi } from '../../../../api/OrganizationApi';
import { ActivationStatus, TaskStatus } from '../../../../structures/enums';
import {
    CacheContextI,
    FilterCriteria
} from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import GreenRedPill from '../../../Templates/GreenRedPill/GreenRedPill';
import OrganizationDetailsModal from '../OrganizationDetailModal/OrganizationDetailsModal';
import AddOrganizationModal from './AddOrganizationModal';
import EditOrganizationModal from './EditOrganizationModal';
import OrganizationTableFilterMenu from './OrganizationTableFilterMenu';
import AddEditEntityTableButtons from '../../../Templates/EntityTable/AddEditEntityTableButtons';
import {
    OrgStatus,
    OrganizationStatus
} from '../../../../structures/organizationEnums';
import { useGridApiRef } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export const OrganizationUpdateContext =
    React.createContext<CacheContextI | null>(
        null // // get this controlling out of date
    );

export const OrganizationFilterContext =
    React.createContext<FilterCriteria | null>(null);

function OrganizationsTable(): JSX.Element {
    const [outOfDate, setOutOfDate] = React.useState(true);
    const navigate = useNavigate();

    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'status',
            headerName: 'STATUS',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: statusCellRenderer,
            sortable: false
        },
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'type',
            headerName: 'TYPE',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'address',
            headerName: 'ADDRESS',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'pLName',
            headerName: 'Liaison Name',
            width: 70,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'pLPhone',
            headerName: 'Liaison Phone',
            width: 70,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'pLEmail',
            headerName: 'Liaison Email',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'createdAt',
            headerName: 'CREATED',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderResponsibleDate,
            sortable: true
        },
        {
            field: 'updatedAt',
            headerName: 'UPDATED',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderResponsibleDate,
            sortable: true
        }
    ];

    function statusCellRenderer(params: GridRenderCellParams): JSX.Element {
        const goodStatuses = [
            TaskStatus.COMPLETE,
            ActivationStatus.ACTIVE,
            OrganizationStatus.ACTIVE,
            OrgStatus.OPEN
        ];
        const isGood = goodStatuses.includes(params.value);
        return <GreenRedPill good={isGood} text={params.value} />;
    }

    function generateEditOrganizationsModal(
        selectedEntities: GridSelectionModel
    ): JSX.Element {
        return (
            <EditOrganizationModal
                open={selectedEntities.length > 0}
                selectedEntity={selectedEntities?.[0]}
            />
        );
    }

    function generateAddOrganizationsModal(): JSX.Element {
        return (
            <AddOrganizationModal
                open={true}
                handleClose={() => navigate('/organizations/')}
            />
        );
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen: boolean,
        handleClose: Function,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <OrganizationTableFilterMenu
                        filters={filterCriteria}
                        setFilters={setFilterCriteria}
                        handleClose={handleClose}
                    />
                }
            />
        );
    }

    function generateTableActions(
        selectionModel: GridSelectionModel
    ): JSX.Element {
        return (
            <AddEditEntityTableButtons
                entity={'Organizations'}
                selectionModel={selectionModel}
                editable={true}
            />
        );
    }

    return (
        <OrganizationUpdateContext.Provider value={{ outOfDate, setOutOfDate }}>
            <EntityTable
                tableKey={['organizations']}
                generateColumns={generateColumns}
                generateEditEntityModal={generateEditOrganizationsModal}
                generateAddEntityModal={generateAddOrganizationsModal}
                entityDetailModal={<OrganizationDetailsModal />}
                generateEntityFilterForm={generateFilterForm}
                api={OrgApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                selectionMode="single"
                apiRef={apiRef}
                generateTableActions={generateTableActions}
            />
        </OrganizationUpdateContext.Provider>
    );
}

export default OrganizationsTable;
