import React from 'react';
import StandardModal from '../../Templates/StandardModal/StandardModal';
import EditPermissionsForm from './EditPermissionsForm';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

interface Props {
  selectionModel: GridSelectionModel;
}

export default function EditPermissionsModal({
  selectionModel
}: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <StandardModal
      open={true}
      handleClose={() => navigate('/permissions/')}
      headerText={'Edit Permissions'}
    >
      <EditPermissionsForm
        handleClose={() => navigate('/permissions/')}
        selectionModel={selectionModel}
      />
    </StandardModal>
  );
}
