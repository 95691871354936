/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, FormGroup } from '@mui/material';
import { Stack } from '@mui/system';
import { FilterCriteria } from '../../../structures/interfaces';

interface Props {
    displayedFilterState: FilterCriteria;
    // TODO: use accurate types instead of Function
    handleClose: Function;
    setFilters: Function;
    children: any;
}

function TableFilterMenu(props: Props): JSX.Element {
    const { displayedFilterState, setFilters } = props;

    function applyCurrentFilters(): void {
        setFilters(displayedFilterState);
        props.handleClose();
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <FormGroup sx={{ width: '80%', marginTop: 10 }}>
                <Stack
                    alignItems={'stretch'}
                    direction={'column'}
                    width="100%"
                    spacing={3}
                >
                    {props.children}
                </Stack>
            </FormGroup>
            <Button
                size="large"
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => applyCurrentFilters()}
            >
                Apply
            </Button>
        </Box>
    );
}

export default TableFilterMenu;
