/* eslint-disable @typescript-eslint/ban-types */
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button } from '@mui/material';
import { TabI } from '../../../../structures/interfaces';
import PageTabs from '../../PageTabs/PageTabs';
import './EntityDetailsModalHeader.css';

type TabInfo = Omit<TabI, "renderPage">

interface Props {
  handleClose: Function;
  tabs: TabInfo[];
}

function EntityDetailsModalHeader(props: Props): JSX.Element {
  const { handleClose, tabs } = props;

  return (
    <Box
      sx={{
        height: 'inherit',
        display: 'grid',
        gridTemplateColumns: '30% 1fr'
      }}
    >
      <div>
        <Button
          variant="text"
          color="secondary"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
          onClick={() => handleClose()}
        >
          Back
        </Button>
      </div>
      <div>
        <PageTabs tabs={tabs} />
      </div>
    </Box>
  );
}

export default EntityDetailsModalHeader;
