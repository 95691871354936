import { StatusType } from '../../structures/enums';
import { ScheduleSummaryResponse } from '../../structures/scheduleInterfaces';

import { faker } from '@faker-js/faker'; // TODO:

export const generateScheduleSummaryRows = (
  numRows: number
): ScheduleSummaryResponse[] => {
  const scheduleSummaries: ScheduleSummaryResponse[] = [];

  for (let i = 0; i < numRows; i++) {
    const scheduleSummary: ScheduleSummaryResponse = {
      schedule_id: faker.string.uuid(),
      subuser_id: faker.string.uuid(),
      status: faker.helpers.arrayElement(Object.values(StatusType)),
      name: faker.person.fullName(),
      assessment_id: faker.string.uuid(),
      startDate: faker.date.past().toISOString(),
      progress: faker.number.int({ max: 100, min: 0 }),
      endDate: faker.date.past().toISOString(),
      cadence: faker.helpers.arrayElement(['weekly', 'monthly', 'yearly']),
      track: 'Track ' + faker.number.int({ max: 3, min: 1 }),
      expired: 'yes',
      delete_status: '',
      completionDate: faker.date.past().toISOString(),
      completed_assessments: faker.number.int({ max: 10, min: 0 }),
      total_assessments: faker.number.int({ max: 10, min: 0 })
    };

    scheduleSummaries.push(scheduleSummary);
  }

  return scheduleSummaries;
};
