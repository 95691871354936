/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Button } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    isSelected: boolean;
    isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered'
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main
        }
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode]
        }
    }),
    ...(day.day() === 0 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%'
    }),
    ...(day.day() === 6 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%'
    })
})) as React.ComponentType<CustomPickerDayProps>;

function isInSameWeek(dayA: Dayjs, dayB: Dayjs | null | undefined) {
    if (dayB == null) {
        return false;
    }

    return dayA.isSame(dayB, 'week');
}

function Day(
    props: PickersDayProps<Dayjs> & {
        selectedDay?: Dayjs | null;
        hoveredDay?: Dayjs | null;
    }
) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

function currentRange(value: Dayjs): string {
    if (value.isAfter(dayjs().startOf('week'))) {
        return 'This Week';
    } else {
        return `Week of ${value.startOf('week').format('MMM')} ${value
            .startOf('week')
            .get('D')}`;
    }
}

function WeekPickerButton(props: any): JSX.Element {

    const {currentRange, togglePickerOpen, ...attrs} = props;
    return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "start"}}>
            {/*  HACK: this next line was needed to get the calendar to render in the right position */}
            <TextField
                style={{ opacity: 0, width: 0, height: 0 }}
                {...attrs}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    e.preventDefault()
                }
            />

            <Button
                onClick={() => togglePickerOpen()}
                endIcon={<CalendarMonthIcon />}
                size="large"
            >
                {currentRange}
            </Button>
        </div>
    );
}

interface Props {
    value: Dayjs;
    setValue: (arg0: Dayjs) => void;
}

export default function WeekPicker({ value, setValue }: Props): JSX.Element {
    const [pickerOpen, setPickerOpen] = React.useState(false);
    const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value}
                onChange={(newValue: Dayjs | null) => {
                    if (newValue === null) {
                        return;
                    }
                    setValue(newValue);
                }}
                slots={{ day: Day, textField: WeekPickerButton }}
                slotProps={{
                    textField: {
                        togglePickerOpen: () => setPickerOpen(!pickerOpen),
                        currentRange: currentRange(value)
                    } as any,
                    day: (ownerState) =>
                        ({
                            selectedDay: value,
                            hoveredDay,
                            onPointerEnter: () => setHoveredDay(ownerState.day),
                            onPointerLeave: () => setHoveredDay(null)
                        } as any)
                }}
                maxDate={dayjs().endOf('week')}
                open={pickerOpen}
                onClose={() => setPickerOpen(false)}
            />
        </LocalizationProvider>
    );
}
