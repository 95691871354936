import React from 'react';
import './ModalHeader.css';
import { Typography } from '@mui/material';

interface Props {
  title: string;
}
export default function ModalHeader({ title }: Props): JSX.Element {
  return (
    <div className="modal-header">
      <Typography variant="body1" marginLeft={5} color="white">
        {title}
      </Typography>
    </div>
  );
}
