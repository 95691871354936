/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

export interface InfoPanelSectionData {
  [key: string]: React.ReactNode;
  children: React.ReactNode;
}
// use context for cache invalidation
export function DetailedEntityInfoPanel(props: Props): JSX.Element {
  const { children } = props;
  const [editEntityModalState, setEditEntityModalState] = React.useState<
    number[]
  >([]);


  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        padding: 4,
        height: '95%',
        position: 'relative',
        overflow: 'scroll'
      }}
    >
      {children}
    </Box>
  );
}

export default DetailedEntityInfoPanel;
