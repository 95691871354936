import { EntityTableRequest } from '../structures/interfaces';
import {
    UserAssessmentSummary,
    UserAssessmentSummaryResponse
} from '../structures/assessmentRecordInterfaces';
import { TableRows } from './EntityApi';
import { RequestResponse } from '../structures/responses';
import { mapUserAssessmentSummaryResponseToFrontEnd } from './userAssessmentMappers';
import { query, sendRequest } from './SeqApi';

export * as UserAssessmentApi from './UserAssessmentApi';

const PROCESS_NAME = 'assessment_record';

export async function getSortedSummaries(
    pageReq: EntityTableRequest
): Promise<TableRows<UserAssessmentSummary>> {
    const variables: { [key: string]: any } = {
        action: 'get_user_assessment_summaries',
        ...pageReq
    };
    try {
        const response = await sendRequest(variables, PROCESS_NAME, undefined);
        const data = response.data as unknown as RequestResponse<
            TableRows<UserAssessmentSummaryResponse>
        >;
        if (data.status !== 'success') {
            throw Error(data.message);
        }
        return {
            rows: mapUserAssessmentSummaryResponseToFrontEnd(
                data.resp_variables.rows
            ),
            totalRows: data.resp_variables.totalRows
        };
    } catch (err) {
        console.log('could not retrieve assessments');
        console.log(err);
        throw err;
    }
}

export const summaries = query<TableRows<UserAssessmentSummaryResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
        action: 'get_user_assessment_summaries',
        ...pageReq
    }),
    queryKey: (pageReq) => [PROCESS_NAME, pageReq],
    processName: PROCESS_NAME,
    transform: (r) => ({
        rows: mapUserAssessmentSummaryResponseToFrontEnd(r.rows),
        totalRows: r.totalRows
    })
});
