import { Chip, Stack, Tooltip } from '@mui/material';

interface Props {
  categories: string[];
}
export const CategoryCell = (props: Props): JSX.Element => {
  const { categories } = props;
  return (
    <Tooltip title={categories.join(', ')} arrow>
      <Stack direction="row" spacing={1}>
        {categories.map((category, index) => (
          <Chip
            key={index}
            label={category}
            variant="outlined"
            color="primary"
            size="small"
          />
        ))}
      </Stack>
    </Tooltip>
  );
};
