import { Box, Skeleton, TextField, Typography, useTheme } from '@mui/material';
import Slider, { SliderProps } from 'rc-slider';
import { Controller, useFormContext } from 'react-hook-form';

// import '../../../../../../assets/index.less';
import 'rc-slider/assets/index.css';
import { Assessment } from '../../../../structures/assessmentInterfaces';
let calculatedThresholdVals = [0, 20, 30, 40, 100];
let lowerRangeBound = 0;
let upperRangeBound = 100;
let scoreRangeVal = 100;

function AssessmentScoringThresholdForm({
  loading
}: {
  loading: boolean;
}): JSX.Element {
  const theme = useTheme();
  const {
    formState: { errors },
    control,
    getValues,
    watch
  } = useFormContext<Assessment>();

  if (getValues('scoreRange') && getValues('scoringThresholds')) {
    lowerRangeBound = watch(`scoreRange.${0}.value`) as unknown as number;
    upperRangeBound = watch(`scoreRange.${1}.value`) as unknown as number;
    scoreRangeVal = upperRangeBound - lowerRangeBound;

    calculatedThresholdVals = watch('scoringThresholds').map((val) => {
      return Math.floor(
        Math.floor((val * scoreRangeVal) / 100) + lowerRangeBound
      );
    });
  }

  const handleStyle = {
    width: 30,
    height: 30,
    borderRadius: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    border: 'none',
    marginTop: -30
  };
  const draggingShadowStyle = `0 0 4px 2px ${theme.palette.secondary.main}`;

  const handleRender: SliderProps['handleRender'] = (node, nodeProps) => {
    if (nodeProps.index === 0 || nodeProps.index === 4) {
      return (
        <div
          {...node.props}
          style={{
            ...node.props.style,
            ...handleStyle,
            pointerEvents: 'none',
            opacity: 0.7
          }}
        >
          {calculatedThresholdVals[nodeProps.index]}
        </div>
      );
    } else {
      return (
        <div
          {...node.props}
          style={
            nodeProps.dragging
              ? {
                  ...handleStyle,
                  ...node.props.style,
                  boxShadow: draggingShadowStyle
                }
              : { ...handleStyle, ...node.props.style }
          }
        >
          {calculatedThresholdVals[nodeProps.index]}
        </div>
      );
    }
  };

  return (
    <>
      {!loading ? (
        <Box>
          <Typography variant="h6" sx={{ marginTop: 5, marginBottom: 5 }}>
            Scoring
          </Typography>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Controller
              render={({ field }) => (
                <Slider
                  range
                  count={4}
                  {...field}
                  pushable
                  min={0}
                  max={100}
                  trackStyle={[
                    {
                      backgroundColor: theme.palette.success.main,
                      height: 20,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0
                    },
                    {
                      backgroundColor: theme.palette.warning.light,
                      height: 20,
                      borderRadius: 0
                    },
                    {
                      backgroundColor: theme.palette.warning.main,
                      height: 20,
                      borderRadius: 0
                    },
                    {
                      backgroundColor: theme.palette.error.light,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      height: 20,
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5
                    }
                  ]}
                  handleRender={handleRender}
                  railStyle={{
                    backgroundColor: 'white',
                    height: 20,
                    border: '1px solid #d3d3d3'
                  }}
                  style={{
                    marginRight: 20,
                    marginLeft: 20,
                    marginBottom: 20,
                    marginTop: 20
                  }}
                  disabled={false}
                />
              )}
              name="scoringThresholds"
              control={control}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 5,
              marginTop: 3,
              justifyContent: 'space-between'
            }}
          >
            <Controller
              rules={{
                validate: (value) => {
                  return value >= 0 || 'Min must be greater than 0';
                }
              }}
              render={({ field }) => (
                <TextField
                  label="Score Min"
                  size="small"
                  type="number"
                  inputProps={{ min: 0, minLength: 1 }}
                  {...field}
                  error={errors.scoreRange != null}
                  onChange={(e) => {
                    field.onChange(parseFloat(e.target.value));
                  }}
                  sx={{ width: 100 }}
                />
              )}
              name={`scoreRange.${0}.value`}
              control={control}
            />
            <Controller
              rules={{
                validate: (value) => {
                  return value >= 0 || 'Max must be greater than 0';
                }
              }}
              render={({ field }) => (
                <TextField
                  label="Score Max"
                  size="small"
                  type="number"
                  inputProps={{
                    min: getValues(`scoreRange.${0}.value`),
                    minLength: 1
                  }}
                  {...field}
                  error={errors.scoreRange != null}
                  onChange={(e) => {
                    const targetVal = parseFloat(e.target.value);
                    field.onChange(targetVal);
                  }}
                  sx={{ width: 100 }}
                />
              )}
              name={`scoreRange.${1}.value`}
              control={control}
            />
          </Box>
        </Box>
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={'30%'} />
      )}
    </>
  );
}

export default AssessmentScoringThresholdForm;
