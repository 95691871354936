import { useNavigate } from 'react-router-dom';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import AssessmentForm from './AssessmentForm';
// import "./AddUserModal.css";

export default function CreateAssessmentModal(): JSX.Element {
  const navigate = useNavigate();

  return (
    <StandardModal
      open={true}
      handleClose={() => navigate(-1)}
      headerText={`Create Assessment`}
    >
      <AssessmentForm handleClose={() => navigate(-1)} />
    </StandardModal>
  );
}
