import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult
} from '@hello-pangea/dnd';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Question from './Question';
import { Assessment } from '../../../../structures/assessmentInterfaces';

function AssessmentQuestionsForm({
  loading
}: {
  loading: boolean;
}): JSX.Element {
  const formMethods = useFormContext<Assessment>();

  const questionMethods = useFieldArray<Assessment, 'questions', 'questionsId'>(
    {
      control: formMethods.control,
      name: 'questions',
      keyName: 'questionsId'
    }
  );

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const currentQuestions = formMethods.getValues('questions');
    const question = currentQuestions[source.index];
    const newQuestions = Array.from(currentQuestions);
    newQuestions.splice(source.index, 1);
    newQuestions.splice(destination.index, 0, question);
    const updatedQuestions = newQuestions.map((q, index) => ({
      ...q,
      order_num: index
    }));
    formMethods.setValue('questions', updatedQuestions);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginTop: 5, marginBottom: 5 }}>
        Questions
      </Typography>
      {!loading ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questions" direction="vertical">
            {(provided: DroppableProvided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3
                }}
              >
                {questionMethods.fields.map((question, index) => {
                  return (
                    <Question
                      key={question.questionsId}
                      index={index}
                      question={question}
                    />
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>

          <Box>
            <Button
              onClick={() => {
                questionMethods.append({
                  prompt: '',
                  options: [
                    {
                      points: 0,
                      flag: false,
                      text: ''
                    }
                  ],
                  order_num: questionMethods.fields.length
                });
              }}
            >
              <Typography>Add Question</Typography>
            </Button>
          </Box>
        </DragDropContext>
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
      )}
    </Box>
  );
}

export default AssessmentQuestionsForm;
