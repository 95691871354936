/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { DashboardMetrics, StatusType } from '../structures/enums';
import SeqApi, { sendRequest, SendRequestOptions } from './SeqApi';
import {
  Entity,
  EntityTableRequest,
  UpdateScheduleRequest
} from '../structures/interfaces';
import EntityApi, { EntityRequest, TableRows } from './EntityApi';
import { GridRowId } from '@mui/x-data-grid';
import { RequestResponse } from '../structures/responses';
import { mapScheduleSummaryRespToFrontEnd } from './scheduleMappers';
import {
  ScheduleSummary,
  ScheduleSummaryResponse,
  UpdateScheduleReq
} from '../structures/scheduleInterfaces';

export interface UserAssessmentsRequest {
  userId: number;
}

export interface SchedulePausedStatus {
  id: number;
  paused: boolean;
}
export interface ScheduleStatusRes {
  schedule_id: number;
  status: StatusType;
}
const PROCESS_NAME = 'schedule';
async function getSortedSummaries(
    pageReq: EntityTableRequest
  ): Promise<TableRows<ScheduleSummary>> {
    const variables: { [key: string]: any } = {
      action: 'get_schedule_summaries',
      ...pageReq
    };
    variables.filters = pageReq.filters;
    try {
      const response = await sendRequest(
        variables,
        PROCESS_NAME,
        undefined,
        {
          mockRequest: false
        } as SendRequestOptions
      );
      const data = response.data as unknown as RequestResponse<
        TableRows<ScheduleSummaryResponse>
      >;
      return {
        rows: mapScheduleSummaryRespToFrontEnd(data.resp_variables.rows),
        totalRows: data.resp_variables.totalRows
      };
    } catch (err) {
      console.log('could not retrieve schedules');
      console.log(err);
      throw err;
    }
  }
export default class ScheduleApi extends EntityApi {
  static singleton = new ScheduleApi();

  getSortedSummaries = getSortedSummaries;
  static getSortedSummaries = getSortedSummaries;

  changeStatus = async (
    schedule_id: GridRowId,
    status: StatusType
  ): Promise<ScheduleStatusRes> => {
    const variables: { [key: string]: any } = {
      action: 'change_schedule_status',
      schedule_id: schedule_id,
      status: status
    };
    try {
      const response = await sendRequest(
        variables,
        PROCESS_NAME,
        undefined,
        { mockRequest: false } as SendRequestOptions
      );
      const data =
        response.data as unknown as RequestResponse<ScheduleStatusRes>;
      if (data.status !== 'success') {
        throw new Error(data.message);
      }
      return data.resp_variables;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  togglePausedStatus = async (id: GridRowId): Promise<SchedulePausedStatus> => {
    const variables: { [key: string]: any } = {
      action: 'toggle_paused_status',
      id: id
    };
    try {
      //const response = await sendRequest(variables);
      //const data = response.data as Assessment[];
      return {
        id: 10000,
        paused: true
      };
    } catch (err) {
      console.log('could not toggle paused status');
      console.log(err);
      throw err;
    }
  };
  updateSchedules = async (
    scheduleUpdates: UpdateScheduleRequest[]
  ): Promise<number[]> => {
    const variables: { [key: string]: any } = {
      action: 'update_schedules',
      scheduleUpdates
    };
    try {
      //const response = await sendRequest(variables);
      //const data = response.data as Assessment[];
      return [10000, 12454];
    } catch (err) {
      console.log('could not toggle paused status');
      console.log(err);
      throw err;
    }
  };

  updateSchedule = async (
    schedule: UpdateScheduleReq
  ): Promise<{ schedule_id: string }> => {
    const variables = {
      action: 'update_schedule',
      schedule: schedule
    };
    try {
      const response = await sendRequest(
        variables,
        PROCESS_NAME,
        undefined,
        {
          mockRequest: false
        } as SendRequestOptions
      );
      const data = response.data as unknown as RequestResponse<{
        schedule_id: string;
      }>;
      if (data.status !== 'success') {
        throw new Error(data.message);
      }

      return data.resp_variables;
    } catch (err) {
      console.log('could not update schedule');
      console.log(err);
      throw err;
    }
  };

  async getEntity(req: EntityRequest): Promise<Entity> {
    throw new Error('Method not implemented.');
  }
}
