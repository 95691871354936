import { Box, Skeleton, Typography } from '@mui/material';
import {UserApi} from '../../../../api/UserApi';

interface Props {
    userId: string;
}
export const UserIdToUser = (props: Props): JSX.Element => {
    const { userId } = props;
    // TODO: staleTime to prevent refetching
    const {data: user, status} = UserApi.userName.useQuery(userId);

    const authorName = user?.user_id ? user.name : userId;

    return userId ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {status === 'pending' ? (
                <Skeleton />
            ) : status === 'error' ? (
                <Typography>{userId}</Typography>
            ) : (
                <Typography variant="body1">
                    {authorName}
                </Typography>
            )}
        </Box>
    ) : (
        <>{'N/A'}</>
    );
};
