import React from 'react';
import Section from '../../Templates/Section/Section';
import OrganizationsTable from './OrganizationsTable/OrganizationsTable';
// import Organizations from '../Pages/UsersPage/UsersPage'
// import Assessments from '../Pages/UsersPage/UsersPage'

function OrganizationsPage(): JSX.Element {
  return (
    <div className="whole-width">
      <Section title="Organizations">{<OrganizationsTable />}</Section>
    </div>
  );
}

export default OrganizationsPage;
