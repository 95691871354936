import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { AssessmentRecordApi } from '../../../../api/AssessmentRecordApi';
interface Props {
    userId: string;
}
export function UserAssessmentsTableActions(props: Props): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();

    const { userId } = props;
    const markAllAsReviewed = AssessmentRecordApi.markAllAsReviewed.useMutation();

    useEffect(() => {
        if (markAllAsReviewed.data && !markAllAsReviewed.isError) {
            enqueueSnackbar(`Marked ${markAllAsReviewed.data.num_updated} records as reviewed`, {
                variant: 'success'
            });
        }
    }, [markAllAsReviewed.data]);

    useEffect(() => {
        if (markAllAsReviewed.isError) {
            enqueueSnackbar(`Error setting reviewed status`, {
                variant: 'error'
            });
        }
    }, [markAllAsReviewed.isError]);

    return (
        <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <LoadingButton
                variant="contained"
                color="secondary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={() => markAllAsReviewed.mutate({subuserId: userId})}
                loading={markAllAsReviewed.isPending}
            >
                Mark All Reviewed
            </LoadingButton>
        </Stack>
    );
}
