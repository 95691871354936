import { Box } from '@mui/material';
import { QuestionAnswer } from '../../../../structures/assessmentRecordInterfaces';
import { AnswerRecordDisplay } from './AnswerRecordDisplay';

interface Props {
  answers: QuestionAnswer[];
}
export const AssessmentRecordQuestions = ({ answers }: Props): JSX.Element => {
  const sortedAnswers = answers.sort((a, b) => a.order_num - b.order_num);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        {sortedAnswers.map((answerRecord, index) => (
          <AnswerRecordDisplay
            key={index}
            answerRecord={answerRecord}
            questionNumber={index + 1}
          />
        ))}
      </Box>
    </>
  );
};
