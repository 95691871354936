import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ProfileApi from '../../../api/ProfileApi';
import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';
import { ProfileData } from '../../../structures/profile';
import StandardModal from '../../Templates/StandardModal/StandardModal';
import { EditNameForm } from './EditNameForm';
import { EditPasswordForm } from './EditPasswordForm';
import { EditPhoneForm } from './EditPhoneForm';
interface Props {
  detailName: string | undefined;
  detailData: string | undefined;
  handleEdit?: () => void;
  loading: boolean;
}
const ProfileDetail = (props: Props) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
        alignItems: 'center'
      }}
    >
      <Typography variant="body1">{props.detailName}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {props.loading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          <Typography variant="body1">{props.detailData}</Typography>
        )}
        {props.handleEdit && (
          <IconButton
            onClick={props.handleEdit}
            // disabled={props.detailData == undefined}
          >
            <ModeEditOutlineRoundedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
export const UserProfileInfo = () => {
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [stale, setStale] = useState(true);
  //const userSessionContext = useContext(UserSessionContext);
  const {
    execute: refreshSessionData,
    loading: loadingSessionData,
    data
  } = useAsyncRequest(
    ProfileApi.singleton.getProfileInfo,
    ProfileApi.singleton
  );

  // const {
  //   execute: getProfile,
  //   data: profile,
  //   loading
  // } = useAsyncRequest(
  //   ProfileApi.singleton.getProfileInfo,
  //   ProfileApi.singleton
  // );

  // const mapUserSessionContextToProfileData = (): ProfileData => {
  //   return {
  //     first_name: userSessionContext.userSessionData?.first_name,
  //     last_name: userSessionContext.userSessionData?.last_name,
  //     email: userSessionContext.userSessionData?.user_email,
  //     phone_number: userSessionContext.userSessionData
  //       ?.phone_number as unknown as number,
  //     user_id: userSessionContext.userSessionData?.user_id
  //   } as ProfileData;
  // };

  useEffect(() => {
    const fetchProfileData = async () => {
      await refreshSessionData();
    };

    if (stale) {
      fetchProfileData().then(() => setStale(false));
    }
  }, [stale]);

  const updateProfile = async (attributesToUpdate: Partial<ProfileData>) => {
    try {
      const newProfile = {
        ...data,
        ...attributesToUpdate
      } as ProfileData;
      await ProfileApi.singleton.updateProfileInfo(newProfile);
      setStale(true);
    } catch (err) {
      console.log('error updating profile', err);
      throw err;
    }
  };

  function displayEditModal() {
    return (
      <>
        <StandardModal
          handleClose={() => setEditName(false)}
          headerText="Edit Name"
          open={editName}
          width="600px"
          height="300px"
        >
          <EditNameForm
            firstName={data?.first_name as string}
            lastName={data?.last_name as string}
            updateProfile={updateProfile}
            setModalOpen={setEditName}
          />
        </StandardModal>

        <StandardModal
          handleClose={() => setEditPhone(false)}
          headerText="Edit Phone"
          open={editPhone}
          width="600px"
          height="300px"
        >
          <EditPhoneForm
            phone={data?.phone_number as unknown as number}
            updateProfile={updateProfile}
            setModalOpen={setEditPhone}
          />
        </StandardModal>

        <StandardModal
          handleClose={() => setEditPassword(false)}
          headerText="Edit Password"
          open={editPassword}
          width="600px"
          height="450px"
        >
          <EditPasswordForm
            handleProfileUpdate={() => {
              setEditPassword(false);
              setStale(true);
            }}
          />
        </StandardModal>
      </>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack spacing={5}>
        <ProfileDetail
          loading={loadingSessionData}
          detailData={data?.user_id}
          detailName="User Id"
        />
        <ProfileDetail
          detailData={data?.first_name}
          detailName="First Name"
          handleEdit={() => setEditName(true)}
          loading={loadingSessionData}
        />
        <ProfileDetail
          loading={loadingSessionData}
          detailData={data?.last_name}
          detailName="Last Name"
          handleEdit={() => setEditName(true)}
        />
        <ProfileDetail
          loading={loadingSessionData}
          detailData={data?.email}
          detailName="Email"
        />
        <ProfileDetail
          loading={loadingSessionData}
          detailData={data?.phone_number as unknown as string}
          detailName="Phone Number"
          handleEdit={() => setEditPhone(true)}
        />
        <ProfileDetail
          loading={loadingSessionData}
          detailData={'***********'}
          detailName="Password"
          handleEdit={() => setEditPassword(true)}
        />
      </Stack>
      {displayEditModal()}
    </Box>
  );
};
