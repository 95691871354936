/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridSelectionModel,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import {
    CacheContextI,
    FilterCriteria
} from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import OrganizationDetailsModal from '../../OrganizationsPage/OrganizationDetailModal/OrganizationDetailsModal';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { AssessmentApi } from '../../../../api/AssessmentApi';
import { AssessmentRowActions } from './AssessmentRowActions';
import CreateAssessmentModal from './CreateAssessmentModal';
import EditAssessmentModal from './EditAssessmentModal';
import { AssessmentTableFilterMenu } from './AssessmentTableFilterMenu';
import { CategoryCell } from './CategoryCell';
import { AssessmentStatus } from '../../../../structures/assessmentEnums';
import GreenRedPill from '../../../Templates/GreenRedPill/GreenRedPill';
import AssignAssessmentModal from './AssignAssessmentsModal/AssignAssessmentModal';

export const AssessmentUpdateContext =
    React.createContext<CacheContextI | null>(
        null // // get this controlling out of date
    );

export const PermissionFilterContext =
    React.createContext<FilterCriteria | null>(null);

export function AssessmentTable(): JSX.Element {
    const [outOfDate, setOutOfDate] = React.useState(true);

    const navigate = useNavigate();

    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const [assignModalOpen, setAssignModalOpen] = React.useState(false);
    const [assignModalIds, setAssignModalIds] = React.useState<GridRowId[]>([]);

    function openAssignModal(ids: GridRowId[]): void {
        setAssignModalIds(ids);
        setAssignModalOpen(true);
    }


    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: statusCellRenderer,
            sortable: true
        },
        {
            field: 'friendlyName',
            headerName: 'Friendly Name',
            width: 300,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'track',
            headerName: 'Track',
            width: 50,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params) => {
                return <CategoryCell categories={params.value} />;
            },
            sortable: true
        },
        {
            field: 'action',
            headerName: 'Row Actions',
            minWidth: 215,
            headerClassName: 'table-column-title',
            sortable: false,
            renderCell: (params) => (
                <AssessmentRowActions apiRef={apiRef} gridParams={params} openEditModal={() => navigate(`${params.row.id}/edit`)} />
            ) // render pause and cancel buttons
        }
    ];

    function editScheduleModal(
        selectedEntities: GridSelectionModel
    ): JSX.Element {
        return <EditAssessmentModal selectionModel={selectedEntities} />;
    }

    function addAssessmentModal(): JSX.Element {
        return <CreateAssessmentModal />;
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen: boolean,
        handleClose: () => void,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <AssessmentTableFilterMenu // TODO: change to ScheduleTableFilterMenu
                        filters={filterCriteria}
                        setFilters={setFilterCriteria}
                        handleClose={handleClose}
                    />
                }
            />
        );
    }

    function statusCellRenderer(params: GridRenderCellParams): JSX.Element {
        const goodStatuses = [AssessmentStatus.ACTIVE];
        const isGood = goodStatuses.includes(params.value);
        return <GreenRedPill good={isGood} text={params.value} />;
    }

    const generateTableActions = (
        selectionModel: GridSelectionModel
    ): JSX.Element => {
        return (
            <AssessmentTableButtons
                entity={"Assessments"}
                selectionModel={selectionModel}
                openAssignModal={openAssignModal}
            />
        );
    };

    return (
        <AssessmentUpdateContext.Provider value={{ outOfDate, setOutOfDate }}>
            <AssignAssessmentModal open={assignModalOpen} assessmentIds={assignModalIds} close={() => setAssignModalOpen(false)}/>
            <EntityTable
                pinnedColumns={{ right: ['action'] }}
                tableKey={['assessments']}
                generateColumns={generateColumns}
                generateEditEntityModal={editScheduleModal}
                generateAddEntityModal={addAssessmentModal}
                entityDetailModal={<OrganizationDetailsModal />}
                generateEntityFilterForm={generateFilterForm}
                api={AssessmentApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                generateTableActions={generateTableActions}
                apiRef={apiRef}
                selectionMode="multiple"
            />
        </AssessmentUpdateContext.Provider>
    );
}

import { Add } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';

interface Props {
    entity: string;
    selectionModel: GridSelectionModel;
    openAssignModal: (ids: GridRowId[]) => void;
}

function AssessmentTableButtons(props: Props): JSX.Element {
    const { entity, selectionModel, openAssignModal } = props;
    const navigate = useNavigate();
    return (
        <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Button
                variant="contained"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => navigate(`add/`)}
            >
                Add {entity}
            </Button>
            <Button
                variant="contained"
                startIcon={<Add />}
                disabled={selectionModel.length === 0}
                onClick={() => openAssignModal(selectionModel)}
            >
                Assign Selected
            </Button>
        </Stack>
    );
}


