import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import ScheduleApi from '../../../../api/ScheduleApi';
import useAsyncRequest from '../../../../helpers/hooks/useAsyncRequest';
import { StatusType } from '../../../../structures/enums';
interface Props {
  gridParams: GridRenderCellParams;
  apiRef: React.MutableRefObject<GridApiPro>;
}
export const ScheduleRowActions = (props: Props): JSX.Element => {
  const { execute: changeStatus, loading: changeStatusLoading } =
    useAsyncRequest(ScheduleApi.singleton.changeStatus, ScheduleApi.singleton);
  async function handleEnd() {
    try {
      const result = await changeStatus(
        props.gridParams.row.id,
        StatusType.COMPLETE
      );
      if (!result.data || result.error) {
        throw result.error?.message;
      }
      props.apiRef.current.updateRows([
        {
          id: props.gridParams.row.id,
          status: result.data.status
        }
      ]);
    } catch (err) {
      Error("couldn't toggle assessment review status");
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <LoadingButton
        variant="contained"
        loading={changeStatusLoading}
        size="small"
        onClick={handleEnd}
        disabled={
          props.gridParams.row.status === "COMPLETE" ||
          props.gridParams.row.status === "EXPIRED"
        }
        startIcon={<StopCircleOutlinedIcon />}
      >
        End
      </LoadingButton>
    </Box>
  );
};
