import { Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AssessmentRecordApi } from '../../../../api/AssessmentRecordApi';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import { AssessmentRecordDetails } from './AssessmentRecordDetails';
import { AssessmentRecordQuestions } from './AssessmentRecordQuestions';
import { AssessmentRecordScoreBreakDown } from './AssessmentRecordScoreBreakDown';
import { AssessmentApi } from '../../../../api/AssessmentApi';

function AssessmentRecordViewer() {
    const navigate = useNavigate();
    const params = useParams();
    // TODO: check for error
    const recordId = params.entityId as string;

    // TODO: enqueueSnackbar on error (not found!)
    const record = AssessmentRecordApi.get.useQuery(recordId);

    const assessment = AssessmentApi.get.useQuery(
        record.data?.originalAssessmentId as string,
        {
            enabled: !!record.data?.originalAssessmentId
        }
    );

    const answers = AssessmentRecordApi.getAnswers.useQuery(record.data?.userAssessmentId as string, {
        enabled: !!record.data?.userAssessmentId
    })

    return (
        <StandardModal
            headerText={`Assessment Record:  ${params.entityId}`}
            open={true}
            handleClose={() => navigate(-1)}
        >
            <Box
                sx={{
                    padding: 5,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: '90%'
                }}
            >
                {record.isLoading && (
                    <>
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            style={{ marginTop: 15 }}
                        />
                    </>
                )}

                {record.isSuccess && (
                    <>
                        <AssessmentRecordDetails assessment={record.data} />
                        {assessment.isSuccess && (
                            <AssessmentRecordScoreBreakDown
                                assessment={record.data}
                                assessmentDetails={assessment.data}
                            />
                        )}
                    </>
                )}

                {answers.isLoading && <Skeleton variant="rectangular" />}

                {assessment.isLoading && (
                    <Skeleton
                        variant="rectangular"
                        height={200}
                        style={{ marginTop: 15 }}
                    />
                )}

                {answers.isSuccess && (
                    <AssessmentRecordQuestions
                        answers={answers.data}
                    />
                )}
            </Box>
        </StandardModal>
    );
}

export default AssessmentRecordViewer;
