import React from 'react';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import OrganizationForm from './OrganizationForm';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function AddOrganizationModal({
  open,
  handleClose
}: Props): JSX.Element {
  return (
    <StandardModal
      open={open}
      handleClose={handleClose}
      headerText={'Add Organization'}
    >
      <OrganizationForm handleClose={handleClose} />
    </StandardModal>
  );
}
