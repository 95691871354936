/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    TextField
} from '@mui/material';
import pluralize from 'pluralize';
import { FilterOperator } from '../../../structures/enums';
import { FilterCriterion } from '../../../structures/interfaces';
import EntityFilter from './EntityFilter';
import { useCallback, useMemo } from 'react';
import _ from "lodash"

interface Props {
    fieldName: string;
    fieldTitle: string;
    handleCriterionChange: (
        field: string,
        operator: FilterOperator | undefined,
        args: string[] | undefined
    ) => void;
    displayedCriterion: FilterCriterion | null;
    defaultOptions?: string[];
    options?: string[];
    mapOptionsToDisplayValue?: (option: unknown) => string;
}

const filterComponentPropKeys = [
    'fieldName',
    'fieldTitle',
    'handleCriterionChange',
    'displayedCriterion',
    'defaultOptions',
    'options',
    'mapOptionsToDisplayValue'
] satisfies (keyof Props)[];
type FilterComponentPropOptions = typeof filterComponentPropKeys;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// TODO: don't take in Api, make caller pass result of getFilterOptions
function MultiSelectFilter(props: Props): JSX.Element {
    const filterProps = useMemo(() => {
        return _.pick(props, filterComponentPropKeys);
    }, [props])
    return (
        <EntityFilter
            filterTitle={props.fieldTitle}
            generateFilterChip={() => (
                <FilterChip
                    criterion={props.displayedCriterion}
                    handleChange={props.handleCriterionChange}
                    fieldName={props.fieldName}
                />
            )}
            filterComponent={<FilterComponent {...filterProps} />}
        />
    );
}

type FilterChipProps = {
    criterion: Props['displayedCriterion'];
    handleChange: Props['handleCriterionChange'];
    fieldName: Props['fieldName'];
};

function FilterChip({
    criterion,
    handleChange,
    fieldName
}: FilterChipProps) {
    function handleChipDelete(): void {
        handleChange(fieldName, undefined, undefined);
    }
    const operator = (criterion?.operator ?? '') as string;
    const numArguments = criterion?.arguments?.length ?? 0;
    if (numArguments === 0) {
        return <></>;
    }
    return (
        <Chip
            label={operator + ' ' + (numArguments as unknown as string)}
            onDelete={handleChipDelete}
            sx={{ maxWidth: 190 }}
        />
    );
}


type FilterComponentProps = Pick<Props, FilterComponentPropOptions[number]>;

function FilterComponent(props: FilterComponentProps) {
    const {
        fieldName,
        fieldTitle,
        handleCriterionChange,
        displayedCriterion: criterion,
        defaultOptions
    } = props;

    const options = useMemo(() => {
        if (props.options) {
            return props.options;
        }
        if (defaultOptions) {
            return defaultOptions;
        }
        return [];
    }, [defaultOptions, props.options]);

    const renderOption = useCallback(
        (option: string) => {
            return props.mapOptionsToDisplayValue?.(option) ?? String(option);
        },
        [props.mapOptionsToDisplayValue]
    );

    return (
        <FormControl sx={{ width: '100%' }}>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={options}
                disableCloseOnSelect
                value={criterion?.arguments ?? []}
                getOptionLabel={renderOption}
                onChange={(_event, value) => {
                    const typedValue = value.length === 0 ? undefined : value;
                    handleCriterionChange(
                        fieldName,
                        FilterOperator.ANY_OF,
                        typedValue
                    );
                }}
                renderOption={(p, option, { selected }) => (
                    <li {...p}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {renderOption(option)}
                    </li>
                )}
                ListboxProps={{ style: { zIndex: 9999 } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={pluralize(fieldTitle)}
                    />
                )}
            />
        </FormControl>
    );
}

export default MultiSelectFilter;
