import { yupResolver } from '@hookform/resolvers/yup';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Stack,
    TextField
} from '@mui/material';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UserApi } from '../../../../api/UserApi';
import { EntityTableRequest } from '../../../../structures/interfaces';
import {
    Liaison,
    SubUserResponse
} from '../../../../structures/userInterfaces';

interface Props {
    handleAddLiaison: (addedPrimaryLiaison: Liaison) => void;
}

interface FormInputs {
    subUser: SubUserResponse | null;
    title: string;
}

// associated_user_id: string;
//   date_added: string;
//   email: string;
//   name: string;
//   phone: string;
//   subuser_id: string;

const FormInputsSchema: yup.SchemaOf<FormInputs> = yup.object().shape({
    subUser: yup
        .object()
        .shape({
            subuser_id: yup.string().required(),
            name: yup.string().required('Liaison Full Name is required'),
            phone: yup.string().required(),
            email: yup.string().required('Liaison Email is required')
        })
        .required('Liaison is required'),
    title: yup.string().required('Title is required')
});
export const LiaisonPicker = (props: Props) => {
    const { handleAddLiaison } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const {
        formState: { errors: formErrors },
        control,
        handleSubmit,
        register
    } = useForm<FormInputs>({
        resolver: yupResolver(FormInputsSchema),
        defaultValues: {
            subUser: null,
            title: ''
        }
    });

    //this component should allow the user to search for a liaison by email, give them a title and add them to the organization
    //Strategy: use a select component to search for a liaison by email, then use a text field to give them a title, then use a button to add them to the organization
    //TODO: implement this component

    const pageReq: EntityTableRequest = {
        firstRow: 0,
        lastRow: 10,
        searchQuery: searchTerm,
        filters: {},
        sortModel: []
    };
    const subusersQuery = UserApi.bulkByQuery.useQuery(pageReq);
    const subUsers = useMemo(() => subusersQuery.data?.rows ?? [], [subusersQuery.data]);
    //When the search input to the select component changes, call getSubusers with the search input as the search term
    //and set the options of the select component to the results ids
    //The above should be debounced so that it doesn't make a call for every keystroke
    //the options for select should show up as name and email but the values for those options should be the id

    const debouncedSearch = _.debounce(async (value) => {
        console.log('debounced search', value);
    }, 300);

    const formSubmitHandler: SubmitHandler<FormInputs> = async (
        data: FormInputs
    ) => {
        // console.log('submitting', data);
        if (data.subUser) {
            const addedPrimaryLiaison: Liaison = {
                subuser_id: data.subUser.subuser_id,
                name: data.subUser?.name,
                title: data.title,
                email: data.subUser.email,
                phone: data.subUser.phone
            };
            handleAddLiaison(addedPrimaryLiaison);
        }
    };

    const onInvalid = (errors: unknown): void =>
        console.error('there are form errors:', errors);

    const handleClickSubmit = async () => {
        await handleSubmit(formSubmitHandler, onInvalid)();
    };

    return (
        <Box>
            <Stack spacing={2} marginTop={2}>
                <Controller
                    name="subUser"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            value={field.value}
                            options={subUsers}
                            inputValue={searchTerm}
                            clearOnEscape={false}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.email})`
                            }
                            onInputChange={(_event, value) => {
                                setSearchTerm(value);
                                debouncedSearch(value);
                            }}
                            onChange={(_event, value) => {
                                field.onChange(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select liaison"
                                    variant="outlined"
                                    error={!!formErrors.subUser}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => {
                                return value
                                    ? option.subuser_id === value.subuser_id
                                    : false;
                            }}
                            loading={subusersQuery.isLoading}
                            size="small"
                            defaultValue={null}
                        />
                    )}
                />

                <TextField
                    size="small"
                    label="Liaison Title"
                    placeholder="Director Of Athletics"
                    error={!!formErrors.title}
                    {...register('title')}
                />
                {subusersQuery.isError && (
                    <Alert severity="error">{subusersQuery.error.message}</Alert>
                )}
                <Button
                    variant="contained"
                    sx={{ backgroundColor: 'primary.light' }}
                    size="small"
                    onClick={handleClickSubmit}
                    // disabled={getValues('subUser') === null}
                >
                    Add Liaison
                </Button>
            </Stack>
        </Box>
    );
};
