import React from 'react';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
// import "./AddUserModal.css";
import UsersForm from '../../../controls/UsersForm/UsersForm';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function AddUserModal({
  open,
  handleClose
}: Props): JSX.Element {
  return (
    <StandardModal
      open={open}
      handleClose={handleClose}
      headerText={'Add User'}
    >
      <>
        <UsersForm handleClose={handleClose} />;
      </>
    </StandardModal>
  );
}
