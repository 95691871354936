/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Chip, Typography, useTheme } from '@mui/material';
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import NotesApi from '../../../../api/NotesApi';
import createDefaultObject from '../../../../helpers/createDefaultObject';
import useResolveFilters from '../../../../helpers/useResolveFilters';
import { UserSeverity } from '../../../../structures/enums';
import {
    CacheContextI,
    FilterCriteria
} from '../../../../structures/interfaces';
import AddEditEntityTableButtons from '../../../Templates/EntityTable/AddEditEntityTableButtons';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers,
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import AddPermissionsModal from '../../PermissionsPage/AddPermissionsModal';
import EditPermissionsModal from '../../PermissionsPage/EditPermissionsModal';
import NotesTableFilterMenu from './NotesTableFilterMenu';
import { AuthoredSeverityChangeCell } from './AuthoredSeverityChangeCell';
import {UserIdToUser} from "src/components/Templates/EntityTable/userIdToUser/UserIdToUser"
import { IdCell } from '../../../Templates/EntityTable/IdCell';
import { useGridApiRef } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import dayjs from 'dayjs';

export const PermissionCacheContext = React.createContext<CacheContextI | null>(
    null
);

export const PermissionFilterContext =
    React.createContext<FilterCriteria | null>(null);

interface Props {
    permaFilters?: FilterCriteria;
}

function UserNotesTable(props: Props): JSX.Element {
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'id',
            headerName: 'ID',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams): JSX.Element => (
                <IdCell id={params.value} link={`viewNote/${params.value}`} />
            )
        },
        {
            field: 'authorId',
            headerName: 'AUTHOR',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams): JSX.Element => (
                <UserIdToUser userId={params.value} />
            )
        },
        {
            field: 'severity',
            headerName: 'SEVERITY',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams): JSX.Element => (
                <AuthoredSeverityChangeCell severityData={params.value} />
            )
        },
        {
            field: 'createdAt',
            headerName: 'DATE CREATED',
            width: 200,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams): JSX.Element => {
                // console.log('params', params);
                // turn params.value.date into the date and time of the broswer
                const date = params.value.date;

                return (
                    <Box>
                        <Typography variant="body1">
                            {date
                                ? dayjs(date).format('MM/DD/YYYY hh:mm A')
                                : 'N/A'}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: 'content',
            headerName: 'NOTE',
            width: 400,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams): JSX.Element => {
                return <Box>{params.value}</Box>;
            }
        }
    ];

    function generateEditPermissionsModal(
        selectedEntities: GridSelectionModel
    ): JSX.Element {
        return <EditPermissionsModal selectionModel={selectedEntities} />;
    }

    function generateAddPermissionsModal(): JSX.Element {
        return <AddPermissionsModal />;
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen,
        handleClose,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <NotesTableFilterMenu
                        filters={filterCriteria}
                        setFilters={setFilterCriteria}
                        handleClose={handleClose}
                    />
                }
            />
        );
    };

    return (
        <PermissionCacheContext.Provider value={{ outOfDate, setOutOfDate }}>
            <EntityTable
                tableKey={['notes']}
                generateColumns={generateColumns}
                generateEditEntityModal={generateEditPermissionsModal}
                generateAddEntityModal={generateAddPermissionsModal}
                entityDetailModal={<></>}
                generateEntityFilterForm={generateFilterForm}
                api={NotesApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                generateTableActions={() => <></>}
                apiRef={apiRef}
                initialState={{
                    permaFilters: props.permaFilters
                }}
            />
        </PermissionCacheContext.Provider>
    );
}

export default UserNotesTable;
