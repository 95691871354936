/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as metricDB from '../../api/mockDB/metric';
import * as userDB from '../../api/mockDB/user';
import {
  Assessment,
  AssessmentRecord,
  AssessmentResponse,
  AssessmentSummary,
  AssessmentSummaryResponse
} from '../../structures/assessmentInterfaces';
import { DashboardMetrics, UserSeverity } from '../../structures/enums';
import {
  DataOverTimeI,
  NoteSummary,
  Permission,
  PermissionSummary,
  ResponseStatus
} from '../../structures/interfaces';
import {
  AssessmentRecordSummary,
  AssessmentRecordSummaryResponse,
  QuestionAnswerResponse,
  SetReviewedStatusResponse
} from '../../structures/assessmentRecordInterfaces';
import {
  SubUserResponse,
  User,
  UserSummary
} from '../../structures/userInterfaces';
import {
  BaseOrganization,
  Group,
  GroupSummaryResponse,
  OrgGroup,
  OrgSummary,
  Organization,
  OrganizationDetailsFormInputI,
  UserGroupsResponse
} from '../../structures/organizationInterfaces';
import { ProfileData } from '../../structures/profile';
import { BackendLibraryI, RequestResponse } from '../../structures/responses';
import { UserSessionData } from '../../structures/userSession';
import { baseLib } from './mainHandler';
import { TableRows } from '../../api/EntityApi';
import { last } from 'lodash';
import { generateSubUserRows } from '../../helpers/mockGeneration/subUserMockers';
import { ScheduleSummaryResponse } from '../../structures/scheduleInterfaces';
import {
  generateAssessmentRecordRows,
  generateAssessmentRows
} from '../../helpers/mockGeneration/assessmentMockers';
import { generateBaseOrganizations } from '../../helpers/mockGeneration/organizationMockers';
import { generateNoteSummaryRows } from '../../helpers/mockGeneration/noteMockers';

const stableSubUserRows = generateSubUserRows(25);
const stableAssessments = generateAssessmentRows(25);
const stableBaseOrganizations = generateBaseOrganizations(25);
const stableAssessmentRecords = generateAssessmentRecordRows(25);
const stableNotes = generateNoteSummaryRows(25);
const apiUrl = '/mock';

export const actionLibrary: BackendLibraryI = {
  get_metric: async (variables: Variables): Promise<RequestResponse<any>> => {
    const metric: DataOverTimeI | undefined = await metricDB.read(
      variables.type as DashboardMetrics,
      variables.date as string
    );
    const data = {
      status: 'success',
      resp_variables: metric
    };
    return data;
  },
  get_subuser_summary: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const usersSummary: UserSummary[] | undefined =
      await baseLib.get_subuser_summary(variables);
    const data = {
      status: 'success',
      resp_variables: {
        rows: usersSummary,
        totalRows: 2
      }
    };
    return data;
  },
  get_user: async (variables: Variables): Promise<RequestResponse<any>> => {
    const user: User | undefined = await userDB.read(variables.id as number);
    const data = {
      status: 'success',
      resp_variables: user
    };
    return data;
  },
  create_user: async (variables: Variables): Promise<RequestResponse<any>> => {
    const user: User | undefined = await userDB.read(
      variables.userId as number
    );
    const data = {
      status: 'success',
      resp_variables: user
    };
    return data;
  },
  update_users: async (variables: Variables): Promise<RequestResponse<any>> => {
    const users: User[] = await baseLib.update_users(variables);
    const data = {
      status: 'success',
      resp_variables: users
    };
    return data;
  },
  get_assessment_record_summaries: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const assessmentRecordSummaries: AssessmentRecordSummaryResponse[] =
      stableAssessmentRecords.slice(0, 5);
    const data = {
      status: 'success',
      resp_variables: {
        rows: assessmentRecordSummaries,
        totalRows: 5
      }
    };
    return data;
  },
  get_assessment_summaries: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const assessmentSummaries: AssessmentSummary[] | undefined =
      await baseLib.get_assessment_summaries(variables);
    const data = {
      status: 'success',
      resp_variables: {
        rows: assessmentSummaries,
        totalRows: 2
      }
    };
    // console.log('returning the data', data);
    return data;
  },
  get_orgs_summary: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const orgsSummary: OrgSummary[] | undefined =
      await baseLib.get_orgs_summary(variables);
    const data = {
      status: 'success',
      resp_variables: {
        rows: orgsSummary,
        totalRows: 2
      }
    };
    return data;
  },
  get_assessment: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const assessment: Assessment | undefined = await baseLib.get_assessment(
      variables
    );
    const data = {
      status: 'success',
      resp_variables: assessment
    };
    return data;
  },
  get_organization: async (
    variables: Variables
  ): Promise<RequestResponse<BaseOrganization>> => {
    const data: RequestResponse<BaseOrganization> = {
      status: 'success',
      resp_variables: {
        org_id: 'dkijs',
        name: 'dkijs',
        primaryLiaison: {
          subuser_id: '3280sk',
          title: 'dkijs',
          name: 'dkijs',
          email: 'dkijs',
          phone: 'dkijs',
          associated_user_id: 'dkijs'
        },
        updated_on: 'dkijs',
        created_on: 'dkijs',
        address1: 'dkijs',
        address2: 'dkijs',
        city: 'dkijs',
        state: 'Texas',
        zip: 'dkijs',
        country: 'United States',
        status: 'Active',
        type: 'University'
      }
    };
    return data;
  },
  get_permissions_summary: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const permissionsSummary: PermissionSummary[] =
      await baseLib.get_permissions_summary(variables);
    const data = {
      status: 'success',
      resp_variables: permissionsSummary
    };
    return data;
  },
  get_schedule_summaries: async (
    variables: Variables
  ): Promise<RequestResponse<TableRows<ScheduleSummaryResponse>>> => {
    const scheduleSummaries: ScheduleSummaryResponse[] =
      await baseLib.get_schedule_summaries(variables);
    const data = {
      status: 'success',
      resp_variables: {
        rows: scheduleSummaries,
        totalRows: 2
      }
    };
    return data;
  },
  get_permision: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const permission: Permission | undefined = await baseLib.get_permission(
      variables
    );
    const data = {
      status: 'success',
      resp_variables: permission
    };
    return data;
  },
  update_assessment: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const response: ResponseStatus = await baseLib.create_assessment(variables);
    //TODO: fix this thing
    const data = {
      status: 'success',
      resp_variables: {
        id: 10000
      }
    };
    return data;
  },
  update_permissions: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const userIds: number[] = await baseLib.update_permissions(variables);
    const data = {
      status: 'success',
      resp_variables: userIds
    };
    return data;
  },
  get_org_form_resp_variables: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const orgFormData: OrganizationDetailsFormInputI | undefined =
      await baseLib.get_org_Organization_form_data(variables);
    const data = {
      status: 'success',
      resp_variables: orgFormData
    };
    return data;
  },
  get_groups_summary: async (
    variables: Variables
  ): Promise<RequestResponse<TableRows<GroupSummaryResponse>>> => {
    const firstRow = variables.firstRow as unknown as number;
    const lastRow = variables.lastRow as unknown as number;
    // hardcoding id for now
    const groupSummaries = [].slice(firstRow, lastRow);
    const data = {
      status: 'success',
      resp_variables: {
        rows: groupSummaries,
        totalRows: 2
      }
    };
    return data;
  },
  update_organization: async (
    variables: Variables
  ): Promise<RequestResponse<{ org_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        org_id: '10000'
      }
    };
    return data;
  },
  get_notes_summary: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const firstRow = variables.firstRow as unknown as number;
    const lastRow = variables.lastRow as unknown as number;
    const totalRows = lastRow - firstRow;
    // hardcoding id for now
    const groupSummaries = stableNotes.slice(firstRow, lastRow);
    const data = {
      status: 'success',
      resp_variables: {
        rows: groupSummaries,
        totalRows: 2
      }
    };
    return data;

    return data;
  },
  get_assessment_record: async (
    variables: Variables
  ): Promise<RequestResponse<AssessmentRecord>> => {
    const assessmentRecord: AssessmentRecord =
      await baseLib.get_assessment_record(variables);
    const data = {
      status: 'success',
      resp_variables: assessmentRecord
    };
    return data;
  },
  get_profile_info: async (
    variables: Variables
  ): Promise<RequestResponse<ProfileData>> => {
    const data = {
      status: 'success',
      resp_variables: {
        user_id: '23423',
        first_name: 'John',
        last_name: 'Doe',
        email: 'alfred@stevemadere.com',
        phone_number: 5122990497
      }
    } as RequestResponse<ProfileData>;
    return data;
  },
  send_validate_phone_text: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: {}
    };
    return data;
  },
  validate_phone_change: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: {}
    };
    return data;
  },
  edit_password: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: {}
    };
    return data;
  },
  SESS: async (
    variables: Variables
  ): Promise<RequestResponse<UserSessionData>> => {
    const data = {
      status: 'success',
      resp_variables: {
        current_org: 'askedfj',
        org_name: 'askedfj',
        server_type: 'askedfj',
        user_email: 'askedfj',
        user_id: 'askedfj',
        user_perm: 'askedfj'
      }
    } as RequestResponse<UserSessionData>;
    return data;
  },
  update_profile_info: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    // console.log('update profile info', variables);
    const data = {
      status: 'success',
      resp_variables: {}
    };
    return data;
  },
  add_assessment_note: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: {}
    };
    return data;
  },
  mark_all_assessment_records_reviewed: async (
    variables: Variables
  ): Promise<RequestResponse<string[]>> => {
    const data = {
      status: 'success',
      resp_variables: ['adkfja', 'alksdjf', 'akjd']
    };
    return data;
  },
  get_assessment_record_severity: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: {
        severity: UserSeverity.MEDIUM
      }
    };
    return data;
  },
  get_filter_options: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: { options: ['option 1', 'option 2', 'option 3'] }
    };
    return data;
  },
  get_user_name: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const data = {
      status: 'success',
      resp_variables: { name: 'John Doe', id: variables.userId }
    };
    return data;
  },
  get_user_names_bulk: async (
    variables: Variables
  ): Promise<RequestResponse<any>> => {
    const user_ids = variables.user_ids as string[];
    const data = {
      status: 'success',
      resp_variables: [
        {
          username: 'alfred@stevemadere.com',
          user_id: user_ids[0],
          fname: 'Alfredd',
          lname: 'Madere'
        },
        {
          username: '2wconvery@gmail.com',
          user_id: user_ids[1],
          fname: 'Wes',
          lname: 'Convery'
        }
      ]
    };
    return data;
  },
  update_subuser: async (
    variables: Variables
  ): Promise<RequestResponse<{ subuser_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        subuser_id: '1234'
      }
    };
    return data;
  },
  get_sub_users_by_query: async (
    variables: Variables
  ): Promise<RequestResponse<TableRows<SubUserResponse>>> => {
    try {
      const firstRow = variables.firstRow as unknown as number;
      const lastRow = variables.lastRow as unknown as number;
      const totalRows = lastRow - firstRow;
      // hardcoding id for now
      const userSummaries = stableSubUserRows.slice(firstRow, lastRow);
      const data = {
        status: 'success',
        resp_variables: {
          rows: userSummaries,
          totalRows: totalRows || 7
        }
      };
      return Promise.resolve(data);
    } catch (err) {
      console.log(' handler', err);
      throw err;
    }
  },
  get_subuser_basic: async (
    variables: Variables
  ): Promise<RequestResponse<SubUserResponse>> => {
    const data = {
      status: 'success',
      resp_variables: {
        subuser_id: '1234',
        name: 'John Doe',
        email: 'sdfsjE@skdj.com',
        phone: '5122990497',
        date_added: '2020-01-01',
        associated_user_id: 'faker.random.uuid(sdk)'
      }
    };
    return data;
  },
  get_org_groups: async (
    variables: Variables
  ): Promise<RequestResponse<OrgGroup[]>> => {
    const data = {
      status: 'success',
      resp_variables: [
        {
          group_id: '1234',
          name: 'Group 1',
          category: 'Basketball',
          liaisons: [
            {
              id: '1234',
              title: 'title 1'
            }
          ]
        }
      ]
    };
    return data;
  },
  attach_group_to_organization: async (
    variables: Variables
  ): Promise<RequestResponse<{ group_id: string; org_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        group_id: '1234',
        org_id: '1234'
      }
    };
    return data;
  },
  attach_subuser_to_group: async (
    variables: Variables
  ): Promise<RequestResponse<{ group_id: string; subuser_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        group_id: '1234',
        subuser_id: '1234'
      }
    };
    return data;
  },

  update_group: async (
    variables: Variables
  ): Promise<RequestResponse<{ group_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        group_id: '1234'
      }
    };
    return data;
  },
  remove_subuser_from_group: async (
    variables: Variables
  ): Promise<RequestResponse<{ group_id: string; subuser_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        group_id: '1234',
        subuser_id: '1234'
      }
    };
    return data;
  },
  get_subuser_groups: async (
    variables: Variables
  ): Promise<RequestResponse<UserGroupsResponse>> => {
    const data = {
      status: 'success',
      resp_variables: {
        subuser_id: 'dkjs',
        groups: [
          {
            group_id: '1234',
            group_name: 'Epic group',
            org_id: '1234',
            org_name: 'Epic org',
            category: 'Basketball'
          }
        ]
      }
    };
    return data;
  },
  add_subuser_to_group: async (
    variables: Variables
  ): Promise<RequestResponse<{ group_id: string; subuser_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        group_id: '1234',
        subuser_id: '1234'
      }
    };
    return data;
  },
  change_schedule_status: async (
    variables: Variables
  ): Promise<RequestResponse<{ schedule_id: string; status: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        schedule_id: '1234',
        status: 'ended'
      }
    };
    return data;
  },
  update_schedule: async (
    variables: Variables
  ): Promise<RequestResponse<{ schedule_id: string }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        schedule_id: '1234'
      }
    };
    return data;
  },
  get_subusers_bulk: async (
    variables: Variables
  ): Promise<RequestResponse<SubUserResponse[]>> => {
    try {
      const userSummaries = (variables.subuser_ids as string[]).map((id, i) => {
        const user = stableSubUserRows[i];
        user.subuser_id = id;
        return user;
      });
      // hardcoding id for now

      const data = {
        status: 'success',
        resp_variables: userSummaries
      };
      return data;
    } catch (err) {
      console.error(' handler', err);
      throw err;
    }
  },
  get_bulk_assessments: async (
    variables: Variables
  ): Promise<RequestResponse<AssessmentSummaryResponse[]>> => {
    try {
      const assessments = (variables.assessment_ids as string[]).map(
        (id, i) => {
          const assessment = stableAssessments[i];
          assessment.assessment_id = id;
          return assessment;
        }
      );
      // hardcoding id for now

      const data = {
        status: 'success',
        resp_variables: assessments
      };
      return data;
    } catch (err) {
      console.error(' handler', err);
      throw err;
    }
  },
  get_org_pl: async (
    variables: Variables
  ): Promise<RequestResponse<SubUserResponse>> => {
    const data = {
      status: 'success',
      resp_variables: {
        subuser_id: '1234',
        name: 'John Doe',
        email: 'sdfsjE@skdj.com',
        phone: '5122990497',
        date_added: '2020-01-01',
        associated_user_id: 'faker.random.uuid(sdk)'
      }
    };
    return data;
  },
  get_bulk_organizations: async (
    variables: Variables
  ): Promise<RequestResponse<BaseOrganization[]>> => {
    const organizations = (variables.org_ids as string[]).map((id, i) => {
      const organization = stableBaseOrganizations[i];
      organization.org_id = id;
      return organization;
    });
    // hardcoding id for now

    const data = {
      status: 'success',
      resp_variables: organizations
    };
    return data;
  },
  get_subuser_orgs: async (
    variables: Variables
  ): Promise<RequestResponse<BaseOrganization[]>> => {
    const data = {
      status: 'success',
      resp_variables: [stableBaseOrganizations[0], stableBaseOrganizations[1]]
    };
    return data;
  },
  set_reviewed_status: async (
    variables: Variables
  ): Promise<RequestResponse<SetReviewedStatusResponse>> => {
    const data = {
      status: 'success',
      resp_variables: {
        assessment_record_id: '1234',
        reviewed: true
      }
    };
    return data;
  },
  change_assessment_status: async (
    variables: Variables
  ): Promise<RequestResponse<{ assessment_id: string; retired: number }>> => {
    const data = {
      status: 'success',
      resp_variables: {
        assessment_id: '1234',
        retired: 1
      }
    };
    return data;
  }
};

interface Variables {
  action: string;
  request_library: string;
  [key: string]: string | number | unknown;
}

export interface ReqForm {
  lib: string;
  variables: string;
}

export async function performAction(
  variables: Variables,
  actions: BackendLibraryI
): Promise<Promise<RequestResponse<any>>> {
  if (variables.action in actionLibrary) {
    const res = await actionLibrary[variables.action](variables).catch(
      (err: string) => console.log(err)
    );
    if (res === undefined) {
      throw new Error(
        'undefined behavior for vars' + JSON.stringify(variables)
      );
    }
    return res;
  } else {
    console.log('the vars were', variables);
    throw new Error('no handler found for: ' + variables.action);
  }
}
