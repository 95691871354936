/* eslint-disable @typescript-eslint/no-unused-vars */
import { dbData } from './metricDB';
import { MetricI, DataOverTimeI } from '../../structures/interfaces';
import { User } from '../../structures/userInterfaces';
import { PartialUser } from './user';
import { DashboardMetrics } from '../../structures/enums';
import dayjs from 'dayjs';

interface MetricHistory {
  latest: DataOverTimeI;
  previous: DataOverTimeI;
}

interface MetricDB {
  type: DashboardMetrics;
  history: MetricHistory;
}

const metrics: MetricDB[] = [...dbData];

// async function create(user: User): Promise<User> {
//   users.push(user);
//   return user;
// }

// async function readAllUnordered(): Promise<MetricI[]> {
//   return metrics;
// }

async function read(
  metricType: DashboardMetrics,
  date: string
): Promise<DataOverTimeI | undefined> {
  const targetMetric = metrics.find((metric) => metric.type === metricType);
  let targetHistoricalData;
  if (targetMetric !== undefined) {
    // use date to decide if latest should be returned

    const objDate = dayjs(date);
    if (objDate.startOf('week') < dayjs().startOf('week')) {
      targetHistoricalData = targetMetric.history.previous;
    } else {
      targetHistoricalData = targetMetric.history.latest;
    }
  }

  return targetHistoricalData;
  // figure out correct typings
}

// interface Obj {
//   [key: string]: User;
// }

// function groupById(array: User[]): Obj {
//   return array.reduce((obj: Obj, value) => {
//     obj[value.userId] = value;
//     return obj;
//   }, {});
// }

// async function update(
//   id: string,
//   update: PartialUser
// ): Promise<User | undefined> {
//   users.forEach((user) => {
//     if ((user.userId as unknown) === id) {
//       return { ...user, update };
//     }
//   });
//   return await read(id);
// }

// async function deleteUser(userId: string): Promise<User[]> {
//   return users.filter((user) => (user.userId as unknown) !== userId);
// }

// async function reset(): Promise<void> {
//   users = [...usersData];
// }

export { read };
