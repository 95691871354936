/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import * as assessmentDB from '../../api/mockDB/assessments';
import * as groupDB from '../../api/mockDB/group/groups';
import * as metricDB from '../../api/mockDB/metric';
import * as organizationDB from '../../api/mockDB/organization';
import * as userDB from '../../api/mockDB/user';
import {
  DashboardMetrics,
  StatusType,
  UserSeverity
} from '../../structures/enums';
import {
  DataOverTimeI,
  FilterCriteria,
  MetricI,
  NoteSummary,
  PermissionSummary,
  ResponseStatus,
  UpdatePermissionRequest
} from '../../structures/interfaces';
import { AssessmentRecordSummary } from '../../structures/assessmentRecordInterfaces';
import {
  UpdateSubUserRequest,
  User,
  UserSummary,
  UserSummaryResponse
} from '../../structures/userInterfaces';
import {
  AssessmentCategories,
  AssessmentFrequency,
  AssessmentStatus,
  QuestionTypes
} from '../../structures/assessmentEnums';
import {
  Assessment,
  AssessmentRecord,
  AssessmentSummaryResponse,
  AssessmentSummary,
  AssessmentResponse
} from '../../structures/assessmentInterfaces';
import { randomSixDigitInteger } from '../../helpers/randomNumber';
import { generateSummaryRows as generateOrgSummaryRows } from '../../helpers/mockGeneration/organizationMockers';
import {
  Group,
  OrgSummary,
  OrgSummaryResponse,
  Organization,
  OrganizationDetailsFormInputI
} from '../../structures/organizationInterfaces';
import { generateUserSummaryRows } from '../../helpers/mockGeneration/userMockers';
import { ScheduleSummaryResponse } from '../../structures/scheduleInterfaces';
import { generateScheduleSummaryRows } from '../../helpers/mockGeneration/scheduleMockers';

interface MetricBody {
  metric: MetricI;
}

interface MetricReq {
  metricType: DashboardMetrics;
  date: string;
}

const apiUrl = '/mock';

// export const handlers = [
//   rest.post<ReqForm>(`${apiUrl}`, async (req, res, ctx) => {
//     const dbResponse = await mockInternalRounting(req.body)
//     console.log("dbResponce", dbResponse)
//     if (dbResponse === false) {
//       return await res(
//         ctx.status(404),
//         ctx.json({ status: 404, message: "User not found" })
//       );
//     }

//     return await res(ctx.json(dbResponse));
//   }),
// ];

interface BackendLibraryI {
  [key: string]: Function;
}

const stableUserSummaries = generateUserSummaryRows(25);
const stableOrgSummaries = generateOrgSummaryRows(25);
const stableScheduleSummaries = generateScheduleSummaryRows(25);

export const baseLib: BackendLibraryI = {
  get_metric: async (
    variables: Variables
  ): Promise<DataOverTimeI | undefined> => {
    return await metricDB.read(
      variables.type as DashboardMetrics,
      variables.date as string
    );
  },
  get_subuser_summary: async (
    variables: Variables
  ): Promise<UserSummaryResponse[] | undefined> => {
    try {
      const filters: any[] = variables.filters as any[]; // this is messed up, hacking for now to focus on front end stuff

      // hardcoding id for now
      let lastIndex = 7;
      if (
        (variables.firstRow as unknown as number) &&
        (variables.lastRow as unknown as number)
      ) {
        lastIndex =
          (variables.lastRow as unknown as number) -
          (variables.firstRow as unknown as number);
        console.log('assigned last index to ', lastIndex);
      }
      return stableUserSummaries.slice(0, lastIndex);
    } catch (err) {
      console.log(' handler', err);
    }
  },
  get_user: async (variables: Variables): Promise<User | undefined> => {
    return await userDB.read(variables.userId as number);
  },
  create_user: async (variables: Variables): Promise<User | undefined> => {
    return await userDB.read(variables.userId as number);
  },

  get_assessment_summaries: async (
    variables: Variables
  ): Promise<AssessmentSummaryResponse[] | undefined> => {
    try {
      const filters: any[] = variables.filters as any[]; // this is messed up, hacking for now to focus on front end stuff
      const assessmentSummaries = [
        {
          assessment_id: '42de9a64f3102e13685d3afbef6d2284',
          description: 'asda',
          scoringThresholds: [0, 26, 60, 80, 100],
          scoreRange: [
            {
              value: 0
            },
            {
              value: 100
            }
          ],
          category: ['Depression'],
          track: '1',
          status: 'Active',
          recurrence: 4,
          cadence: 'Monthly',
          royalty: 'false',
          friendlyName: 'abc',
          codeName: 'zzz',
          assessmentUmbrella: 'asd',
          created_by: '6602fbf0',
          updated_by: '6602fbf0',
          delete_status: 0,
          createdAt: '2023-05-22 13:53:21',
          updatedAt: '2023-05-22 13:53:21'
        },
        {
          assessment_id: '79e605e810c542c1ef3ec2fc67f6sd8db0',
          description: 'asda',
          scoringThresholds: [0, 26, 60, 80, 100],
          scoreRange: [
            {
              value: 0
            },
            {
              value: 100
            }
          ],
          category: ['Depression'],
          track: '1',
          status: 'Active',
          recurrence: 4,
          cadence: 'Monthly',
          royalty: 'false',
          friendlyName: 'bcd',
          codeName: 'aaa',
          assessmentUmbrella: 'asd',
          created_by: '6602fbf0',
          updated_by: '6602fbf0',
          delete_status: 0,
          createdAt: '2023-05-22 02:39:18',
          updatedAt: '2023-05-22 02:39:18'
        }
      ];
      let lastIndex = 7;
      if (
        (variables.firstRow as unknown as number) &&
        (variables.lastRow as unknown as number)
      ) {
        lastIndex =
          (variables.lastRow as unknown as number) -
          (variables.firstRow as unknown as number);
        console.log('assigned last index to ', lastIndex);
      }
      return assessmentSummaries.slice(0, lastIndex);
    } catch (err) {
      console.log(' handler', err);
    }
  },
  get_orgs_summary: async (
    variables: Variables
  ): Promise<OrgSummaryResponse[] | undefined> => {
    const filters: any[] = variables.filters as any[]; // this is messed up, hacking for now to focus on front end stuff

    let lastIndex = 7;
    if (
      (variables.firstRow as unknown as number) &&
      (variables.lastRow as unknown as number)
    ) {
      lastIndex =
        (variables.lastRow as unknown as number) -
        (variables.firstRow as unknown as number);
    }
    return stableOrgSummaries.slice(0, lastIndex);
  },
  get_assessment: async (
    variables: Variables
  ): Promise<AssessmentResponse | undefined> => {
    return {
      status: AssessmentStatus.ACTIVE,
      track: '1',
      assessmentUmbrella: 'My Assessment',
      codeName: 'MA 1',
      friendlyName: 'Checkup',
      royalty: 'false',
      cadence: AssessmentFrequency.MONTHLY,
      reccurance: 4,
      category: [AssessmentCategories.SUBSTANCE_ABUSE],
      scoreRange: [{ value: 0 }, { value: 100 }],
      scoringThresholds: [0, 40, 60, 80, 100],
      description: 'My Assessment',
      questions: [
        {
          type: QuestionTypes.MULTIPLE_CHOICE,
          prompt: 'my good prompt',
          assessment_id: 'jskalk',
          question_id: 'kdjaslksj',
          options: [
            {
              points: 3,
              flag: false,
              text: 'awesome option'
            }
          ]
        }
      ],
      assessment_id: 'skdajs'
    };
  },
  get_org_Organization: async (
    variables: Variables
  ): Promise<Organization | undefined> => {
    return await organizationDB.read(variables.id as number);
  },
  get_permissions_summary: async (): Promise<PermissionSummary[]> => {
    const mockPermissionSummaries: PermissionSummary[] = [
      {
        id: 10000,
        role: 'Admin',
        userName: 'Lori Seviola',
        userPhone: '5122990497',
        userEmail: 'alfred@stevemadere.com',
        effectiveDate: dayjs().toISOString()
      },
      {
        id: 10001,
        role: 'Employee',
        userName: 'Bobby Joe',
        userPhone: '5126982777',
        userEmail: 'example@employeeemail.com',
        effectiveDate: dayjs().toISOString()
      }
    ];
    return mockPermissionSummaries;
  },
  get_schedule_summaries: async (
    variables: Variables
  ): Promise<ScheduleSummaryResponse[] | undefined> => {
    try {
      const filters: any[] = variables.filters as any[]; // this is messed up, hacking for now to focus on front end stuff

      // hardcoding id for now
      let lastIndex = 7;
      if (
        (variables.firstRow as unknown as number) &&
        (variables.lastRow as unknown as number)
      ) {
        lastIndex =
          (variables.lastRow as unknown as number) -
          (variables.firstRow as unknown as number);
        console.log('assigned last index to ', lastIndex);
      }
      return stableScheduleSummaries.slice(0, lastIndex);
    } catch (err) {
      console.log(' handler', err);
    }
  },
  get_permission: async (
    variables: Variables
  ): Promise<UpdatePermissionRequest> => {
    return {
      id: '10001',
      role: 'Admin',
      memberEmail: 'admin@john.com',
      effectiveDate: dayjs().toISOString()
    };
  },
  create_assessment: async (variables: Variables): Promise<ResponseStatus> => {
    const assessmentAttributes = variables.assessment as Assessment;
    const newAssessment = {
      ...assessmentAttributes
    };
    console.log('creating assessment', newAssessment);
    return { success: true };
  },
  update_permissions: async (variables: Variables): Promise<number[]> => {
    const response = [];
    for (const update of variables.permissionUpdates as UpdatePermissionRequest[]) {
      let userUpdateRes;
      if (update.id != null) {
        userUpdateRes = update.id;
      } else {
        const newPermission = {
          ...update,
          id: Math.floor(Math.random() * 1000000)
        };
        userUpdateRes = newPermission.id;
        console.log('creating permission', newPermission);
      }
      response.push(userUpdateRes as number); // this is trash dont do this
    }
    return response;
  },

  get_groups_summary: async (
    variables: Variables
  ): Promise<Group[] | undefined> => {
    return await groupDB.readBy(variables.OrganizationId as number);
  },
  create_Organization: async (
    variables: Variables
  ): Promise<OrganizationDetailsFormInputI | undefined> => {
    const response = {};
    const orgUpdateRes = await organizationDB.create(
      variables.orgToCreate as OrganizationDetailsFormInputI
    );
    return orgUpdateRes;
  },
  get_assessment_record: async () => {
    const data: AssessmentRecord = {
      id: 10000,
      assessmentId: 10001,
      assessmentUmbrella: 'MMPI',
      codeName: 'MMPI 1-2',
      friendlyName: 'Mental health!!',
      royalty: 'yes',
      track: 'track 1',
      category: AssessmentCategories.MENTAL_HEALTH,
      scoringThresholds: [30, 50, 85],
      scoreRange: [{ value: 0 }, { value: 100 }],
      description: 'This is a test description',
      score: 87,
      severity: UserSeverity.CRITICAL,
      responses: [
        {
          id: 10000,
          question: {
            id: 10000,
            text: 'How are you feeling today?',
            type: QuestionTypes.MULTIPLE_CHOICE,
            options: [
              {
                id: 10000,
                letter: 'A',
                text: "I'm feeling great!",
                points: 0,
                flag: false
              },
              {
                id: 10001,
                letter: 'B',
                text: "I'm feeling horrible!",
                points: 4,
                flag: false
              },
              {
                id: 10002,
                letter: 'C',
                text: "I've never felt worse!",
                points: 9,
                flag: true
              }
            ]
          },
          flagged: false,
          response: 'B'
        },
        {
          id: 10000,
          flagged: false,
          question: {
            id: 10000,
            text: 'How are your workouts feeling',
            type: QuestionTypes.MULTIPLE_CHOICE,
            options: [
              {
                id: 10000,
                letter: 'A',
                text: 'Slow',
                points: 0,
                flag: false
              },
              {
                id: 10001,
                letter: 'B',
                text: 'Fast',
                points: 4,
                flag: false
              },
              {
                id: 10002,
                letter: 'C',
                text: 'Very slow',
                points: 9,
                flag: true
              }
            ]
          },
          response: 'B'
        },
        {
          id: 10000,
          flagged: true,
          question: {
            id: 10000,
            text: 'How often do you think about stress?',
            type: QuestionTypes.MULTIPLE_CHOICE,
            options: [
              {
                id: 10000,
                letter: 'A',
                text: 'Somewhat often',
                points: 10,
                flag: false
              },
              {
                id: 10001,
                letter: 'B',
                text: 'Never',
                points: 0,
                flag: false
              },
              {
                id: 10002,
                letter: 'C',
                text: 'Most of the time',
                points: 20,
                flag: true
              },
              {
                id: 10002,
                letter: 'D',
                text: 'All the time, every day',
                points: 20,
                flag: true
              }
            ]
          },
          response: 'C'
        }
      ]
    };
    return data;
  }
};

interface Variables {
  action: string;
  request_library: string;
  [key: string]: string | number | unknown;
}

export interface ReqForm {
  lib: string;
  variables: string;
}

export async function mockInternalRounting(body: ReqForm): Promise<any> {
  const parsedVariables = JSON.parse(body.variables);

  if (parsedVariables.action in baseLib) {
    const res = await baseLib[parsedVariables.action](parsedVariables).catch(
      (err: string) => console.log(err)
    );
    if (res === undefined) {
      console.log('I know where this is throwing');
      throw new Error('undefined behavior for vars', parsedVariables);
    }

    return { res };
  } else {
    throw new Error('no handler found for: ', parsedVariables.action);
  }
}
