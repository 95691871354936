import z from 'zod';
import validator from 'validator';

const TWILIO_SUPPORTED_COUNTRIES = [
    'United States',
    'Canada',
    'Australia',
    'Chile',
    'Czech Republic',
    'Estonia',
    'Hungary',
    'Israel',
    'Italy',
    'Lithuania',
    'Philippines',
    'Poland',
    'Portugal',
    'Switzerland',
    'Sweden',
    'United Kingdom'
] as const;

type SupportedCountry = (typeof TWILIO_SUPPORTED_COUNTRIES)[number];

type MaybeArray<T> = T | T[];

const TWILIO_SUPPORTED_LOCALE_MAP: Record<
    SupportedCountry,
    MaybeArray<validator.MobilePhoneLocale>
> = {
    'United States': 'en-US',
    Canada: 'en-CA',
    Australia: 'en-AU',
    Chile: 'es-CL',
    'Czech Republic': 'cs-CZ',
    Estonia: 'et-EE',
    Hungary: 'hu-HU',
    Israel: 'he-IL',
    Italy: 'it-IT',
    Lithuania: 'lt-LT',
    Philippines: 'en-PH',
    Poland: 'pl-PL',
    Portugal: 'pt-PT',
    Switzerland: ['de-CH', 'fr-CH', 'it-CH'],
    Sweden: 'sv-SE',
    'United Kingdom': 'en-GB'
};

const SUPPORTED_EXTENSIONS = {
    'United States': '+1',
    Canada: '+1',
    Australia: '+61',
    Chile: '+56',
    'Czech Republic': '+420',
    Estonia: '+372',
    Hungary: '+36',
    Israel: '+972',
    Italy: '+39',
    Lithuania: '+370',
    Philippines: '+63',
    Poland: '+48',
    Portugal: '+351',
    Switzerland: '+41',
    Sweden: '+46',
    'United Kingdom': '+44'
};

const SUPPORTED_EXTENSIONS_STR = Object.entries(SUPPORTED_EXTENSIONS)
    .map(([country, ext]) => `${country}: ${ext}`)
    .join('\n');

const ALLOWED_LOCALES = Object.values(TWILIO_SUPPORTED_LOCALE_MAP).flat();

export const zPhone = z.coerce
    .string()
    // be nice :)
    .transform((val) => val.trim())
    // validator.isMobilePhone() will not accept a phone number with the NANP country code
    .transform(replaceNANPCode)
    // see comment in removeSeparators
    .transform(removeSeparators)
    // check if its a valid phone number in general
    .refine(validator.isMobilePhone, { message: 'Invalid Phone Number' })
    // check if its of a valid locale (second check for better error message)
    .refine((val) => validator.isMobilePhone(val, ALLOWED_LOCALES), {
        message:
            'Unfortunately your phone number is from an unsupported country. The following countries and their respective extensions are supported:\n' + SUPPORTED_EXTENSIONS_STR
    })
    // sanitize the number for storing in the database in a consistent format
    .transform(sanitizePhoneNumber)
    // third sanity check to make sure the number is still valid after sanitizing
    .refine((val) => val.match(/^[0-9]+$/) !== null, {
        message: 'Internal error. Failed to sanitize phone number'
    });

// Removes all non-numeric characters from a phone number
// does not check if the number is valid (or that is is of valid length)
// NOTE: does not remove country code
function sanitizePhoneNumber(phone: string) {
    // remove all non-numeric characters
    const strippedPhone = phone.replace(/[^0-9]/g, '');
    return strippedPhone;
}

const NANP_CODE = /^\+?011/;
// Checks if a phone number starts with the NANP country code ([+]011)
// and replaces it with a +1
// NANP info: https://en.wikipedia.org/wiki/International_direct_dialing#International_call_prefix
function replaceNANPCode(phone: string) {
    if (NANP_CODE.test(phone) && phone.length > 10) {
        // only replace the first instance of the NANP code
        return phone.replace(NANP_CODE, '1');
    }
    return phone;
}

// removes all allowed separators ['-', ' ', '.'] from the phone number
// this should be done before passing to validator.isMobilePhone
// as it does not accept '.' at all, and only accepts '-' and ' ' occasionally
// this makes the validation more leneient,
// but the validity of the phone number is still checked
function removeSeparators(phone: string) {
    return phone.replace(/[-. ]/g, '');
}
