import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    TextField
} from '@mui/material';
import pluralize from 'pluralize';
import { FilterOperator } from '../../../structures/enums';
import { FilterCriterion } from '../../../structures/interfaces';
import EntityFilter from './EntityFilter';
import {
    GroupSummary,
} from '../../../structures/organizationInterfaces';
import { useEffect, useMemo, useState } from 'react';
import { OrgApi } from '../../../api/OrganizationApi';
import { GroupApi } from '../../../api/GroupApi';
import { useDebouncedETRSearch } from '../../../helpers/debounced-etr-search';

interface Props {
    fieldName: string;
    fieldTitle: string;
    handleCriterionChange: (
        field: string,
        operator: FilterOperator | undefined,
        args: string[] | undefined
    ) => void;
    displayedCriterion: FilterCriterion | null;
    defaultOptions?: string[];
    mapOptionsToDisplayValue?: (option: unknown) => string;
    groupFilterCriterion: FilterCriterion | null;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// FIXME: CLEAN
function OrgFilter(props: Props): JSX.Element {
    const { fieldName, fieldTitle, handleCriterionChange, displayedCriterion } =
        props;
    // FIXME: use setOrgSearch
    const [orgSearch, _setOrgSearch] = useDebouncedETRSearch(500)
    const orgs = OrgApi.sortedSummaries.useQuery(orgSearch);

    const orgNameMap = useMemo(() => {
        if (!orgs.data) return {}
        return Object.fromEntries(orgs.data.rows.map((org) => [org.name, org]));
    }, [orgs.data]);

    // FIXME: use setGroupSearch
    const [groupSearch, _setGroupSearch] = useDebouncedETRSearch(500);
    const groups = GroupApi.sortedSummaries.useQuery(groupSearch);

    useEffect(() => {
        if (!displayedCriterion || !displayedCriterion.arguments || displayedCriterion.arguments.length === 0) {
            handleCriterionChange("user_groups", undefined, undefined);
        }
    }, [displayedCriterion?.arguments])

    function handleChipDelete(): void {
        handleCriterionChange(fieldName, undefined, undefined);
    }

    function generateFilterChip(): JSX.Element {
        const operator = (displayedCriterion?.operator ?? '') as string;
        const numArguments = displayedCriterion?.arguments?.length ?? 0;
        if (numArguments === 0) {
            return <></>;
        }
        return (
            <Chip
                label={operator + ' ' + (numArguments as unknown as string)}
                onDelete={handleChipDelete}
                sx={{ maxWidth: 190 }}
            />
        );
    }

    function genFilterComponent(): JSX.Element {
        return (
            <>
                <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                        multiple
                        id="orgs-filter"
                        options={orgs.data?.rows ?? []}
                        getOptionLabel={(option) => `${option.name}`}
                        isOptionEqualToValue={(option, value) => {
                            return value ? option.name === value.name : false;
                        }}
                        value={
                            displayedCriterion?.arguments
                                ? displayedCriterion.arguments.map(
                                      (arg: string) => ({ name: arg })
                                  )
                                : []
                        }
                        onChange={(_event, value) => {
                            const typedValue =
                                value.length === 0
                                    ? undefined
                                    : value.map((item) => item.name);
                            handleCriterionChange(
                                fieldName,
                                FilterOperator.ANY_OF,
                                typedValue
                            );
                        }}
                        renderOption={(p, option, { selected }) => (
                            <li {...p}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {props.mapOptionsToDisplayValue
                                    ? props.mapOptionsToDisplayValue(option)
                                    : option.name}
                            </li>
                        )}
                        ListboxProps={{ style: { zIndex: 9999 } }}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    placeholder={pluralize(fieldTitle)}
                                />
                            </>
                        )}
                        renderTags={() => null}
                    />
                </FormControl>
                {!!displayedCriterion?.arguments &&
                    displayedCriterion.arguments.map(
                        (orgName) =>
                            orgNameMap[orgName] && (
                                <GroupFilter
                                    key={orgName}
                                    handleCriterionChange={
                                        handleCriterionChange
                                    }
                                    orgName={orgName}
                                    orgId={orgNameMap[orgName].id}
                                    groups={groups.data?.rows ?? []}
                                    groupFilterCriterion={
                                        props.groupFilterCriterion
                                    }
                                />
                            )
                    )}
            </>
        );
    }

    return (
        <EntityFilter
            filterTitle={fieldTitle}
            generateFilterChip={generateFilterChip}
            filterComponent={genFilterComponent()}
        />
    );
}

export default OrgFilter;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Collapse,
    IconButton,
    IconButtonProps,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

interface ExpandMoreProps extends IconButtonProps {
    expand?: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const opts = {...props, expand: undefined}
    return <IconButton {...opts} sx={{ padding: 1 }} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

interface GroupFilterProps {
    orgId: string;
    orgName: string;
    groups: GroupSummary[];
    groupFilterCriterion: FilterCriterion | null;
    handleCriterionChange: (
        field: string,
        operator: FilterOperator | undefined,
        args: string[] | undefined
    ) => void;
}

function GroupFilter({
    orgName,
    orgId,
    groups,
    handleCriterionChange,
    groupFilterCriterion
}: GroupFilterProps) {
    const relevantGroups = useMemo(
        () => groups.filter((group) => group.organizationId === orgId),
        [groups, orgId]
    );
    const [expanded, setExpanded] = useState(false);
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingY: '8px'

                }}
            >
                <Typography variant="subtitle1">{orgName}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}
                onClick={() => setExpanded(!expanded)}
                >
                    <Typography variant="subtitle2">Groups</Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                    </ExpandMore>
                </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                        multiple
                        id={`group-filter-${orgName}`}
                        options={relevantGroups}
                        getOptionLabel={(option) => `${option.name}`}
                        isOptionEqualToValue={(option, value) => {
                            return value ? option.name === value.name : false;
                        }}
                        value={
                            groupFilterCriterion?.arguments
                                ? groupFilterCriterion.arguments.map(
                                      (arg: string) => ({ name: arg })
                                  )
                                : []
                        }
                        onChange={(_, value) => {
                            const typedValue =
                                value.length === 0
                                    ? undefined
                                    : value.map((item) => item.name);
                            handleCriterionChange(
                                'user_groups',
                                FilterOperator.ANY_OF,
                                typedValue
                            );
                        }}
                        renderOption={(p, option, { selected }) => (
                            <li {...p}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        ListboxProps={{ style: { zIndex: 9999 } }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Group" />
                        )}
                    />
                </FormControl>
            </Collapse>
        </div>
    );
}
