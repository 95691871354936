/* eslint-disable @typescript-eslint/no-unused-vars */
import AssignmentIcon from '@mui/icons-material/Assignment';
import RuleIcon from '@mui/icons-material/Rule';

import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import { Typography } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import {UserApi} from '../../../../api/UserApi';
import { ActivationStatus } from '../../../../structures/enums';
import { CacheContextI, Entity, TabI } from '../../../../structures/interfaces';
import { User } from '../../../../structures/userInterfaces';
import EntityDetailModal from '../../../Templates/EntityDetailModal/EntityDetailModal';
import GreenRedPill from '../../../Templates/GreenRedPill/GreenRedPill';
import EditUserModal from '../EditUserModal/EditUserModal';
import UserInfoPanelSection from './UserInfoPanelSection';
import UserAssessmentsViewer from './UserAssessmentsViewer';
import './UserDetailsModal.css';
import UserAssessmentSchedulesViewer from './UserAssessmentSchedulesViewer';
import UserAssignedAssessmentsViewer from "./UserAssignedAssessmentsViewer"

import { UserDetailsPanel } from './UserDetailsPanel';

interface Props {
  fromOrgModal?: boolean;
}

export const UserCacheContext = React.createContext<CacheContextI | null>(null);

export default function UserDetailsModal(props: Props): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();

  const backNavigation = (props.fromOrgModal? -3 : '/users/') as string
    const userId = params.entityId;
    if (!userId) {
        navigate(backNavigation)
        return <></>;
    }

  const pageTabs: TabI[] = [
    {
      name: 'Completed Assessments',
      icon: AssignmentIcon,
      link: 'assessments/',
      renderPage: () => (
        <UserAssessmentsViewer userId={userId} />
      ) // change this to just be an element, should get the user to render from the url
    },
    {
      name: 'Assigned Assessments',
      icon: RuleIcon,
      link: 'assigned/',
      renderPage: () => (
        <UserAssignedAssessmentsViewer userId={params.entityId as unknown as number} />
      ) // change this to just be an element, should get the user to render from the url
    },
    {
      name: 'Schedules',
      icon: EventRepeatOutlinedIcon,
      link: 'schedules/',
      renderPage: () => (
        <UserAssessmentSchedulesViewer
          userId={params.entityId as unknown as number}
        />
      )
    }
  ];

  useEffect(() => {
    if (params.entityId == null) {
      navigate(backNavigation);
    }
  }, [params.entityId]);

  // custom modal
  return (
    <EntityDetailModal
      handleClose={() => {
        navigate(backNavigation);
      }}
      isOpen={true}
      pageTabs={pageTabs}
      renderEntityDetails={() => (
        <UserDetailsPanel userId={params.entityId as string} />
      )}
    />
  );
}
