/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButtonProps,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { Box } from '@mui/system';
import {
  DatePicker,
  DateRangePicker,
  LocalizationProvider
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import * as React from 'react';
import {
  DateFilterModifiers,
  FilterOperator
} from '../../../structures/enums';
import {
  FilterCriterion,
  FilterInputTF
} from '../../../structures/interfaces';

import EntityFilter from './EntityFilter';

interface Props {
  fieldName: string;
  fieldTitle: string;
  handleCriterionChange: (
    field: string,
    operator: FilterOperator | undefined,
    args: string[] | undefined
  ) => void;
  displayedCriterion: FilterCriterion | null;
}
//TODO: add an undefined option
function DateFilter(props: Props): JSX.Element {
  const { fieldName, fieldTitle, handleCriterionChange, displayedCriterion } =
    props;

  const [operators, setOperators] = React.useState<FilterOperator[]>(
    Object.values(DateFilterModifiers) as unknown as FilterOperator[]
  );

  React.useEffect(() => {
    if (displayedCriterion?.operator === FilterOperator.IS_NULL) {
      handleCriterionChange(fieldName, displayedCriterion?.operator, ['']);
    }
  }, [displayedCriterion?.operator]);

  function generateSingleDatePicker(): JSX.Element {
    const currentDateVal =
      displayedCriterion?.arguments?.[0] != null
        ? displayedCriterion?.arguments?.[0]
        : null;
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          orientation="landscape"
          openTo="day"
          disabled={displayedCriterion?.operator === FilterOperator.IS_NULL}
          value={currentDateVal}
          onChange={(newValue) => {
            let typedDate = '';
            if (newValue != null) {
              typedDate = newValue;
            }
            handleCriterionChange(fieldName, displayedCriterion?.operator, [
              typedDate
            ]);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    );
  }

  function generateDateRangePicker(): JSX.Element {
    const date1 =
      displayedCriterion?.arguments?.[0] != null
        ? displayedCriterion?.arguments?.[0]
        : null;
    const date2 =
      displayedCriterion?.arguments?.[1] != null
        ? displayedCriterion?.arguments?.[1]
        : null;
    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: 'Start', end: 'End' }}
      >
        <DateRangePicker
          value={[date1, date2]}
          onChange={(newValue) => {
            const withoutNulls: string[] = newValue.filter(
              (n) => n != null
            ) as string[];
            handleCriterionChange(
              fieldName,
              displayedCriterion?.operator,
              withoutNulls
            );
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    );
  }
  function generateDatePicker(): JSX.Element {
    if (displayedCriterion?.operator === FilterOperator.BETWEEN) {
      return generateDateRangePicker();
    } else {
      return generateSingleDatePicker();
    }
  }

  function handleChipDelete(): void {
    handleCriterionChange(fieldName, undefined, undefined);
  }

  function generateFilterChip(): JSX.Element {
    const operator = displayedCriterion?.operator;
    const date1 =
      displayedCriterion?.arguments?.[0] != null
        ? dayjs(displayedCriterion?.arguments?.[0]).format('MM/DD/YYYY')
        : '';
    const date2 =
      displayedCriterion?.arguments?.[1] != null
        ? dayjs(displayedCriterion?.arguments?.[1]).format('MM/DD/YYYY')
        : '';
    let dateText = date1;
    if (operator === FilterOperator.BETWEEN) {
      if (date1 === '' || date2 === '') {
        return <></>;
      }
      dateText = `${date1} - ${date2}`;
    } else if (operator == null) {
      return <></>;
    } else if (date1 === '') {
      return <></>;
    }
    return (
      <Chip
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        label={operator + ' ' + dateText}
        onDelete={handleChipDelete}
        sx={{ maxWidth: 175 }}
      />
    );
  }

  function genFilterComponent(): JSX.Element {
    return (
      <FormControl sx={{ width: '100%' }}>
        {/* <InputLabel id="OrganizationTypeFilter">Organization Group</InputLabel> */}
        <RadioGroup>
          {operators.map((operator) => (
            <FormControlLabel
              key={operator}
              control={
                <Radio
                  checked={displayedCriterion?.operator === operator}
                  onChange={() =>
                    handleCriterionChange(
                      fieldName,
                      operator,
                      displayedCriterion?.arguments
                    )
                  }
                  value={operator}
                />
              }
              label={operator}
            />
          ))}
        </RadioGroup>

        {generateDatePicker()}
      </FormControl>
    );
  }

  return (
    <EntityFilter
      filterTitle={fieldTitle}
      generateFilterChip={generateFilterChip}
      filterComponent={genFilterComponent()}
    />
  );
}

export default DateFilter;
