import StandardModal from '../../../../Templates/StandardModal/StandardModal';
import AssignAssessmentForm from './AssignAssessmentForm';
import { GridRowId } from '@mui/x-data-grid';
// import "./AddUserModal.css";

interface AssignAssessmentModalProps {
    open: boolean;
    assessmentIds: GridRowId[];
    close: () => void;
}

export default function AssignAssessmentModal(props: AssignAssessmentModalProps): JSX.Element {
  const {open, assessmentIds, close} = props;
  const headerText = `Assign Assessment${assessmentIds.length > 1 ? 's' : `: ${assessmentIds[0]}`}`;
  return (
    <StandardModal
      open={open}
      handleClose={() => close()}
      headerText={headerText}
      width="65%"
    >
      <AssignAssessmentForm
        handleClose={() => close()}
        assessmentIds={assessmentIds.map(String)}
      />
    </StandardModal>
  );
}
