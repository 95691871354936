/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';
import {
  CreatePermissionInput,
  Entity,
  EntityTableRequest,
  PartiallyOptional,
  PermissionSummary,
  UpdatePermissionRequest
} from '../structures/interfaces';
import { User } from '../structures/userInterfaces';
import EntityApi, { EntityRequest, TableRows } from './EntityApi';
import { RequestResponse } from '../structures/responses';
import { sendRequest } from './SeqApi';

interface Week {
  day: Dayjs;
}
interface DataOverTimeFromJson {
  reference: number;
  previousWeekDelta: number;
  previousMonthDelta: number;
}

interface GetUserRequest {
  id: number | string;
}

export type UpdateUserRequest = Partial<User>;
async function getSortedSummaries(
    pageReq: EntityTableRequest
  ): Promise<TableRows<PermissionSummary>> {
    const variables = {
      action: 'get_permissions_summary',
      filters: []
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as RequestResponse<
        TableRows<PermissionSummary>
      >;
      return res as unknown as TableRows<PermissionSummary>;
    } catch (err) {
      console.log('could not get sorted permission summaries');
      throw err;
    }
  }
export default class PermissionApi extends EntityApi {
  static singleton = new PermissionApi();

  getSortedSummaries = getSortedSummaries;
  static getSortedSummaries = getSortedSummaries;

  async getEntity(req: EntityRequest): Promise<Entity> {
    const variables = {
      action: 'get_permission',
      id: req.id
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as Entity;
      return res;
    } catch (err) {
      console.log('could not get permission');
      throw err;
    }
  }

  updatePermissions = async (
    permissionUpdates: UpdatePermissionRequest[]
  ): Promise<number[]> => {
    const variables = {
      action: 'update_permissions',
      permissionUpdates
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as number[];
      return res;
    } catch (err) {
      console.log('could not update permissions');
      throw err;
    }
  };

  updateUsers = async (userUpdates: UpdateUserRequest[]): Promise<number[]> => {
    const variables = {
      action: 'update_users',
      userUpdates
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as number[];
      return res;
    } catch (err) {
      console.log('could not update users');
      throw err;
    }
  };

  createEntity = async (
    entityToCreate: CreatePermissionInput
  ): Promise<Entity> => {
    const variables = {
      action: 'create_permission',
      entityToCreate
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as Entity;
      return res;
    } catch (err) {
      console.log('could not create permission');
      throw err;
    }
  };
}
