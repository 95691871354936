import {
  ScheduleSummary,
  ScheduleSummaryResponse
} from '../structures/scheduleInterfaces';

export const mapScheduleSummaryRespToFrontEnd = (
  schedules: ScheduleSummaryResponse[]
): ScheduleSummary[] => {
  return schedules.map((schedule: ScheduleSummaryResponse) => {
    return {
      id: schedule.schedule_id,
      userId: schedule.subuser_id,
      status: schedule.status,
      name: schedule.name,
      track: schedule.track,
      assessmentId: schedule.assessment_id,
      startDate: schedule.startDate,
      progress: schedule.progress,
      endDate: schedule.endDate,
      cadence: schedule.cadence,
      completed_assessments: schedule.completed_assessments,
      total_assessments: schedule.total_assessments
    };
  });
};
