import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import z from "zod"
import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';
import {zPhone} from '../../../helpers/phone-validator';

import { ProfileData } from '../../../structures/profile';

interface PhoneFormI {
  phone: string;
}

const phoneSchema = z.object({
  phone: zPhone
});

interface Props {
  // setStep: (step: number) => void;
  updateProfile: (attributesToUpdate: Partial<ProfileData>) => Promise<unknown>;
  phone: number | undefined;
  setModalOpen: (open: boolean) => void;
}
function PhoneForm({ phone, updateProfile, setModalOpen }: Props): JSX.Element {
  const { loading, error, execute } = useAsyncRequest(updateProfile);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<PhoneFormI>({
    resolver: zodResolver(phoneSchema),
    defaultValues: {
      phone: phone?.toString() ?? ''
    }
  });

  const formSubmitHandler: SubmitHandler<PhoneFormI> = async (
    data: PhoneFormI
  ) => {
    try {
      const result = await execute({
        phone_number: data.phone as unknown as number
      });
      if (result.error == null) {
        setModalOpen(false);
      } else {
        throw result.error;
      }
    } catch (e) {
      console.log('could not accept phone', e);
    }
  };

  const onInvalid = (errors: unknown): void =>
    console.error('houston we got em', errors);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(formSubmitHandler, onInvalid)}
        noValidate
        sx={{ mt: 1 }}
        width={'100%'}
      >
        <TextField
          margin="normal"
          fullWidth
          id="phone"
          label="phone"
          autoFocus
          inputProps={{
            type: 'tel'
          }}
          error={errors.phone != null}
          helperText={errors.phone?.message ?? ''}
          {...register('phone')}
        />

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
        >
          Verify
        </LoadingButton>
        {error != null && <Alert severity="error">{error.message}</Alert>}
      </Box>
    </Box>
  );
}

export default PhoneForm;
