/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';
import {
    Entity,
    EntityTableRequest,
    PartiallyOptional
} from '../structures/interfaces';
import { SubUser, SubUserResponse, User } from '../structures/userInterfaces';
import EntityApi, { EntityRequest, TableRows } from './EntityApi';
import { mutation, query, sendRequest, SendRequestOptions } from './SeqApi';
import { RequestResponse } from '../structures/responses';
import {
    BaseOrganization,
    OrgGroup,
    OrgSummary,
    OrgSummaryResponse,
    Organization,
    OrganizationDetailsFormInputI,
    UpdateOrgRequestShell
} from '../structures/organizationInterfaces';
import { mapOrgSummaryResponseToFrontEnd } from './organizationMappers';
import { mapSubUserResponseToSubUser } from './userMappers';
import { EntityTypes } from '../structures/enums';

interface Week {
    day: Dayjs;
}
interface DataOverTimeFromJson {
    reference: number;
    previousWeekDelta: number;
    previousMonthDelta: number;
}

interface GetUserRequest {
    id: number | string;
}

export type UpdateUserRequest = Partial<User>;

export * as OrgApi from './OrganizationApi';

const PROCESS_NAME = 'organization';

export async function getSortedSummaries(
    pageReq: EntityTableRequest
): Promise<TableRows<OrgSummary>> {
    const variables = {
        action: 'get_orgs_summary',
        ...pageReq
    };
    try {
        const response = await sendRequest(variables, PROCESS_NAME, undefined, {
            mockRequest: false
        } as SendRequestOptions);
        const data = response.data as unknown as RequestResponse<
            TableRows<OrgSummaryResponse>
        >;
        if (data.status !== 'success') throw new Error(data.message);
        return {
            rows: mapOrgSummaryResponseToFrontEnd(data.resp_variables.rows), // may need to map these with mapSchedulesToScheduleSummaries(
            totalRows: data.resp_variables.totalRows
        };
    } catch (err) {
        console.log('could not get sorted org summaries');
        throw err;
    }
}

export const sortedSummaries = query<TableRows<OrgSummaryResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
    action: 'get_orgs_summary',
    ...pageReq
    }),
    queryKey: (pageReq) => [PROCESS_NAME, pageReq],
    processName: PROCESS_NAME,
    transform: (r) => ({
        rows: mapOrgSummaryResponseToFrontEnd(r.rows),
        totalRows: r.totalRows
    })
})

export const filterOptions = query<{options: string[]}>()({
    variables: (filterField: string) => ({
        action: 'get_filter_options',
        entityType: EntityTypes.ORGANIZATIONS,
        filterField: filterField
    }),
    queryKey: (filterField) => ['filterOptions', EntityTypes.ORGANIZATIONS, filterField],
    processName: 'data',
    transform: (r) => r.options
});

export const get = query<BaseOrganization>()({
    variables: (org_id: string) => ({
        action: 'get_organization',
        org_id
    }),
    queryKey: (org_id) => [PROCESS_NAME, org_id],
    processName: PROCESS_NAME,
    transform: (r) => r
})

export const update = mutation<{ org_id: string }>()({
    variables: (updateOrgReq: UpdateOrgRequestShell) => ({
        action: 'update_organization',
        ...updateOrgReq
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

export const groups = query<OrgGroup[]>()({
    variables: (org_id: string) => ({
        action: 'get_org_groups',
        org_id
    }),
    queryKey: (org_id) => [PROCESS_NAME, org_id, 'groups'],
    processName: PROCESS_NAME,
    transform: (r) => r
})

export const linkGroup = mutation<{ org_id: string; group_id: string }>()({
    variables: ({org_id, group_id}: {org_id: string, group_id: string}) => ({
        action: 'attach_group_to_organization',
        org_id,
        group_id
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
})

export const primaryLiason = query<SubUserResponse>()({
    variables: (org_id: string) => ({
        action: 'get_org_pl',
        org_id
    }),
    queryKey: (org_id) => [PROCESS_NAME, org_id, 'primaryLiaison'],
    processName: PROCESS_NAME,
    transform: (r) => mapSubUserResponseToSubUser([r])[0]
});

// NOTE: unused
export const getBulk = query<BaseOrganization[]>()({
    variables: (org_ids: string[]) => ({
    action: 'get_bulk_organizations',
    org_ids
    }),
    queryKey: (org_ids) => [PROCESS_NAME, ...org_ids],
    processName: PROCESS_NAME,
    transform: (r) => r
})
