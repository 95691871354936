/* eslint-disable @typescript-eslint/no-unused-vars */
import { dbData } from './userDB';
import { User, UserSummary } from '../../structures/userInterfaces';
import dayjs from 'dayjs';
import { UserSeverity } from '../../structures/enums';
import { GroupName } from '../../structures/organizationEnums';

let users: User[] = [...dbData];

export type PartialUser = Partial<User>;

async function create(user: User): Promise<User> {
  users = [...users, user]; // why is users immutable???
  return user;
}

// user.groups.map((group) => group.name),
//
async function readAllSummaries(): Promise<UserSummary[]> {
  const userSummaries = users.map((user) => {
    return {} as UserSummary;
  });
  return userSummaries;
}

async function read(userId: number): Promise<User | undefined> {
  return {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: 10000,
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  }; // temporary
}

interface Obj {
  [key: string]: User;
}

function groupById(array: User[]): Obj {
  return array.reduce((obj: Obj, value) => {
    obj[value.id] = value;
    return obj;
  }, {});
}

async function update(
  id: number,
  update: PartialUser
): Promise<User | undefined> {
  const userIndexToUpdate = users.findIndex(
    (user) => (user.id as unknown) === id
  );
  if (userIndexToUpdate >= 0) {
    const tempUsers = [...users];
    tempUsers[userIndexToUpdate] = { ...users[userIndexToUpdate], ...update };
    users = [...tempUsers];
  }
  return await Promise.resolve(users[userIndexToUpdate]);
}

async function deleteUser(userId: string): Promise<User[]> {
  return users.filter((user) => (user.id as unknown) !== userId);
}

async function reset(): Promise<void> {
  users = [...dbData];
}

export { create, read, deleteUser, reset, update, readAllSummaries };
