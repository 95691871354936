import { EntityTypes } from '../structures/enums';
import { EntityTableRequest } from '../structures/interfaces';
import {
    GroupSummary,
    GroupSummaryResponse,
    UpdateGroupRequestShell
} from '../structures/organizationInterfaces';
import { RequestResponse } from '../structures/responses';
import { TableRows } from './EntityApi';
import { mutation, query, sendRequest } from './SeqApi';
import { mapGroupSummariesToFrontEnd } from './groupMappers';

export * as GroupApi from './GroupApi';

const PROCESS_NAME = 'group';

export interface CreateGroupRequest {
    name: string;
    type: string;
}

export async function getSortedSummaries(
    pageReq: EntityTableRequest
): Promise<TableRows<GroupSummary>> {
    const variables = {
        action: 'get_groups_summary',
        ...pageReq
    };
    try {
        const response = await sendRequest(variables, PROCESS_NAME, undefined, {
            mockRequest: false
        });
        const data = response.data as unknown as RequestResponse<
            TableRows<GroupSummaryResponse>
        >;
        if (data.status !== 'success') {
            throw Error(data.message);
        }
        return {
            rows: mapGroupSummariesToFrontEnd(data.resp_variables.rows),
            totalRows: data.resp_variables.totalRows
        };
    } catch (err) {
        console.log('could not get groups summaries');
        throw err;
    }
}

export const sortedSummaries = query<TableRows<GroupSummaryResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
        action: 'get_groups_summary',
        ...pageReq
    }),
    queryKey: (pageReq) => [PROCESS_NAME, pageReq],
    processName: PROCESS_NAME,
    transform: (r) => ({
        rows: mapGroupSummariesToFrontEnd(r.rows),
        totalRows: r.totalRows
    })
});

export const update = mutation<{ group_id: string }>()({
    variables: (group: UpdateGroupRequestShell) => ({
        action: 'update_group',
        ...group
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

// delete not allowed variable name
export const remove = mutation<{ group_id: string }>()({
    variables: (group_id: string) => ({
        action: 'delete_group',
        group_id
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

export const filterOptions = query<{ options: string[] }>()({
    variables: (filterField: string) => ({
        action: 'get_filter_options',
        entityType: EntityTypes.ORG_GROUPS,
        filterField: filterField
    }),
    processName: 'data',
    queryKey: (filterField) => [
        'filterOptions',
        EntityTypes.ORG_GROUPS,
        filterField
    ],
    transform: (r) => r.options
});
