import { faker } from '@faker-js/faker'; // TODO:
import { NoteSummaryResponse } from '../../structures/interfaces';

export const generateNoteSummaryRows = (
  numRows: number
): NoteSummaryResponse[] => {
  const noteSummaries: NoteSummaryResponse[] = [];

  for (let i = 0; i < numRows; i++) {
    const noteSummary: NoteSummaryResponse = {
      note_id: faker.string.uuid(),
      assessment_record_id: faker.string.uuid(),
      content: faker.lorem.paragraph(),
      createdAt: faker.date.past().toISOString(),
      change_by_user_id: faker.string.uuid(),
      severity_after: faker.number.int({ min: 0, max: 3 }),
      severity_before: faker.number.int({ min: 0, max: 3 })
    };

    noteSummaries.push(noteSummary);
  }

  return noteSummaries;
};
