import React from 'react';
import './Section.css';
import { Box, Typography } from '@mui/material';
// import UsersTable from "../../Pages/UsersPage/UsersTable/UsersTable";

interface Props {
  title: string;
  children?: React.ReactNode;
}

const Section = (props: Props): JSX.Element => {
  return (
    <div className="section-container">
      <div className="header-organizer">
        <Box marginTop={5} marginBottom={5}>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '20px'
            }}
          >
            {props.title}
          </Typography>
        </Box>
      </div>
      {props.children}
    </div>
  );
};

export default Section;
