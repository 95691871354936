import { Box, useTheme } from '@mui/material';
import { AssessmentRecordSummary } from '../../../../structures/assessmentRecordInterfaces';
import { Assessment } from '../../../../structures/assessmentInterfaces';
import {
    ReactNode,
    useMemo,
} from 'react';

interface Props {
    assessment: AssessmentRecordSummary;
    assessmentDetails: Assessment | null;
}

export function AssessmentRecordScoreBreakDown({
    assessmentDetails,
    assessment
}: Props) {
    const theme = useTheme();

    const thresholds = useMemo(() => {
        if (!assessmentDetails) return [0, 20, 30, 40, 100];
        return assessmentDetails.scoringThresholds;
    }, [assessmentDetails]);

    const scoreRange: [number, number] = useMemo(() => {
        const low = thresholds[0];
        const high = thresholds.at(-1) ?? 100;
        return [low, high];
    }, [thresholds]);

    const score = useMemo(() => {
        if (!assessment) return 0;
        return assessment.score;
    }, [assessment]);

    const bars = useMemo(() => {
        const bars = [];
        for (let i = 1; i < thresholds.length; i++) {
            bars.push(thresholds[i] - thresholds[i - 1]);
        }
        console.log('bars', bars);
        return bars;
    }, [thresholds]);

    const barWidths = useMemo(() => {
        return bars.map((b) => `${(100 * b) / scoreRange[1]}%`).concat(['0%']);
    }, [bars, scoreRange]);
    console.log("barWidths", barWidths)

    const colors = useMemo(
        () => [
            theme.palette.success.main,
            theme.palette.warning.light,
            theme.palette.warning.main,
            theme.palette.error.main
        ],
        [theme]
    );

    return (
        <Box
            sx={{
                position: 'relative',
                marginBottom: 15,
                marginTop: 10,
                marginRight: 10,
                width: '100%',
                height: '100px'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", height: 30 }}>
                <div
                    style={{ width: `${(100 * score) / scoreRange[1]}%` }}
                ></div>
                <LineLabel
                    value={`score: ${score}`}
                    placement="top"
                    score={true}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {barWidths.map((width, i) => (
                    <div
                        key={i}
                        style={{
                            width,
                            translate: -5
                        }}
                    >
                        {i % 2 === 0 && (
                            <LineLabel value={thresholds[i]} placement="top" />
                        )}
                    </div>
                ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {barWidths.map((width, i) => (
                    <div
                        key={i}
                        style={{
                            width,
                            height: 20,
                            backgroundColor: colors[i],
                            borderRadius:
                                i === 0
                                    ? '5px 0 0 5px'
                                    : i === barWidths.length - 2
                                    ? '0 5px 5px 0'
                                    : '0 0 0 0'
                        }}
                    ></div>
                ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {barWidths.map((width, i) => (
                    <div
                        key={i}
                        style={{
                            width,
                            translate: -5
                        }}
                    >
                        {i % 2 === 1 && (
                            <LineLabel
                                value={thresholds[i]}
                                placement="bottom"
                            />
                        )}
                    </div>
                ))}
            </div>
        </Box>
    );
}

type LineLabelProps = {
    value: ReactNode;
    placement: 'top' | 'bottom';
    score?: boolean;
};
function LineLabel({ value, placement, score }: LineLabelProps) {
    const flexDirection = placement === 'top' ? 'column' : 'column-reverse';
    if (score) {
        const width = 80;
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection,
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width
                }}
            >
                <div style={{ zIndex: 3, backgroundColor: "white", translate: -10 }}>{value}</div>
                <div
                    style={{ position: "absolute", zIndex: 0, backgroundColor: 'black', width: 2, height: 52, translate: "0 22px"}}
                ></div>
            </div>
        );
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection,
                justifyContent: 'center',
                alignItems: 'center',
                width: 10
            }}
        >
            <div style={{ zIndex: 2, backgroundColor: "white" }}>{value}</div>
            <div
                style={{ backgroundColor: 'black', width: 2, height: 10 }}
            ></div>
        </div>
    );
}
