import { Delete } from '@mui/icons-material';
import {
    Alert,
    Autocomplete,
    Box,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { useEffect } from 'react';
import {
    Controller,
    FieldErrors,
    UseFieldArrayReturn,
    useFormContext
} from 'react-hook-form';
import { Liaison } from '../../../../structures/userInterfaces';
import { AddLiaison } from './AddLiaison';
import {
    GroupFormInput,
    OrganizationDetailsFormInputI
} from '../../../../structures/organizationInterfaces';
import { UserApi } from '../../../../api/UserApi';
import { GroupCategories } from '../../../../structures/organizationEnums';
import { GroupApi } from '../../../../api/GroupApi';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

interface Props {
    groupArrayMethods: UseFieldArrayReturn<
        OrganizationDetailsFormInputI,
        'groups',
        'groupsId'
    >;
    fieldIndex: number;
    formErrors: FieldErrors<OrganizationDetailsFormInputI>;
}
export const NewGroupForm = (props: Props) => {
    const orgFormMethods = useFormContext<OrganizationDetailsFormInputI>();
    const { formErrors, groupArrayMethods, fieldIndex } = props;
    const deleteGroup = GroupApi.remove.useMutation();
    const handleUpdateGroupLiaisons = (liaisons: Liaison[]) => {
        const groupState = orgFormMethods.getValues(`groups.${fieldIndex}`);
        const newGroupState: GroupFormInput = {
            ...groupState,
            liaisons: liaisons
        };

        groupArrayMethods.update(fieldIndex, newGroupState);
        orgFormMethods.trigger('groups');
    };
    const { enqueueSnackbar } = useSnackbar();

    const getSubuser = UserApi.get.fetchQuery();

    useEffect(() => {
        const populateLiaisons = async () => {
            const updatedLiaisons = [];
            for (const liaison of orgFormMethods.getValues(
                `groups.${fieldIndex}`
            ).liaisons) {
                if (liaison.email === '') {
                    try {
                        // TODO: useQueries
                        const result = await getSubuser(
                            liaison.subuser_id as string
                        );
                        if (result) {
                            const newLiaison: Liaison = {
                                subuser_id: result.subuser_id,
                                email: result.email,
                                name: result.name,
                                phone: result.phone,
                                title: liaison.title
                            };
                            updatedLiaisons.push(newLiaison);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    updatedLiaisons.push(liaison);
                }
            }
            handleUpdateGroupLiaisons(updatedLiaisons);
        };
        if (
            orgFormMethods
                .getValues(`groups.${fieldIndex}`)
                .liaisons.find((liaison) => liaison.email === '') !== undefined
        ) {
            populateLiaisons();
        }
    }, [orgFormMethods.getValues(`groups.${fieldIndex}`).liaisons]);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useEffect(() => {}, [groupArrayMethods.fields[fieldIndex].liaisons]);

    const handleDeleteGroup = async () => {
        try {
            if (groupArrayMethods.fields[fieldIndex].groupId === 'new') {
                groupArrayMethods.remove(fieldIndex);
                return;
            }
            // console.log('group id', groupArrayMethods.fields[fieldIndex].groupId);
            await deleteGroup.mutateAsync(
                groupArrayMethods.fields[fieldIndex].groupId as string
            );
            if (deleteGroup.isError) {
                enqueueSnackbar(
                    `Error deleting group: ${deleteGroup.error.message}`,
                    {
                        variant: 'error'
                    }
                );
                throw `Error deleting group: ${deleteGroup.error.message}`;
            }
            groupArrayMethods.remove(fieldIndex);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item>
                    <Typography sx={{ fontSize: '16px', marginTop: 4 }}>
                        {groupArrayMethods.fields[fieldIndex].groupName ||
                            'Group Name'}
                    </Typography>
                    <Controller
                        name={`groups.${fieldIndex}.category`}
                        control={orgFormMethods.control}
                        defaultValue={null}
                        render={({ field }) => (
                            <Autocomplete
                                freeSolo
                                onChange={(e, value) =>
                                    field.onChange(value ? value : null)
                                }
                                value={field.value}
                                size="small"
                                options={Object.values(GroupCategories)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Group Category"
                                        error={
                                            !!orgFormMethods?.formState?.errors
                                                ?.groups?.[fieldIndex]?.category
                                        }
                                        sx={{
                                            width: 400,
                                            marginRight: 4,
                                            marginTop: 4
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                    <TextField
                        size="small"
                        label="Group Name"
                        sx={{ width: 400, marginTop: 2, marginRight: 4 }}
                        placeholder="Womens varsity Basketball"
                        {...orgFormMethods.register(
                            `groups.${fieldIndex}.groupName`
                        )}
                        error={!!formErrors?.groups?.[fieldIndex]?.groupName}
                    />
                </Grid>
                <Grid item>
                    <AddLiaison
                        updateAddedLiaisons={handleUpdateGroupLiaisons}
                        currentAddedLiaisons={
                            groupArrayMethods.fields[fieldIndex].liaisons
                        }
                    />
                    {formErrors?.groups?.[fieldIndex]?.liaisons && (
                        <Alert severity="error">
                            {formErrors.groups[fieldIndex]?.liaisons?.message}
                        </Alert>
                    )}
                </Grid>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <LoadingButton
                        startIcon={<Delete />}
                        onClick={() => handleDeleteGroup()}
                        size="small"
                        sx={{ width: 100 }}
                        loading={deleteGroup.isPending}
                    >
                        Delete
                    </LoadingButton>
                </Box>
            </Grid>
        </Box>
    );
};
