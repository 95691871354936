import { useState } from 'react';
import UserInfoPanelSection from '../../UsersPage/UserDetailsModal/UserInfoPanelSection';
import { UseQueryResult } from '@tanstack/react-query';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import Alert from '@mui/material/Alert/Alert';
import { ReportDialog } from './ReportDialog';
import { UserApi } from '../../../../api/UserApi';

interface Props {
    userId: string;
}

export const UserDetailsPanel = (props: Props): JSX.Element => {
    // TODO: subuser details endpoint
    const user = UserApi.get.useQuery(props.userId);

    const userDetails = (u: NonNullable<typeof user.data>) => ({
        // Status: userData?.status,
        Phone: u.phone,
        'User ID': u.subuser_id,
        Email: u.email,
        'Created At': u.date_added
    });
    const groups = UserApi.groups.useQuery(props.userId);

    const groupDetails = (gs: NonNullable<typeof groups.data>) =>
        gs.map((g) => ({
            [g.group.name]: g.group.category
        }));

    const orgs = UserApi.subuserOrgs.useQuery(props.userId);

    const orgDetails = (userOrgs: NonNullable<typeof orgs.data>) =>
        userOrgs.map((org) => ({
            Type: org.type,
            Name: org.name,
            Address: org.address1
        }));

    const [reportDialogOpen, setReportDialogOpen] = useState(false);

    return (
        <>
            <Typography variant="h4" marginBottom={5}>
                User: {user.data?.name || 'Loading...'}
            </Typography>

            <Button
                variant="outlined"
                component="label"
                size="medium"
                sx={{ height: 45 }}
                onClick={() => setReportDialogOpen(true)}
            >
                Create Report
            </Button>
            <ReportDialog
                open={reportDialogOpen}
                userId={props.userId}
                handleClose={() => setReportDialogOpen(false)}
            />
            <DetailsSection
                query={user}
                details={userDetails}
                title="Details"
                errorMessage="Error fetching user data."
            />
            <DetailsSection
                query={orgs}
                details={orgDetails}
                title="Organization"
                errorMessage="Error fetching user organizations."
            />
            <DetailsSection
                query={groups}
                details={groupDetails}
                title="Groups"
                errorMessage="Error fetching user groups."
            />
        </>
    );
};

function DetailsSection<T>({
    query,
    details,
    title,
    errorMessage
}: {
    query: UseQueryResult<T>;
    details: (data: T) => any;
    title: string;
    errorMessage: string;
}) {
    if (query.isLoading) {
        return <Skeleton />;
    }
    if (query.isError) {
        return <Alert severity="error">{errorMessage}</Alert>;
    }
    const detailsData = query.data ? details(query.data) : null;
    return <UserInfoPanelSection data={detailsData} title={title} />;
}
