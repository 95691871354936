export function psySubdomain() {
    let subdomain = process.env.REACT_APP_PSY_HOST;
    if (!subdomain || subdomain === "") {
        subdomain = "portal";
    }
    return subdomain;
}
export default function psyUrl() {
    const subdomain = psySubdomain();
    return `https://${subdomain}.psychlytics.com`;
}
