import { Edit } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

interface Props {
    selectionModel: GridSelectionModel;
}

export default function UserTableActions(props: Props): JSX.Element {
    const { selectionModel } = props;
    const navigate = useNavigate();
    return (
        <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Button
                variant="contained"
                startIcon={<Edit />}
                disabled={selectionModel.length === 0}
                onClick={() => navigate('edit/')}
            >
                Edit User
            </Button>

            <Button
                variant="contained"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => navigate(`add/`)}
            >
                Add User
            </Button>
            <Button
                variant="contained"
                disabled={selectionModel.length === 0}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => navigate(`assign/`)}
            >
                Assign
            </Button>
        </Stack>
    );
}
