/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react';

type AsyncFunction<T extends any[], R> = (...args: T) => Promise<R>;

type AsyncResult<R> = { error: null; data: R } | { error: Error; data: null };
interface AsyncRequestState<T extends any[], R> {
    loading: boolean;
    error: Error | null;
    data: R | null;
    execute: (...args: T) => Promise<AsyncResult<R>>;
}

interface ErrorWithStatus {
    status: number;
}

function isErrorWithStatus(err: any): err is ErrorWithStatus {
    return err && typeof err.status === 'number';
}

function useAsyncRequest<T extends any[], R>(
    asyncFunction: AsyncFunction<T, R>,
    thisArg?: any
): AsyncRequestState<T, R> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<R | null>(null);

    const handleRequest = useCallback(
        async (...args: T) => {
            setLoading(true);
            setError(null);
            const boundFunction = thisArg
                ? asyncFunction.bind(thisArg)
                : asyncFunction;

            try {
                const result = await boundFunction(...args);
                setData(result);
                return { error: null, data: result };
            } catch (err) {
                console.log('setting error', err);
                let errorToSet: Error;
                if (err instanceof Error) {
                    errorToSet = err;
                } else if (isErrorWithStatus(err)) {
                    errorToSet = new Error(
                        `Request failed with status code ${err.status}`
                    );
                } else {
                    errorToSet = new Error('An unknown error occurred');
                }
                setError(errorToSet);
                return { error: errorToSet, data: null };
            } finally {
                setLoading(false);
            }
        },
        [asyncFunction, thisArg]
    );

    return { loading, error, data, execute: handleRequest };
}

export default useAsyncRequest;
