import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { InfoPanelSectionData } from './DetailedUserInfoPanel';

interface Props {
    data: InfoPanelSectionData | null;
    title: string;
}

export default function UserInfoPanelSection(props: Props): JSX.Element {
    const { data, title } = props;

    return (
        <Box sx={{ marginBottom: 10 }}>
            <Box>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
            </Box>
            <Body data={data} />
        </Box>
    );
}

function getLabelValuePairs<T extends Record<string, React.ReactNode>>(data: T | T[] | null) {
    if (data === null) return [];
    if (Array.isArray(data)) {
        return data.flatMap(obj => Object.entries(obj))
    }
    return Object.entries(data)
}

function Body({ data }: { data: InfoPanelSectionData | null }) {
    const pairs = getLabelValuePairs(data);
    return (
        <>
        {pairs.map(([label, value], i) => (
            <BodyElement key={i} label={label} value={value} />
            ))
            }
        </>
    )
}

function BodyElement({ label, value }: { label: string; value: React.ReactNode }) {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 3
            }}
        >
            <Typography variant="subtitle2">{label}</Typography>{' '}
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {value}
            </Typography>
        </Box>
    );
}
