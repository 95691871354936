import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridRowId } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { OrgApi } from '../../../../api/OrganizationApi';
import {
    GroupFormInput,
    OrgGroup,
    OrganizationDetailsFormInputI,
    UpdateGroupRequestShell,
    UpdateOrgRequestShell,
    BaseOrganization
} from '../../../../structures/organizationInterfaces';
import { Liaison } from '../../../../structures/userInterfaces';
import { OrganizationUpdateContext } from './OrganizationsTable';
import GroupLiaisonsForm from './GroupsForm';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import './OrganizationForm.css';
import {GroupApi} from '../../../../api/GroupApi';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { UserApi } from '../../../../api/UserApi';
import ExitModal from '../../../../components/Templates/ExitModal/ExitModal';

const LiaisonFormSchema: yup.SchemaOf<Liaison> = yup.object().shape({
    subuser_id: yup.string().required(),
    name: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().required(),
    title: yup.string().required()
});

const groupSchema: yup.SchemaOf<GroupFormInput> = yup.object().shape({
    groupId: yup.string().required(),
    groupName: yup.string().required('Group name is required'),
    category: yup.string().required('Category is required'),
    liaisons: yup.array().of(LiaisonFormSchema).min(1).required()
});

const OrganizationDetailsSchema: yup.SchemaOf<OrganizationDetailsFormInputI> =
    yup.object().shape({
        name: yup.string().required('Organization name is required'),
        status: yup.string().required('Organization status is required'),
        type: yup.string().required('Organization type is required'),
        primaryLiaison: yup
            .object()
            .shape({
                subuser_id: yup.string().required(),
                name: yup.string().required(),
                phone: yup.string().optional(),
                email: yup.string().optional(),
                title: yup.string().required()
            })
            .nullable()
            .required('Must have at least one primary liaison'),
        address1: yup.string().required('Address is required'),
        address2: yup.string(),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
        country: yup.string().required(),
        zip: yup
            .string()
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code')
            .required('Zip code is required'),
        groups: yup.array().of(groupSchema).optional(),
        id: yup.string().required()
    });

interface Props {
    handleClose: () => void;
    // TODO: why optional?
    OrganizationToEdit?: GridRowId;
}

// TODO: make create/cancel into a component

export default function OrganizationForm(props: Props): JSX.Element {
    const { handleClose, OrganizationToEdit } = props;
    const orgId = OrganizationToEdit;

    const { outOfDate: updateTableFlag, setOutOfDate: setUpdateTableFlag } =
        useContext(OrganizationUpdateContext) || {};

    const updateOrg = OrgApi.update.useMutation();

    const org = OrgApi.get.useQuery(orgId as string, {
        enabled: !!orgId
    });
    useEffect(() => {
        if (!org.data) return
        const formState = methods.getValues()
        const newFormState = getFormStateFromOrganization(org.data, formState)
        methods.reset(newFormState)
    }, [org.data])

    const groups = OrgApi.groups.useQuery(orgId as string, {
        enabled: !!orgId

    });

    useEffect(() => {
        if (!groups.data) return;
        methods.setValue('groups', mapGroupsToForm(groups.data));
    }, [groups.data]);

    // TODO: FIX
    const updateGroup = GroupApi.update.useMutation();

    const addUserToGroup = UserApi.addToGroup.useMutation();

    const { enqueueSnackbar } = useSnackbar();
    const [uploadedFile] = useState<File | null>(null);

    const methods = useForm<OrganizationDetailsFormInputI>({
        resolver: yupResolver(OrganizationDetailsSchema),
        defaultValues: {
            id: 'new',
            name: '',
            status: '',
            type: '',
            primaryLiaison: null,
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            groups: [
                {
                    groupId: 'new',
                    groupName: '',
                    category: null,
                    liaisons: []
                }
            ],
            country: ''
        }
    });

    const currentPrimaryLiaison = methods.getValues('primaryLiaison');

    const getSubuser = UserApi.get.fetchQuery();

    const populateLiaison = async () => {
        if (!currentPrimaryLiaison?.subuser_id) {
            return;
        }
        try {
            // TODO: useQuery
            const result = await getSubuser(currentPrimaryLiaison?.subuser_id);
            if (result) {
                const newLiaison: Liaison = {
                    subuser_id: result.subuser_id,
                    email: result.email,
                    name: result.name,
                    phone: result.phone,
                    title: currentPrimaryLiaison.title
                };
                methods.setValue('primaryLiaison', newLiaison);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (
            currentPrimaryLiaison?.email === '' &&
            currentPrimaryLiaison?.subuser_id
        ) {
            populateLiaison();
        }
    }, [currentPrimaryLiaison]);

    const formSubmitHandler: SubmitHandler<
        OrganizationDetailsFormInputI
    > = async (data: OrganizationDetailsFormInputI) => {
        try {
            // TODO: make all of this one request (transaction!)
            const formData = { ...data, photo: uploadedFile }; // Add the file object to the form data
            const updateOrgReq = mapFormDataToUpdateOrgReq(formData);
            const updateOrgResult = await updateOrg.mutateAsync(updateOrgReq);
            if (updateOrg.isError) {
                enqueueSnackbar(
                    `Error creating organization: ${updateOrg.error.message}`,
                    {
                        variant: 'error'
                    }
                );
                throw updateOrg.error.message;
            }
            const addGroupLiaisonsPromises = [];

            for (const group of formData.groups) {
                const updateGroupResult = await updateGroup.mutateAsync(
                    mapGroupFormToUpdateGroupReq(group, updateOrgResult.org_id)
                );
                if (updateGroup.isError) {
                    enqueueSnackbar(
                        `Failed to update Group: ${updateGroup.error.message}`,
                        {
                            variant: 'error'
                        }
                    );
                    throw updateGroup.error.message;
                }
                for (const liaison of group.liaisons) {
                    addGroupLiaisonsPromises.push(
                        addUserToGroup.mutateAsync(
                            {
                                subuser_id: liaison.subuser_id,
                                group_id: updateGroupResult.group_id,
                                title: liaison.title,
                                is_liaison: true
                            },
                            {
                                onError: (err, variables) => {
                                    const liaison = group.liaisons.find(
                                        (l) =>
                                            l.subuser_id ===
                                            variables.subuser_id
                                    );
                                    let name = liaison?.name;
                                    if (!name) {
                                        name = 'one or more liaisons';
                                    }
                                    enqueueSnackbar(
                                        `Failed to link ${name} to group: ${err}`,
                                        {
                                            variant: 'error'
                                        }
                                    );
                                }
                            }
                        )
                    );
                }
            }

            await Promise.allSettled(addGroupLiaisonsPromises);
            if (setUpdateTableFlag) {
                setUpdateTableFlag(!updateTableFlag);
            }
            enqueueSnackbar(`Success!`, {
                variant: 'success'
            });
            handleClose();
        } catch (err) {
            console.log(err);
        }
    };

    const onInvalid = (errors: any): void =>
        console.error('houston we got em', errors);

    const [exitModal, setExitModal] = useState(false);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(formSubmitHandler, onInvalid)}
                style={{ height: '100%' }}
            >
                <Box
                    className="no-scroll-bar"
                    sx={{
                        overflowY: 'scroll',
                        height: '90%',
                        display: 'grid ',
                        gridTemplateRows: '1fr 1fr'
                    }}
                >
                    <Box>
                        {org.isError && (
                            <Alert severity="error">{org.error.message}</Alert>
                        )}
                        <OrganizationDetailsForm />
                    </Box>
                    <Box>
                        <Typography variant="h6" marginBottom={5}>
                            Groups
                        </Typography>
                        {groups.isError && (
                            <Alert severity="error">
                                {groups.error.message}
                            </Alert>
                        )}
                        <GroupLiaisonsForm />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10%'
                    }}
                >
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{ height: 45 }}
                            onClick={() => setExitModal(true)}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={
                                Object.keys(methods.formState.errors).length !==
                                    0 ||
                                groups.isLoading ||
                                org.isLoading
                            }
                            color="secondary"
                            size="medium"
                            sx={{
                                height: 45,
                                fontColor: 'white'
                            }}
                            loading={updateOrg.isPending}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                    {exitModal && (
                        <ExitModal
                            handleCloseAll={() => handleClose()}
                            handleClose={() => setExitModal(false)}
                            isOpen
                        />
                    )}
                </Box>
            </form>
        </FormProvider>
    );
}

function mapGroupsToForm(groups: OrgGroup[]) {
    //get current form state
    const newGroups: GroupFormInput[] = groups.map((group) => {
        return {
            groupId: group.group_id,
            groupName: group.name,
            category: group.category,
            // TODO: why does OrgGroup have Partial<Liason>[]
            liaisons: group.liaisons.map((liaison) => {
                return {
                    subuser_id: liaison.subuser_id!,
                    title: liaison.title!,
                    name: '',
                    phone: '',
                    email: ''
                };
            }) satisfies Liaison[]
        };
    });

    return newGroups;
}

const mapFormDataToUpdateOrgReq = (formData: OrganizationDetailsFormInputI) => {
    const orgReq: UpdateOrgRequestShell = {
        organization: {
            org_id: formData.id,
            name: formData.name,
            status: formData.status,
            type: formData.type,
            primaryLiaison: {
                subuser_id: formData.primaryLiaison?.subuser_id as string,
                title: formData.primaryLiaison?.title as string
            },
            address1: formData.address1,
            address2: formData.address2,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country
        }
    };
    return orgReq;
};

const mapGroupFormToUpdateGroupReq = (
    group: GroupFormInput,
    orgId: string
): UpdateGroupRequestShell => {
    const groupReq: UpdateGroupRequestShell = {
        group: {
            group_id: group.groupId,
            name: group.groupName,
            category: group.category as string,
            org_id: orgId
        }
    };
    return groupReq;
};

 const getFormStateFromOrganization = (org: BaseOrganization, currentFormState: OrganizationDetailsFormInputI) => {
        //get current form state
        const newFormState: OrganizationDetailsFormInputI = {
            ...currentFormState,
            id: org.org_id,
            name: org.name,
            status: org.status,
            type: org.type,
            address1: org.address1,
            address2: org.address2,
            city: org.city,
            state: org.state,
            zip: org.zip,
            country: org.country,
            primaryLiaison: org.primaryLiaison
        };
    return newFormState;
    };
