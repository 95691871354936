import React from 'react';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import OrganizationForm from './OrganizationForm';
import { GridRowId } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  selectedEntity: GridRowId;
}

export default function EditOrganizationModal({
  open,
  selectedEntity
}: Props): JSX.Element {
  const navigate = useNavigate();
  return (
    <StandardModal
      open={open}
      handleClose={() => navigate('/organizations/')}
      headerText={'Edit Organization: ' + selectedEntity}
    >
      <OrganizationForm
        handleClose={() => navigate('/organizations/')}
        OrganizationToEdit={selectedEntity}
      />
    </StandardModal>
  );
}
