export enum OrgType {
  ATHLETIC = 'Athletic',
  CORPORATE = 'Corporate'
}

export enum OrgStatus {
  OPEN = 'Open',
  CLOSED = 'Closed'
}

export enum GroupName {
  BASKETBALL_W = 'Basketball - Women',
  VOLLYBALL_BEACH_W = 'Beach Volleyball - Women'
}

export enum OrganizationStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum Organizations {
  UT = 'U of Texas',
  OU = 'U of Oklahoma'
}

export enum GroupCategories {
  BASEBALL_MEN = 'Baseball - Men',
  BASKETBALL_WOMEN = 'Basketball - Women',
  BASKETBALL_MEN = 'Basketball - Men',
  SOCCER_WOMEN = 'Soccer - Women',
  SOCCER_MEN = 'Soccer - Men'

  //Basketball - Women
}

//Baseball - Men
