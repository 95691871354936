import { Delete } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Liaison } from '../../../../structures/userInterfaces';

interface Props {
  //no props
  liaison: Liaison;
  handleDeleteLiaison: (liaisonId: string) => void;
}
export const AddedPrimaryLiaisonInfo = (props: Props) => {
  const { liaison, handleDeleteLiaison } = props;
  return (
    <Box
      sx={{
        borderRadius: 5,
        borderColor: 'primary.main',
        borderWidth: 2,
        borderStyle: 'solid',
        padding: 2
      }}
    >
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Primary Liaison</Typography>
          <IconButton
            onClick={() => handleDeleteLiaison(liaison.subuser_id as string)}
            size="small"
          >
            <Delete />
          </IconButton>
        </Box>
        <Typography variant="body1">User Id: {liaison.subuser_id}</Typography>
        <Typography variant="body1">Name: {liaison.name}</Typography>
        <Typography variant="body1">Title: {liaison.title}</Typography>
        <Typography variant="body1">Email: {liaison.email}</Typography>
        <Typography variant="body1">Phone: {liaison.phone}</Typography>
      </Stack>
    </Box>
  );
};
