import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { AssessmentApi } from '../../../../api/AssessmentApi';
import { AssessmentStatus } from '../../../../structures/assessmentEnums';
import { Edit } from '@mui/icons-material';

interface Props {
    gridParams: GridRenderCellParams;
    apiRef: React.MutableRefObject<GridApiPro>;
    openEditModal: () => void;
}
export const AssessmentRowActions = (props: Props): JSX.Element => {
    const changeStatus = AssessmentApi.changeStatus.useMutation()
    async function handleRetire() {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //Talk about how this will work, best not to have to send the entire assessment object at this point because i don't have it
            const result = await changeStatus.mutateAsync({id: props.gridParams.row.id, retired: 1 });
            if (changeStatus.isError)
                throw new Error(`couldn't toggle assessment review status: ${changeStatus.error}`);
            props.apiRef.current.updateRows([
                {
                    id: props.gridParams.row.id,
                    status: result.status
                }
            ]);
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    width: '100%',
                    justifyContent: 'center'
                }}
                onClick={() => props.openEditModal()}
            >
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<Edit color="inherit" />}
                >
                    Edit
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    width: '100%',
                    justifyContent: 'center'
                }}
            >
                <LoadingButton
                    loading={changeStatus.isPending}
                    variant="outlined"
                    size="small"
                    onClick={handleRetire}
                    disabled={
                        props.gridParams.row.status === AssessmentStatus.RETIRED
                    }
                    startIcon={<StopCircleOutlinedIcon />}
                >
                    Retire
                </LoadingButton>
            </Box>
        </>
    );
};
