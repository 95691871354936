/* eslint-disable @typescript-eslint/no-unused-vars */
import { Group } from '../../../structures/organizationInterfaces';
import { dbData } from './groupsDB';

const groups: Group[] = [...dbData];

async function readBy(OrganizationId: number): Promise<Group[] | undefined> {
  const OrganizationGroups = groups.filter(
    (group) => group.OrganizationId === OrganizationId
  );
  return OrganizationGroups;
}

export { readBy };
