/* eslint-disable @typescript-eslint/no-empty-interface */
import { Alert, Box } from '@mui/material';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { Liaison } from '../../../../structures/userInterfaces';
import { AddLiaison } from './AddLiaison';
import { OrganizationDetailsFormInputI } from '../../../../structures/organizationInterfaces';

interface Props {
  currentPrimaryLiaison: Liaison | null;
  setLiaison: (liaison: Liaison) => void;
  formErrors: FieldErrors<OrganizationDetailsFormInputI>;
}

export const AddPrimaryLiasonToOrganization = (props: Props): JSX.Element => {
  const methods = useFormContext<OrganizationDetailsFormInputI>();
  const { formErrors } = props;

  const handleUpdatePrimaryLiaisons = (liaisons: Liaison[]) => {
    //add to the organization foråm context
    const newPrimaryLiaisonState: Liaison = liaisons[1] || liaisons[0];
    methods.setValue('primaryLiaison', newPrimaryLiaisonState, {
      shouldValidate: true
    });
  };

  const currentAddedLiaisons =
    methods.getValues('primaryLiaison') != null
      ? [methods.getValues('primaryLiaison') as Liaison] // fix this
      : [];
  return (
    <Box>
      <AddLiaison
        updateAddedLiaisons={handleUpdatePrimaryLiaisons}
        currentAddedLiaisons={currentAddedLiaisons}
      />
      {formErrors.primaryLiaison && (
        <Alert severity="error">{formErrors.primaryLiaison.message}</Alert>
      )}
    </Box>
  );
};
