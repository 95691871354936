import { SeverityOptions } from '../structures/enums';
import { NoteSummary, NoteSummaryResponse } from '../structures/interfaces';

export const mapNotesSummaryToFrontEnd = (
  assessmentRecordSummaryResponses: NoteSummaryResponse[]
): NoteSummary[] => {
  return assessmentRecordSummaryResponses.map(
    (assessmentRecordSummaryResponse) => {
      return {
        id: assessmentRecordSummaryResponse.note_id,
        assessmentRecordId:
          assessmentRecordSummaryResponse.assessment_record_id,
        createdAt: {
          date: assessmentRecordSummaryResponse.createdAt,
          authorId: assessmentRecordSummaryResponse.change_by_user_id
        },
        severity: {
          before:
            SeverityOptions[
              assessmentRecordSummaryResponse.severity_before
            ],
          after:
            SeverityOptions[
              assessmentRecordSummaryResponse.severity_after
            ],
          authorId: assessmentRecordSummaryResponse.change_by_user_id
        },
        content: assessmentRecordSummaryResponse.content,
        authorId: assessmentRecordSummaryResponse.change_by_user_id
      };
    }
  );
};
