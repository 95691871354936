import {
  UserAssessmentSummary,
  UserAssessmentSummaryResponse
} from '../structures/assessmentRecordInterfaces';

export const mapUserAssessmentSummaryResponseToFrontEnd = (
  userAssessmentSummaryResponses: UserAssessmentSummaryResponse[]
): UserAssessmentSummary[] => {
  return userAssessmentSummaryResponses.map(
    (userAssessmentSummaryResponse) => {
      return {
        id: userAssessmentSummaryResponse.user_assessment_id,
        assessment_id: userAssessmentSummaryResponse.assessment_id,
        codeName: userAssessmentSummaryResponse.codeNameAtStart ?? userAssessmentSummaryResponse.codeName,
        friendlyName: userAssessmentSummaryResponse.friendlyNameAtStart ??userAssessmentSummaryResponse.friendlyName,
        subuser_id: userAssessmentSummaryResponse.subuser_id,
        start_date: userAssessmentSummaryResponse.start_date,
        status: userAssessmentSummaryResponse.status,
        assignment_date: userAssessmentSummaryResponse.assignment_date,
        expire_date: userAssessmentSummaryResponse.expire_date,
        associated_schedule: userAssessmentSummaryResponse.associated_schedule,
        assigned_by: userAssessmentSummaryResponse.assigned_by,
        end_date: userAssessmentSummaryResponse.end_date,
      };
    }
  );
};