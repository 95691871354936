import dayjs from 'dayjs';
import { ActivationStatus } from '../../../structures/enums';
import { Group } from '../../../structures/organizationInterfaces';
import { GroupName } from '../../../structures/organizationEnums';

export const dbData: Group[] = [
  {
    id: 10000,
    OrganizationId: 10000,
    name: GroupName.BASKETBALL_W,
    status: ActivationStatus.ACTIVE,
    assignedAssessment: {
      name: 'MPI',
      score: 89,
      date: dayjs().toISOString()
    },
    liaisonName: 'Jane Doe',
    created: dayjs().toISOString(),
    updated: dayjs().toISOString()
  },
  {
    id: 10001,
    name: GroupName.BASKETBALL_W,
    status: ActivationStatus.ACTIVE,
    assignedAssessment: {
      name: 'A1',
      score: 89,
      date: dayjs().toISOString()
    },
    liaisonName: 'Jeff Doe',
    created: dayjs().toISOString(),
    updated: dayjs().toISOString()
  },
  {
    id: 10002,
    name: GroupName.BASKETBALL_W,
    status: ActivationStatus.ACTIVE,
    assignedAssessment: {
      name: 'MPI',
      score: 89,
      date: dayjs().toISOString()
    },
    liaisonName: 'Bob Doe',
    created: dayjs().toISOString(),
    updated: dayjs().toISOString()
  }
];
