import { GridSelectionModel } from '@mui/x-data-grid';
import React from 'react';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
// import "./AddUserModal.css";
import UsersForm from '../../../controls/UsersForm/UsersForm';
interface Props {
  open: boolean;
  handleClose: () => void;
  selectionModel: GridSelectionModel;
}

export default function EditUserModal(props: Props): JSX.Element {
  const { open, handleClose, selectionModel } = props;
  return (
    <StandardModal
      open={open}
      handleClose={handleClose}
      headerText={'Edit User'}
    >
      <UsersForm usersToEdit={selectionModel} handleClose={handleClose} />
    </StandardModal>
  );
}
