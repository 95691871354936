import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { UserIdToUser } from './userIdToUser/UserIdToUser';

interface Props {
    authorId: string;
    date: string;
}
export const ResponsibleDate = (props: Props): JSX.Element => {
    const { authorId, date } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">
                {date ? dayjs(date).format('M/DD/YYYY') : '-'}
            </Typography>
            <UserIdToUser userId={authorId} />
        </Box>
    );
};
