import {
  AssessmentCategories,
  AssessmentFrequency,
  AssessmentStatus
} from '../structures/assessmentEnums';
import {
  AssessmentSummary,
  AssessmentSummaryResponse
} from '../structures/assessmentInterfaces';

export const mapAssessmentsToAssessmentSummaries = (
  assessments: AssessmentSummaryResponse[]
): AssessmentSummary[] => {
  return assessments.map((assessment: AssessmentSummaryResponse) => {
    return {
      id: assessment.assessment_id,
      assessmentUmbrella: assessment.assessmentUmbrella,
      codeName: assessment.codeName,
      friendlyName: assessment.friendlyName,
      royalty: assessment.royalty,
      cadence: assessment.cadence as AssessmentFrequency,
      recurrence: assessment.recurrence,
      status: assessment.status as AssessmentStatus,
      track: assessment.track,
      category: assessment.category as AssessmentCategories[],
      createdAt: {
        date: assessment.createdAt,
        authorId: assessment.created_by
      },
      updatedAt: {
        date: assessment.updatedAt,
        authorId: assessment.updated_by
      }
    };
  });
};
