/* eslint-disable @typescript-eslint/ban-types */
import { Drawer } from '@mui/material';
import * as React from 'react';

interface Props {
  filterDrawerOpen: boolean;
  setFilterDrawerClosed: Function;
  filterMenu: JSX.Element;
}
function TableFilterDrawer(props: Props): JSX.Element {
  const { filterDrawerOpen, setFilterDrawerClosed, filterMenu } = props;
  const filterAnchor = 'right';
  return (
    <Drawer
      anchor={filterAnchor}
      open={filterDrawerOpen}
      onClose={() => setFilterDrawerClosed()}
      sx={{ zIndex: 1300 }} // TODO: set zIndex in theme
      PaperProps={{
        sx: { width: 500 }
      }}
    >
      {filterMenu}
    </Drawer>
  );
}

export default TableFilterDrawer;
