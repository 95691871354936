import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import z  from "zod"
import { zodResolver } from '@hookform/resolvers/zod';

import { UserApi } from '../../../../api/UserApi';
import {
  Liaison,
  UpdateSubUserRequest
} from '../../../../structures/userInterfaces';
import { zPhone } from '../../../../helpers/phone-validator';

const zLiasonForm = z.object({
    subuser_id: z.string(),
    name: z.string({required_error: 'Liaison Full Name is required'}),
    title: z.string({required_error: 'Liaison Title is required'}),
    phone: zPhone,
    email: z.string({required_error: 'Liaison Email is required'}).email({message: 'Email is not valid'})

})

interface Props {
  handleAddLiaison: (addedPrimaryLiaison: Liaison) => void;
}
export const AddLiaisonForm = (props: Props) => {
  const { handleAddLiaison: handleAddLiaison } = props;
  const {
    control,
    formState: { errors },
    register,
    handleSubmit
  } = useForm<Liaison>({
    resolver: zodResolver(zLiasonForm),
    defaultValues: {
      name: '',
      title: '',
      phone: '',
      email: '',
      subuser_id: 'new'
    }
  });
  const createLiaison = UserApi.update.useMutation();

  const onInvalid = (errors: unknown): void =>
    console.error('there are form errors:', errors);

  const mapLiaisonFormToUpdateSubUserRequest = (
    data: Liaison
  ): UpdateSubUserRequest => {
    return {
      subuser_id: data?.subuser_id || 'new',
      name: data.name,
      email: data.email,
      phone: data.phone
    };
  };

  const formSubmitHandler: SubmitHandler<Liaison> = async (data: Liaison) => {
    // console.log('submitting', data);
    try {
      const createSubUserRequest = mapLiaisonFormToUpdateSubUserRequest(data);
      const result = await createLiaison.mutateAsync(createSubUserRequest);
      if (!result || createLiaison.isError) {
        throw createLiaison.error;
      }
      const createdLiaison: Liaison = {
        subuser_id: result.subuser_id,
        name: data.name,
        email: data.email,
        // transform phone to db format
        phone: zPhone.parse(data.phone),
        title: data.title
      };
      handleAddLiaison(createdLiaison);
    } catch (error) {
      console.error(error);
    }
  };
  // because you can't have nested forms
  const handleClick = async () => {
    await handleSubmit(formSubmitHandler, onInvalid)();
  };

  return (
    <Stack spacing={2} marginTop={2}>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label="Liaison Full Name"
            error={errors.name != null}
            placeholder="Smith, Bob"
          />
        )}
        name="name"
        defaultValue={''}
        control={control}
      />
      <Controller
        render={({ field }) => (
          <TextField
            size="small"
            label="Liaison Title"
            placeholder="Director Of Athletics"
            {...field}
            error={errors.title != null}
          />
        )}
        name="title"
        control={control}
      />
      <TextField
        margin="normal"
        fullWidth
        id="phone"
        size="small"
        label="phone"
        autoFocus
        error={errors.phone != null}
        helperText={errors.phone?.message ?? ''}
        {...register('phone')}
      />
      <Controller
        render={({ field }) => (
          <TextField
            size="small"
            label="Liaison Email"
            placeholder="primaryid@Organization.com"
            {...field}
            error={errors.email != null}
          />
        )}
        name="email"
        control={control}
      />
      <LoadingButton
        variant="contained"
        sx={{ backgroundColor: 'primary.light' }}
        size="small"
        loading={createLiaison.isPending}
        onClick={handleClick}
      >
        Create Liaison
      </LoadingButton>
      {createLiaison.isError && (
        <Alert severity="error">
          {createLiaison.error.message || 'Error creating liaison'}
        </Alert>
      )}
    </Stack>
  );
};
