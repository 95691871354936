/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useEntityFilter from '../../../../helpers/useEntityFilter';
import {
    FilterCriteria,
} from '../../../../structures/interfaces';
import DateFilter from '../../../Templates/EntityTable/DateFilter';
import TableFilterMenu from '../../../Templates/EntityTable/TableFilterMenu';
import {
    mapToActiveStatus,
    mapToUserSeverity,
    mapToYesNo
} from '../../../../api/userMappers';
import OrgFilter from '../../../Templates/EntityTable/OrgFilter';
import MultiSelectIntegerFilter from '../../../Templates/EntityTable/MultiSelectIntegerFilter';

interface Props {
    filters: FilterCriteria;
    handleClose: Function;
    setFilters: Function;
}

function UserAssessmentsFilterMenu(props: Props): JSX.Element {
    // use effect to use the get orgs summary api call to get the orgs and set the default options
    // for the orgs filter

    const { filters, handleClose, setFilters } = props;

    const { displayedFilterState, handleCriterionChange } =
        useEntityFilter(filters ?? {});
    //also possible to filter by...
    // severity_updated_on;
    // severity_updated_by;
    return (
        <TableFilterMenu
            displayedFilterState={displayedFilterState}
            handleClose={handleClose}
            setFilters={setFilters}
        >
            <MultiSelectIntegerFilter
                displayedCriterion={displayedFilterState.severity}
                fieldName={'severity'}
                fieldTitle={'Severity'}
                handleCriterionChange={handleCriterionChange}
                defaultOptions={[0, 1, 2, 3]}
                mapOptionsToDisplayValue={mapToUserSeverity}
            />
            <MultiSelectIntegerFilter
                displayedCriterion={displayedFilterState.active}
                fieldName={'active'}
                fieldTitle={'Active'}
                handleCriterionChange={handleCriterionChange} //TODO: needs to send 1 or 0
                defaultOptions={[1, 0]}
                mapOptionsToDisplayValue={mapToActiveStatus}
            />
            <OrgFilter
                displayedCriterion={displayedFilterState.organizationNames}
                fieldName={'organizationNames'}
                fieldTitle={'Organization'}
                handleCriterionChange={handleCriterionChange}
                groupFilterCriterion={displayedFilterState.user_groups}
            />
            <MultiSelectIntegerFilter
                displayedCriterion={displayedFilterState.all_records_reviewed}
                fieldName={'all_records_reviewed'}
                fieldTitle={'Fully Reviewed'}
                handleCriterionChange={handleCriterionChange}
                defaultOptions={[0, 1]}
                mapOptionsToDisplayValue={mapToYesNo}
            />
            <DateFilter
                displayedCriterion={displayedFilterState.date_added}
                fieldName={'date_added'}
                fieldTitle={'Date Created'}
                handleCriterionChange={handleCriterionChange}
            />
        </TableFilterMenu>
    );
}

export default UserAssessmentsFilterMenu;
