/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CreatePermissionInput,
  Entity,
  EntityTableRequest,
  NoteSummary,
  NoteSummaryResponse,
  UpdatePermissionRequest
} from '../structures/interfaces';
import { RequestResponse } from '../structures/responses';
import EntityApi, { EntityRequest, TableRows } from './EntityApi';
import { sendRequest, SendRequestOptions } from './SeqApi';
import { mapNotesSummaryToFrontEnd } from './noteMappers';
import { FilterOperator } from '../structures/enums';

async function getSortedSummaries(
    pageReq: EntityTableRequest
  ): Promise<TableRows<NoteSummary>> {
    const variables: { [key: string]: unknown } = {
      action: 'get_notes_summaries',
      ...pageReq
    };
    variables.filters = pageReq.filters;
    try {
      const response = await sendRequest(
        variables,
        API_PROCESS,
        undefined,
        { mockRequest: false } as SendRequestOptions
      );
      const data = response.data as unknown as RequestResponse<
        TableRows<NoteSummaryResponse>
      >;
      if (data.status !== 'success')
        throw new Error('could not get sorted notes summaries');
      return {
        rows: mapNotesSummaryToFrontEnd(data.resp_variables.rows),
        totalRows: data.resp_variables.totalRows
      };
    } catch (err) {
      console.log('could not get sorted notes summaries');
      throw err;
    }
  }

const API_PROCESS = 'assessment_record';

export default class NotesApi extends EntityApi {
  static singleton = new NotesApi();

  getSortedSummaries = getSortedSummaries;
  static getSortedSummaries = getSortedSummaries;

  async getEntity(req: EntityRequest): Promise<Entity> {
    // cannot be handled
    const variables = {
      action: 'get_notes_summaries',
      filters: {
        note_id: {
          operator: FilterOperator.EQUALS,
          arguments: [req.id as unknown as string]
        }
      },
      firstRow: 0,
      lastRow: 1,
      sortModel: {},
      searchQuery: "",
    };
    try {
      const response = await sendRequest(variables);
      const data = response.data as unknown as RequestResponse<
        TableRows<NoteSummaryResponse>
      >;
      return data.resp_variables.rows[0] as unknown as Entity
    } catch (err) {
      console.log('could not get permission');
      throw err;
    }
  }

  updatePermissions = async (
    permissionUpdates: UpdatePermissionRequest[]
  ): Promise<number[]> => {
    const variables = {
      action: 'update_permissions',
      permissionUpdates
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as number[];
      return res;
    } catch (err) {
      console.log('could not update permissions');
      throw err;
    }
  };

  createEntity = async (
    entityToCreate: CreatePermissionInput
  ): Promise<Entity> => {
    const variables = {
      action: 'create_permission',
      entityToCreate
    };
    try {
      const response = await sendRequest(variables);
      const res = response.data as unknown as Entity;
      return res;
    } catch (err) {
      console.log('could not create permission');
      throw err;
    }
  };
}
