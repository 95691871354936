import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';
import { ProfileData } from '../../../structures/profile';

interface NameFormI {
  firstName: string;
  lastName: string;
}

const nameSchema: yup.SchemaOf<NameFormI> = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Must be at least 2 chars')
    .max(30, 'May not be more than 30 chars')
    .required(),
  lastName: yup
    .string()
    .min(2, 'Must be at least 2 chars')
    .max(30, 'May not be more than 30 chars')
    .required()
});
interface Props {
  firstName: string;
  lastName: string;
  updateProfile: (attributesToUpdate: Partial<ProfileData>) => Promise<unknown>;
  setModalOpen: (open: boolean) => void;
}
export const EditNameForm = (props: Props) => {
  const { error, execute, loading } = useAsyncRequest(props.updateProfile);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NameFormI>({
    resolver: yupResolver(nameSchema),
    defaultValues: {
      firstName: props.firstName,
      lastName: props.lastName
    }
  });

  const formSubmitHandler: SubmitHandler<NameFormI> = async (
    data: NameFormI
  ) => {
    try {
      const result = await execute({
        first_name: data.firstName,
        last_name: data.lastName
      });
      if (result.error == null) {
        props.setModalOpen(false);
      } else {
        throw result.error;
      }
    } catch (err) {
      console.log('could not validate name');
    }
  };

  const onInvalid = (errors: unknown): void =>
    console.error('houston we got em', errors);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(formSubmitHandler, onInvalid)}
          noValidate
          sx={{ mt: 1 }}
          width={'100%'}
        >
          <TextField
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            error={errors.firstName != null}
            helperText={errors.firstName?.message ?? ''}
            {...register('firstName')}
          />
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            autoFocus
            error={errors.lastName != null}
            helperText={errors.lastName?.message ?? ''}
            {...register('lastName')}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Confirm Name
          </LoadingButton>
          {error && (
            <Alert severity="error">
              {error.message || 'An unknown error occured'}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
};
