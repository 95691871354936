export enum AssessmentStatus {
    ACTIVE = 'Active',
    RETIRED = 'Retired'
}

export enum AssessmentCategories {
    DISCLAIMER = 'Disclaimer',
    MENTAL_HEALTH = 'Mental Health',
    DEPRESSION = 'Depression',
    SUBSTANCE_ABUSE = 'Substance Abuse',
    ANXIETY = 'Anxiety',
    TRIAGE_TOOL = 'Triage Tool',
    SLEEP_DISTURBANCE = 'Sleep Disturbance',
    ALCOHOL_MISUSE = 'Alcohol Misuse',
    DRUGS = 'Drugs',
    DISORDERED_EATING = 'Disordered Eating',
    ADHD = 'ADHD',
    BIPOLAR_DISORDER = 'Bipolar Disorder',
    PTSD = 'PTSD',
    GAMBLING = 'Gambling',
    PSYCHOSIS = 'Psychosis',
    INTERVENTION_MONITORING = 'Intervention & Monitoring',
    CLINICAL_ASSESSMENT = 'Clinical Assessment',
    COMPUTERIZED_COGNITIVE_COACHING = 'Computerized Cognitive Coaching (C.C.C.)',
    REMINDER = 'Reminder',
    OTHER = 'Other'
}
export enum AssessmentFrequency {
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    BI_WEEKLY = 'Bi-weekly',
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly'
}

export enum QuestionTypes {
    MULTIPLE_CHOICE = 'multiple choice',
    MULTIPLE_MULTIPLE = 'multiple multiple',
    SHORT_RESPONSE = 'short response',
    SCALE = 'scale',
    TRUE_FALSE = 'tf',
    CHECKBOX = 'checkbox'
}
