import { createContext, useContext, useState, ReactNode } from 'react';
import { ProfileModal } from '../ProfileModal/ProfileModal';

interface ProfileModalContextType {
  openProfileModal: () => void;
  closeProfileModal: () => void;
}

const ProfileModalContext = createContext<ProfileModalContextType | undefined>(
  undefined
);

export const useProfileModal = (): ProfileModalContextType => {
  const context = useContext(ProfileModalContext);
  if (!context) {
    throw new Error(
      'useProfileModal must be used within a ProfileModalProvider'
    );
  }
  return context;
};

interface ProfileModalProviderProps {
  children: ReactNode;
}

export const ProfileModalProvider = ({
  children
}: ProfileModalProviderProps) => {
  const [displayProfile, setDisplayProfile] = useState(false);

  const openProfileModal = () => setDisplayProfile(true);
  const closeProfileModal = () => setDisplayProfile(false);

  return (
    <ProfileModalContext.Provider
      value={{ openProfileModal, closeProfileModal }}
    >
      {children}
      {displayProfile && <ProfileModal handleClose={closeProfileModal} />}
    </ProfileModalContext.Provider>
  );
};
