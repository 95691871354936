import { User } from '../../structures/userInterfaces';
import { UserSeverity } from '../../structures/enums';
import { randomSixDigitInteger } from '../../helpers/randomNumber';

export const dbData: User[] = [
  {
    severity: {
      severity: UserSeverity.NORMAL,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: false
  },
  {
    severity: {
      severity: UserSeverity.LOW,
      author: 'lori'
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: false
  },
  {
    severity: {
      severity: UserSeverity.CRITICAL,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  },
  {
    severity: {
      severity: UserSeverity.MEDIUM,
      author: undefined
    },
    id: randomSixDigitInteger(),
    group: 'Basketball',
    assessments: 'assessments data',
    organization: 'U of Oklahoma',
    status: 'Active',
    actions: 'action menu place holder',
    phone: '5122990497',
    email: 'faker@faker.com',
    reviewed: true
  }
];
