import { useMemo } from 'react';
import {OrgApi} from '../../../../api/OrganizationApi';
import UserInfoPanelSection from '../../UsersPage/UserDetailsModal/UserInfoPanelSection';
import { Alert, Skeleton, Typography } from '@mui/material';
import { InfoPanelSectionData } from '../../UsersPage/UserDetailsModal/DetailedUserInfoPanel';

interface Props {
    orgId: string;
}
export const OrgDetailsPanel = (props: Props): JSX.Element => {
    const org = OrgApi.get.useQuery(props.orgId)

    const groups = OrgApi.groups.useQuery(props.orgId)

    const detailsData = useMemo(() => ({
        Id: org.data?.org_id.toUpperCase().slice(0, 8),
        Status: org.data?.status,
        Type: org.data?.type,
        Address: org.data?.address1
    }), [org.data])

    const groupsData: InfoPanelSectionData = {};

    groups.data?.forEach((group) => {
        groupsData[group.category] = group.name;
    });

    const primaryLiasonData = {
        Name: org.data?.primaryLiaison.name,
        Email: org.data?.primaryLiaison.email,
        Phone: org.data?.primaryLiaison.phone,
        Label: org.data?.primaryLiaison.title || 'No title loaded'
    };

    return (
        <>
            <Typography variant="h4" marginBottom={5}>
                {org.isLoading ? <Skeleton /> : org.data?.name}
            </Typography>
            {org.isLoading && <Skeleton />}
            {org.isError && (
                <Alert severity="error">
                    Error fetching organization data.
                </Alert>
            )}
            {!org.isLoading && (
                <>
                    <UserInfoPanelSection data={detailsData} title="Details" />
                </>
            )}
            {groups.isLoading && <Skeleton />}
            {groups.isError && (
                <Alert severity="error">
                    Error fetching organization groups.
                </Alert>
            )}
            {!groups.isLoading && !groups.isError && (
                <>
                    <UserInfoPanelSection data={groupsData} title="Groups" />
                </>
            )}
            {org.isLoading && <Skeleton />}
            {org.isError && (
                <Alert severity="error">Error fetching primary liaison.</Alert>
            )}
            {!org.isLoading && !org.isError && (
                <>
                    <UserInfoPanelSection
                        data={primaryLiasonData}
                        title="Primary liaison"
                    />
                </>
            )}
        </>
    );
};
