import React from 'react';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import { useNavigate, useParams } from 'react-router-dom';
import SchedulesForm from './SchedulesForm';
// import "./AddUserModal.css";

export default function AddScheduleModal(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <StandardModal
      open={true}
      handleClose={() => navigate(-1)}
      headerText={`Add Schedule${
        params.id != null ? `: User ${params.id}` : ''
      }`}
    >
      <SchedulesForm handleClose={() => navigate(-1)} userId={params.id} />
    </StandardModal>
  );
}
