import { Box } from '@mui/material';
import { TabI } from '../../structures/interfaces';
import PageTabsHeader from '../Templates/PageTabs/PageTabsHeader';
import { ReactComponent as PsychLogo } from '../assets/icons/Psychlogoheader.svg';
import ControlCenter from './ControlCenter/ControlCenter';
import './MainHeader.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

export default function MainHeader(): JSX.Element {
  const headerTabs: TabI[] = [
    {
        name: 'Dashboard',
        icon: AssessmentOutlinedIcon,
        link: '/dashboard/'
    },
    {
      name: 'Users',
      icon: PersonOutlineOutlinedIcon,
      link: '/users/'
    },
    {
      name: 'Organizations',
      icon: CorporateFareOutlinedIcon,
      link: '/organizations/'
    },
    {
      name: 'Assessments',
      icon: AssignmentOutlinedIcon,
      link: '/assessments/'
    },
    {
      name: 'Schedules',
      icon: PersonOutlineOutlinedIcon,
      link: '/schedules/'
    }
  ];
  

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        height: 60,
        position: 'relative',
        dispaly: 'grid',
        gridTemplateColumns: '260px 1fr 100px 130px',
        gridTemplateAreas: 'logo tabs notifications toolbar'
      }}
      className="main-header" // TODO: fix this
    >
      <div>
        <PsychLogo className="header-logo"></PsychLogo>
      </div>
      <Box sx={{ height: 'inherit' }}>
        <PageTabsHeader tabs={headerTabs}></PageTabsHeader>
      </Box>
      <div className="tool-bar-area center-vertically">
        <ControlCenter />
      </div>
    </Box>
  );
}
