import { Stack } from '@mui/system';

export function UserAssignedAssessmentsTableActions(): JSX.Element {

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
    >
      
    </Stack>
  );
}
