import {
  OrgSummary,
  OrgSummaryResponse
} from '../structures/organizationInterfaces';

export const mapOrgSummaryResponseToFrontEnd = (
  organizations: OrgSummaryResponse[]
): OrgSummary[] => {
  return organizations.map((organization: OrgSummaryResponse) => {
    return {
      id: organization.org_id,
      status: organization.status,
      name: organization.name,
      type: organization.type,
      address:
        organization.address1 +
        ' ' +
        organization.address2 +
        ' ' +
        organization.city +
        ' ' +
        organization.state +
        ' ' +
        organization.zip +
        ' ' +
        organization.country,
      groups: organization.groups,
      pLName: organization.pLName,
      pLPhone: organization.pLPhone,
      pLEmail: organization.pLEmail,
      createdAt: {
        date: organization.created_on,
        authorId: organization.created_by
      },
      updatedAt: {
        date: organization.created_on,
        authorId: organization.updated_by
      }
    };
  });
};
