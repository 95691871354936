import { GridSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import AssessmentForm from './AssessmentForm';
// import "./AddUserModal.css";
interface Props {
  selectionModel: GridSelectionModel;
}

export default function EditAssessmentModal({
  selectionModel
}: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <StandardModal
      open={true}
      handleClose={() => navigate(-1)}
      headerText={`Edit Assessment ${selectionModel[0]}`}
    >
      <AssessmentForm
        selectionModel={selectionModel}
        handleClose={() => navigate(-1)}
      />
    </StandardModal>
  );
}
