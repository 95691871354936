/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useEntityFilter from '../../../../helpers/useEntityFilter';
import { FilterCriteria } from '../../../../structures/interfaces';
import DateFilter from '../../../Templates/EntityTable/DateFilter';
import MultiSelectFilter from '../../../Templates/EntityTable/MultiSelectFilter';
import TableFilterMenu from '../../../Templates/EntityTable/TableFilterMenu';

interface Props {
  filters: FilterCriteria;
  handleClose: Function;
  setFilters: Function;
}

function OrganizationTableFilterMenu(props: Props): JSX.Element {
  const { filters, handleClose, setFilters } = props;

  const { displayedFilterState, handleCriterionChange } =
    useEntityFilter(filters);

  return (
    <TableFilterMenu
      displayedFilterState={displayedFilterState}
      handleClose={handleClose}
      setFilters={setFilters}
    >
      <DateFilter
        displayedCriterion={displayedFilterState.created_on}
        fieldName={'created_on'}
        fieldTitle={'Date Created'}
        handleCriterionChange={handleCriterionChange}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.updated_on}
        fieldName={'updated_on'}
        fieldTitle={'Date Updated'}
        handleCriterionChange={handleCriterionChange}
      />
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.status}
        fieldName={'status'}
        fieldTitle={'Status'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={['Active', 'Inactive']}
      />
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.type}
        fieldName={'type'}
        fieldTitle={'Type'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={['University', 'Company']}
      />
      {/* <MultiSelectFilter
        displayedCriterion={displayedFilterState.groups}
        fieldName={'groups'}
        fieldTitle={'Groups'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={['An issue has occured']}
        Api={OrgApi}
      /> */}
    </TableFilterMenu>
  );
}

export default OrganizationTableFilterMenu;
