// src/mocks/handlers.js
import { rest } from 'msw';

// import 'formdata-polyfill';
import { actionLibrary, performAction } from './handlers/actionHandler';

function parseMultipartFormData(bodyText: string, contentType: string) {
  const formData = new FormData();
  const boundary = contentType.match(/boundary=(.*)/)?.[1];

  if (!boundary) {
    throw new Error('No boundary found in the content type');
  }

  const parts = bodyText
    .split(`--${boundary}`)
    .filter((part) => part.trim() !== '' && part.trim() !== '--');

  parts.forEach((part) => {
    const [headerText, value] = part.split('\r\n\r\n');
    const nameMatch = headerText.match(/name="([^"]+)"/);
    const name = nameMatch ? nameMatch[1] : null;

    if (name) {
      formData.append(name, value.trim());
    }
  });

  return formData;
}

export const handlers = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest.post('/mock', async (req, res, ctx): Promise<any> => {
    // Check if the user is authenticated in this session
    //TODO figure out how to get ahold of the request body in a usable way
    // const formData = await req.formData();

    try {
      const reqText = await req.text();
      const formData = parseMultipartFormData(
        reqText,
        req.headers.get('content-type') || ''
      );
      const variablesJson = JSON.parse(formData.get('variables') as string);
      // const action = variablesJson.action;
      await new Promise((r) => setTimeout(r, 500)); // simulate network latency
      const actionResponce = await performAction(variablesJson, actionLibrary);

      return res(ctx.status(200), ctx.json(actionResponce));
    } catch (e) {
      console.error("got an error in mock server's handler", e);
      return res(ctx.status(500), ctx.json({ error: e }));
    }
    // return data;
  })
];
