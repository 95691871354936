import { Chip, useTheme } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Severity } from '@/structures/enums';

interface Props {
  severity: Severity;
  authorId?: string;
}
export function SeverityChip({ authorId, severity }: Props): JSX.Element {
  const theme = useTheme();
  let backgroundColor: string;

  switch (severity) {
    case "LOW":
      backgroundColor = theme.palette.warning.light;
      break;
    case "NORMAL":
      backgroundColor = theme.palette.success.main;
      break;
    case "MEDIUM":
      backgroundColor = theme.palette.warning.main;
      break;
    case "CRITICAL":
      backgroundColor = theme.palette.error.light;
      break;
    default:
      backgroundColor = theme.palette.grey[400];
  }
  const style = {
    background: backgroundColor,
    height: 25
  };
  return severity ? (
    <Chip
      icon={authorId ? <AccountCircleOutlinedIcon /> : <></>}
      label={severity}
      sx={style}
    />
  ) : (
    <></>
  );
}
