/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import {
    DashboardMetrics,
    FilterOperator,
    UserSeverity
} from '../structures/enums';
import SeqApi, {
    mutation,
    query,
    sendRequest,
    SendRequestOptions
} from './SeqApi';
import { Entity, EntityTableRequest } from '../structures/interfaces';
import {
    AssessmentRecordSummary,
    AssessmentRecordSummaryResponse,
    QuestionAnswer,
    QuestionAnswerResponse,
    SetFlaggedStatusResponse,
    SetReviewedStatusResponse
} from '../structures/assessmentRecordInterfaces';
import EntityApi, { TableRows } from './EntityApi';
import { RequestResponse } from '../structures/responses';
import { AssessmentRecord } from '../structures/assessmentInterfaces';
import {
    mapAssessmentRecordSummaryResponseToFrontEnd,
    mapQuestionAnswersResponseToFrontEnd
} from './assessmentRecordMappers';

export interface UserAssessmentsRequest {
    userId: number;
}

export interface AssessmentReviewedStatus {
    id: number;
    reviewed: boolean;
}

export * as AssessmentRecordApi from './AssessmentRecordApi';

const PROCESS_NAME = 'assessment_record';

export async function getSortedSummaries(
    pageReq: EntityTableRequest
): Promise<TableRows<AssessmentRecordSummary>> {
    const variables: { [key: string]: any } = {
        action: 'get_assessment_record_summaries',
        ...pageReq
    };
    try {
        const response = await sendRequest(variables, PROCESS_NAME, undefined, {
            mockRequest: false
        } as SendRequestOptions);
        const data = response.data as unknown as RequestResponse<
            TableRows<AssessmentRecordSummaryResponse>
        >;
        if (data.status !== 'success') {
            throw Error(data.message);
        }
        return {
            rows: mapAssessmentRecordSummaryResponseToFrontEnd(
                data.resp_variables.rows
            ),
            totalRows: data.resp_variables.totalRows
        };
    } catch (err) {
        console.log('could not retrieve assessments');
        console.log(err);
        throw err;
    }
}

export const sortedSummaries = query<TableRows<AssessmentRecordSummaryResponse>>()({
    variables: (pageReq: EntityTableRequest) => ({
    action: 'get_assessment_record_summaries',
    ...pageReq
    }),
    queryKey: (pageReq) => [PROCESS_NAME, pageReq],
    processName: PROCESS_NAME,
    transform: (r) => ({
        rows: mapAssessmentRecordSummaryResponseToFrontEnd(r.rows),
        totalRows: r.totalRows
    })
});

export const get = query<TableRows<AssessmentRecordSummaryResponse>>()({
    variables: (id: string) => ({
        // TODO: fix backend get_assessment_record to return all info
        action: 'get_assessment_record_summaries',
        ...({
            filters: {
                assessment_record_id: {
                    operator: FilterOperator.EQUALS,
                    arguments: [id]
                }
            },
            sortModel: [],
            firstRow: 0,
            lastRow: 1,
            searchQuery: ''
        } satisfies EntityTableRequest)
    }),
    queryKey: (id) => [PROCESS_NAME, id],
    processName: PROCESS_NAME,
    transform: (r) => mapAssessmentRecordSummaryResponseToFrontEnd(r.rows)[0]
});

export const getAnswers = query<QuestionAnswerResponse[]>()({
    variables: (user_assessment_id: string) => ({
        action: 'retrieve_user_assessment_answers',
        user_assessment_id
    }),
    processName: PROCESS_NAME,
    queryKey: (user_assessment_id) => [
        PROCESS_NAME,
        user_assessment_id,
        'answers'
    ],
    transform: (r) => mapQuestionAnswersResponseToFrontEnd(r)
});

export const addNote = mutation<{ note_id: string }>()({
    variables: (params: {
        recordId: string;
        severity: number;
        note: string;
    }) => ({
        action: 'add_assessment_note',
        note_data: {
            assessment_record_id: params.recordId,
            content: params.note,
            severity: params.severity
        }
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

export const markAllAsReviewed = mutation<{ num_updated: number }>()({
    variables: (params: { subuserId: string }) => ({
        action: 'mark_all_assessment_records_reviewed',
        subuser_id: params.subuserId
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

// TODO: getSeverity

export const setReviewed = mutation<SetReviewedStatusResponse>()({
    variables: (params: {id: string, reviewed: boolean}) => ({
        action: 'set_reviewed_status',
        assessment_record_id: params.id,
        reviewed: params.reviewed ? 1 : 0
    }),
    processName: PROCESS_NAME,
    transform: (r) => r
});

export const setFlagged = mutation<SetFlaggedStatusResponse>()({
    variables: (params: {id: string, flagged: boolean}) => ({
        action: 'mark_assessment_record_flagged',
        assessment_record_id: params.id,
        flagged: params.flagged ? 1 : 0
    }),
    processName: PROCESS_NAME,
    transform: (r) => ({...r, flagged: Boolean(r.flagged)})
});
