/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useState } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useSnackbar } from 'notistack';
import {
    Controller,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext
} from 'react-hook-form';
import createDefaultObject from '../../../../helpers/createDefaultObject';
import {
    EntityTableRequest,
    ScheduleFormInput
} from '../../../../structures/interfaces';
import useAsyncRequest from '../../../../helpers/hooks/useAsyncRequest';
import { AssessmentApi } from '../../../../api/AssessmentApi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { UpdateScheduleReq } from '../../../../structures/scheduleInterfaces';
import _ from 'lodash';
import { UserApi } from '../../../../api/UserApi';
import ScheduleApi from '../../../../api/ScheduleApi';
import { AssessmentFrequency } from '../../../../structures/assessmentEnums';
import { formatDateForBackend } from '../../../../api/SeqApi';

interface Props {
    handleRemove: (index: number) => void;
    scheduleId: string;
    handleIdUpdate: (index: number, id: string) => void;
    index: number;
}

const scheduleSchema: yup.SchemaOf<ScheduleFormInput> = yup.object().shape({
    id: yup.string().required(),
    recurrence: yup.number().required(),
    cadence: yup.string().oneOf(Object.values(AssessmentFrequency)).required(),
    assessment: yup
        .object()
        .shape({ id: yup.string().required(), name: yup.string().required() })
        .nullable()
        .required(),
    startDate: yup.string().required(),
    name: yup.string().required(),
    user: yup
        .object()
        .shape({
            id: yup.string().required(),
            name: yup.string().required(),
            email: yup.string().required()
        })
        .nullable()
        .required()
});

export default function ScheduleInputs(props: Props): JSX.Element {
    const { handleRemove, scheduleId, index } = props;
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [assessmentSearchTerm, setAssessmentSearchTerm] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<ScheduleFormInput>({
        resolver: yupResolver(scheduleSchema),
        defaultValues: {
            id: 'new',
            assessment: null,
            startDate: '',
            user: null,
            cadence: AssessmentFrequency.WEEKLY,
            recurrence: 5
        }
    });

    const {
        execute: getAssessments,
        data: assessments,
        loading: getAssessmentsLoading
    } = useAsyncRequest(
        AssessmentApi.getSortedSummaries,
    );

    const pageReq: EntityTableRequest = {
        firstRow: 0,
        lastRow: 10,
        searchQuery: userSearchTerm,
        filters: {},
        sortModel: []
    };

    const {data: users, isLoading: getUsersLoading} = UserApi.bulkByQuery.useQuery(pageReq);

    const assessmentOptions = assessments?.rows?.map((assessment) => {
        return {
            id: assessment.id,
            name: assessment.friendlyName,
            cadence: assessment.cadence as string,
            recurrence: assessment.recurrence
        };
    });

    const {
        execute: updateSchedule,
        loading: updateScheduleLoading,
        error: updateScheduleError,
        data: updateScheduleData
    } = useAsyncRequest(
        ScheduleApi.singleton.updateSchedule,
        ScheduleApi.singleton
    );

    useEffect(() => {
        if (!updateScheduleError && updateScheduleData) {
            enqueueSnackbar(`Created schedule`, {
                variant: 'success'
            });
        }
    }, [updateScheduleData]);

    useEffect(() => {
        if (updateScheduleError) {
            enqueueSnackbar(
                `Error creating schedule: ${updateScheduleError.message}`,
                {
                    variant: 'error'
                }
            );
        }
    }, [updateScheduleError]);

    const userOptions = users?.rows?.map((user) => ({
        id: user.subuser_id,
        name: user.name,
        email: user.email
    }));


    const debouncedAssessmentSearch = _.debounce(async (value) => {
        try {
            const pageReq: EntityTableRequest = {
                firstRow: 0,
                lastRow: 10,
                searchQuery: value,
                filters: {},
                sortModel: []
            };
            const result = await getAssessments(pageReq);
            if (!result.data || result.error) {
                throw result.error?.message || 'Error fetching organizations';
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    const mapFormToUpdateScheduleReq = (data: ScheduleFormInput) => {
        const req: UpdateScheduleReq = {
            schedule_id: data.id,
            subuser_id: data.user?.id as string,
            assessment_id: data.assessment?.id as string,
            recurrence: data.recurrence,
            cadence: data.cadence,
            startDate: formatDateForBackend(data.startDate),
            name: data.name as string
        };
        return req;
    };

    const formSubmitHandler: SubmitHandler<ScheduleFormInput> = async (
        data: ScheduleFormInput
    ) => {
        try {
            const req = mapFormToUpdateScheduleReq(data);
            const result = await updateSchedule(req);
            if (!result.data || result.error) {
                throw result.error?.message || 'Error updating schedule';
            }
            handleRemove(index);
        } catch (e) {
            console.log('could not validate code', e);
        }
    };

    const onInvalid = (errors: unknown): void =>
        console.error('houston we got em', errors);

    return (
        <Box
            sx={{
                width: '100%',
                marginTop: 3,
                border: 'solid',
                borderRadius: 5,
                borderWidth: 2,
                padding: 2,
                borderColor: 'primary.main'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>
                    {scheduleId === 'new' ? 'New Schedule' : 'Edit Schedule'}
                </Typography>
                <IconButton onClick={() => handleRemove(index)} size="small">
                    <Delete />
                </IconButton>
            </Box>
            <Grid container spacing={2}>
                <Grid item>
                    <Controller
                        name={`name` as const}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={'Schedule Name'}
                                size="small"
                                error={!!errors.name}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name={`user` as const}
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={field.value}
                                fullWidth
                                sx={{ width: 200 }}
                                options={userOptions || []}
                                inputValue={userSearchTerm}
                                clearOnEscape={false}
                                getOptionLabel={(option) =>
                                    `${option.name} - ${option.email}`
                                }
                                onInputChange={(_event, value) => {
                                    setUserSearchTerm(value || '');
                                }}
                                onChange={(_event, value) => {
                                    if (
                                        !value ||
                                        !value.id ||
                                        !value.name ||
                                        !value.email
                                    ) {
                                        field.onChange(null);
                                        return;
                                    }
                                    const user = {
                                        id: value?.id,
                                        name: value?.name,
                                        email: value?.email
                                    };
                                    field.onChange(user);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select User"
                                        variant="outlined"
                                        error={!!errors.user}
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    return value
                                        ? option.id === value.id
                                        : false;
                                }}
                                loading={getUsersLoading}
                                size="small"
                                defaultValue={null}
                                noOptionsText={
                                    !userOptions
                                        ? 'Search users'
                                        : 'No options found'
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name={`assessment` as const}
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={field.value}
                                fullWidth
                                sx={{ width: 200 }}
                                options={assessmentOptions || []}
                                inputValue={assessmentSearchTerm}
                                clearOnEscape={false}
                                getOptionLabel={(option) => `${option.name}`}
                                onInputChange={(_event, value) => {
                                    setAssessmentSearchTerm(value || '');
                                    debouncedAssessmentSearch(value);
                                }}
                                onChange={(_event, value) => {
                                    // console.log('value', value);
                                    if (!value || !value.id || !value.name) {
                                        field.onChange(null);
                                        return;
                                    }
                                    const assessment = {
                                        id: value?.id,
                                        name: value?.name,
                                        cadence: value?.cadence,
                                        recurrence: value?.recurrence
                                    };
                                    // console.log('setting value', assessment);
                                    setValue('cadence', assessment.cadence);
                                    setValue(
                                        'recurrence',
                                        assessment.recurrence
                                    );
                                    field.onChange(assessment);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Assessment"
                                        variant="outlined"
                                        error={!!errors.assessment}
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    return value
                                        ? option.id === value.id
                                        : false;
                                }}
                                loading={getAssessmentsLoading}
                                size="small"
                                defaultValue={null}
                                noOptionsText={
                                    !assessmentOptions
                                        ? 'Search assessments'
                                        : 'No options found'
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        render={({ field }) => (
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">
                                    Cadence
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    sx={{ width: 200 }}
                                    {...field}
                                    error={!!errors.cadence}
                                    size="small"
                                    label="Cadence"
                                >
                                    {Object.values(AssessmentFrequency).map(
                                        (freq) => (
                                            <MenuItem key={freq} value={freq}>
                                                {freq}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        )}
                        name={`cadence` as const}
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                size="small"
                                {...field}
                                error={!!errors.recurrence}
                                type="number"
                                label="Recurrence"
                            />
                        )}
                        name={`recurrence` as const}
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    orientation="landscape"
                                    openTo="day"
                                    label="Start Date"
                                    {...field}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            error={!!errors.startDate}
                                            sx={{ width: 200 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                        name={`startDate` as const}
                        control={control}
                    />
                </Grid>
            </Grid>
            <LoadingButton
                onClick={handleSubmit(formSubmitHandler, onInvalid)}
                size="small"
                variant="outlined"
                sx={{ width: 200, marginTop: 4 }}
                loading={updateScheduleLoading}
            >
                Create
            </LoadingButton>
        </Box>
    );
}
