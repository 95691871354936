/* eslint-disable @typescript-eslint/no-unused-vars */
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useNavigate, useParams } from 'react-router';
import EntityDetailModal from '../../../Templates/EntityDetailModal/EntityDetailModal';
import OrganizationGroupsViewer from './OrganizationGroupsViewer';
import OrganizationUsersViewer from './OrganizationUsersViewer';
import { OrgDetailsPanel } from './OrgDetailsPanel';
import { useEffect } from 'react';
import { TabI } from '../../../../structures/interfaces';
// import "./AddUserModal.css";

export default function OrganizationDetailsModal(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const pageTabs: TabI[] = [
    {
      name: 'Users',
      icon: PersonOutlineOutlinedIcon,
      link: 'users/',
      renderPage: () => {
        return (
          <OrganizationUsersViewer
            orgId={params.entityId as unknown as string}
          />
        );
      }
    },
    {
      name: 'Groups',
      icon: GroupsOutlinedIcon,
      link: 'groups/',
      renderPage: () => (
        <OrganizationGroupsViewer
          orgId={params.entityId as unknown as string}
        />
      )
    }
  ];

  useEffect(() => {
    if (params.entityId == null) {
      navigate('/organizations/');
    }
  }, [params.entityId]);

  // custom modal
  return (
    <EntityDetailModal
      handleClose={() => navigate('/organizations/')}
      isOpen={(params.entityId as unknown as number) != null}
      pageTabs={pageTabs}
      renderEntityDetails={() => (
        <OrgDetailsPanel orgId={params.entityId as string} />
      )}
    />
  );
}
