/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography } from '@mui/material';
import {
    GridColDef,
    GridRenderCellParams,
    useGridApiRef
} from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import * as React from 'react';
import { FilterCriteria } from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers,
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import UserAssessmentRecordsFilterMenu from './UserAssessmentsFilterMenu';
import { ScheduleRowActions } from './ScheduleRowActions';
import ScheduleApi from '../../../../api/ScheduleApi';
import { ScheduleStatusChip } from './ScheduleStatusChip';
import AddScheduleModal from './AddScheduleModal';
import { FilterOperator } from '../../../../structures/enums';

interface Props {
    userId: number | null;
}

export const UserAsessmentsFilterContext =
    React.createContext<FilterCriteria | null>(null);

function UserAssessmentSchedulesViewer(props: Props): JSX.Element {
    const { userId } = props;
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams) => {
                return <Typography>{params.value}</Typography>;
            },
            sortable: false
        },
        {
            field: 'userId',
            headerName: 'User Name',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderUser,
            sortable: false
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params) => (
                <ScheduleStatusChip status={params.row.status} />
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            headerClassName: 'table-column-title'
        },
        {
            field: 'track',
            headerName: 'Track',
            width: 100,
            headerClassName: 'table-column-title'
        },

        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 120,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },
        {
            field: 'progress',
            headerName: 'Progress',
            width: 75,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'endDate',
            headerName: 'Ended Date',
            width: 200,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderResponsibleDate
        },
        {
            field: 'action',
            headerName: 'Row Actions',
            width: 175,
            headerClassName: 'table-column-title',
            renderCell: (params) => (
                <ScheduleRowActions apiRef={apiRef} gridParams={params} />
            ) // render pause and cancel buttons
        }
    ];

    function generateEditScheduleModal(): JSX.Element {
        return <></>;
    }

    function generateAddScheduleModal(): JSX.Element {
        return <AddScheduleModal />;
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen,
        handleClose,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <UserAssessmentRecordsFilterMenu
                        filters={filterCriteria}
                        handleClose={handleClose}
                        setFilters={setFilterCriteria}
                    />
                }
            />
        );
    };
    const generateTableActions = (): JSX.Element => {
        // const userType = localStorage.getItem('USER_TYPE');
        // return userType === 'psychologist' ? <></> : <></>;
        return <></>;
    };

    return (
        <Box sx={{ height: '100%' }}>
            <EntityTable
                pinnedColumns={{ right: ['action'] }}
                tableKey={['schedules', userId]}
                generateColumns={generateColumns}
                api={ScheduleApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                generateEditEntityModal={generateEditScheduleModal}
                generateAddEntityModal={generateAddScheduleModal}
                entityDetailModal={<></>}
                generateEntityFilterForm={generateFilterForm}
                generateTableActions={generateTableActions}
                apiRef={apiRef}
                notesModal={<></>}
                initialState={ {
                        permaFilters: {
                            subuser_id: {
                                operator: FilterOperator.EQUALS,
                                arguments: [userId as unknown as string]
                            }
                        }
                    } }
            />
        </Box>
    );
}

export default UserAssessmentSchedulesViewer;
