import StandardModal from '../../Templates/StandardModal/StandardModal';
import { UserProfileInfo } from './UserProfileInfo';

interface Props {
  handleClose: () => void;
}
export const ProfileModal = ({ handleClose }: Props) => {
  return (
    <StandardModal handleClose={handleClose} headerText="Profile" open={true}>
      <UserProfileInfo />
    </StandardModal>
  );
};
