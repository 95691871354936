import { faker } from '@faker-js/faker';
import {
  RecentUserAssessmentMicroRecordResponse,
  UserSummaryResponse,
} from '../../structures/userInterfaces';
import { StatusOptions } from '../../structures/enums';

export const generateUserSummaryRows = (
  numRows: number
): UserSummaryResponse[] => {
  const userSummaries = [];

  for (let i = 0; i < numRows; i++) {
    const userSummary = {
        status: faker.helpers.arrayElement(StatusOptions),
      subuser_id: faker.string.uuid(),
      severity: faker.number.int({ min: 0, max: 3 }),
      severity_updated_by: faker.string.uuid(),
      flagged: faker.number.int({ min: 0, max: 1 }),
      severity_updated_on: faker.date.recent().toISOString(),
      // recentAssessmentRecords: generateRecentMicroAssessments(2),
      organizationNames: [faker.company.name(), faker.company.name()],
      user_groups: [faker.lorem.word(), faker.lorem.word(), faker.lorem.word()],
      all_records_reviewed: faker.number.int({ min: 0, max: 1 }),
      name: faker.person.fullName(),
      email: faker.internet.email(),
      phone: faker.phone.number(),
      active: faker.number.int({ min: 0, max: 1 }),
      date_added: faker.date.recent().toISOString()
    };

    userSummaries.push(userSummary);
  }

    return userSummaries as unknown as UserSummaryResponse[]
};

export const generateRecentMicroAssessments = (number: number) => {
  const recentMicroAssessments = [];

  for (let i = 0; i < number; i++) {
    const recentMicroAssessment: RecentUserAssessmentMicroRecordResponse = {
      assessment_record_id: faker.string.uuid(),
      user_assessment_id: faker.string.uuid(),
      severity: faker.number.int({ min: 0, max: 3 }),
      score: faker.number.int({ min: 0, max: 100 }),
      subuser_id: faker.string.uuid(),
      completion_date: faker.date.recent().toISOString(),
      flagged: faker.number.int({ min: 0, max: 1 }),
      last_reviewed: faker.date.recent().toISOString(),
      reviewed_by: faker.string.uuid(),
      start_date: faker.date.recent().toISOString(),
      original_assessment_id: faker.string.uuid(),
      has_notes: faker.number.int({ min: 0, max: 1 }),
      code_name: faker.lorem.word(),
      friendly_name: faker.lorem.word()
    };

    recentMicroAssessments.push(recentMicroAssessment);
  }

  return recentMicroAssessments;
};
