/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    TextField
} from '@mui/material';
import pluralize from 'pluralize';
import * as React from 'react';
import EntityApi from '../../../api/EntityApi';
import { FilterOperator } from '../../../structures/enums';
import { FilterCriterion, FilterIntCriterion } from '../../../structures/interfaces';

import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';
import EntityFilter from './EntityFilter';

interface Props {
    fieldName: string;
    fieldTitle: string;
    handleCriterionChange: (
        field: string,
        operator: FilterOperator | undefined,
        args: string[] | undefined
    ) => void;
    displayedCriterion: FilterCriterion | null;
    defaultOptions?: (number | string)[];
    mapOptionsToDisplayValue?: (option: unknown) => string;

    Api?: typeof EntityApi;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultiSelectIntegerFilter(props: Props): JSX.Element {
    const {
        fieldName,
        fieldTitle,
        handleCriterionChange,
        displayedCriterion,
        Api,
        defaultOptions
    } = props;
    const {
        execute: loadOptions,
        loading,
        data
    } = useAsyncRequest(
        Api?.singleton.getFilterOptions ??
        (() => Promise.resolve(defaultOptions ?? [])),
        Api?.singleton
    );

    React.useEffect(() => {
        loadOptions(fieldName);
    }, [loadOptions]);

    // const [options, setOptions] = React.useState<string[]>(defaultOptions ?? []);

    // Do this to fetch options once there is a backend
    // React.useEffect(() => {
    //   const fetchData = async (): Promise<any> => {
    //     const groups = await Api.singleton.getNames();
    //     console.log(groups);
    //     return groups;
    //   };

    //   fetchData()
    //     .then((groups) => setOptions(groups))
    //     .catch((err) => console.log(err));
    // }, []);

    function handleChipDelete(): void {
        handleCriterionChange(fieldName, undefined, undefined);
    }

    function generateFilterChip(): JSX.Element {
        const operator = (displayedCriterion?.operator ?? '') as string;
        const numArguments = displayedCriterion?.arguments?.length ?? 0;
        if (numArguments === 0) {
            return <></>;
        }
        return (
            <Chip
                label={operator + ' ' + (numArguments as unknown as string)}
                onDelete={handleChipDelete}
                sx={{ maxWidth: 190 }}
            />
        );
    }

    function genFilterComponent(): JSX.Element {
        return (
            <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={defaultOptions ?? []}
                    disableCloseOnSelect
                    value={displayedCriterion?.arguments ?? []}
                    getOptionLabel={(option) => {
                        return props.mapOptionsToDisplayValue
                            ? props.mapOptionsToDisplayValue(option)
                            : option.toString();
                    }}
                    onChange={(event, value) => {
                        const typedValue = value.length === 0 ? undefined : (value as string[]);
                        handleCriterionChange(fieldName, FilterOperator.ANY_OF, typedValue);
                    }}
                    renderOption={(p, option, { selected }) => (
                        <li {...p}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {props.mapOptionsToDisplayValue
                                ? props.mapOptionsToDisplayValue(option)
                                : option}
                        </li>
                    )}
                    ListboxProps={{ style: { zIndex: 9999 } }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={pluralize(fieldTitle)} />
                    )}
                />
            </FormControl>
        );
    }

    return (
        <EntityFilter
            filterTitle={fieldTitle}
            generateFilterChip={generateFilterChip}
            filterComponent={genFilterComponent()}
        />
    );
}

export default MultiSelectIntegerFilter;
