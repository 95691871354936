import { FilterCriteria } from '../structures/interfaces';

import _ from 'lodash';
import { FilterOperator } from '../structures/enums';

const requiresTwoArgs = [FilterOperator.BETWEEN];

export function resolveFilters(displayedCriteria: FilterCriteria): FilterCriteria {
    let resolvedFilterCriteria = displayedCriteria;
    for (const key of Object.keys(resolvedFilterCriteria)) {
        const operator = resolvedFilterCriteria[key].operator;
        const args = resolvedFilterCriteria[key].arguments;
        if (Object.values(resolvedFilterCriteria[key]).includes(undefined)) {
            resolvedFilterCriteria = _.omit(resolvedFilterCriteria, key);
        } else if (
            requiresTwoArgs.includes(operator as FilterOperator) &&
            (args as string[]).length !== 2
        ) {
            resolvedFilterCriteria = _.omit(resolvedFilterCriteria, key);
        }
    }
    return resolvedFilterCriteria;
}

function useResolveFilters() {
    return {
        resolveFilters
    };
}

export default useResolveFilters;
