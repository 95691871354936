/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useState } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useSnackbar } from 'notistack';
import {
    Controller,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext
} from 'react-hook-form';
import createDefaultObject from '../../../../../helpers/createDefaultObject';
import {
    EntityTableRequest,
    UserAssignmentFormInput
} from '../../../../../structures/interfaces';
import { AssessmentApi } from '../../../../../api/AssessmentApi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Add, Delete } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import _, { update } from 'lodash';
import { UserApi } from '../../../../../api/UserApi';
import { AssessmentFrequency } from '../../../../../structures/assessmentEnums';
import { fi } from '@faker-js/faker';
import { AssignAssessmentToSubuserRequest } from '@/structures/userInterfaces';

interface Props {
    handleRemove: (index: number) => void;
    userId: string;
    handleIdUpdate: (index: number, id: string) => void;
    index: number;
    assessmentIds: string[];
}

const userAssignmentSchema: yup.SchemaOf<UserAssignmentFormInput> = yup
    .object()
    .shape({
        id: yup.string().required(),
        expireDate: yup.string().required(),
        user: yup
            .object()
            .shape({
                id: yup.string().required(),
                name: yup.string().required(),
                email: yup.string().required()
            })
            .nullable()
            .required()
    });

export default function AssessmentAssignmentInputs(props: Props): JSX.Element {
    const { handleRemove, userId, index } = props;
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<UserAssignmentFormInput>({
        resolver: yupResolver(userAssignmentSchema),
        defaultValues: {
            id: 'new',
            user: null,
            expireDate: ''
        }
    });
    const pageReq: EntityTableRequest = {
        firstRow: 0,
        lastRow: 10,
        searchQuery: userSearchTerm,
        filters: {},
        sortModel: []
    };

    const { data: users, isLoading: getUsersLoading } =
        UserApi.bulkByQuery.useQuery(pageReq);

    // FIXME: create assignMultipleAssessmentsToUser api function or use the error returned
    // by useAsyncRequest to handle errors (i.e. snackbar) how the api intends
    const assignAssessment = UserApi.assignAssessment.useMutation();

    const formSubmitHandler: SubmitHandler<UserAssignmentFormInput> = async (
        data: UserAssignmentFormInput
    ) => {
        try {
            if (!data.user || !data.user.id) {
                throw new Error("Unable to assign assessment. No user")
            }
            const requests = props.assessmentIds.map(
                (assessment_id): AssignAssessmentToSubuserRequest => ({
                    assessment_id,
                    subuser_id: data.user!.id,
                    expireDate: data.expireDate
                })
            );
            const results = await Promise.allSettled(
                requests.map((a) => assignAssessment.mutateAsync(a))
            );
            for (let i = 0; i < results.length; i++) {
                const res = results[i];
                const req = requests[i];
                let msg = '';
                let variant: 'error' | 'success' = 'error';
                switch (res.status) {
                    case 'rejected':
                        msg = `Error assigning assessment: ${req.assessment_id} to user: ${req.subuser_id}. Reason: ${res.reason}`;
                        variant = 'error';
                        break;
                    case 'fulfilled':
                        msg = `Assigned assessment: ${req.assessment_id} to user: ${req.subuser_id}`;
                        variant = 'success';
                        break;
                }
                enqueueSnackbar(msg, {
                    variant
                });
            }
        } catch (e) {
            console.log('could not validate code', e);
        }
    };

    const userOptions = users?.rows?.map((user) => ({
        id: user.subuser_id,
        name: user.name,
        email: user.email
    }));

    const handleRemoveUser = (index: number) => {
        handleRemove(index);
    };

    const onInvalid = (errors: unknown): void =>
        console.error('houston we got em', errors);

    return (
        <Box sx={{ width: '100%', marginTop: 3 }}>
            <Grid container spacing={2}>
                <Grid item sx={{ width: '30vw', marginRight: '1vw' }}>
                    <Controller
                        name={`user` as const}
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={field.value}
                                fullWidth
                                sx={{ width: 200 }}
                                options={userOptions || []}
                                inputValue={userSearchTerm}
                                clearOnEscape={false}
                                getOptionLabel={(option) =>
                                    `${option.name} - ${option.email}`
                                }
                                onInputChange={(_event, value) => {
                                    setUserSearchTerm(value || '');
                                }}
                                onChange={(_event, value) => {
                                    if (
                                        !value ||
                                        !value.id ||
                                        !value.name ||
                                        !value.email
                                    ) {
                                        field.onChange(null);
                                        return;
                                    }
                                    const user = {
                                        id: value?.id,
                                        name: value?.name,
                                        email: value?.email
                                    };
                                    field.onChange(user);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select User"
                                        variant="outlined"
                                        error={!!errors.user}
                                        sx={{ width: '30vw' }}
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => {
                                    return value
                                        ? option.id === value.id
                                        : false;
                                }}
                                loading={getUsersLoading}
                                size="small"
                                defaultValue={null}
                                noOptionsText={
                                    !userOptions
                                        ? 'Search users'
                                        : 'No options found'
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Controller
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    orientation="landscape"
                                    openTo="day"
                                    label="Expire Date"
                                    {...field}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            error={!!errors.expireDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                        name={`expireDate` as const}
                        control={control}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <LoadingButton
                    onClick={handleSubmit(formSubmitHandler, onInvalid)}
                    size="small"
                    variant="outlined"
                    sx={{ width: 200, marginTop: 2 }}
                    loading={assignAssessment.isPending}
                    startIcon={<Add />}
                >
                    Assign
                </LoadingButton>
                <LoadingButton
                    startIcon={<Delete />}
                    onClick={() => handleRemoveUser(index)}
                    size="small"
                    sx={{ width: 100 }}
                >
                    Remove
                </LoadingButton>
            </Box>
        </Box>
    );
}
