import { SubUserResponse } from '../../structures/userInterfaces';

import { faker } from '@faker-js/faker'; // TODO:

export const generateSubUserRows = (numRows: number): SubUserResponse[] => {
  const userSummaries: SubUserResponse[] = [];

  for (let i = 0; i < numRows; i++) {
    const userSummary: SubUserResponse = {
      subuser_id: faker.string.uuid(),
      name: faker.person.fullName(),
      email: faker.internet.email(),
      phone: faker.phone.number(),
      date_added: faker.date.past().toISOString(),
      associated_user_id: faker.string.uuid()
    };

    userSummaries.push(userSummary);
  }

  return userSummaries;
};
