/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import {
    CacheContextI,
    FilterCriteria
} from '../../../../structures/interfaces';
import AddEditEntityTableButtons from '../../../Templates/EntityTable/AddEditEntityTableButtons';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers,
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import OrganizationDetailsModal from '../../OrganizationsPage/OrganizationDetailModal/OrganizationDetailsModal';
import { ScheduleStatusChip } from '../../UsersPage/UserDetailsModal/ScheduleStatusChip';
import { ScheduleRowActions } from '../../UsersPage/UserDetailsModal/ScheduleRowActions';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import AddScheduleModal from '../../UsersPage/UserDetailsModal/AddScheduleModal';
import ScheduleApi from '../../../../api/ScheduleApi';
import { Typography } from '@mui/material';
import ScheduleTableFilterMenu from './ScheduleTableFilterMenu';
import { UserIdToUser } from 'src/components/Templates/EntityTable/userIdToUser/UserIdToUser';

export const PermissionCacheContext = React.createContext<CacheContextI | null>(
    null
);

export const PermissionFilterContext =
    React.createContext<FilterCriteria | null>(null);

export function ScheduleTable(): JSX.Element {
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'userName',
            headerName: 'User Name',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderUser,
            sortable: false
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: (params) => (
                <ScheduleStatusChip status={params.row.status} />
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            headerClassName: 'table-column-title'
        },
        {
            field: 'track',
            headerName: 'Track',
            width: 100,
            headerClassName: 'table-column-title'
        },

        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 120,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderDate
        },
        {
            field: 'progress',
            headerName: 'Progress',
            width: 75,
            headerClassName: 'table-column-title',
            renderCell: (params: GridRenderCellParams) => (
                <Typography>{params.value}%</Typography>
            ),
            sortable: false
        },
        {
            field: 'endDate',
            headerName: 'Ended Date',
            width: 200,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.renderResponsibleDate
        },
        {
            field: 'action',
            headerName: 'Row Actions',
            width: 175,
            headerClassName: 'table-column-title',
            renderCell: (params) => (
                <ScheduleRowActions apiRef={apiRef} gridParams={params} />
            ),
            sortable: false
        }
    ];


    function generateEditScheduleModal(
    ): JSX.Element {
        return <></>;
    }

    function generateAddScheduleModal(): JSX.Element {
        return <AddScheduleModal />;
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen,
        handleClose,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <ScheduleTableFilterMenu
                        handleClose={handleClose}
                        filters={filterCriteria}
                        setFilters={setFilterCriteria}
                    />
                }
            />
        );
    }

    const generateTableActions = (
        selectionModel: GridSelectionModel
    ): JSX.Element => {
        // const userType = localStorage.getItem('USER_TYPE');
        // return userType === 'psychologist' ? <></> : <></>;
        return (
            <AddEditEntityTableButtons
                entity={'Schedules'}
                selectionModel={selectionModel}
                editable={false}
            />
        );
    };

    return (
        <PermissionCacheContext.Provider value={{ outOfDate, setOutOfDate }}>
            <EntityTable
                pinnedColumns={{ right: ['action'] }}
                tableKey={['schedules']}
                generateColumns={generateColumns}
                generateEditEntityModal={generateEditScheduleModal}
                generateAddEntityModal={generateAddScheduleModal}
                entityDetailModal={<OrganizationDetailsModal />}
                generateEntityFilterForm={generateFilterForm}
                api={ScheduleApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                selectionMode="none"
                generateTableActions={generateTableActions}
                apiRef={apiRef}
            />
        </PermissionCacheContext.Provider>
    );
}
