import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  handleCloseAll: () => void;
  handleClose: () => void;
  isOpen: boolean;
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25%',
  height: '15%',
  bgcolor: 'background.default',
  border: 'none',
  boxShadow: 24,
  borderRadius: 2,
  padding: 2
};

export default function ExitModal({
  handleClose,
  handleCloseAll,
  isOpen
}: Props): JSX.Element {
  return (
    <Modal open={isOpen} sx={{ zIndex: 20000 }}>
      <Box sx={modalStyle}>
        <Box sx={{ display: 'grid', gridTemplateRows: '2vh 2vh' }}>
          <Typography
            sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }}
          >
            Are you sure you want to exit?
          </Typography>
          <Typography
            sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }}
          >
            Your work will not save
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '10vw 10vw',
            justifyContent: 'center',
            height: '50%',
            gap: '1rem',
            paddingTop: '1rem'
          }}
        >
          <Button
            sx={{
              backgroundColor: 'background.default',
              color: 'primary.main',
              fontSize: '12px',
              padding: '0.5rem 0.75rem',
              '&:hover': {
                backgroundColor: 'background.paper'
              }
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            // write code that this button will close the modal
            onClick={() => {
              handleCloseAll();
            }}
            sx={{
              backgroundColor: 'primary.light',
              color: 'background.default',
              padding: '0.5rem 0.75rem',
              fontSize: '12px',
              '&:hover': {
                backgroundColor: 'primary.main'
              }
            }}
          >
            Leave without saving
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
