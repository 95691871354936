import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import StandardModal from '../../../Templates/StandardModal/StandardModal';
import NotesApi from '../../../../api/NotesApi';
import { UserApi } from '../../../../api/UserApi';
import { FilterOperator } from '../../../../structures/enums';
import { SeverityChip } from '../../../Templates/EntityTable/SeverityChip';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';

export const AssessmentNoteModal = () => {
    const params = useParams();
    const { data: note } = useQuery({
        queryFn: async () => {
            const { rows: notes } = await NotesApi.singleton.getSortedSummaries(
                {
                    filters: {
                        note_id: {
                            operator: FilterOperator.EQUALS,
                            arguments: [params.noteId as string]
                        }
                    },
                    sortModel: [],
                    firstRow: 0,
                    lastRow: 1,
                    searchQuery: ''
                }
            );
            if (notes.length === 0) {
                throw new Error(`No note with id ${params.noteId}`);
            }
            return notes[0];
        },
        queryKey: ['notes', params.noteId]
    });

    const { data: author } = UserApi.userName.useQuery(
        note?.createdAt.authorId ?? '',
        { enabled: note !== undefined }
    );

    const navigate = useNavigate();
    return (
        <StandardModal
            handleClose={() => navigate(-1)}
            headerText={`View Note: ${params.noteId}`}
            open={true}
            width="80%"
            height="min-content"
        >
            <>
                <Box
                    className="no-scroll-bar"
                    sx={{
                        overflowY: 'scroll',
                        height: '90%',
                        marginTop: 0,
                        padding: 2
                    }}
                >
                    <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
                        <Typography variant="h6">Content:</Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontStyle: 'italic' }}
                        >
                            {note?.content}
                        </Typography>

                        <Divider sx={{ marginY: 2 }} />

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">
                                    Author:
                                    <br />
                                    {author?.name ?? "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">
                                    Created at:
                                    <br />
                                    {note
                                        ? dayjs(note?.createdAt?.date).format(
                                              'MM/DD/YYYY h:MM A'
                                          )
                                        : ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {note ? (
                                    note.severity.before ===
                                    note.severity.after ? (
                                        <Typography variant="subtitle1">
                                            Severity:
                                            <br />
                                            {
                                                <SeverityChip
                                                    severity={
                                                        note.severity.after
                                                    }
                                                />
                                            }
                                        </Typography>
                                    ) : (
                                        <Typography variant="subtitle1">
                                            Severity:
                                            <br />
                                            Changed from{' '}
                                            {
                                                <SeverityChip
                                                    severity={
                                                        note.severity.before
                                                    }
                                                />
                                            }{' '}
                                            to{' '}
                                            {
                                                <SeverityChip
                                                    severity={
                                                        note.severity.after
                                                    }
                                                />
                                            }
                                        </Typography>
                                    )
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10%'
                    }}
                >
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{ height: 45 }}
                            onClick={() => navigate(-1)}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </>
        </StandardModal>
    );
};
