/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Box } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {AssessmentApi} from '../../../../api/AssessmentApi';

import { LoadingButton } from '@mui/lab';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
    AssessmentCategories,
    AssessmentFrequency,
    AssessmentStatus,
    QuestionTypes
} from '../../../../structures/assessmentEnums';
import ExitModal from '../../../../components/Templates/ExitModal/ExitModal';
import {
    Assessment,
    ChoiceOption,
    Question,
    RangeBound
} from '../../../../structures/assessmentInterfaces';
import { AssessmentUpdateContext } from './AssessmentTable';
import AssessmentDetailsForm from './AssessmentDetailsForm';
import AssessmentQuestionsForm from './AssessmentQuestionsForm';
import AssessmentScoringThresholdsForm from './AssessmentScoringThresholdsForm';

const ChoiceOptionSchema: yup.SchemaOf<ChoiceOption> = yup.object().shape({
    points: yup.number().required('Points are required'),
    flag: yup.boolean().required('Flag is required'),
    text: yup.string().required('Text is required')
});

type QuestionInputs = Pick<Question, 'prompt' | 'options' | 'type'>;

const QuestionSchema: yup.SchemaOf<QuestionInputs> = yup.object().shape({
    type: yup
        .mixed()
        .oneOf(Object.values(QuestionTypes))
        .required('Question type is required'),
    prompt: yup.string().required('Question prompt is required'),
    options: yup.array().of(ChoiceOptionSchema).required()
});

const RangeBoundSchema: yup.SchemaOf<RangeBound> = yup.object().shape({
    value: yup.number().required('Must have a value')
});

const AssessmentSchema: yup.SchemaOf<Assessment> = yup.object().shape({
    assessment_id: yup.string().required('Assessment id is required'),
    assessmentUmbrella: yup.string().required('Assessment name is required'),
    codeName: yup.string().required('Assessment code name is required'),
    friendlyName: yup.string().required('Friendly name is required'),
    royalty: yup
        .string()
        .oneOf(['true', 'false'])
        .required('Must specify royalty indicator required'),
    cadence: yup
        .mixed()
        .oneOf(Object.values(AssessmentFrequency))
        .required('Cadence is required'),
    recurrence: yup.number().required('Recurrence is required'),
    status: yup
        .mixed()
        .oneOf(Object.values(AssessmentStatus))
        .required('Status is required'),
    track: yup.string().required('Track is required'),
    category: yup
        .array()
        .of(yup.mixed().oneOf(Object.values(AssessmentCategories)))
        .required('Category is required'),
    scoringThresholds: yup
        .array()
        .of(yup.number().required())
        .min(3)
        .required('Must have at least 3 scoring thresholds'),
    description: yup.string().required('Description is required'),
    scoreRange: yup.array().of(RangeBoundSchema).min(2).max(2).required(),
    questions: yup.array().of(QuestionSchema).optional()
});

const blankAssessmentDefaults: Assessment = {
    status: AssessmentStatus.ACTIVE,
    track: '1',
    assessmentUmbrella: '',
    codeName: '',
    friendlyName: '',
    royalty: 'false',
    cadence: AssessmentFrequency.MONTHLY,
    recurrence: 4,
    category: [] as AssessmentCategories[],
    scoreRange: [{ value: 0 }, { value: 100 }],
    scoringThresholds: [0, 40, 60, 80, 100],
    questions: [
        {
            prompt: '',
            options: [
                {
                    points: 0,
                    flag: false,
                    text: ''
                }
            ],
            order_num: 0
        }
    ],
    description: '',
    assessment_id: 'new'
};

interface Props {
    handleClose: () => void;
    selectionModel?: GridSelectionModel;
}

function AssessmentForm(props: Props) {
    const methods = useForm<Assessment>({
        resolver: yupResolver(AssessmentSchema),
        defaultValues: blankAssessmentDefaults
    });

    const [exitModal, setExitModal] = useState(false);

    const updateAssessment = AssessmentApi.update.useMutation();

    const attachQuestions = AssessmentApi.attachQuestions.useMutation();

    const { outOfDate: updateTableFlag, setOutOfDate: setUpdateTableFlag } =
        useContext(AssessmentUpdateContext) || {};

    const [generalError, setGeneralError] = useState('');

    /// value will only be undefined when query is disabled
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const assessment = AssessmentApi.get.useQuery(props.selectionModel?.at(0)!, {
        enabled: props.selectionModel !== undefined && props.selectionModel.length > 0,
    });

    const formData = useMemo(() => {
        if (assessment.data && props.selectionModel!.length > 1) {
            setGeneralError(
                'You can only select one assessment to edit at a time'
            );
        }
        if (assessment.data) {
            return assessment.data;
        }
        return blankAssessmentDefaults;
    }, [assessment.data, props.selectionModel])

    // TODO: make inner component that takes in a values prop
    // to reset the form instead of using useEffect
    useEffect(() => {
        methods.reset(formData);
    }, [formData])

    const formSubmitHandler: SubmitHandler<Assessment> = async (
        data: Assessment
    ) => {
        const dataWithoutQuestions = {
            ...data,
            questions: []
        };

        const updateAssessmentResult = await updateAssessment.mutateAsync(
            dataWithoutQuestions
        );

        if (updateAssessment.isError) {
            console.log('setting general error');
            setGeneralError(
                updateAssessment.error.message || 'Unknown error'
            );
            return;
        }

        await attachQuestions.mutateAsync({
            assessment_id: updateAssessmentResult.assessment_id,
            questions: data.questions
        });

        if (attachQuestions.isError) {
            setGeneralError(attachQuestions.error.message);
            return;
        }

        if (setUpdateTableFlag) {
            setUpdateTableFlag(!updateTableFlag);
        }

        props.handleClose();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInvalid = (errors: any): void =>
        console.error('houston we got em', errors);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(formSubmitHandler, onInvalid)}
                style={{ height: '100%', width: '100%' }}
            >
                <Box
                    className="no-scroll-bar"
                    sx={{
                        overflowY: 'scroll',
                        height: '90%',
                        flexDirection: 'column',
                        display: 'flex',
                        width: '100%',
                        gap: 3
                    }}
                >
                    {generalError && (
                        <Alert severity="error">{generalError}</Alert>
                    )}
                    <AssessmentDetailsForm loading={assessment.isLoading} />
                    <AssessmentScoringThresholdsForm
                        loading={assessment.isLoading}
                    />
                    <AssessmentQuestionsForm loading={assessment.isLoading} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10%'
                    }}
                >
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{ height: 45 }}
                            onClick={() => setExitModal(true)}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={
                                Object.keys(methods.formState.errors).length !==
                                0
                            }
                            loading={updateAssessment.isPending}
                            size="medium"
                            sx={{ height: 45, fontColor: 'white' }}
                            color="secondary"
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                    {exitModal && (
                        <ExitModal
                            handleCloseAll={props.handleClose}
                            handleClose={() => setExitModal(false)}
                            isOpen
                        />
                    )}
                </Box>
            </form>
        </FormProvider>
    );
}

export default AssessmentForm;
