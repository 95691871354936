/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import TableFilterMenu from '../../../Templates/EntityTable/TableFilterMenu';
import DateFilter from '../../../Templates/EntityTable/DateFilter';
import { FilterCriteria } from '../../../../structures/interfaces';
import { FilterOperator } from '../../../../structures/enums';
import useEntityFilter from '../../../../helpers/useEntityFilter';
import MultiSelectFilter from '../../../Templates/EntityTable/MultiSelectFilter';
import ScalarFilter from '../../../Templates/EntityTable/ScalarFilter';

interface Props {
  filters: FilterCriteria;
  handleClose: Function;
  setFilters: Function;
}
function UserAssignedAssessmentsFilterMenu(props: Props): JSX.Element {
  const { filters, handleClose, setFilters } = props;

  const { displayedFilterState, handleCriterionChange } =
    useEntityFilter(filters);

  return (
    <TableFilterMenu
      displayedFilterState={displayedFilterState}
      handleClose={handleClose}
      setFilters={setFilters}
    >
      <DateFilter
        displayedCriterion={displayedFilterState.start_date}
        fieldName={'start_date'}
        fieldTitle={'Date Started'}
        handleCriterionChange={handleCriterionChange}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.end_date}
        fieldName={'end_date'}
        fieldTitle={'Date Completed'}
        handleCriterionChange={handleCriterionChange}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.assignment_date}
        fieldName={'assignment_date'}
        fieldTitle={'Date Assigned'}
        handleCriterionChange={handleCriterionChange}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.expire_date}
        fieldName={'expire_date'}
        fieldTitle={'Date Expired'}
        handleCriterionChange={handleCriterionChange}
      />
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.status}
        fieldName={'status'}
        fieldTitle={'Status'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={['EXPIRED', 'NOT_STARTED', 'COMPLETE', 'IN_PROGRESS']}
      />
    </TableFilterMenu>
  );
}

export default UserAssignedAssessmentsFilterMenu;
