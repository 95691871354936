import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
interface Props {
  id: string;
  link: string;
}
export function IdCell({ id, link }: Props): JSX.Element {
  return (
    <Button component={Link} to={link}>
      {id.slice(0, 8)}
    </Button>
  );
}
