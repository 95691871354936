import { query } from './SeqApi';

export * as MetricApi from './MetricApi';
const PROCESS_NAME = 'data';

interface Data {
  completed_assessments: number;
  end_date: string;
  expired_assessments: number;
  organizations_created: number;
  start_date: string;
}


export const changes = query<Data>()({
    variables: (params: { start_date: string, end_date: string }) => ({
        action: 'get_metrics',
        ...params
    }),
    queryKey: (params) => [PROCESS_NAME, params],
    processName: PROCESS_NAME,
    transform: (r) => r
});
