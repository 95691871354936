/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/no-children-prop */
import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Stack
} from '@mui/material';
import { Box } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UserApi } from '../../../api/UserApi';
import {
    OrgGroup,
    OrgSummary,
    UserInputI
} from '../../../structures/organizationInterfaces';
import ExitModal from '../../../components/Templates/ExitModal/ExitModal';
import parseSpreadsheet, {
    type User as ParsedUser
} from '../../../helpers/xlsx';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Grid, IconButton, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {OrgApi} from '../../../api/OrganizationApi';
import { EntityTableRequest } from '../../../structures/interfaces';
import {
    Controller,
    FormProvider,
    useFieldArray,
    UseFieldArrayReturn,
    useForm,
    useFormContext,
    useWatch
} from 'react-hook-form';
import { Alert, Autocomplete } from '@mui/material';
import { GroupInputI } from '../../../structures/organizationInterfaces';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import z from 'zod';
import { zPhone } from '../../../helpers/phone-validator';
import { SubUser } from '../../../structures/userInterfaces';
import { useDebouncedETRSearch } from '../../../helpers/debounced-etr-search';

interface Props {
    handleClose: Function;
    usersToEdit?: GridSelectionModel;
}

export interface UsersFormInputI {
    users: UserInputI[];
}

const zUserInput = z.object({
    id: z.string().min(1),
    groups: z.array(
        z.object({
            organization: z.object({
                id: z.string().min(1),
                name: z.string().min(1, {message: 'Organization name is required'})
            }, {required_error: 'Organization is required'}),
            group: z.object({
                id: z.string().min(1),
                name: z.string().min(1, {message: 'Group name is required'})
            }, {required_error: 'Group is required'})
        })
    ),
    email: z.string().min(1, {message: 'Email is required'}).email('Enter a valid email'),
    phone: zPhone,
    name: z.string({required_error: 'Name is required'}).min(1, {message: 'Name is required'})
});

function generateEmptyUser() {
    return {
        id: 'new',
        email: '',
        name: '',
        phone: '',
        groups: []
    };
}

type UserSpreadsheet = {
    users: ParsedUser[];
    groups: GroupList[];
};

type UsersFormMode = 'edit' | 'add';
type GroupList = {
    organization: {
        id: string;
        name: string;
    };
    group: {
        id: string;
        name: string;
    };
};
type UserEntry = {
    id: string;
    email: string;
    phone: string;
    name: string;
    groups: GroupList[];
};

function addGroupsToUsers(users: SubUser[]) {
    const groups = {
        groups: [
            {
                organization: {
                    id: '',
                    name: ''
                },
                group: {
                    id: '',
                    name: ''
                }
            }
        ]
    };
    return users.map((u) => Object.assign(u, groups));
}

type UserFormType = {
    users: UserEntry[];
    mode: UsersFormMode;
};

export default function UsersForm(props: Props): JSX.Element {
    const { handleClose } = props;
    const mode: UsersFormMode =
        props.usersToEdit !== undefined ? 'edit' : 'add';

    const usersToEditIds = useMemo(
        () => props.usersToEdit?.map((id) => id.toString()) ?? [],
        [props.usersToEdit]
    );

    const getSubusersBulk = UserApi.getBulk.fetchQuery();

    const methods = useForm<UserFormType>({
        defaultValues:
            mode === 'edit'
                ? async () => ({
                      users: await getSubusersBulk(usersToEditIds)
                          .then(addGroupsToUsers),
                      mode
                  })
                : { users: [generateEmptyUser()], mode },
        resolver: zodResolver(
            z.object({
                users: zUserInput.array(),
                mode: z.enum(['edit', 'add'])
            })
        )
    });

    // TODO: add skeletons for loading users when in edit mode

    function handleSetSpreadsheet(sp: UserSpreadsheet) {
        const users = sp.users.map((u) => ({
            ...u,
            id: 'new',
            groups: sp.groups
        }));
        methods.setValue('users', users);
        // validate the form
        methods.trigger('users');
    }

    return (
        <FormProvider {...methods}>
            <form>
                <UserFormHeader
                    {...{ handleSetSpreadsheet, handleClose, mode }}
                />
                <UsersList />
            </form>
        </FormProvider>
    );
}

function AddUserButton(props: { onClick: () => void }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
                type="button"
                variant="contained"
                sx={{
                    width: 250,
                    backgroundColor: 'primary.light',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                onClick={props.onClick}
            >
                Add User
            </Button>
        </Box>
    );
}

type UsersFormHeaderProps = {
    mode: UsersFormMode;
    handleSetSpreadsheet: (sp: UserSpreadsheet) => void;
    handleClose: Function;
    haveUploadedSpreadsheet?: boolean;
};

function UserFormHeader(props: UsersFormHeaderProps) {
    const { mode, handleSetSpreadsheet, handleClose } = props;
    const [fileDialogOpen, setFileDialogOpen] = useState(false);
    const [exitModal, setExitModal] = useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                height: '10%'
            }}
        >
            <Stack spacing={2} direction="row">
                {mode === 'add' && (
                    <>
                        <Button
                            variant="outlined"
                            component="label"
                            size="medium"
                            sx={{ height: 45 }}
                            onClick={() => setFileDialogOpen(true)}
                        >
                            Upload Spreadsheet
                        </Button>
                        <FileDialog
                            open={fileDialogOpen}
                            onClose={() => setFileDialogOpen(false)}
                            setSpreadsheet={handleSetSpreadsheet}
                        />
                    </>
                )}
            </Stack>
            <Button
                variant="outlined"
                size="medium"
                sx={{ height: 45 }}
                onClick={() => setExitModal(true)}
            >
                Close
            </Button>
            {exitModal && (
                <ExitModal
                    handleCloseAll={() => handleClose()}
                    handleClose={() => setExitModal(false)}
                    isOpen
                />
            )}
        </Box>
    );
}

type UserArrayFields = UseFieldArrayReturn<UserFormType, 'users', 'fieldId'>;

function UsersList() {
    const userFields: UserArrayFields = useFieldArray({
        name: 'users',
        keyName: 'fieldId'
    });

    return (
        <Box
            className="no-scroll-bar"
            sx={{ overflowY: 'scroll', height: '600px', marginTop: 3 }}
        >
            <Stack spacing={5}>
                {userFields.fields.map((user, i) => {
                    return (
                        <Box
                            key={i + user.name}
                            sx={{
                                minHeight: 50,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <UserInputs index={i} userFields={userFields} />
                        </Box>
                    );
                })}
                <AddUserButton
                    onClick={() => userFields.append(generateEmptyUser())}
                />
            </Stack>
        </Box>
    );
}

export interface FileDialogProps {
    open: boolean;
    setSpreadsheet: (sp: UserSpreadsheet) => void;
    onClose: () => void;
}

function FileDialog(props: FileDialogProps) {
    // TODO: convert to form
    const { setSpreadsheet, open } = props;
    const [selectedOrg, setSelectedOrg] = useState<OrgSummary | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [parsedUsers, setParsedUsers] = useState<ParsedUser[]>([]);

    const hasOrg = selectedOrg !== null;
    const hasGroup = selectedGroup !== null;
    const hasFile = selectedFile !== null;
    const hasUsers = parsedUsers.length > 0;

    const pageReq: EntityTableRequest = {
        firstRow: 0,
        // FIXME: this constrains select to 10 orgs
        lastRow: 10,
        searchQuery: '',
        filters: {},
        sortModel: []
    };
    const orgs = OrgApi.sortedSummaries.useQuery(pageReq);

    const orgGroups = OrgApi.groups.useQuery(selectedOrg?.id as string, {
        enabled: !!selectedOrg
    })
    function handleClose() {
        setSelectedOrg(null);
        setSelectedGroup(null);
        setSelectedFile(null);
        setParsedUsers([]);
        props.onClose();
    }

    function handleSubmit() {
        if (!hasOrg) {
            console.error('selectedOrg is null');
            return;
        }
        if (!hasGroup) {
            console.error('selectedGroup is null');
            return;
        }
        if (!hasFile) {
            console.error('selectedFile is null');
            return;
        }
        if (!hasUsers) {
            console.error('no parsed users');
            return;
        }
        if (!orgs.data) {
            console.error('no orgs');
            return;
        }
        if (!orgGroups.data || orgGroups.data[selectedGroup] === undefined) {
            console.error('no selected group');
            return;
        }
        const spreadSheet: UserSpreadsheet = {
            users: parsedUsers,
            groups: [
                {
                    organization: {
                        id: selectedOrg.id,
                        name: selectedOrg.name
                    },
                    group: {
                        id: orgGroups.data[selectedGroup].group_id,
                        name: orgGroups.data[selectedGroup].name
                    }
                }
            ]
        };
        setSpreadsheet(spreadSheet);
        handleClose();
    }

    const uploadRef = useRef<HTMLInputElement>(null);

    function openFileUpload() {
        if (uploadRef.current !== null) {
            uploadRef.current.click();
        }
    }
    const snackbar = useSnackbar();
    const [err, setErr] = useState<Error | null>(null);

    function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const file =
            e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
        if (!file) {
            // leave selectedFile untouched in case it is present
            return;
        }
        // TODO: use as backup if parsing fails
        const cb = (users: ParsedUser[] | Error) => {
            if (users instanceof Error) {
                console.error("couldn't parse spreadsheet");
                setErr(users);
            } else {
                console.error('parsed spreadsheet');
                setParsedUsers(users);
            }
        };
        parseSpreadsheet(file, cb);
        if (err) {
            snackbar.enqueueSnackbar(
                `Failed to parse spreadsheet.\n${String(err)}`,
                {
                    variant: 'error'
                }
            );
            setSelectedFile(null);
            console.error(err);
            return;
        }
        setSelectedFile(file);
        snackbar.enqueueSnackbar(`Successfully parsed spreadsheet.`, {
            variant: 'success'
        });
    }

    // TODO: add org and group selection to this dialog
    // create handleUploadSpreadsheet in parent
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle sx={{ background: 'primary' }}>
                Upload Spreadsheet
            </DialogTitle>
            <Box sx={{ width: '400px', padding: '10px' }}>
                <Stack spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel id="org-select-label">
                            Organization
                        </InputLabel>
                        <Select
                            name="org"
                            labelId="org-select-label"
                            label="Organization"
                            required={true}
                            value={!hasOrg ? '' : selectedOrg + ''}
                            onChange={(e) => {
                                const i = +e.target.value
                                setSelectedOrg(orgs.data?.rows[i] ?? null)
                            }}
                            sx={{ width: '400px' }}
                        >
                            {orgs.data &&
                                orgs.data.rows.map((org, i) => (
                                    <MenuItem key={i} value={i}>
                                        {org.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth disabled={!hasOrg}>
                        <InputLabel id="group-select-label">Group</InputLabel>
                        <Select
                            name="group"
                            labelId="group-select-label"
                            label="Group"
                            required={true}
                            value={!hasGroup ? '' : selectedGroup + ''}
                            onChange={(e) => setSelectedGroup(+e.target.value)}
                        >
                            {orgGroups.data &&
                                orgGroups.data.map((group, i) => (
                                    <MenuItem key={i} value={i}>
                                        {group.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Stack spacing={2} direction="row">
                        <FormControl fullWidth>
                            <InputLabel id="file-select-label">File</InputLabel>
                            <Select
                                name="file"
                                labelId="file-select-label"
                                label="File"
                                required={true}
                                value={!hasFile ? '' : selectedFile.name}
                                onOpen={() => {
                                    return;
                                }}
                                IconComponent={() => <></>}
                                readOnly
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <MenuItem value={'none'}>None</MenuItem>
                                {selectedFile && (
                                    <MenuItem value={selectedFile.name}>
                                        {selectedFile.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={openFileUpload}
                        >
                            <FileUploadIcon
                                sx={{
                                    color:
                                        !hasOrg || !hasGroup
                                            ? undefined
                                            : 'white'
                                }}
                            />
                            <input
                                ref={uploadRef}
                                hidden
                                onChange={handleUpload}
                                // TODO: make sure this mime type is lenient enough
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                type="file"
                            />
                        </Button>
                    </Stack>
                    <Button
                        type="submit"
                        disabled={!hasOrg || !hasGroup || !hasFile || !hasUsers}
                        variant="contained"
                        size="medium"
                        sx={{ height: 45 }}
                        onClick={handleSubmit}
                    >
                        {hasFile && !hasUsers ? (
                            <CircularProgress size="25px" />
                        ) : (
                            'Upload'
                        )}
                    </Button>
                </Stack>
            </Box>
        </Dialog>
    );
}

interface UserInputProps {
    index: number;
    userFields: UserArrayFields;
}
// FIXME: not working

function UserInputs({ index, userFields }: UserInputProps): JSX.Element {
    const methods = useFormContext<UserFormType>();
    const {
        setValue,
        getValues,
        getFieldState,
        control,
        formState: { errors: formErrors }
    } = methods;
    const errors = formErrors.users?.[index] ?? {};
    const user = `users.${index}` as const;
    const removeUser = userFields.remove;
    const {
        fields: groups,
        remove: removeGroup,
        append: appendGroup
    } = useFieldArray({
        name: `${user}.groups`,
        keyName: 'fieldId'
    });
    const userId = useWatch({
        control,
        name: `${user}.id`,
        defaultValue: 'new'
    });
    // const field = watch(user)
    const query = UserApi.groups.useQuery(userId, {
        enabled: userId !== 'new'
    });

    const userGroups = useMemo(() => query.data ?? [], [query.data]);

    useEffect(() => {
        if (userId !== 'new' && !getFieldState(`${user}.groups`).isDirty) {
            setValue(`${user}.groups`, userGroups);
        }
    }, [userGroups]);

    const updateSubUser = UserApi.update.useMutation()

    const addUserToGroup = UserApi.addToGroup.useMutation();

    async function fieldSubmitHandler(data: UserEntry) {
        try {
            const updateReq = {
                subuser_id: data.id,
                email: data.email,
                phone: zPhone.parse(data.phone),
                name: data.name
            };
            const updateSubUserResult = await updateSubUser.mutateAsync(updateReq);
            if (!updateSubUserResult || updateSubUser.isError) {
                throw (
                    updateSubUser.error?.message || 'Error updating user'
                );
            }
            for (const group of data.groups) {
                const addUserToGroupResult = await addUserToGroup.mutateAsync({
                    subuser_id: updateSubUserResult.subuser_id,
                    group_id: group.group.id,
                    title: 'SUBJECT'
                });
                if (!addUserToGroupResult || addUserToGroup.isError) {
                    throw (
                        addUserToGroup.error?.message ||
                        'Error associating user to group'
                    );
                }
            }
            removeUser(index);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box
            sx={{
                borderStyle: 'solid',
                borderRadius: 5,
                borderWidth: 2,
                padding: '16px 12px',
                borderColor: 'primary.main',
                width: '100%'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton onClick={() => removeUser(index)} size="small">
                    <Delete />
                </IconButton>
            </Box>
            <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item>
                    <Controller
                        name={`${user}.email`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                error={!!errors.email}
                                size="small"
                                label="Email"
                                autoFocus
                                {...field}
                                value={field.value || ''}
                                placeholder="example@domain.com"
                                InputLabelProps={{
                                    shrink: field.value ? true : false
                                }}
                                sx={{ width: '30vw' }}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name={`${user}.phone`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                id="phone"
                                size="small"
                                label="Phone"
                                error={!!errors.phone}
                                {...field}
                                onChange={(e) => {
                                    console.log(zPhone.safeParse(e.target.value))
                                    setValue(
                                        `${user}.phone`,
                                        e.target.value,
                                        {
                                            shouldValidate: true,
                                            shouldTouch: true
                                        }
                                    );
                                }}
                                value={field.value || ''}
                                InputLabelProps={{
                                    shrink: field.value ? true : false
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name={`${user}.name`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                id="name"
                                size="small"
                                label="Name"
                                error={!!errors.name}
                                {...field}
                                value={field.value || ''}
                                InputLabelProps={{
                                    shrink: field.value ? true : false
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Box>
                {groups.map((group, i) => {
                    return (
                        <Box key={group.fieldId}>
                            <GroupSelection
                                index={i}
                                removeSelf={() => removeGroup(i)}
                                userId={userId}
                                user={user}
                            />
                        </Box>
                    );
                })}
                <Button
                    variant="contained"
                    sx={{ marginTop: 2, backgroundColor: 'primary.light' }}
                    onClick={() =>
                        appendGroup({
                            organization: { id: '', name: '' },
                            group: { id: '', name: '' }
                        })
                    }
                >
                    Add To Organization
                </Button>
            </Box>
            <LoadingButton
                variant="outlined"
                loading={updateSubUser.isPending || addUserToGroup.isPending}
                sx={{ marginTop: 4 }}
                onClick={() => fieldSubmitHandler(getValues(user))}
            >
                {userId === 'new' ? 'Create' : 'Update'}
            </LoadingButton>
        </Box>
    );
}

interface GroupSelectionProps {
    removeSelf: () => void;
    index: number;
    userId: string;
    user: `users.${number}`;
}

const GroupSelection = (props: GroupSelectionProps) => {
    const { removeSelf, index, userId, user } = props;
    const methods = useFormContext<UserFormType>();
    const {
        control,
        setValue,
        formState: { errors: formErrors },
        watch
    } = methods;
    const [, userIdx]: ['users', `${number}`] = user.split('.') as [
        'users',
        `${number}`
    ];
    const errors = formErrors?.users?.[userIdx]?.groups?.[index] ?? {};


    const [orgSearch, setOrgSearch] = useDebouncedETRSearch(200)
    const orgs = OrgApi.sortedSummaries.useQuery(orgSearch)

    const group = `${user}.groups.${index}` as const;

    const thisGroup = watch(group);

    const groupsQ = OrgApi.groups.useQuery(thisGroup.organization?.id as string, {
        enabled: !!thisGroup.organization?.id
    })

    const groups = useMemo(() => {
        if (!groupsQ.data) {
            return [];
        }
        return mapOrgGroupsToGroupInputI(groupsQ.data);
    }, [groupsQ.data])

    const {
        mutate: removeUserFromOrgGroup,
        error: removeUserFromOrgGroupError,
        isPending: removeUserFromOrgGroupLoading
    } = UserApi.removeFromGroup.useMutation();


    const handleRemoveGroup = () => {
        if (userId === 'new' || thisGroup.group.id === '') {
            removeSelf();
            return;
        }
        removeUserFromOrgGroup(
            {
                subuserId: userId,
                groupId: thisGroup.group.id
            },
            {
                onSuccess: () => {
                    removeSelf();
                }
            }
        );
        removeSelf();
    };


    return (
        <Box
            sx={{
                display: 'flex',
                marginTop: 3,
                gap: 2
            }}
        >
            <Controller
                name={`${group}.organization` as const}
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        value={field.value}
                        fullWidth
                        options={orgs.data?.rows.map((org) => ({
                            id: org.id,
                            name: org.name
                        })) ?? []}
                        inputValue={orgSearch.searchQuery}
                        clearOnEscape={false}
                        defaultValue={null}
                        getOptionLabel={(option) => option.name}
                        onInputChange={(_event, value) => {
                            setOrgSearch(value || '');
                        }}
                        onChange={(_event, value) => {
                            if (!value || !value.id || !value.name) {
                                setValue(`${group}.group`, {
                                    id: '',
                                    name: ''
                                });
                                field.onChange(null);
                                return;
                            }
                            const organization = {
                                id: value?.id,
                                name: value?.name
                            };
                            setValue(`${group}.group`, {
                                id: '',
                                name: ''
                            });
                            field.onChange(organization);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Organization"
                                variant="outlined"
                                error={!!errors?.organization}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => {
                            return !!value && option.id === value.id
                        }}
                        loading={orgs.isLoading}
                        size="small"
                    />
                )}
            />
            <Controller
                name={`${group}.group.id` as const}
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                            Group
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            error={errors.group != null}
                            size="small"
                            label="Group"
                            defaultValue={null}
                            {...field}
                            value={
                                groups.find((g) => g.id === field.value)
                                    ? field.value
                                    : ''
                            }
                            onChange={(event) => {
                                field.onChange(event.target.value);
                            }}
                        >
                            {groups.map((group, i) => {
                                return (
                                    <MenuItem
                                        key={i + group.id}
                                        value={group.id}
                                    >
                                        {group.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            />
            <LoadingButton
                startIcon={<Delete />}
                onClick={() => handleRemoveGroup()}
                size="small"
                sx={{ width: 200 }}
                loading={removeUserFromOrgGroupLoading}
                disabled={removeUserFromOrgGroupLoading}
            >
                remove
            </LoadingButton>
            {removeUserFromOrgGroupError && (
                <Alert severity="error">
                    {removeUserFromOrgGroupError.message}
                </Alert>
            )}
        </Box>
    );
};

const mapOrgGroupsToGroupInputI = (groups: OrgGroup[]): GroupInputI[] => {
    const newGroups: GroupInputI[] = groups.map((group) => {
        return {
            id: group.group_id,
            name: group.name
        };
    });

    return newGroups;
};
