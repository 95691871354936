import { Box, Typography } from '@mui/material';
import { AssessmentRecordSummary } from '../../../../structures/assessmentRecordInterfaces';
interface Props {
  assessment: AssessmentRecordSummary;
}
export const AssessmentRecordDetails = ({ assessment }: Props): JSX.Element => {
  // console.log(assessment)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column ' }}>
      <Typography variant="body1">
        Original Assessment Id: {assessment?.originalAssessmentId}
      </Typography>
      <Typography variant="body1">Record Id: {assessment?.id}</Typography>

      <Typography variant="body1">
        Assessment Code: {assessment?.code_name}
      </Typography>
      <Typography variant="body1">Flagged: {assessment?.flagged}</Typography>
      <Typography variant="body1">
        Friendly Name: {assessment?.friendly_name}
      </Typography>
      <Typography variant="body1">
        Last Reviewed: {assessment?.last_reviewed.value} -{' '}
        {assessment?.last_reviewed.authorId}
      </Typography>
      <Typography variant="body1">
        Description: {assessment?.description}
      </Typography>
    </Box>
  );
};
