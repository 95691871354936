import {
  AssessmentRecordSummary,
  AssessmentRecordSummaryResponse,
  QuestionAnswer,
  QuestionAnswerResponse
} from '../structures/assessmentRecordInterfaces';
import { UserSeverity } from '../structures/enums';

export const mapAssessmentRecordSummaryResponseToFrontEnd = (
  assessmentRecordSummaryResponses: AssessmentRecordSummaryResponse[]
): AssessmentRecordSummary[] => {
  return assessmentRecordSummaryResponses.map(
    (assessmentRecordSummaryResponse) => {
      return {
        id: assessmentRecordSummaryResponse.assessment_record_id,
        originalAssessmentId:
          assessmentRecordSummaryResponse.original_assessment_id,
        userAssessmentId: assessmentRecordSummaryResponse.user_assessment_id,
        severity:
          Object.values(UserSeverity)[assessmentRecordSummaryResponse.severity],
        score: assessmentRecordSummaryResponse.score,
        userId: assessmentRecordSummaryResponse.subuser_id,
        completion_date: assessmentRecordSummaryResponse.completion_date,
        flagged: assessmentRecordSummaryResponse.flagged === 1,
        start_date: assessmentRecordSummaryResponse.start_date,
        has_notes: assessmentRecordSummaryResponse.has_notes === 1,
        code_name: assessmentRecordSummaryResponse.code_name,
        friendly_name: assessmentRecordSummaryResponse.friendly_name,
        name: assessmentRecordSummaryResponse.name,
        email: assessmentRecordSummaryResponse.email,
        last_reviewed: {
          value: assessmentRecordSummaryResponse.last_reviewed,
          authorId: assessmentRecordSummaryResponse.reviewed_by
        },
        scoringThresholdAtStart:
          assessmentRecordSummaryResponse.scoringThresholdAtStart,
        description: assessmentRecordSummaryResponse.description
      };
    }
  );
};

export const mapQuestionAnswersResponseToFrontEnd = (
  questionAnswerResponses: QuestionAnswerResponse[]
): QuestionAnswer[] => {
  return questionAnswerResponses.map((questionAnswerResponse) => {
    const obj: QuestionAnswer = {
      id: questionAnswerResponse.user_assessment_answer_id,
      assessmentRecordId: questionAnswerResponse.user_assessment_id,
      questionSnapshot: questionAnswerResponse.question_snapshot,
      optionsSnapshot: questionAnswerResponse.options_snapshot,
      answer: questionAnswerResponse.answer,
      type: questionAnswerResponse.type,
      flagged: questionAnswerResponse.flagged === 1,
      points: questionAnswerResponse.points,
      order_num: questionAnswerResponse.order_num
    };
    return obj;
  });
};
