import {
  Autocomplete,
  Box,
  MenuItem,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  AssessmentCategories,
  AssessmentFrequency,
  AssessmentStatus
} from '../../../../structures/assessmentEnums';
import { Assessment } from '../../../../structures/assessmentInterfaces';

function AssessmentDetailsForm({ loading }: { loading: boolean }): JSX.Element {
  const {
    formState: { errors },
    control
  } = useFormContext<Assessment>();
  const categoryOptions = Object.values(AssessmentCategories);

  return (
    <>
      {!loading ? (
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ marginBottom: 5 }}>
            Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 5,
              flexWrap: 'wrap'
            }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  label="Status"
                  size="small"
                  select
                  sx={{ width: 300 }}
                  {...field}
                  error={errors.status != null}
                >
                  <MenuItem value={AssessmentStatus.ACTIVE}>Active</MenuItem>
                  <MenuItem value={AssessmentStatus.RETIRED}>Retired</MenuItem>
                </TextField>
              )}
              name="status"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Track"
                  size="small"
                  select
                  {...field}
                  error={errors.track != null}
                  sx={{ width: 300 }}
                >
                  <MenuItem value={'1'}>Track 1</MenuItem>
                  <MenuItem value={'2'}>Track 2</MenuItem>
                  <MenuItem value={'3'}>Track 3</MenuItem>
                </TextField>
              )}
              name="track"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={categoryOptions}
                  value={field.value}
                  limitTags={1}
                  size={'small'}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.category != null}
                      label="Category"
                      size="small"
                      placeholder="Choose options"
                    />
                  )}
                />
              )}
              name="category"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <TextField
                  label="Umbrella Assessment"
                  size="small"
                  {...field}
                  sx={{ width: 300 }}
                  error={errors.assessmentUmbrella != null}
                />
              )}
              name="assessmentUmbrella"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Assessment Code"
                  size="small"
                  {...field}
                  sx={{ width: 300 }}
                  error={errors.codeName != null}
                />
              )}
              name="codeName"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Friendly Name"
                  size="small"
                  {...field}
                  sx={{ width: 300 }}
                  error={errors.codeName != null}
                />
              )}
              name="friendlyName"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Royalty"
                  size="small"
                  select
                  sx={{ width: 300 }}
                  {...field}
                  error={errors.royalty != null}
                >
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </TextField>
              )}
              name="royalty"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Cadence"
                  size="small"
                  select
                  sx={{ width: 300 }}
                  {...field}
                  error={errors.cadence != null}
                >
                  {Object.values(AssessmentFrequency).map((frequency, i) => (
                    <MenuItem key={i} value={frequency}>
                      {frequency}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="cadence"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Reccurance"
                  size="small"
                  type="number"
                  {...field}
                  sx={{ width: 300 }}
                  error={errors.recurrence != null}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
              )}
              name="recurrence"
              control={control}
              defaultValue={1}
            />
          </Box>

          <Box sx={{ marginTop: 5, width: '100%' }}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Description"
                  size="small"
                  multiline
                  type="text"
                  {...field}
                  error={errors.description != null}
                  sx={{ width: '100%' }}
                  rows={4}
                />
              )}
              name="description"
              control={control}
            />
          </Box>
        </Box>
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={'30%'} />
      )}
    </>
  );
}

export default AssessmentDetailsForm;
