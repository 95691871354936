import { Chip, useTheme } from '@mui/material';
import * as React from 'react';

interface Props {
  good: boolean;
  text: string;
}
function GreenRedPill(props: Props): JSX.Element {
  const customTheme = useTheme();
  const { good, text } = props;
  const color = good
    ? customTheme.palette.success.main
    : customTheme.palette.error.light;
  return <Chip label={text} sx={{ height: 25, backgroundColor: color }} />;
}

export default GreenRedPill;
