/* eslint-disable @typescript-eslint/no-unused-vars */
import { User, UserSummary } from '../../structures/userInterfaces';
import dayjs from 'dayjs';
import { UserSeverity, UserStatus } from '../../structures/enums';
import {
  Organization,
  OrganizationDetailsFormInputI
} from '../../structures/organizationInterfaces';
import { OrgStatus, OrgType } from '../../structures/organizationEnums';

export type PartialUser = Partial<User>;

async function create(
  org: OrganizationDetailsFormInputI
): Promise<OrganizationDetailsFormInputI> {
  console.log('Organization created', org);
  return org;
}

// org.groups.map((group) => group.name),
//
// async function readAllSummaries(): Promise<OrgSummary[]> {
//   const orgsSummary = orgs.map((org) => {
//     const orgSummary: OrgSummary = {
//       id: org.id as unknown as string,
//       status: org.details.status,
//       name: org.details.name,
//       type: org.details.type,
//       address: org.details.address,
//       groups: org.groups.map((group) => group.name),
//       pLName: org.details.primaryLiaison.name,
//       pLPhone: org.details.primaryLiaison.phone as unknown as string,
//       pLEmail: org.details.primaryLiaison.email,
//       created: org.details.created,
//       updated: org.details.updated
//     };
//     return orgSummary;
//   });
//   return orgsSummary;
// }

async function read(id: number): Promise<Organization | undefined> {
  return {} as Organization; // figure out correct typings
}

interface Obj {
  [key: string]: User;
}

// function groupById(array: User[]): Obj {
//   return array.reduce((obj: Obj, value) => {
//     obj[value.orgId] = value;
//     return obj;
//   }, {});
// }

// async function update(
//   id: number,
//   update: PartialUser
// ): Promise<User | undefined> {
//   const orgIndexToUpdate = orgs.findIndex(
//     (org) => (org.orgId as unknown) === id
//   );
//   if (orgIndexToUpdate >= 0) {
//     const tempUsers = [...orgs];
//     tempUsers[orgIndexToUpdate] = { ...orgs[orgIndexToUpdate], ...update };
//     orgs = [...tempUsers];
//   }
//   return await Promise.resolve(orgs[orgIndexToUpdate]);
// }

async function deleteOrg(id: string): Promise<Organization[]> {
  return [] as Organization[]; // figure out correct typings
}

async function reset(): Promise<void> {
  return;
}

export { create, read, deleteOrg, reset };
