/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import * as React from 'react';
import TableFilters from './TableFilterDrawer';
import { GridColDef } from '@mui/x-data-grid';
import { FilterCriteria, FilterPushData } from '../../../structures/interfaces';
import _ from "lodash"

interface Props {
    tableActions: JSX.Element;
    generateEntityFilterForm: Function | undefined;
    appliedFilters: FilterCriteria;
    clearFilters: Function;
    searchValue: string;
    setSearchValue: (value: string) => void;
}

function EntityTableControls(props: Props): JSX.Element {
    const {
        tableActions,
        appliedFilters,
        generateEntityFilterForm,
        clearFilters,
        setSearchValue,
        searchValue
    } = props;
    const [filterDrawerOpen, setFilterDrawerOpen] = React.useState(false);
    // local search value so input value is not debounced
    const [localSearchValue, _setLocalSearchValue] =
        React.useState(searchValue);
    const updateSearchValue = _.debounce((value) => setSearchValue(value), 200);
    function setLocalSearchValue(value: string) {
        _setLocalSearchValue(value);
        updateSearchValue(value);
    }

    function renderClearFiltersButton(): JSX.Element | null {
        return Object.keys(appliedFilters).length > 0 ? (
            <Button
                variant="outlined"
                startIcon={<FilterAltOffIcon />}
                onClick={() => clearFilters()}
            >
                Clear {Object.keys(appliedFilters).length}
            </Button>
        ) : null;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 50px 1fr',
                alignItems: 'stretch',
                marginBottom: 2,
                width: '100%'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {tableActions}
            </Box>
            <Box></Box>
            <Stack
                direction={'row'}
                spacing={2}
                justifyContent="flex-end"
                alignItems={'center'}
            >
                {renderClearFiltersButton()}
                <Button
                    variant="contained"
                    startIcon={<FilterListIcon />}
                    size="medium"
                    onClick={() => setFilterDrawerOpen(true)}
                >
                    Filter
                </Button>
                {generateEntityFilterForm?.(filterDrawerOpen, () =>
                    setFilterDrawerOpen(false)
                )}
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: 300, minWidith: 100 }}
                    value={localSearchValue}
                    onChange={(e) => setLocalSearchValue(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
        </Box>
    );
}

export default EntityTableControls;
