/* eslint-disable @typescript-eslint/ban-types */
import { Box } from '@mui/system';
import * as React from 'react';
import { GroupApi } from '../../../../api/GroupApi';
import { CacheContextI } from '../../../../structures/interfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import OrganizationGroupsFilterMenu from './OrganizationGroupsFilterMenu';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { FilterOperator } from '../../../../structures/enums';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

interface Props {
    orgId: string;
}

export const OrganizationsCacheContext =
    React.createContext<CacheContextI | null>(null);

function OrganizationGroupsViewer(props: Props): JSX.Element {
    const { orgId } = props;
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.idCellRenderer,
            sortable: false
        },
        {
            field: 'name',
            headerName: 'NAME',
            width: 250,
            headerClassName: 'table-column-title'
        },
        {
            field: 'category',
            headerName: 'CATEGORY',
            width: 150,
            headerClassName: 'table-column-title'
        },
        {
            field: 'liaisonName',
            headerName: 'LIAISON NAME',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'liaisonTitle',
            headerName: 'LIAISON TITLE',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'liaisonEmail',
            headerName: 'LIAISON EMAIL',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'liaisonPhone',
            headerName: 'LIAISON PHONE',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        }
    ];

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen: boolean,
        handleClose: Function,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <OrganizationGroupsFilterMenu
                        filters={filterCriteria}
                        handleClose={handleClose}
                        setFilters={setFilterCriteria}
                    />
                }
            />
        );
    };

    return (
        <Box sx={{ height: '60%' }}>
            <EntityTable
                tableKey={['organizations', orgId, 'groups']}
                initialState={{
                    permaFilters: {
                        org_id: {
                            operator: FilterOperator.EQUALS,
                            arguments: [orgId as unknown as string]
                        }
                    }
                }}
                generateColumns={generateColumns}
                api={GroupApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                generateEntityFilterForm={generateFilterForm}
                generateTableActions={() => <></>}
                apiRef={apiRef}
                selectionMode="none"
            />
        </Box>
    );
}

export default OrganizationGroupsViewer;
