import { UserSeverity, SeverityOptions, UserStatus } from '../structures/enums';
import {
    SubUser,
    SubUserBulkResponse,
    SubUserResponse,
    UserSummary,
    UserSummaryResponse
} from '../structures/userInterfaces';

const nullOrElse = <T, R>(
    value: T | null,
    fn: (arg0: T) => R
): R | null => (value === null ? null : fn(value));

export const mapUserSummaryResponseToFrontEnd = (
    users: UserSummaryResponse[]
): UserSummary[] => {

    return users.map((user: UserSummaryResponse) => {
        return {
            id: user.subuser_id,
            last: {
                severity: nullOrElse(user.last_severity, (s) => SeverityOptions[s]),
                reviewed: nullOrElse(user.last_reviewed, Boolean),
                status: user.last_status,
                flagged: nullOrElse(user.last_flagged, Boolean)
            },
            groups: user.user_groups,
            name: user.name,
            organizationNames: user.organizationNames,
            active: Boolean(user.active),
            flagged: Boolean(user.flagged),
            reviewed: Boolean(user.all_records_reviewed),
            email: user.email,
            phone: user.phone
        };
    });
};

export const mapSubUserResponseToSubUser = (users: SubUserResponse[]) => {
    return users.map((user: SubUserResponse) => {
        return {
            id: user.subuser_id,
            name: user.name,
            email: user.email,
            phone: user.phone
        };
    });
};

export const mapSubUserBulkResponseToSubUsers = (
    users: SubUserBulkResponse
): SubUser[] => {
    const subUsers = Object.values(users).map((user) => {
        return {
            id: user.subuser_id,
            name: user.name,
            email: user.email,
            phone: user.phone
        };
    });
    return subUsers;
};

export const mapToUserSeverity = (option: unknown) => {
    switch (option) {
        case 0:
            return UserSeverity.LOW;
        case 1:
            return UserSeverity.NORMAL;
        case 2:
            return UserSeverity.MEDIUM;
        case 3:
            return UserSeverity.CRITICAL;
        default:
            return UserSeverity.UNKNOWN;
    }
};

export const mapToActiveStatus = (option: unknown) => {
    switch (option) {
        case 0:
            return UserStatus.INACTIVE;
        case 1:
            return UserStatus.ACTIVE;
        default:
            return '';
    }
};

export const mapUserSeverityToNumber = (severity: UserSeverity) => {
    switch (severity) {
        case UserSeverity.NORMAL:
            return 0;
        case UserSeverity.LOW:
            return 1;
        case UserSeverity.MEDIUM:
            return 2;
        case UserSeverity.CRITICAL:
            return 3;
        default:
            return 4;
    }
};

export const mapToYesNo = (option: unknown) => {
    switch (option) {
        case 0:
            return 'No';
        case 1:
            return 'Yes';
        default:
            return '';
    }
};
