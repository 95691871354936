import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfileApi from '../../../api/ProfileApi';
import useAsyncRequest from '../../../helpers/hooks/useAsyncRequest';
import { redirectToLogin } from '../../../helpers/redirectToLogin';
import { UserSessionData } from '../../../structures/userSession';
import { UserSessionContext } from './UserSessionContext';

const UserSessionProvider: React.FC<React.PropsWithChildren<object>> = ({
  children
}) => {
  const [userSessionData, setUserSessionData] =
    useState<UserSessionData | null>(null);

  const { execute, loading, error } = useAsyncRequest(
    ProfileApi.singleton.fetchUserSessionData,
    ProfileApi.singleton
  );

  const logout = async () => {
    try {
      await ProfileApi.singleton.logoutUser();
    } catch (err) {
      console.log('error logging out', err);
    } finally {
      setUserSessionData(null);
      redirectToLogin();
    }
  };

  const refresh = async () => {
    try {
      const sessionDataResult = await execute();

      if (sessionDataResult.error !== null || sessionDataResult.data === null) {
        throw sessionDataResult.error;
      }
      setUserSessionData(sessionDataResult.data.resp_variables);
    } catch (e) {
      console.log('could not fetch user session data', e);
      logout();
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  if (userSessionData !== null) {
    return (
      <UserSessionContext.Provider value={{ userSessionData, logout, refresh }}>
        {children}
      </UserSessionContext.Provider>
    );
  } else if (loading) {
    return <div></div>;
  } else if (error !== null) {
    if (process.env.NODE_ENV === 'development') {
      console.log('error', error);
      return <Box>Fake login page for local</Box>;
    }
    return <></>;
  } else {
    return <div></div>;
  }
};

export default UserSessionProvider;
