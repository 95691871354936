/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosResponse } from 'axios';
import { CustomAxiosRequestConfig } from '../mocks/customAxiosRequestConfig';
import customAxios from '../mocks/customAxios';
import { worker } from '../mocks/browser';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import psyUrl from '../helpers/psy-url';
import { RequestResponse } from 'src/structures/responses';
import {
    QueryCache,
    QueryClient,
    queryOptions,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions
} from '@tanstack/react-query';
import React from 'react';

dayjs.extend(utc);

export interface SendRequestOptions {
    mockRequest?: boolean;
}

export function formatDateForBackend(date: string) {
    const zulu = dayjs.utc(date).format('YYYY-MM-DD HH:mm:ss');
    return zulu;
}

customAxios.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    if (config.bypassMsw) {
        worker.stop();
    } else {
        worker.start();
    }
    return config;
});

const API_PROCESS = 'seq-cms';
const REQUEST_LIB = 'portal';
const MOCK_API = '/mock';
const BASE_API = `${psyUrl()}/psy-api`;

export async function sendRequest<
    ResponseData = any,
    Response extends RequestResponse<ResponseData> = RequestResponse<ResponseData>
>(
    variables: Record<string, unknown>,
    apiProcess: string = API_PROCESS,
    requestLib: string = REQUEST_LIB,
    options: SendRequestOptions = { mockRequest: false }
) {
    // type Response=RequestResponse<ResponseData>
    const formData = new FormData();

    variables.process = apiProcess;

    formData.append('variables', JSON.stringify(variables));
    formData.append('request_library', requestLib);

    // send possible testing call
    let response;
    //in production mode, send request to real endput unless mockRequest is true
    if (process.env.NODE_ENV === 'production') {
        response = await axios.post<Response>(
            options.mockRequest ? MOCK_API : BASE_API,
            formData
        );
    } else {
        // in development mode, if requestLib is account, send to mock api
        if (requestLib === 'account') {
            response = await axios.post<Response>(MOCK_API, formData);
        } else {
            // in development mode, if requestLib is not account, send to real endpoint with is_testing set to the api key
            formData.append(
                'is_testing',
                process.env.API_KEY ?? 'NO API KEY FOUND'
            );
            response = await axios.post<Response>(
                options.mockRequest ? MOCK_API : BASE_API,
                formData
            );
        }
    }
    const data = response.data;
    // TODO: LOGIN CHECK

    if (response.status !== 200) {
        throw new Error('Server error, not 200 response', {
            cause: variables.action
        });
    }

    if (data.status !== 'success') {
        throw new Error(
            `Error: ${data.status}: ${data.message ?? 'no message provided'}`,
            { cause: variables.action }
        );
    }
    return response!;
}

export default class SeqApi {
    isTesting(): boolean {
        return process.env.NODE_ENV !== 'production';
    }

    async sendRequest(
        variables: Record<string, unknown>,
        apiProcess: string = API_PROCESS,
        requestLib: string = REQUEST_LIB,
        options: SendRequestOptions = { mockRequest: false }
    ): Promise<AxiosResponse<FormData>> {
        return sendRequest(
            variables,
            apiProcess,
            requestLib,
            options
        ) as unknown as Promise<AxiosResponse<FormData>>;
    }
}

export type QueryOpts<
    Params,
    ResponseData,
    QueryData,
    Variables extends Record<string, unknown> = Record<string, unknown>
> = {
    variables: (p: Params) => Variables;
    transform: (d: ResponseData, r: RequestResponse<ResponseData>) => QueryData;
    queryKey?: (p: Params) => unknown[];
    processName?: string;
    requestLib?: string;
    mock?: boolean;
    postUpdate?: (d: NonNullable<QueryData>, key: unknown[], cache: QueryClient) => void;
};

// secondary function to allow for type inference of Params and QueryData (the result)
// https://stackoverflow.com/a/60378737
export const query =
    <ResponseData>() =>
    <Params, QueryData>({
        variables,
        processName,
        transform,
        requestLib,
        mock,
        queryKey: queryKeyFn,
        postUpdate,
    }: QueryOpts<Params, ResponseData, QueryData>) => {
        type AdditionalQueryOpts = {
            enabled?: boolean;
        };
        const mockRequest =
            mock !== undefined ? { mockRequest: mock } : undefined;

        const getOpts = (params: Params, opts?: AdditionalQueryOpts) => {
            const vars = variables(params);
            const queryKey = queryKeyFn ? queryKeyFn(params) : [vars];
            const _opts = queryOptions({
                queryKey,
                async queryFn() {
                    const r = await sendRequest<ResponseData>(
                        vars,
                        processName,
                        requestLib,
                        mockRequest
                    )
                    return transform(r.data.resp_variables, r.data)
                },
                ...opts
            });
            return _opts;
        };

        return {
            useQuery(p: Params, opts?: AdditionalQueryOpts) {
                const res = useQuery(getOpts(p, opts))
                usePostUpdate(postUpdate, res.data, queryKeyFn ? queryKeyFn(p) : [variables(p)])
                return res
            },
            // TODO: useSuspenseQuery
            fetchQuery() {
                const queryClient = useQueryClient();
                return async (p: Params) => {
                    // FIXME: why cast required?
                    const res = await queryClient.fetchQuery(getOpts(p)) as QueryData;
                    if (postUpdate != null && res != null) {
                        postUpdate(res, queryKeyFn ? queryKeyFn(p) : [variables(p)], queryClient)
                    }
                    return res
                }
            }
        };
    };

function usePostUpdate<QueryData, Params>(
    postUpdate: ((d: NonNullable<QueryData>, key: unknown[], cache: QueryClient) => void) | undefined,
    data: QueryData | undefined,
    key: unknown[],
) {
    const queryClient = useQueryClient();
    const changed = useChanged(data)
    if (postUpdate != null && changed && data != null) {
        postUpdate(data, key, queryClient)
    }
}

function useChanged<TDeps>(deps: TDeps) {
    const depsRef = React.useRef(deps);
    const changed = Object.is(depsRef.current, deps);
    if (changed) {
        depsRef.current = deps;
    }
    return changed;

}

export type MutationOpts<
    Params,
    ResponseData,
    QueryData,
    Variables extends Record<string, unknown> = Record<string, unknown>
> = {
    variables: (p: Params) => Variables;
    transform: (d: ResponseData, r: RequestResponse<ResponseData>) => QueryData;
    processName?: string;
    requestLib?: string;
    mock?: boolean;
};

// secondary function to allow for type inference of Params and QueryData (the result)
// https://stackoverflow.com/a/60378737
export const mutation =
    <ResponseData>() =>
    <Params, QueryData>({
        variables,
        processName,
        transform,
        requestLib,
        mock
    }: MutationOpts<Params, ResponseData, QueryData>) => {
        type AdditionalMutationOpts = undefined;
        const mockRequest =
            mock !== undefined ? { mockRequest: mock } : undefined;

        const getOpts = (client: QueryClient, opts?: AdditionalMutationOpts) => {
            const _opts: UseMutationOptions<QueryData, Error, Params> = {
                async mutationFn(params: Params) {
                    const vars = variables(params);
                    return await sendRequest<ResponseData>(
                        vars,
                        processName,
                        requestLib,
                        mockRequest
                    ).then((r) => transform(r.data.resp_variables, r.data));
                },
                onSuccess() {
                    // PERF: allow point invalidations
                    client.invalidateQueries();
                },
                ...(opts ?? {})
            };
            return _opts;
        };
        return {
            useMutation(opts?: AdditionalMutationOpts) {
                const client = useQueryClient();
                const mut = useMutation(getOpts(client, opts))
                return mut
            }
        };
    };
