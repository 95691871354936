import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useSnackbar } from 'notistack';
import { AssessmentRecordApi } from '../../../../api/AssessmentRecordApi';
import dayjs from 'dayjs';

interface Props {
    apiRef: React.MutableRefObject<GridApiPro>;
    params: GridRenderCellParams;
}
export const AssessmentReviewedCell = (props: Props): JSX.Element => {
    const { apiRef, params } = props;
    const { enqueueSnackbar } = useSnackbar();

    const setReviewed = AssessmentRecordApi.setReviewed.useMutation();

    const isReviewed = setReviewed.data?.reviewed ?? params.value.value !== null;

    const toggleReviewedStatus = async (id: string) => {
        try {
            const newReviewedStatus = !isReviewed;
            const result = await setReviewed.mutateAsync({id, reviewed: newReviewedStatus});
            if (setReviewed.isError) {
                enqueueSnackbar(`Failed to change status`, {
                    variant: 'error'
                });
                throw setReviewed.error.message;
            }
            enqueueSnackbar(
                `Changed reviewed status to: ${result.reviewed}`,
                {
                    variant: 'success'
                }
            );

            apiRef.current.updateRows([
                {
                    id: id,
                    last_reviewed: {
                        value: result.reviewed
                            ? dayjs().toISOString()
                            : null,
                        authorId: 'place holder'
                    }
                }
            ]);
        } catch (err) {
            console.log("couldn't toggle assessment review status");
        }
    };

    return (
        <>
            {setReviewed.isError && <div>error</div>}
            {setReviewed.isPending && <div></div>}
            {!setReviewed.isPending && !setReviewed.isError && (
                <IconButton onClick={() => toggleReviewedStatus(params.row.id)}>
                    {isReviewed ? (
                        <CheckCircleOutlineOutlinedIcon color="success" />
                    ) : (
                        <CircleOutlinedIcon />
                    )}
                </IconButton>
            )}
        </>
    );
};
