/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useContext, useRef } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import * as yup from 'yup';
import PermissionApi from '../../../api/PermissionApi';
import createDefaultObject from '../../../helpers/createDefaultObject';
import {
  Entity,
  UpdatePermissionRequest
} from '../../../structures/interfaces';
import { PermissionCacheContext } from './PermissionTable';
import PermissionInputs from './PermissionInputs';

interface Props {
  handleClose: Function;
  permissionsToEdit?: GridSelectionModel;
}

export interface PermissionsFormInputI {
  permissions: UpdatePermissionRequest[];
}

const permissionSchema: yup.SchemaOf<UpdatePermissionRequest> = yup
  .object()
  .shape({
    id: yup.string().optional(),
    role: yup.string().required(),
    memberEmail: yup.string().email().required(),
    effectiveDate: yup.string().required()
  });

const permissionsFormSchema: yup.SchemaOf<PermissionsFormInputI> = yup
  .object()
  .shape({
    permissions: yup.array().of(permissionSchema).required()
  });

export default function PermissionsForm(props: Props): JSX.Element {
  const { handleClose, permissionsToEdit } = props;
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const permissionCacheContext = useContext(PermissionCacheContext);
  const methods = useForm<PermissionsFormInputI>({
    resolver: yupResolver(permissionsFormSchema),
    defaultValues: async (): Promise<PermissionsFormInputI> => {
      if (permissionsToEdit != null) {
        try {
          const permissionPromises = permissionsToEdit.map(
            async (permissionId) =>
              await PermissionApi.singleton.getEntity({
                id: permissionId as number
              })
          );
          const resolvedPermissions = await Promise.all(permissionPromises);
          return {
            permissions: resolvedPermissions.map(
              mapPermissionToPermissionFormData
            )
          };
        } catch (err) {
          console.log('could not permissions edit form');
          throw err;
        }
      } else {
        return {
          permissions: [createDefaultObject<UpdatePermissionRequest>()]
        };
      }
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray<
    PermissionsFormInputI,
    'permissions',
    'permissionsId'
  >({
    control: methods.control,
    name: 'permissions',
    keyName: 'permissionsId'
  });

  function mapPermissionToPermissionFormData(
    permission: Entity
  ): UpdatePermissionRequest {
    return {
      id: permission.id as unknown as string,
      role: permission.role != null ? permission.role : '',
      memberEmail: permission.memberEmail != null ? permission.memberEmail : '',
      effectiveDate:
        permission.effectiveDate != null ? permission.effectiveDate : ''
    };
  }

  const formSubmitHandler: SubmitHandler<PermissionsFormInputI> = (
    data: PermissionsFormInputI
  ) => {
    // console.log('submitting', data);
    PermissionApi.singleton
      .updatePermissions(fields)
      .catch((err: any) => console.log(err));
    permissionCacheContext?.setOutOfDate(true);
    handleClose();
  };

  const onInvalid = (errors: any): void =>
    console.error('houston we got em', errors);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(formSubmitHandler, onInvalid)}
        style={{ height: '100%' }}
      >
        <Box
          className="no-scroll-bar"
          sx={{ overflowY: 'scroll', height: '90%', marginTop: 3 }}
        >
          <Stack spacing={5}>
            {fields.map((permission, i) => {
              return (
                <Box
                  key={i}
                  sx={{ minHeight: 50, display: 'flex', alignItems: 'center' }}
                >
                  <PermissionInputs
                    fields={fields}
                    field={permission}
                    key={i}
                    index={i}
                    remove={remove}
                  />
                </Box>
              );
            })}
            {fields?.[0]?.id == null && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                sx={{ width: 250 }}
                disabled={fields?.[0]?.id != null}
                onClick={() => {
                  append(createDefaultObject<UpdatePermissionRequest>());
                }}
              >
                Add Permission
              </Button>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10%'
          }}
        >
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              size="medium"
              sx={{ height: 45 }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={Object.keys(methods.formState.errors).length !== 0}
              size="medium"
              sx={{ height: 45, fontColor: 'white' }}
              color="secondary"
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
}
