import FlagIcon from '@mui/icons-material/Flag';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { QuestionAnswer } from '../../../../structures/assessmentRecordInterfaces';
import { AnswerChoice } from './AnswerChoice';
import { b64DecodeUnicode } from '../../../../api/ProfileApi';
import { QuestionTypes } from 'src/structures/assessmentEnums';

interface Props {
    answerRecord: QuestionAnswer;
    questionNumber: number;
}
export const AnswerRecordDisplay = ({
    answerRecord,
    questionNumber
}: Props): JSX.Element => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '3px solid',
                borderColor: grey[500],
                borderRadius: 2,
                padding: 3,
                backgroundColor: grey[200]
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid',
                    borderColor: grey[500]
                }}
            >
                <Typography variant="h6">Question {questionNumber}</Typography>
                {answerRecord.flagged && <FlagIcon color="secondary" />}
            </Box>
            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
                <Typography variant="body1">
                    {b64DecodeUnicode(answerRecord.questionSnapshot)}
                </Typography>
            </Box>
            <Box
                sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}
            >
                <AnswerDisplay question={answerRecord} />
            </Box>
        </Box>
    );
};

function AnswerDisplay(props: { question: QuestionAnswer }) {
    const shortAnswer = <ShortResponseAnswerDisplay question={props.question} />;
    const other = <OptionAnswerDisplay question={props.question} />;
    if (props.question.type === QuestionTypes.SHORT_RESPONSE) {
        return shortAnswer;
    }
    return other;
}

function ShortResponseAnswerDisplay(props: { question: QuestionAnswer }) {
    const answer = props.question.answer[0];

    return <div>
        <textarea readOnly>
            {answer}
        </textarea>
    </div>
}

function OptionAnswerDisplay(props: { question: QuestionAnswer }) {
    return (
        <>
            {props.question.optionsSnapshot.map((option, index) => (
                <AnswerChoice
                    key={index}
                    questionOption={option}
                    chosen={props.question.answer
                        .map((answer_id) => answer_id.toLowerCase())
                        .includes((option.id ?? '').toLowerCase())}
                />
            ))}
        </>
    );
}
