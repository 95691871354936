/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  IconButton,
  IconButtonProps,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import * as React from 'react';

interface Props {
  filterComponent: JSX.Element;
  generateFilterChip: Function;
  filterTitle: string;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} sx={{ padding: 1 }} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

function EntityFilter(props: Props): JSX.Element {
  const { filterComponent, generateFilterChip, filterTitle } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  return (
    <Box
      sx={{
        border: 3,
        borderColor: 'primary.main',
        borderRadius: 2,
        boxSizing: 'border-box',
        padding: 3
      }}
    >
      {/* <CardHeader title="Date Created" subheader="Between date and date" /> */}
      <Box
        id="filterHeader"
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '150px 1fr 50px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{filterTitle}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {generateFilterChip()}
        </Box>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ paddingTop: 2 }}>{filterComponent}</Box>
      </Collapse>
    </Box>
  );
}

export default EntityFilter;
