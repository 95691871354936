import Dialog from '@mui/material/Dialog/Dialog';
import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import PreviewIcon from '@mui/icons-material/Preview';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import CheckBox from '@mui/material/Checkbox/Checkbox';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { Control, Controller, useForm } from 'react-hook-form';
import { AssessmentRecordApi } from '../../../../api/AssessmentRecordApi';
import { FilterOperator } from '../../../../structures/enums';
import { EntityTableRequest } from '../../../../structures/interfaces';
import { useCallback, useMemo, useState } from 'react';
import { UserApi } from '../../../../api/UserApi';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Alert from '@mui/material/Alert/Alert';
import Button from '@mui/material/Button/Button';

type ReportFormState = {
    includeHistory: boolean;
    includeRecords: string[];
};

export function ReportDialog({
    userId,
    open,
    handleClose
}: {
    userId: string;
    open: boolean;
    handleClose: () => void;
}) {
    const [url, setUrl] = useState<string | null>(null);

    const {
        handleSubmit,
        control,
        setError,
        reset,
        formState: { isSubmitting, isSubmitSuccessful, errors }
    } = useForm<ReportFormState>({
        defaultValues: {
            includeHistory: true,
            includeRecords: []
        }
    });

    const getReport = UserApi.getReport.fetchQuery();

    async function onSubmit(data: ReportFormState) {
        console.log(data);
        try {
            const url = await getReport({
                ...data,
                userId
            });
            console.log({ url });
            setUrl(url);
        } catch (e) {
            console.error(e);
            const err = e as Error;
            setError('root.url', { message: err.message, type: err.name });
        }
    }

    const onClose = useCallback(() => {
        reset();
        handleClose();
    }, [handleClose, reset]);

    return (
        <Dialog open={open} onClose={onClose}>
            <Box sx={{ width: '400px', padding: '30px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="column" spacing={4}>
                        <Controller
                            control={control}
                            name="includeHistory"
                            render={({ field }) => (
                                <FormControl>
                                    <FormControlLabel
                                        control={<Switch {...field} />}
                                        label="Assessment History"
                                    />
                                    <FormHelperText>
                                        Include a page in the report detailing
                                        their assessment history
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />
                        <AssessmentRecordsSelect
                            userId={userId}
                            control={control}
                        />
                        <ActionButtons
                            isSubmitting={isSubmitting}
                            isSubmitSuccessful={isSubmitSuccessful}
                            url={url}
                        />
                        {errors.root &&
                            Object.keys(errors.root).map((e) => (
                                <Alert severity="error" key={e}>
                                    {errors.root![e].message}
                                </Alert>
                            ))}
                    </Stack>
                </form>
            </Box>
        </Dialog>
    );
}

function ActionButtons(props: {
    isSubmitting: boolean;
    isSubmitSuccessful: boolean;
    url: string | null;
}) {
    const { isSubmitting, isSubmitSuccessful, url } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly'
            }}
        >
            <Button type="submit" variant="outlined" color="error">
                Generate
            </Button>
            <ButtonGroup
                disabled={(!isSubmitSuccessful && !isSubmitting) || !url}
                sx={{
                    width: '100%',
                    justifyContent: 'flex-end'
                }}
            >
                <LoadingButton
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    component="a"
                    href={url!}
                    download="report.pdf"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={<PreviewIcon />}
                    component="a"
                    href={url!}
                    target="_blank"
                >
                    Preview
                </LoadingButton>
            </ButtonGroup>
        </div>
    );
}

type Record = NonNullable<
    ReturnType<typeof AssessmentRecordApi.sortedSummaries.useQuery>['data']
>['rows'][number];

function compareRecords(a: Record, b: Record) {
    const byName = a.friendly_name.localeCompare(b.friendly_name);
    if (byName !== 0) return byName;
    const byDate = b.completion_date.localeCompare(a.completion_date);
    return byDate;
}

function recordParams(userId: string) {
    return {
        filters: {
            subuser_id: {
                operator: FilterOperator.EQUALS,
                arguments: [userId]
            }
        },
        firstRow: 0,
        // TODO: fetch more if totalRows > 100
        lastRow: 100,
        sortModel: [
            {
                field: 'completion_date',
                sort: 'desc' as const
            }
        ],
        searchQuery: ''
    } satisfies EntityTableRequest;
}

function AssessmentRecordsSelect({
    userId,
    control
}: {
    userId: string;
    control: Control<ReportFormState>;
}) {
    const assessmentRecordParams = useMemo(
        () => recordParams(userId),
        [userId]
    );

    const records = AssessmentRecordApi.sortedSummaries.useQuery(
        assessmentRecordParams
    );
    const sortedRecords = useMemo(() => {
        if (!records.data) return;
        const sorted = [...records.data.rows];
        sorted.sort(compareRecords);
        return sorted;
    }, [records.data]);

    const renderValue = useCallback(
        (selectedIds: string[]) => {
            const selected = selectedIds
                .map((id) => sortedRecords?.find((a) => a.id === id))
                .map((a) => a?.friendly_name);
            return <div>{selected.join(', ')}</div>;
        },
        [sortedRecords]
    );

    return (
        <Controller
            control={control}
            name="includeRecords"
            render={({ field }) => {
                field.disabled =
                    records.isLoading || sortedRecords?.length === 0;
                return (
                    <FormControl>
                        <InputLabel id="select-assessments">
                            {sortedRecords?.length === 0
                                ? 'This user has no completed assessments'
                                : 'Include Assessments'}
                        </InputLabel>
                        <Select
                            labelId="select-assessments"
                            label="Include Assessments"
                            renderValue={renderValue}
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 300 } }
                            }}
                            IconComponent={() => (
                                <IconComponent isLoading={records.isLoading} />
                            )}
                            multiple
                            {...field}
                        >
                            {sortedRecords?.map((record) => (
                                <MenuItem key={record.id} value={record.id}>
                                    <CheckBox
                                        checked={field.value.includes(
                                            record.id
                                        )}
                                    />
                                    <RecordItem record={record} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            Include detailed summaries of specific assessments
                        </FormHelperText>
                    </FormControl>
                );
            }}
        />
    );
}

function RecordItem({ record }: { record: Record }) {
    const completionDate = record.completion_date?.split(' ').at(0) ?? '{unknown}'
    const secondary = `Date: ${completionDate} Score: ${record.score} Severity: ${record.severity}`
    return (
        <ListItemText
            primary={record.friendly_name}
            secondary={secondary}
        />
    );
}

function IconComponent(props: { isLoading: boolean }) {
    return props.isLoading ? (
        <CircularProgress
            sx={{
                translate: -20
            }}
            size={20}
        />
    ) : null;
}
