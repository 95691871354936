/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Stack } from '@mui/system';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  useTheme
} from '@mui/material';
import createDefaultObject from '../../../../helpers/createDefaultObject';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './PhoneNumberInput.css';

import { ClassNames } from '@emotion/react';
import { NewGroupForm } from './NewGroupForm';
import {
  GroupFormInput,
  OrganizationDetailsFormInputI
} from '../../../../structures/organizationInterfaces';

const defaultGroup: GroupFormInput = {
  groupId: 'new',
  category: null,
  groupName: '',
  liaisons: []
};

const groups = ['group1, group2, group3'];

function GroupLiaisonsForm(): JSX.Element {
  const theme = useTheme();
  const {
    control,
    register,
    watch,
    formState: { errors }
  } = useFormContext<OrganizationDetailsFormInputI>();

  const arrayMethods = useFieldArray<
    OrganizationDetailsFormInputI,
    'groups',
    'groupsId'
  >({
    control,
    name: 'groups',
    keyName: 'groupsId'
  });

  return (
    <Stack spacing={5}>
      {arrayMethods.fields.map((field, index) => {
        return (
          <Box
            key={field.groupsId}
            sx={{
              borderStyle: 'solid',
              borderRadius: 5,
              borderColor: 'primary.main',
              borderWidth: 2,
              padding: 2
            }}
          >
            <NewGroupForm
              groupArrayMethods={arrayMethods}
              fieldIndex={index}
              formErrors={errors}
            />
          </Box>
        );
      })}
      <Button
        type="button"
        variant="contained"
        color="primary"
        sx={{ width: 250, backgroundColor: 'primary.light' }}
        onClick={() => {
          arrayMethods.append({
            ...defaultGroup
          });
        }}
      >
        Add Group
      </Button>
    </Stack>
  );
}

export default GroupLiaisonsForm;
