import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from '@hello-pangea/dnd';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
    Box,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    Button
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo } from 'react';
import * as ReactDOM from 'react-dom';
import {
    Controller,
    FieldArrayWithId,
    useFormContext,
    useFieldArray,
} from 'react-hook-form';
import { QuestionTypes } from '../../../../structures/assessmentEnums';
import FlagIcon from '@mui/icons-material/Flag';
import {
    Assessment,
    ChoiceOption
} from '../../../../structures/assessmentInterfaces';

export default function Question({
    index,
    question
}: {
    index: number;
    question: FieldArrayWithId<Assessment, 'questions', 'questionsId'>;
}) {
    return (
        <Draggable draggableId={question.questionsId} index={index}>
            {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
            ) => (
                <PortalAwareQuestion
                    provided={provided}
                    snapshot={snapshot}
                    index={index}
                />
            )}
        </Draggable>
    );
}

const portal: HTMLElement = document.createElement('div');
portal.classList.add('my-super-cool-portal');

if (!document.body) {
    throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

const PortalAwareQuestion = (props: {
    index: number;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}) => {
    const provided: DraggableProvided = props.provided;
    const snapshot: DraggableStateSnapshot = props.snapshot;
    const usePortal: boolean = snapshot.isDragging;

    const child: ReactNode = (
        <Box
            {...provided.draggableProps}
            ref={provided.innerRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '3px solid #e0e0e0',
                borderRadius: 2,
                padding: 3,
                backgroundColor: grey[200]
            }}
        >
            <Box sx={{ display: 'grid', gridTemplateColumns: '50px 1fr' }}>
                <Box
                    {...provided.dragHandleProps}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <DragIndicatorIcon sx={{ color: grey[500] }} />
                </Box>
                <QuestionForm index={props.index} />
            </Box>
        </Box>
    );
    if (!usePortal) {
        return child;
    }
    return ReactDOM.createPortal(child, portal);
};

function QuestionForm(props: { index: number }) {
    const index = props.index;
    const {
        formState: { errors },
        control,
        getValues,
        setValue,
        watch
    } = useFormContext<Assessment>();

    const questionType = watch(`questions.${index}.type`)

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Typography variant="h6">Question {index + 1}</Typography>
                {index === 0 ? null : (
                    <IconButton
                        onClick={() => {
                            const newQuestions = Array.from(
                                getValues('questions')
                            );
                            newQuestions.splice(index, 1);
                            setValue('questions', newQuestions);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '60% 1fr',
                    gap: 3,
                    marginTop: 3
                }}
            >
                <Controller
                    render={({ field }) => (
                        <TextField
                            label="Prompt"
                            size="small"
                            {...field}
                            error={errors.questions?.[index]?.prompt != null}
                        />
                    )}
                    name={`questions.${index}.prompt`}
                    control={control}
                />
                <QuestionTypeField index={index} />
            </Box>
            <QuestionInput index={index} type={questionType!} />
        </Box>
    );
}

function QuestionTypeField({ index }: { index: number }) {
    const control = useFormControl();
    return (
        <Controller
            render={({ field }) => (
                <TextField label="Question Type" size="small" {...field} select>
                    <MenuItem value={QuestionTypes.MULTIPLE_CHOICE}>
                        Multiple Choice
                    </MenuItem>
                    <MenuItem value={QuestionTypes.MULTIPLE_MULTIPLE}>
                        Multiple Multiple
                    </MenuItem>
                    <MenuItem value={QuestionTypes.TRUE_FALSE}>
                        True/False
                    </MenuItem>
                    <MenuItem value={QuestionTypes.CHECKBOX}>
                        Check Box
                    </MenuItem>
                    <MenuItem value={QuestionTypes.SHORT_RESPONSE}>
                        Short Response
                    </MenuItem>
                </TextField>
            )}
            name={`questions.${index}.type`}
            control={control}
        />
    );
}

function QuestionInput(props: { index: number, type: QuestionTypes }) {
    // FIXME: handle type change and convert question options to new type
    // (i.e. remove extranious options when going from mc => tf)
    return <Switch value={props.type}>
        <Case value={QuestionTypes.MULTIPLE_CHOICE}>
            <MultipleChoiceQuestion questionIndex={props.index} />
        </Case>
        <Case value={QuestionTypes.TRUE_FALSE}>
            <TFQuestion questionIndex={props.index} />
        </Case>
        <Case value={QuestionTypes.MULTIPLE_MULTIPLE}>
            <MultipleMultipleQuestion questionIndex={props.index} />
        </Case>
        <Case value={QuestionTypes.SHORT_RESPONSE}>
            <ShortResponseQuestion questionIndex={props.index} />
        </Case>
    </Switch>
}

function Switch<T>(props: {value: T, children: JSX.Element[]}) {
    return props.children.find(c => {
        console.log(c.props)
        return c.props["value"] === props.value
    })?.props.children ?? null;
}

type MatchProps<T> = PropsWithChildren<{value: T }>

function Case<T>(props: MatchProps<T>) {
    return <div data-case={props.value}>{props.children}</div>
}


const letterMapping: Record<number, string> = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'I',
    9: 'J',
    10: 'K',
    11: 'L',
    12: 'M',
    13: 'N',
    14: 'O',
    15: 'P',
    16: 'Q',
    17: 'R',
    18: 'S',
    19: 'T',
    20: 'U',
    21: 'V',
    22: 'W',
    23: 'X',
    24: 'Y',
    25: 'Z'
};

function useForm() {
    return useFormContext<Assessment>();
}

function useFormControl() {
    const form = useForm();
    return form.control;
}

function useFormErrors() {
    const form = useForm();
    return form.formState.errors;
}

type FormControl = ReturnType<typeof useFormControl>;

function useQuestionOptions(control: FormControl, questionIndex: number) {
    const {
        fields: options,
        remove,
        append
    } = useFieldArray({
        control: control,
        name: `questions.${questionIndex}.options`,
        keyName: 'questionOptionId'
    });
    return {
        options,
        remove,
        append
    };
}

interface MCQuestionProps {
    questionIndex: number;
}

function MultipleChoiceQuestion({
    questionIndex,
}: MCQuestionProps) {
    const control = useFormControl();
    const {
        options,
        remove: removeOption,
        append: appendOption
    } = useQuestionOptions(control, questionIndex);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    marginTop: 3
                }}
            >
                {options.map((questionOption, index) => (
                    <QuestionOption
                        key={index + questionOption.questionOptionId}
                        index={index}
                        questionIndex={questionIndex}
                        label={letterMapping[index]}
                        remove={index !== 0 ? removeOption : undefined}
                    />
                ))}
            </Box>
            <AddOptionButton
                disabled={options.length >= 10}
                append={appendOption}
            />
        </Box>
    );
}


function MultipleMultipleQuestion({
    questionIndex,
}: MCQuestionProps) {
    const control = useFormControl();
    const {
        options,
        remove: removeOption,
        append: appendOption
    } = useQuestionOptions(control, questionIndex);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    marginTop: 3
                }}
            >
                {options.map((questionOption, index) => (
                    <QuestionOption
                        key={index + questionOption.questionOptionId}
                        index={index}
                        questionIndex={questionIndex}
                        label={letterMapping[index]}
                        remove={index !== 0 ? removeOption : undefined}
                    />
                ))}
            </Box>
            <AddOptionButton
                disabled={options.length >= 10}
                append={appendOption}
            />
        </Box>
    );
}

function TFQuestion({ questionIndex }: { questionIndex: number }) {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    marginTop: 3
                }}
            >
                <QuestionOption
                    index={0}
                    questionIndex={questionIndex}
                    label={'True'}
                />
                <QuestionOption
                    index={1}
                    questionIndex={questionIndex}
                    label={'False'}
                />
            </Box>
        </Box>
    );
}

function ShortResponseQuestion({ questionIndex }: { questionIndex: number }) {
    const {setValue} = useForm();
    useEffect(() => {
        setValue(`questions.${questionIndex}.options`, [], {
            shouldTouch: false,
            shouldValidate: false,
            shouldDirty: false,
        })
    }, [setValue])
    return <></>
}

function QuestionOption(props: {
    remove?: (i: number) => void;
    questionIndex: number;
    index: number;
    label: string;
}) {
    const control = useFormControl();

    let labelWidth = 10
    switch (true) {
        case props.label.length >= 4:
            labelWidth = 30
            break
        case props.label.length > 1:
            labelWidth = 20
            break
    }
    const label = useMemo(() => {
        return (
            <Box alignItems="center" display={'flex'}>
                <Typography variant="body1">{props.label}</Typography>
            </Box>
        );
    }, [props.label]);

    const errors = useFormErrors();

    const isError = useCallback(
        (fieldName: keyof ChoiceOption) => {
            const questionErrors = errors.questions?.[props.questionIndex];
            const optionErrors = questionErrors?.options?.[props.index];
            const fieldError = optionErrors?.[fieldName];
            return fieldError != null;
        },
        [errors, props.questionIndex, props.index]
    );

    const fieldName = useCallback(
        (fieldName: keyof ChoiceOption) => {
            return `questions.${props.questionIndex}.options.${props.index}.${fieldName}` as const;
        },
        [props.questionIndex, props.index]
    );

    return (
        <Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `${labelWidth}px 60% 1fr 50px 50px `,
                    gap: 3
                }}
            >
                {label}
                <Controller
                    render={({ field }) => (
                        <TextField
                            label="Text"
                            size="small"
                            {...field}
                            error={isError('text')}
                        />
                    )}
                    name={fieldName('text')}
                    control={control}
                />
                <Controller
                    render={({ field }) => (
                        <TextField
                            label="Points"
                            size="small"
                            type="number"
                            {...field}
                            error={isError('points')}
                        />
                    )}
                    name={fieldName('points')}
                    control={control}
                />
                <Controller
                    render={({ field }) => (
                        <FlagButton
                            value={!!field.value}
                            set={field.onChange}
                        />
                    )}
                    name={fieldName('flag')}
                    control={control}
                />
                {props.remove && (
                    <RemoveOptionButton
                        index={props.index}
                        remove={props.remove}
                    />
                )}
            </Box>
        </Box>
    );
}

function FlagButton(props: { value: boolean; set: (v: boolean) => void }) {
    const onClick = useCallback(() => {
        props.set(!props.value);
    }, [props.set, props.value]);

    const color = props.value ? 'red' : 'primary.main';

    return (
        <IconButton onClick={onClick} sx={{ color }}>
            <FlagIcon />
        </IconButton>
    );
}

const defaultOption: ChoiceOption = {
    points: 0,
    flag: false,
    text: ''
};

function AddOptionButton(props: {
    append: (o: ChoiceOption) => void;
    disabled: boolean;
}) {
    const onClick = useCallback(() => {
        props.append(defaultOption);
    }, [props.append]);

    return (
        <Box>
            <Button onClick={onClick} disabled={props.disabled}>
                <Typography>Add Option</Typography>
            </Button>
        </Box>
    );
}

function RemoveOptionButton(props: {
    index: number;
    remove: (i: number) => void;
}) {
    const onClick = useCallback(() => {
        props.remove(props.index);
    }, [props.remove, props.index]);

    return (
        <IconButton onClick={onClick}>
            <CloseIcon />
        </IconButton>
    );
}
