import React from 'react';
import Section from '../../Templates/Section/Section';
import '../Pages.css';
import { Box } from '@mui/material';
import { AssessmentTable } from './AssessmentTable/AssessmentTable';

export function AssessmentsPage(): JSX.Element {
  return (
    <Box sx={{ width: '100%' }}>
      <Section title="Assessments">{<AssessmentTable />}</Section>
    </Box>
  );
}
