import { faker } from '@faker-js/faker';
import { AssessmentSummaryResponse } from '../../structures/assessmentInterfaces';
import { AssessmentRecordSummaryResponse } from '../../structures/assessmentRecordInterfaces';
import { OrgStatus } from '../../structures/organizationEnums';

export const generateAssessmentRows = (number: number) => {
  const recentMicroAssessments = [];

  for (let i = 0; i < number; i++) {
    const recentMicroAssessment: AssessmentSummaryResponse = {
      assessment_id: faker.string.uuid(),
      description: faker.lorem.sentence(),
      scoringThresholds: [10, 23, 36, 63, 100],
      scoreRange: [{ value: 0 }, { value: 100 }],
      category: [faker.lorem.word(), faker.lorem.word(), faker.lorem.word()],
      track: faker.lorem.word(),
      status: faker.helpers.arrayElement(Object.values(OrgStatus)),
      recurrence: faker.number.int({ min: 0, max: 100 }),
      cadence: faker.helpers.arrayElement(['weekly', 'monthly', 'yearly']),
      royalty: 'no',
      friendlyName: faker.lorem.word(),
      codeName: faker.lorem.word(),
      assessmentUmbrella: faker.lorem.word(),
      created_by: faker.string.uuid(),
      updated_by: faker.string.uuid(),
      delete_status: 0,
      createdAt: faker.date.past().toISOString(),
      updatedAt: faker.date.past().toISOString()
    };

    recentMicroAssessments.push(recentMicroAssessment);
  }

  return recentMicroAssessments;
};

export const generateAssessmentRecordRows = (number: number) => {
  const assessmentRecordRows = [];
  for (let i = 0; i < number; i++) {
    const assessmentRecordRow: AssessmentRecordSummaryResponse = {
      assessment_record_id: faker.string.uuid(),
      user_assessment_id: faker.string.uuid(),
      severity: faker.number.int({ min: 0, max: 3 }),
      score: faker.number.int({ min: 0, max: 100 }),
      subuser_id: faker.string.uuid(),
      completion_date: faker.date.past().toISOString(),
      flagged: faker.number.int({ min: 0, max: 1 }),
      last_reviewed: faker.date.past().toISOString(),
      start_date: faker.date.past().toISOString(),
      original_assessment_id: faker.string.uuid(),
      has_notes: faker.number.int({ min: 0, max: 1 }),
      code_name: faker.lorem.word(),
      friendly_name: faker.lorem.word(),
      name: faker.lorem.word(),
      email: faker.internet.email(),
      scoringThresholdAtStart: [10, 23, 36, 63, 100],
      reviewed_by: faker.string.uuid(),
      description: faker.lorem.sentence()
    };
    assessmentRecordRows.push(assessmentRecordRow);
  }
  return assessmentRecordRows;
};
