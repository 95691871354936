import { Edit } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
interface Props {
  entity: string;
  selectionModel: GridSelectionModel;
  editable: boolean;
}
function AddEditEntityTableButtons(props: Props): JSX.Element {
  const { entity, selectionModel, editable } = props;
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
    >
      {editable ? (
        <Button
          variant="contained"
          startIcon={<Edit />}
          disabled={selectionModel.length === 0}
          onClick={() => navigate('edit/')}
        >
          Edit {entity}
        </Button>
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={() => navigate(`add/`)}
      >
        Add {entity}
      </Button>
    </Stack>
  );
}

export default AddEditEntityTableButtons;
