import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import { Box, Typography, useTheme } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { OptionSnapshot } from '../../../../structures/assessmentRecordInterfaces';

interface Props {
  questionOption: OptionSnapshot;
  chosen: boolean;
}

export const AnswerChoice = ({
  questionOption,
  chosen
}: Props): JSX.Element => {
  const theme = useTheme();
  //eventually want to display different selection color depending on the choices relative point value
  //make the flag greyed out if the choice wasn't selected
  //add flag to the question if a flag is present on the selected answer choice

  const renderFlag = () => {
    if (questionOption.flag && chosen) {
      return <FlagIcon sx={{ color: theme.palette.secondary.main }} />;
    } else if (questionOption.flag && !chosen) {
      return <OutlinedFlagRoundedIcon sx={{ color: grey[500] }} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '50px 1fr',
          border: '2px solid',
          borderColor: chosen ? theme.palette.secondary.main : grey[500],
          borderRadius: 2,
          height: 40,
          marginLeft: 5,
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 3 }}>
          {/* <Typography variant="body1">{questionOption.}</Typography> */}
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 100px 100px' }}>
          <Typography variant="body1">{questionOption.text}</Typography>
          <Box>{renderFlag()}</Box>
          <Typography variant="body1">
            Points: {questionOption.points}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
