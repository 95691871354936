/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { allCountries } from 'country-region-data';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AddPrimaryLiasonToOrganization } from './AddPrimaryLiasonToOrganization';
import { getValue } from '@testing-library/user-event/dist/utils';
import { Liaison } from '../../../../structures/userInterfaces';
import { OrganizationDetailsFormInputI } from '../../../../structures/organizationInterfaces';

const getRegions = (country: string): string[] => {
  if (country == null) {
    return [];
  }
  return (
    allCountries
      .find((c) => {
        return c[0] === country;
      })?.[2]
      ?.map((reg) => {
        return reg[0];
      }) ?? []
  );
};
// interface Props {
//   handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   previewImage: string | ArrayBuffer | null;
// }
function OrganizationDetailsForm(): JSX.Element {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useFormContext<OrganizationDetailsFormInputI>();

  // const country = watch('country');
  // const [regions, setRegions] = React.useState<JSX.Element[]>([]);
  const regions = getRegions('United States').map(
    // TODO: update this to be global down the line
    (option: any, index) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    }
  );

  // function regenerateRegions(): JSX.Element[] {
  //   const tempRegions = getRegions('United States').map(
  //     // TODO: update this to be global down the line
  //     (option: any, index) => {
  //       return (
  //         <MenuItem key={option} value={option}>
  //           {option}
  //         </MenuItem>
  //       );
  //     }
  //   );
  //   // setRegions(tempRegions);

  //   return regions;
  // }

  // React.useEffect(() => {
  //   setValue('state', '');
  //   regenerateRegions();
  // }, [country]);
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={20}>
        <Grid xs={4} item>
          <Stack spacing={2}>
            <Typography variant="body1">Organization</Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Name"
                  {...field}
                  error={errors.name != null}
                  placeholder="University of Minnesota"
                />
              )}
              name="name"
              defaultValue={''}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Status"
                  select
                  {...field}
                  error={errors.status != null}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </TextField>
              )}
              name="status"
              defaultValue={'Active'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Organization Type"
                  select
                  {...field}
                  error={errors.type != null}
                >
                  <MenuItem value="University">University</MenuItem>
                  <MenuItem value="Company">Company</MenuItem>
                </TextField>
              )}
              name="type"
              defaultValue={''}
              control={control}
            />
            {/* NOT IN MVP */}
            {/* <TextField
              id="photo-uploader"
              type="file"
              size="small"
              inputProps={{
                accept: 'image/*'
              }}
              onChange={(event) => {
                handleImageChange(event as React.ChangeEvent<HTMLInputElement>);
              }}
            />
            {previewImage && (
              <Box>
                <Typography variant="subtitle1">Preview:</Typography>
                <img
                  src={previewImage as string}
                  alt="preview"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
              </Box>
            )} */}
          </Stack>
        </Grid>
        <Grid xs={3} item>
          <Stack spacing={2}>
            <Typography variant="body1">Address</Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Address Line 1"
                  {...field}
                  error={errors.address1 != null}
                />
              )}
              name="address1"
              defaultValue={''}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Address Line 2"
                  {...field}
                  error={errors.address2 != null}
                />
              )}
              name="address2"
              defaultValue={''}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="City"
                  {...field}
                  error={errors.city != null}
                />
              )}
              name="city"
              defaultValue={''}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Country"
                  select
                  {...field}
                  error={errors.country != null}
                >
                  {allCountries.map((country, index) => (
                    <MenuItem key={country[0]} value={country[0]}>
                      {country[0]}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="country"
              defaultValue={'United States'}
              control={control}
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    size="small"
                    sx={{ width: '100%' }}
                    label="State"
                    {...field}
                    select
                    error={errors.state != null}
                  >
                    {regions}
                  </TextField>
                )}
                name="state"
                defaultValue={''}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Zip code"
                    sx={{ width: '100%' }}
                    {...field}
                    error={errors.zip != null}
                  />
                )}
                name="zip"
                defaultValue={''}
                control={control}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid xs={4} item>
          <Stack spacing={2}>
            <Typography variant="body1">Primary Liaison</Typography>
            <AddPrimaryLiasonToOrganization
              currentPrimaryLiaison={watch('primaryLiaison')}
              setLiaison={(liaison: Liaison): void =>
                setValue('primaryLiaison', liaison)
              }
              formErrors={errors}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default OrganizationDetailsForm;
