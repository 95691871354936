import { Chip, useTheme } from '@mui/material';
import { Status } from '../../../../structures/enums';
interface Props {
  status: Status;
}

export function ScheduleStatusChip({ status }: Props): JSX.Element {
  let backgroundColor: string;
  const theme = useTheme();

  switch (status) {
    case "IN_PROGRESS":
      backgroundColor = theme.palette.secondary.light;
      break;
    case "NOT_STARTED":
      backgroundColor = theme.palette.grey[100];
      break;
    case "EXPIRED":
      backgroundColor = theme.palette.warning.main;
      break;
    case "COMPLETE":
      backgroundColor = theme.palette.success.main;
      break;
    default:
      backgroundColor = theme.palette.grey[400];
      status = status satisfies never;
  }
  const style = {
    background: backgroundColor,
    height: 25
  };
  return <Chip label={status.replace('_', ' ')} sx={style} />;
}
