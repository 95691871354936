import { createContext } from 'react';
import { UserSessionData } from '../../../structures/userSession';

interface UserSessionContextType {
  userSessionData: UserSessionData | null;
  logout: () => void;
  refresh: () => Promise<unknown>;
}

export const UserSessionContext = createContext<UserSessionContextType>({
  userSessionData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => null,
  refresh: () => Promise.resolve()
});
