import { ThemeProvider, createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import '../../hstyles/App.css';
import Dashboard from '../Dashboard/Dashboard';
import MainHeader from '../MainHeader/MainHeader';
import OrganizationsPage from '../Pages/OrganizationsPage/OrganizationsPage';
import UsersPage from '../Pages/UsersPage/UsersPage';

import { LicenseInfo } from '@mui/x-license-pro';
import { AssessmentsPage } from '../Pages/AssessmentsPage/AssessmentsPage';
import { SchedulesPage } from '../Pages/SchedulesPage/SchedulesPage';
import { ProfileModalProvider } from './ProfileModalContext/ProfileModalContext';
import UserSessionProvider from './UserSessionContext/UserSessionProvider';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_KEY as string);
// termporary for testing:
localStorage.setItem('USER_TYPE', process.env.REACT_TEST_USER_TYPE as string);

declare module '@mui/material/styles' {
    interface TypographyVariants {
        myVariant: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        myVariant?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        myVariant: true;
    }
}

const testTypography = false;

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#3F547F',
            light: '#3E76CB',
            contrastText: 'rgba(255, 255, 255, 0.85)'
        },
        secondary: {
            main: '#FF6900',
            light: '#fc9a53',
            contrastText: 'rgba(0, 0, 0, 0.87)'
        },
        background: {
            default: '#F8F8FE',
            paper: '#ffffff'
        },
        success: {
            main: '#92CD2880'
        },
        error: {
            main: '#ff0000',
            light: '#FF000066'
        },
        warning: {
            main: '#FF690080',
            light: '#FFC30580'
        }
    },
    typography: {
        fontFamily: testTypography
            ? ['Shadows Into Light', 'cursive'].join(',')
            : ['Open Sans', 'sans-serif'].join(','),
        myVariant: {
            fontSize: '2rem'
        }
    },
    spacing: 4
});

function App(): JSX.Element {
    console.log('app renderd');
    const queryClient = new QueryClient();

    return (
        <SnackbarProvider maxSnack={3}>
            <UserSessionProvider>
                <ThemeProvider theme={customTheme}>
                    <QueryClientProvider client={queryClient}>
                        <ProfileModalProvider>
                            <MainHeader />
                            <div className="page-holder">
                                <Routes>
                                    <Route
                                        path="*"
                                        element={<Navigate to="/dashboard/" />}
                                    />
                                    <Route
                                        path="/"
                                        element={<Navigate to="/dashboard/" />}
                                    />
                                    <Route
                                        path="/dashboard/"
                                        element={<Dashboard />}
                                    />
                                    <Route
                                        path="/users/*"
                                        element={<UsersPage />}
                                    />
                                    <Route
                                        path="/organizations/*"
                                        element={<OrganizationsPage />}
                                    />
                                    <Route
                                        path="/schedules/*"
                                        element={<SchedulesPage />}
                                    />
                                    <Route
                                        path="/assessments/*"
                                        element={<AssessmentsPage />}
                                    />
                                </Routes>
                            </div>
                        </ProfileModalProvider>
                    </QueryClientProvider>
                </ThemeProvider>
            </UserSessionProvider>
        </SnackbarProvider>
    );
}

export default App;
