export enum TaskStatus {
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum NotificationStatusCode {
  GOOD,
  LOW,
  MED,
  CRITICAL
}

export enum MetricUnits {
  PERCENT = '%',
  DOLLARS = '$'
}

export enum DataPeriod {
  PREVIOUS_WEEK,
  PREVIOUS_MONTH,
  REFERENCE
}

export enum DashboardMetrics {
  NEW_OrganizationS,
  PERCENT_ASSESSMENTS_COMPLETED,
  OrganizationS_RECEIVABLE,
  OrganizationS_PAYABLE
}

export enum UserSeverity {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  MEDIUM = 'MEDIUM',
  CRITICAL = 'CRITICAL',
  UNKNOWN = 'UNKNOWN'
}
export const SeverityOptions = ["LOW", "NORMAL", "MEDIUM", "CRITICAL", "UNKNOWN"] as const
export type Severity = typeof SeverityOptions[number]

export enum UserFlagged {
  FLAGGED = 'flagged',
  NOT_FLAGGED = 'not_flagged'
}

export enum UserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum ActivationStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum Groups {
  BASKETBALL = 'Basketball',
  FOOTBALL = 'Football'
}

export enum FilterOperator {
  BETWEEN = 'Between',
  ON = 'On',
  AFTER = 'After',
  BEFORE = 'Before',
  ANY_OF = 'Any Of',
  EQUALS = 'Equals',
  GREATER_THAN = 'Greater Than',
  LESS_THAN = 'Less Than',
  IS_NULL = 'No data',
  IS_SPECIFICALLY = 'Is Specifically' // unique operator, if filtering by a user in Specifically org_id = 'test', it will only return groups associated to the 'test' organization
}

export enum DateFilterModifiers {
  BETWEEN = 'Between',
  ON = 'On',
  AFTER = 'After',
  BEFORE = 'Before',
  IS_NULL = 'No data'
}

export enum ScalarFilterOperators {
  EQUALS = 'Equals',
  GREATER_THAN = 'Greater Than',
  LESS_THAN = 'Less Than',
  BETWEEN = 'Between'
}

export enum StatusType {
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  COMPLETE = 'COMPLETE',
  EXPIRED = 'EXPIRED'
}
export const StatusOptions = ["IN_PROGRESS", "NOT_STARTED", "COMPLETE", "EXPIRED"] as const
export type Status = typeof StatusOptions[number]

export enum EntityTypes {
  ASSESSMENT_RECORD_NOTES = 'assessment_record_notes',
  ASSESSMENT_RECORDS = 'assessment_records',
  ASSESSMENTS = 'assessments',
  ORG_GROUPS = 'org_groups',
  ORG_LIAISONS = 'org_liaisons',
  ORGANIZATIONS = 'organizations',
  SCHEDULES = 'schedules',
  SUBUSERS = 'subusers',
  USER_ASSESSMENTS = 'user_assessments',
  USERS = 'users'
}
