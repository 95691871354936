import { useTableSelection } from 'src/components/Templates/EntityTable/EntityTable';
import Dialog from '@mui/material/Dialog/Dialog';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';

import { getSubuserSummaryKey, UserApi } from 'src/api/UserApi';
import { useMemo, useState } from 'react';
import { AssessmentApi } from 'src/api/AssessmentApi';
import { useDebouncedETRSearch } from 'src/helpers/debounced-etr-search';
import TextField from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Typography from '@mui/material/Typography/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import { AssessmentStatus } from 'src/structures/assessmentEnums';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export default function AssignAssessmentsModal({
    open,
    handleClose
}: Props): JSX.Element {
    const onSubmit = () => handleClose();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { overflowX: 'hidden' } }}
        >
            <Box sx={{ borderRadius: 10 }}>
                <AssignAssessments onSubmit={onSubmit} />
            </Box>
        </Dialog>
    );
}

const tableKey = ['users'];

type UserToAssign = {
    id: string,
    name: string
}

type AssignInput = {
    id: string;
    expiresAt: string;
};

function AssignAssessments(props: { onSubmit: () => void }) {
    const selection = useTableSelection(tableKey);
    const userIds = (selection ?? []) as string[];
    // FIXME: return if no users selected
    const users = useCachedUserNames(userIds)
    const [assessmentId, setAssessmentId] = useState<string | null>(null);
    const [expireDate, setExpireDate] = useState<dayjs.Dayjs | null>(null);

    const input = useMemo<AssignInput | null>(() => {
        if (!assessmentId || !expireDate) return null;
        return {
            id: assessmentId,
            expiresAt: expireDate.format('YYYY-MM-DD HH:mm:ss')
        };
    }, [assessmentId, expireDate]);

    const theme = useTheme();
    return (
        <>
            <div
                style={{
                    backgroundColor: theme.palette.primary.light,
                    width: '100%',
                    padding: 10
                }}
            >
                <Typography variant="h6" color={'white'}>
                    Assign Assessment
                </Typography>
            </div>
            <div
                style={{
                    padding: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 10
                }}
            >
                <SelectAssessment value={assessmentId} set={setAssessmentId} />

                <SelectExpireDate value={expireDate} set={setExpireDate} />

                <AssignButton
                    users={users}
                    assessment={input}
                    onSubmit={props.onSubmit}
                />
            </div>
        </>
    );
}

function useCachedUserNames(userIds: string[]) {
    const queryClient = useQueryClient();
    const users = useMemo(() => {
        const users = new Array<UserToAssign>(userIds.length)

        for (let i = 0; i < userIds.length; i++) {
            const id = userIds[i]
            const user = queryClient.getQueryData(getSubuserSummaryKey(id)) as any | undefined
            console.log('user', user)
            const name = user?.name
            users[i] = {id, name}
        }
        return users
    }, [queryClient, userIds])

    return users
}

function SelectAssessment(props: {
    set: (a: string | null) => void;
    value: string | null;
}) {
    const [assessmentSearch, setAssessmentSearch] = useDebouncedETRSearch(50);
    const query = AssessmentApi.summaries.useQuery(assessmentSearch);
    const assessments = useMemo(() => {
        if (!query.data) return null;
        return query.data.rows.filter(
            (a) => a.status === AssessmentStatus.ACTIVE
        );
    }, [query.data]);

    const assessmentNames = useMemo(() => {
        return assessments?.map((a) => a.friendlyName) ?? [];
    }, [assessments]);

    function onChange<E>(_event: E, val: string | null) {
        if (!val) return;
        const selectedAssessment = assessments?.find(
            (a) => a.friendlyName === val
        );
        if (!selectedAssessment) return;
        props.set(selectedAssessment.id ?? null);
    }

    const value =
        assessments?.find((a) => a.id === props.value)?.friendlyName ?? null;

    return (
        <Autocomplete
            options={assessmentNames}
            value={value}
            limitTags={1}
            size={'small'}
            sx={{ width: 300 }}
            onInputChange={(_event, newValue) => {
                setAssessmentSearch(newValue);
            }}
            onChange={onChange}
            loading={query.isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Assessment"
                    size="small"
                    placeholder="Choose Assessment"
                />
            )}
        />
    );
}

function SelectExpireDate(props: {
    value: dayjs.Dayjs | null;
    set: (v: dayjs.Dayjs | null) => void;
}) {
    function onChange(val: dayjs.Dayjs | null) {
        if (!val) {
            props.set(null);
            return;
        }
        props.set(val.endOf('day'));
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={props.value}
                disablePast
                orientation="landscape"
                openTo="day"
                label="Expire Date"
                onChange={onChange}
                renderInput={(params) => <TextField size="small" {...params} />}
            />
        </LocalizationProvider>
    );
}

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

function AssignButton(props: {
    users: UserToAssign[];
    assessment: AssignInput | null;
    onSubmit: () => void;
}) {
    // TODO: assign assessment to multiple users at once endpoint
    const assignAssessment = UserApi.assignAssessment.useMutation();
    const disabled = props.users.length === 0 || !props.assessment;

    const { enqueueSnackbar } = useSnackbar();

    const onClick = async () => {
        if (!props.assessment) return;
        const assessment = {
            assessment_id: props.assessment.id,
            expireDate: props.assessment.expiresAt
        };
        console.log(assessment);

        for (let i = 0; i < props.users.length; i++) {
            const user = props.users[i];
            const subuser_id = user.id;
            const name = user.name ?? `user ${subuser_id}`;
            try {
                const res = await assignAssessment.mutateAsync(
                    Object.assign({ subuser_id }, assessment)
                );
                console.log('assigned', assessment, 'to', user, res);
                enqueueSnackbar(`Assigned assessment to ${name}`, {
                    variant: 'success'
                });
            } catch (e) {
                console.error(e);
                let errorMessage = `${e}`;
                // HACK: remove three 'error: ' prefixes from the error message
                errorMessage = errorMessage.replace(/error: /gi, '');
                enqueueSnackbar(
                    `Failed to assign assessment to ${name} - ${errorMessage}`,
                    { variant: 'error' }
                );
            }
        }
        props.onSubmit();
    };

    return (
        <Button variant="outlined" disabled={disabled} onClick={onClick}>
            Assign
        </Button>
    );
}
