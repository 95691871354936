import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import ModalHeader from './ModalHeader/ModalHeader';

const modalContentStyle = {
  width: '100% - 4',
  height: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 2,
  padding: 4
};

interface Props {
  open: boolean;
  handleClose: () => void;
  headerText: string;
  children: JSX.Element;
  width?: string;
  height?: string;
  zIndex?: number;
}

export default function StandardModal({
  open,
  handleClose,
  headerText,
  children,
  width,
  height
}: Props): JSX.Element {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: width || '90%',
            height: height || '75%',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: 2
          }}
        >
          <ModalHeader title={headerText} />
          <Box sx={modalContentStyle}>{children}</Box>
        </Box>
      </Modal>
    </div>
  );
}
