import { Chip, useTheme } from '@mui/material';
import { UserSeverity } from '../../../structures/enums';
interface Props {
  severity: UserSeverity;
}

export function SeverityChipPlain({ severity }: Props): JSX.Element {
  let backgroundColor: string;
  const theme = useTheme();

  switch (severity) {
    case UserSeverity.LOW:
      backgroundColor = theme.palette.warning.light;
      break;
    case UserSeverity.NORMAL:
      backgroundColor = theme.palette.success.main;
      break;
    case UserSeverity.MEDIUM:
      backgroundColor = theme.palette.warning.main;
      break;
    case UserSeverity.CRITICAL:
      backgroundColor = theme.palette.error.light;
      break;
    default:
      backgroundColor = theme.palette.error.light;
  }
  const style = {
    background: backgroundColor,
    height: 25
  };
  return <Chip label={severity} sx={style} />;
}
