/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';
import {
  Entity,
  EntityTableRequest,
  PartiallyOptional
} from '../structures/interfaces';
import { User, UserSummary } from '../structures/userInterfaces';
import EntityApi, { EntityRequest } from './EntityApi';
import SeqApi, { SendRequestOptions } from './SeqApi';
import { ProfileData } from '../structures/profile';
import { RequestResponse } from '../structures/responses';
import { UserSessionData } from '../structures/userSession';

interface Week {
  day: Dayjs;
}
interface DataOverTimeFromJson {
  reference: number;
  previousWeekDelta: number;
  previousMonthDelta: number;
}

interface GetUserRequest {
  userId: number | string;
}

export type UpdateUserRequest = Partial<User>;
export function b64EncodeUnicode(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}

// Decoding base64 ⇢ UTF8
//refactor atob to use Buffer.from

export function b64DecodeUnicode(str: string): string {
  try {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  } catch {
    return str;
  }
}

export default class ProfileApi extends SeqApi {
  static singleton = new ProfileApi();
  PROCESS_NAME = 'profile';
  //depricated
  getProfileInfo = async (): Promise<ProfileData> => {
    const variables = {
      action: 'get_profile_info'
    };
    try {
      const response = await this.sendRequest(variables, this.PROCESS_NAME);

      const data = response.data as unknown as RequestResponse<ProfileData>;

      if (data.status !== 'success') {
        throw Error(' not get profile info with message: ' + data.message);
      }
      return data.resp_variables;
    } catch (err) {
      console.log('error out in profile api', err);
      throw err;
    }
  };
  updateProfileInfo = async (profileData: ProfileData): Promise<void> => {
    const variables = {
      action: 'update_profile_info',
      profile_data: profileData
    };
    try {
      const response = await this.sendRequest(
        variables,
        this.PROCESS_NAME,
        undefined,
        {
          mockRequest: false
        } as SendRequestOptions
      );
      const data = response.data as unknown as RequestResponse<unknown>;

      if (data.status !== 'success') {
        throw Error(
          'could not update profile info with message: ' + data.message
        );
      }
    } catch (err) {
      console.log('error out in profile api', err);
      throw err;
    }
  };

  //update profile info should be one big call
  updateName = async (
    firstName: string,
    lastName: string
  ): Promise<ProfileData> => {
    const variables = {
      action: 'update_name',
      first_name: firstName,
      last_name: lastName
    };
    try {
      const response = await this.sendRequest(
        variables,
        this.PROCESS_NAME,
        undefined,
        {
          mockRequest: false
        } as SendRequestOptions
      );

      const data = response.data as unknown as ProfileData;

      // if (response.status !== 'success') {
      //   throw Error('could not get profile info');
      // }
      return data;
    } catch (err) {
      console.log('error out in profile api', err);
      throw err;
    }
  };
  sendValidatePhoneText = async (phoneNumber: number): Promise<void> => {
    const variables = {
      action: 'send_validate_phone_text',
      phone: phoneNumber
    };
    try {
      const response = await this.sendRequest(
        variables,
        this.PROCESS_NAME,
        undefined,
        {
          mockRequest: true
        } as SendRequestOptions
      );

      // if (response.status !== 'success') {
      //   throw Error('could not send text');
      // }
    } catch (err) {
      console.log('error out in profile api', err);
      throw err;
    }
  };
  validatePhoneChange = async (code: string): Promise<void> => {
    const variables = {
      action: 'validate_phone_change',
      code
    };
    try {
      const response = await this.sendRequest(
        variables,
        this.PROCESS_NAME,
        undefined,
        {
          mockRequest: false
        } as SendRequestOptions
      );

      // if (response.status !== 'success') {
      //   throw Error('could not validate phone change token');
      // }
    } catch (err) {
      console.log('error out in profile api', err);
      throw err;
    }
  };
  editPassword = async (
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ): Promise<void> => {
    const variables = {
      action: 'reset_password',
      password_0: b64EncodeUnicode(oldPassword), //old pass
      password_1: b64EncodeUnicode(newPassword), // new pass
      password_2: b64EncodeUnicode(confirmNewPassword) //new confirmed pass
    };
    try {
      const response = await this.sendRequest(variables, 'password', 'user', {
        mockRequest: false
      } as SendRequestOptions);

      // if (response.status !== 'success') {
      //   throw Error('could not edit password');
      // }
    } catch (err) {
      console.log('error out in profile api', err);
      throw err;
    }
  };

  fetchUserSessionData = async (): Promise<
    RequestResponse<UserSessionData>
  > => {
    try {
      const mockThis = process.env.NODE_ENV === 'development';
      const response = await this.sendRequest(
        { action: 'SESS' },
        undefined,
        'session_data',
        { mockRequest: mockThis } as SendRequestOptions
      );
      const data = response.data as unknown as RequestResponse<UserSessionData>;

      const session_data = data.resp_variables;

      if (data.status !== 'success') {
        throw new Error("status was not 'success'");
      }

      if (session_data && session_data.user_id === '') {
        // if no user id, they have to login
        throw new Error('user_id was empty, user needs to login');
      }
      return data;
    } catch (err) {
      console.log('error fetching user session data', err);
      throw err;
    }
  };

  async logoutUser(): Promise<void> {
    try {
      const response = await this.sendRequest(
        { action: 'logout' },
        'logout',
        'user'
      );
    } catch (err) {
      console.log('error logging out user', err);
      throw err;
    } finally {
      document.cookie = 'rm=; Max-Age=-99999999;';
      document.cookie = 'PHPSESSID=; Max-Age=-99999999;';

      // if no user id, they have to login
    }
  }
}
