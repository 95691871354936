import {
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useCallback,
    useMemo,
    useState
} from 'react';
import './DashBoard.css';
import { ReactComponent as OrganizationIcon } from './assets/icons/account_balance.svg';
import { ReactComponent as AssessmentsIcon } from './assets/icons/Clipboard.svg';
import WeekPicker from './WeekPicker/WeekPicker';
import dayjs, { Dayjs } from 'dayjs';
import { MetricApi } from '../../api/MetricApi';
import { UserAssessmentApi } from '../../api/UserAssessmentApi';
import { SysLogApi, SysLogEntry } from '../../api/SysLogApi';
import { EntityTableRequest } from '../../structures/interfaces';
import { FilterOperator } from '../../structures/enums';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { ReactComponent as IncreaseIcon } from './assets/icons/IncreaseIcon.svg';
import { ReactComponent as DecreaseIcon } from './assets/icons/DecreaseIcon.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useQueries } from '@tanstack/react-query';
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import styled from '@emotion/styled';

function useChanges(
    startDate: Dayjs,
    frames: number,
    period: 'week' | 'month'
) {
    const offset = startDate.subtract(frames, period);
    const start_date = offset.startOf(period).format('YYYY-MM-DD');
    const end_date = offset.endOf(period).format('YYYY-MM-DD');
    const changes = MetricApi.changes.useQuery({ start_date, end_date });
    return changes;
}

const Dashboard = (): JSX.Element => {
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('week'));

    const current = useChanges(startDate, 0, 'week');

    const previousWeek = useChanges(startDate, 1, 'week');

    const previousMonth = useChanges(startDate, 1, 'month');

    const twoPreviousWeek = useChanges(startDate, 2, 'week');

    const twoPreviousMonth = useChanges(startDate, 2, 'month');

    return (
        <>
            <div
                style={{
                    display: 'block',
                    width: '100vw',
                    padding: '0 0 10px 60px'
                }}
            >
                <WeekPicker value={startDate} setValue={setStartDate} />
            </div>
            <Grid container spacing={20}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TimeInfoCard
                            title="Assessments Completed"
                            icon={<AssessmentsIcon />}
                            thisWeek={current.data?.completed_assessments}
                            prevWeek={previousWeek.data?.completed_assessments}
                            thisMonth={
                                previousMonth.data?.completed_assessments
                            }
                            prevMonth={
                                twoPreviousMonth.data?.completed_assessments
                            }
                        />
                        <TimeInfoCard
                            title="Organizations Created"
                            icon={<OrganizationIcon />}
                            thisWeek={previousWeek.data?.organizations_created}
                            prevWeek={
                                twoPreviousWeek.data?.organizations_created
                            }
                            thisMonth={
                                previousMonth.data?.organizations_created
                            }
                            prevMonth={
                                twoPreviousMonth.data?.organizations_created
                            }
                        />
                    </div>
                    <AssessmentsCompletedChart />
                </div>
                <ActivityLog />
            </Grid>
        </>
    );
};

export default Dashboard;

interface TimeInfoProps {
    icon: JSX.Element;
    title: string;
    thisWeek?: number;
    prevWeek?: number;
    thisMonth?: number;
    prevMonth?: number;
}

function getDiff(prev?: number, curr?: number) {
    if (prev === undefined || curr === undefined) {
        return undefined;
    }
    return curr - prev;
}

function TimeInfoCard(props: TimeInfoProps) {
    const weekDiff = getDiff(props.prevWeek, props.thisWeek);
    const monthDiff = getDiff(props.prevMonth, props.thisMonth);

    return (
        <Grid>
            <div className="dash-tile">
                <div className="tile-organizer ">
                    <div className="main-metric-holder flex-center">
                        <div className="main-metric-organizer">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <OrganizationIcon />
                            </Box>
                            <div className="main-metric-data">
                                <div className="big-number">
                                    <Typography
                                        sx={{
                                            fontSize: '2.5rem',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {props.thisWeek ?? <Skeleton />}
                                    </Typography>
                                </div>
                                <Typography sx={{ fontSize: '1rem' }}>
                                    {props.title}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <ChangeMetric
                        diff={weekDiff}
                        label="Previous Week"
                        borderRight
                    />
                    <ChangeMetric diff={monthDiff} label="Previous Month" />
                </div>
            </div>
        </Grid>
    );
}

function ChangeMetric(props: {
    diff?: number;
    label: string;
    borderRight?: boolean;
}) {
    const data = useMemo(() => {
        if (props.diff === undefined) {
            return <Skeleton />;
        }
        const absDiff = Math.abs(props.diff);
        return (
            <div className="delta-metric-data">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <ChangeIcon diff={props.diff} />
                </Box>
                <div className="delta-number">
                    <Typography variant="body1">{absDiff}</Typography>
                </div>
            </div>
        );
    }, [props.diff]);

    const className = props.borderRight
        ? 'sub-metric-holder flex-center border-right'
        : 'sub-metric-holder flex-center';

    return (
        <div className={className}>
            <div className="delta-metric-organizer">
                {data}
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="body1">{props.label}</Typography>
                </Box>
            </div>
        </div>
    );
}

function ChangeIcon(props: { diff: number }) {
    if (props.diff === 0) {
        return (
            <>
                {' '}
                <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ color: '#5A5A5A', height: '10px' }}
                />{' '}
            </>
        );
    } else if (props.diff > 0) {
        return <IncreaseIcon />;
    }
    return <DecreaseIcon />;
}

function AssessmentsCompletedChart() {
    const theme = useTheme();
    const [numFrames, _setNumFrames] = useState(10);
    const [_period, _setPeriod] = useState<'week' | 'day'>('week');

    const frameDates = useMemo(() => {
        return Array.from({ length: numFrames }, (_, i) => {
            const start = dayjs()
                .subtract(numFrames - i, 'week')
                .startOf('week');
            return [start, start.endOf('week')] as const;
        });
    }, [numFrames]);

    const getUserAssessmentSummaries = UserAssessmentApi.summaries.fetchQuery();
    const getChanges = MetricApi.changes.fetchQuery();

    const queries = useQueries({
        queries: frameDates.map(([start, end]) => ({
            queryFn: async () => {
                const start_date = start.format('YYYY-MM-DD');
                const end_date = end.format('YYYY-MM-DD');
                const metdata = await getChanges({
                    start_date,
                    end_date
                });
                const assignParams: EntityTableRequest = {
                    firstRow: 0,
                    lastRow: 0,
                    filters: {
                        assignment_date: {
                            operator: FilterOperator.BETWEEN,
                            arguments: [start_date, end_date]
                        }
                    },
                    sortModel: [],
                    searchQuery: ''
                };
                const assignData = await getUserAssessmentSummaries(
                    assignParams
                );
                return Object.assign(metdata, {
                    assigned_assessments: assignData.totalRows
                });
            },
            queryKey: ['metrics', 'week', start, end]
        }))
    });
    const data = useMemo(() => {
        return queries.map((q, i) => ({
            name: `${frameDates[i][0].format('MMM DD')} - ${frameDates[
                i
            ][1].format('MMM DD')}`,
            'Assessments Completed': q.data?.completed_assessments ?? 0,
            'Assessments Expired': q.data?.expired_assessments ?? 0,
            'Assessments Assigned': q.data?.assigned_assessments ?? 0
        }));
    }, [queries]);
    return (
        <BarChart width={730} height={250} data={data}>
            <XAxis dataKey="name" tickFormatter={(s) => s.split(' - ')[0]} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
                dataKey="Assessments Completed"
                fill={theme.palette.primary.light}
            />
            <Bar
                dataKey="Assessments Expired"
                fill={theme.palette.error.main}
            />
            <Bar
                dataKey="Assessments Assigned"
                fill={theme.palette.secondary.main}
            />
        </BarChart>
    );
}

function ActivityLog() {
    const today = dayjs();
    const start = today
        .subtract(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
    const end = today.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const sysLog = SysLogApi.get.useQuery({ start_date: start, end_date: end });
    const theme = useTheme();
    return (
        <div
            style={{
                padding: 5,
                borderRadius: 3,
                border: '1px solid #d8dce5',
                boxShadow: '0px 4px 2px 0px #0000001f'
            }}
        >
            <p style={{ fontSize: '1.5rem', paddingLeft: 10 }}>System Log</p>
            <div
                style={{
                    overflow: 'auto',
                    width: 400,
                    height: 500,
                    border: '1px solid #d8dce5',
                    borderRadius: 3,
                    padding: 5
                }}
            >
                {sysLog.data &&
                    sysLog.data.map((a, i) => (
                        <SepWrapper key={i} i={i} len={sysLog.data.length} color={theme.palette.text.disabled}>
                            <ActivityLogEntry key={i} entry={a} />
                        </SepWrapper>
                    ))}
                {sysLog.error && <div>{`Error: ${sysLog.error}`}</div>}
            </div>
        </div>
    );
}

function SepWrapper({
    children,
    i,
    len,
    color
}: { i: number; len: number; color: string, children: ReactElement<any, any>}) {
    const isLast = i === len - 1;
    if (isLast) {
        return children;
    }
    return (
        <>
            {children}
            <hr
                key={`hr-${i}`}
                style={{
                    backgroundColor: color,
                    height: 1,
                    border: 'none',
                    opacity: 0.3
                }}
            />
        </>
    );
}

const HoverDiv = styled.div`
    border-radius: 5px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

function ActivityLogEntry({ entry }: { entry: SysLogEntry }) {
    const theme = useTheme();

    const FieldSection = useCallback(
        ({ children }: PropsWithChildren) => (
            <div style={{ paddingLeft: 10 }}>{children}</div>
        ),
        []
    );
    const FieldDef = useCallback(
        ({ label, value }: { label: string; value: ReactNode }) => (
            <div>
                <span style={{ color: theme.palette.text.secondary }}>
                    {label}:{' '}
                </span>
                <span>{value}</span>
            </div>
        ),
        [theme]
    );
    const eventIcon = useMemo(() => {
        let color = theme.palette.success.main;
        let overlay = 'rgba(5, 150, 100, 0.5)';
        if (entry.reason === 'EXPIRED') {
            color = theme.palette.warning.main;
            overlay = 'rgba(255, 100, 0, 0.5)';
        }
        return (
            <span
                style={{
                    backgroundColor: color,
                    borderRadius: 3,
                    color: 'white',
                    fontWeight: 'bolder'
                }}
            >
                <span
                    style={{
                        backgroundColor: overlay,
                        borderRadius: 3,
                        padding: 2
                    }}
                >
                    {entry.reason}
                </span>
            </span>
        );
    }, [entry.reason]);
    return (
        <HoverDiv
            style={
                {
                    padding: 5,
                    fontSize: '0.8rem',
                    color: theme.palette.text.primary
                } as any
            }
        >
            <div>
                <span
                    style={{
                        fontSize: '0.8rem',
                        color: theme.palette.text.disabled
                    }}
                >
                    {entry.timestamp}
                </span>
            </div>
            <div>
                <span style={{ fontWeight: 600 }}>
                    {entry.result_name} assigned to {entry.assigned_to_name}
                </span>
            </div>
            <FieldSection>
                <FieldDef label="Due" value={entry.result_expire_date} />
                <div style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <FieldDef label="Reason" value={eventIcon} />
                </div>
                <FieldSection>
                    <FieldDef label="Assessment" value={entry.reason_name} />
                    {entry.reason === 'COMPLETED' ? (
                        <>
                            <FieldDef
                                label="Date"
                                value={entry.reason_completion_date
                                    .split(' ')
                                    .join(' at ')}
                            />
                            <FieldDef
                                label="Score"
                                value={entry.reason_score}
                            />
                        </>
                    ) : (
                        <>
                            <FieldDef
                                label="Date"
                                value={entry.reason_expire_date
                                    .split(' ')
                                    .join(' at ')}
                            />
                        </>
                    )}
                </FieldSection>
            </FieldSection>
            <div style={{ paddingLeft: 10 }}></div>
        </HoverDiv>
    );
}
