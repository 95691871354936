/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityTableRequest } from '../structures/interfaces';
import { RequestResponse } from '../structures/responses';
import SeqApi, { SendRequestOptions } from './SeqApi';

export interface EntityRequest {
  id: number;
}

export interface TableRows<T> {
  rows: T[];
  totalRows: number;
}

export default abstract class EntityApi extends SeqApi {
  static singleton: EntityApi;

  abstract getSortedSummaries(
    req: EntityTableRequest
  ): Promise<TableRows<Object>>;

  async getFilterOptions(filterField: string): Promise<string[]> {
    console.log('using default entity filter options');
    const variables: { [key: string]: any } = {
      action: 'get_filter_options',
      entityType: 'assessment',
      filterField: filterField
    };
    try {
      const response = await this.sendRequest(variables, 'data', undefined, {
        mockRequest: false
      } as SendRequestOptions);
      const data = response.data as unknown as RequestResponse<{
        options: string[];
      }>;
      if (data.status !== 'success') {
        throw new Error('Could not get filter options' + data.message);
      }
      return data.resp_variables.options;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getFilterOptionsInt(filterField: string): Promise<number[]> {
    console.log('using default entity filter options');
    const variables: { [key: string]: any } = {
      action: 'get_filter_options',
      entityType: 'assessment',
      filterField: filterField
    };
    try {
      const response = await this.sendRequest(variables, 'data', undefined, {
        mockRequest: false
      } as SendRequestOptions);
      const data = response.data as unknown as RequestResponse<{
        options: number[];
      }>;
      if (data.status !== 'success') {
        throw new Error('Could not get filter options' + data.message);
      }
      return data.resp_variables.options;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
