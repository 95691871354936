import React from 'react';
import StandardModal from '../../Templates/StandardModal/StandardModal';
import AddPermissionsForm from './AddPermissionsForm';
import { useNavigate } from 'react-router-dom';

export default function AddPermissionsModal(): JSX.Element {
  const navigate = useNavigate();
  return (
    <StandardModal
      open={true}
      handleClose={() => navigate('/permissions/')}
      headerText={'Add Permissions'}
    >
      <AddPermissionsForm handleClose={() => navigate('/permissions/')} />
    </StandardModal>
  );
}
