/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import TableFilterMenu from '../../../Templates/EntityTable/TableFilterMenu';
import DateFilter from '../../../Templates/EntityTable/DateFilter';
import { FilterCriteria } from '../../../../structures/interfaces';
import { FilterOperator } from '../../../../structures/enums';
import useEntityFilter from '../../../../helpers/useEntityFilter';
import MultiSelectFilter from '../../../Templates/EntityTable/MultiSelectFilter';
import ScalarFilter from '../../../Templates/EntityTable/ScalarFilter';

interface Props {
  filters: FilterCriteria;
  handleClose: Function;
  setFilters: Function;
}
function OrganizationGroupsFilterMenu(props: Props): JSX.Element {
  const { filters, handleClose, setFilters } = props;

  const { displayedFilterState, handleCriterionChange } =
    useEntityFilter(filters);

  return (
    <TableFilterMenu
      displayedFilterState={displayedFilterState}
      handleClose={handleClose}
      setFilters={setFilters}
    >
      <MultiSelectFilter
        displayedCriterion={displayedFilterState.status}
        fieldName={'status'}
        fieldTitle={'Status'}
        handleCriterionChange={handleCriterionChange}
        defaultOptions={['Active', 'Inactive']}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.updatedDate}
        fieldName={'updatedDate'}
        fieldTitle={'Date Updated'}
        handleCriterionChange={handleCriterionChange}
      />
      <DateFilter
        displayedCriterion={displayedFilterState.createdDate}
        fieldName={'createdDate'}
        fieldTitle={'Date Created'}
        handleCriterionChange={handleCriterionChange}
      />
    </TableFilterMenu>
  );
}

export default OrganizationGroupsFilterMenu;
