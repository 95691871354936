// eslint-disable no-useless-catch
// https://docs.sheetjs.com/docs/
import * as XLSX from 'xlsx';
export type User = {
    name: string;
    email: string;
    phone: string;
};
type Setter = (users: User[] | Error) => void;

export default function parseSpreadsheet(file: File, cb: Setter) {
    const reader = new FileReader();
    reader.onload = function (e) {
        const data = e.target?.result;
        if (!data) {
            throw new Error('No data');
        }
        if (typeof data === 'string') {
            throw new Error('Data is string');
        }
        const workbook = XLSX.read(data);
        // TODO: how to handle multiple sheets?
        // FIXME: give warning if multiple sheets until we can handle them
        const sheetObj = workbook.Sheets[workbook.SheetNames[0]];
        // FIXME: does sheet_to_json ever not return an unknown[][]?
        // casting as such here but it may be unsafe
        const sheet = XLSX.utils.sheet_to_json<unknown[]>(sheetObj, {
            header: 1
        });
        try {
            const sp = parse(sheet);
            cb(sp);
        } catch (e) {
            if (e instanceof Error) {
                cb(e);
                return;
            }
            console.error({ message: 'error not error!?!', error: e });
        }
    };
    reader.readAsArrayBuffer(file);
}

function parse(sheet: unknown[][]) {
    const length: number = sheet.length - 1;
    const header = sheet[0];
    if (!header || !Array.isArray(header)) {
        throw new Error('No header');
    }
    let nameCol: number | undefined,
        emailCol: number | undefined,
        phoneCol: number | undefined;
    for (let i = 0; i < header.length; i++) {
        const maybeCol = header.at(i);
        if (!maybeCol) {
            throw new Error('Column is undefined?');
        }
        if (typeof maybeCol !== 'string') {
            throw new Error('Column is not string?');
        }
        const col = maybeCol.toLowerCase().trim();
        if (
            (col.includes('first') || col.includes('last')) &&
            col.includes('name')
        ) {
            throw new Error(
                'Names should be in a single column titled "Full Name" or just "name"'
            );
        }
        if (col === 'name' || (col.includes('full') && col.includes('name'))) {
            nameCol = i;
        } else if (col === 'email') {
            emailCol = i;
        } else if (
            col === 'number' ||
            col === 'phone' ||
            (col.includes('phone') && col.includes('number'))
        ) {
            phoneCol = i;
        }
        // TODO: handle unknown columns
    }
    if (nameCol === undefined) throw new Error('No name column');
    if (emailCol === undefined) throw new Error('No email column');
    if (phoneCol === undefined) throw new Error('No phone column');
    const idxs = {
        name: nameCol,
        email: emailCol,
        phone: phoneCol
    };
    const users: User[] = new Array<User>(length);

    // skip header
    const startRow = 1;
    for (let i = startRow; i < sheet.length; i++) {
        if (!Array.isArray(sheet[i])) {
            throw new Error('Row is not array');
        }
        // FIXME: zod validation
        const user = {
            name: parseName(sheet[i][idxs.name]),
            email: parseEmail(sheet[i][idxs.email]),
            phone: parsePhone(sheet[i][idxs.phone])
        };
        users[i] = user;
    }
    return users;
}

function parseName(name: unknown) {
    if (typeof name !== 'string') {
        throw new Error('Name is not string');
    }
    return name.trim();
}

function parseEmail(email: unknown) {
    if (typeof email !== 'string') {
        throw new Error('Email is not string');
    }
    return email.trim();
}

function parsePhone(phone: unknown) {
    if (typeof phone !== 'string') {
        throw new Error('Phone is not string');
    }
    return phone.trim();
}
