import { EntityTableRequest } from "../structures/interfaces";
import { useMemo, useState } from "react";
import lodash from "lodash";

export function useDebouncedETRSearch(debounce: number) {
    const [search, setSearch] = useState<string>('');
    const req: EntityTableRequest | null = useMemo(() => ({
        firstRow: 0,
        lastRow: 10,
        searchQuery: search,
        filters: {},
        sortModel: []
    } ), [search])
    const debouncedSetSearch = lodash.debounce((search: string) => {
        setSearch(search)
    }, debounce)
    return [req, debouncedSetSearch] as const
}
