/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useState } from 'react';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Groups } from '../../../structures/enums';
import { PermissionsFormInputI } from './PermissionsForm';
import { Organizations } from '../../../structures/organizationEnums';

// import "./AddUserModal.css";

interface Props {
  index: number;
  field: FieldArrayWithId<
    PermissionsFormInputI,
    'permissions',
    'permissionsId'
  >;
  remove: Function;
  fields: Array<
    FieldArrayWithId<PermissionsFormInputI, 'permissions', 'permissionsId'>
  >;
}

export default function PermissionInputs({
  index,
  field,
  remove,
  fields
}: Props): JSX.Element {
  const [removed, setRemoved] = useState(false);
  const {
    control,
    register,
    watch,
    formState: { errors }
  } = useFormContext<PermissionsFormInputI>();

  // function handleInputChange(
  //   e:
  //     | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  //     | SelectChangeEvent
  // ): void {
  //   const { name, value } = e.target;

  //   const newFormData = [
  //     ...formData.slice(0, index),
  //     {
  //       ...formData[index],
  //       [name]: value,
  //     },
  //     ...formData.slice(index + 1),
  //   ];
  //   setFormData(newFormData);
  //   // validate()
  // }

  const roles = ['Admin', 'Employee', 'Psychologist'];

  const orgs = [
    {
      id: Organizations.UT,
      title: Organizations.UT
    },
    {
      id: Organizations.OU,
      title: Organizations.OU
    }
  ];

  const groups = [
    {
      id: Groups.BASKETBALL,
      title: Groups.BASKETBALL
    },
    {
      id: Groups.FOOTBALL,
      title: Groups.FOOTBALL
    }
  ];

  // function removeSelf(): void {
  //   const tmpFormData = [...formData];
  //   tmpFormData.splice(index, 1);
  //   const tmpErrors = [...errors];
  //   tmpErrors.splice(index, 1);
  //   setErrors(tmpErrors);
  //   setFormData(tmpFormData);
  // }

  // function animateRemoval(): void {
  //   setRemoved(true);
  // }

  const deletedStyle = {
    backgroundColor: '#CCC',
    transition: 'backgroundcolor 1.5s'
  };

  const normalStyle = {};
  return (
    <Box key={field.permissionsId}>
      <Grid
        container
        spacing={2}
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)' }}
      >
        <Grid item>
          <Typography>{field.id != null ? field.id : '######'}</Typography>
        </Grid>
        <Grid item>
          <TextField
            error={errors.permissions?.[index]?.role != null}
            select
            size="small"
            label="Role"
            key={field.role}
            defaultValue={roles[0]}
            sx={{ width: 200 }}
            {...register(`permissions.${index}.role` as const)}
          >
            {roles.map((status, i) => {
              return (
                <MenuItem key={i} value={status}>
                  {status}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            error={errors.permissions?.[index]?.memberEmail != null}
            size="small"
            label="email"
            key={field.memberEmail}
            defaultValue={''}
            sx={{ width: 200 }}
            {...register(`permissions.${index}.memberEmail` as const)}
          />
        </Grid>

        <Grid item>
          <Controller
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  orientation="landscape"
                  openTo="day"
                  label="Effective Date"
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.permissions?.[index]?.effectiveDate != null}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
            name={`permissions.${index}.effectiveDate`}
            control={control}
          />
        </Grid>

        <Grid item>
          <IconButton
            onClick={() => {
              remove(index);
            }}
            disabled={fields.length <= 1}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
