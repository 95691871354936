import React from 'react';
import Section from '../../Templates/Section/Section';
import '../Pages.css';
import { Box } from '@mui/material';
import { ScheduleTable } from './ScheduleTable/ScheduleTable';

export function SchedulesPage(): JSX.Element {
  return (
    <Box sx={{ width: '100%' }}>
      <Section title="Schedules">{<ScheduleTable />}</Section>
    </Box>
  );
}
