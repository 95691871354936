/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/system';
import * as React from 'react';
import {UserApi} from '../../../../api/UserApi';
import createDefaultObject from '../../../../helpers/createDefaultObject';
import useResolveFilters from '../../../../helpers/useResolveFilters';
import {
    CacheContextI,
    FilterCriteria
} from '../../../../structures/interfaces';
import { AssessmentRecordSummary } from '../../../../structures/assessmentRecordInterfaces';
import EntityTable, {
    GenerateEntityFilterForm,
    StandardCellRenderers
} from '../../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../../Templates/EntityTable/TableFilterDrawer';
import OrganizationUsersFilterMenu from './OrganizationUsersFilterMenu';
import {
    GridColDef,
    GridRenderCellParams,
    GridSelectionModel,
    useGridApiRef
} from '@mui/x-data-grid';
import {
    ActivationStatus,
    FilterOperator,
    TaskStatus,
    UserSeverity
} from '../../../../structures/enums';
import { Chip, Typography, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { SeverityChip } from '../../../Templates/EntityTable/SeverityChip';
import {
    OrgStatus,
    OrganizationStatus
} from '../../../../structures/organizationEnums';
import GreenRedPill from '../../../Templates/GreenRedPill/GreenRedPill';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import UserDetailsModal from '../../UsersPage/UserDetailsModal/UserDetailsModal';
import EditUserModal from '../../UsersPage/EditUserModal/EditUserModal';
import { useNavigate } from 'react-router-dom';
import AddEditEntityTableButtons from '../../../Templates/EntityTable/AddEditEntityTableButtons';
import AddUserModal from '../../UsersPage/AddUserModal/AddUserModal';

interface Props {
    orgId: string;
}

export const OrganizationsCacheContext =
    React.createContext<CacheContextI | null>(null);

function OrganizationUsersViewer(props: Props): JSX.Element {
    const { orgId } = props;
    const [outOfDate, setOutOfDate] = React.useState(true);
    const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;

    const generateColumns: (
        standardCellsRenderers: StandardCellRenderers
    ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            headerClassName: 'table-column-title',
            renderCell: standardCellsRenderers.idCellRenderer,
            sortable: false
        },
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'severity',
            headerName: 'SEVERITY',
            renderCell: (params: GridRenderCellParams): JSX.Element => {
                return (
                    <SeverityChip
                        severity={params.value.value}
                        authorId={params.value.authorId}
                    />
                );
            },
            width: 150,
            headerClassName: 'table-column-title',
            sortable: true
        },
        {
            field: 'status',
            headerName: 'STATUS',
            width: 150,
            headerClassName: 'table-column-title',
            renderCell: statusCellRenderer,
            sortable: true
        },
        {
            field: 'groups',
            headerName: 'GROUP',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },

        {
            field: 'email',
            headerName: 'EMAIL',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        },
        {
            field: 'phone',
            headerName: 'PHONE',
            width: 150,
            headerClassName: 'table-column-title',
            sortable: false
        }
    ];

    const navigate = useNavigate();

    function statusCellRenderer(params: GridRenderCellParams): JSX.Element {
        const goodStatuses = [
            TaskStatus.COMPLETE,
            ActivationStatus.ACTIVE,
            OrganizationStatus.ACTIVE,
            OrgStatus.OPEN
        ];
        const isGood = goodStatuses.includes(params.value);
        return <GreenRedPill good={isGood} text={params.value} />;
    }

    const generateFilterForm: GenerateEntityFilterForm = (
        isOpen,
        handleClose,
        filterCriteria,
        setFilterCriteria
    ) => {
        return (
            <TableFilterDrawer
                filterDrawerOpen={isOpen}
                setFilterDrawerClosed={handleClose}
                filterMenu={
                    <OrganizationUsersFilterMenu
                        filters={filterCriteria}
                        handleClose={handleClose}
                        setFilters={setFilterCriteria}
                    />
                }
            />
        );
    };

    return (
        <Box sx={{ height: '60%' }}>
            <EntityTable
                tableKey={['organizations', orgId, 'users']}
                initialState={{
                    permaFilters: {
                        org_id: {
                            operator: FilterOperator.IS_SPECIFICALLY,
                            arguments: [orgId as unknown as string]
                        }
                    }
                }}
                generateColumns={generateColumns}
                api={UserApi}
                outOfDate={outOfDate}
                setOutOfDate={setOutOfDate}
                entityDetailModal={<UserDetailsModal fromOrgModal={true} />}
                generateEntityFilterForm={generateFilterForm}
                apiRef={apiRef}
                generateTableActions={(_unknown: GridSelectionModel) => <></>}
                selectionMode="none"
            />
        </Box>
    );
}

export default OrganizationUsersViewer;
