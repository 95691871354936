/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';
import * as React from 'react';
import PermissionApi from '../../../api/PermissionApi';
import createDefaultObject from '../../../helpers/createDefaultObject';
import useResolveFilters from '../../../helpers/useResolveFilters';
import {
  CacheContextI,
  FilterCriteria
} from '../../../structures/interfaces';
import AddEditEntityTableButtons from '../../Templates/EntityTable/AddEditEntityTableButtons';
import EntityTable, {
    GenerateEntityFilterForm,
  StandardCellRenderers
} from '../../Templates/EntityTable/EntityTable';
import TableFilterDrawer from '../../Templates/EntityTable/TableFilterDrawer';
import OrganizationDetailsModal from '../OrganizationsPage/OrganizationDetailModal/OrganizationDetailsModal';
import AddPermissionsModal from './AddPermissionsModal';
import EditPermissionsModal from './EditPermissionsModal';
import PermissionTableFilterMenu from './PermissionTableFilterMenu';
import { useGridApiRef } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export const PermissionCacheContext = React.createContext<CacheContextI | null>(
  null
);

export const PermissionFilterContext =
  React.createContext<FilterCriteria | null>(null);

function PermissionTable(): JSX.Element {
  const [outOfDate, setOutOfDate] = React.useState(true);
  const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPro>;
  const generateColumns: (
    standardCellsRenderers: StandardCellRenderers
  ) => GridColDef[] = (standardCellsRenderers: StandardCellRenderers) => [
    {
      field: 'id',
      headerName: 'ID',
      width: 150,
      headerClassName: 'table-column-title',
      renderCell: standardCellsRenderers.idCellRenderer
    },
    {
      field: 'role',
      headerName: 'ROLE',
      width: 150,
      headerClassName: 'table-column-title'
    },
    {
      field: 'userName',
      headerName: 'USER NAME',
      width: 150,
      headerClassName: 'table-column-title'
    },
    {
      field: 'userPhone',
      headerName: 'USER PHONE',
      width: 200,
      headerClassName: 'table-column-title'
    },
    {
      field: 'userEmail',
      headerName: 'USER EMAIL',
      width: 200,
      headerClassName: 'table-column-title'
    },
    {
      field: 'effectiveDate',
      headerName: 'EFFECTIVE',
      width: 150,
      headerClassName: 'table-column-title',
      renderCell: standardCellsRenderers.renderDate
    }
  ];

  const { resolveFilters } = useResolveFilters();

  function generateEditPermissionsModal(
    selectedEntities: GridSelectionModel
  ): JSX.Element {
    return <EditPermissionsModal selectionModel={selectedEntities} />;
  }

  function generateAddPermissionsModal(): JSX.Element {
    return <AddPermissionsModal />;
  }

  const generateFilterForm: GenerateEntityFilterForm = (
    isOpen: boolean,
    handleClose: Function,
    filterCriteria,
    setFilterCriteria
  ) => {
    return (
      <TableFilterDrawer
        filterDrawerOpen={isOpen}
        setFilterDrawerClosed={handleClose}
        filterMenu={
          <PermissionTableFilterMenu
            filters={filterCriteria}
            setFilters={setFilterCriteria}
            handleClose={handleClose}
          />
        }
      />
    );
  }

  function generateTableActions(
    selectionModel: GridSelectionModel
  ): JSX.Element {
    return (
      <AddEditEntityTableButtons
        entity={'Permission'}
        selectionModel={selectionModel}
        editable={true}
      />
    );
  }

  return (
    <PermissionCacheContext.Provider value={{ outOfDate, setOutOfDate }}>
      <EntityTable
        tableKey={['permissions']}
        generateColumns={generateColumns}
        generateEditEntityModal={generateEditPermissionsModal}
        generateAddEntityModal={generateAddPermissionsModal}
        entityDetailModal={<OrganizationDetailsModal />}
        generateEntityFilterForm={generateFilterForm}
        api={PermissionApi}
        outOfDate={outOfDate}
        setOutOfDate={setOutOfDate}
        generateTableActions={generateTableActions}
        apiRef={apiRef}
      />
    </PermissionCacheContext.Provider>
  );
}

export default PermissionTable;
