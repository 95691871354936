import { AssessmentApi } from '../../../../api/AssessmentApi';
import useEntityFilter from '../../../../helpers/useEntityFilter';
import { AssessmentStatus } from '../../../../structures/assessmentEnums';
import { FilterCriteria } from '../../../../structures/interfaces';
import DateFilter from '../../../Templates/EntityTable/DateFilter';
import MultiSelectFilter from '../../../Templates/EntityTable/MultiSelectFilter';
import TableFilterMenu from '../../../Templates/EntityTable/TableFilterMenu';

interface Props {
    filters: FilterCriteria;
    handleClose: () => void;
    setFilters: (filters: FilterCriteria) => void;
}

export function AssessmentTableFilterMenu(props: Props): JSX.Element {
    const { filters, handleClose, setFilters } = props;

    const { displayedFilterState, handleCriterionChange } =
        useEntityFilter(filters);

    const umbrellaFilterOptions = AssessmentApi.filterOptions.useQuery('assessmentUmbrella');
    const trackFilterOptions = AssessmentApi.filterOptions.useQuery('track');

    return (
        <TableFilterMenu
            displayedFilterState={displayedFilterState}
            handleClose={handleClose}
            setFilters={setFilters}
        >
            <DateFilter
                displayedCriterion={displayedFilterState.createdAt}
                fieldName={'createdAt'}
                fieldTitle={'Date Created'}
                handleCriterionChange={handleCriterionChange}
            />
            <DateFilter
                displayedCriterion={displayedFilterState.updatedAt}
                fieldName={'updatedAt'}
                fieldTitle={'Date Updated'}
                handleCriterionChange={handleCriterionChange}
            />
            <MultiSelectFilter
                displayedCriterion={displayedFilterState.assessmentUmbrella}
                fieldName={'assessmentUmbrella'}
                fieldTitle={'Assessment Umbrella'}
                handleCriterionChange={handleCriterionChange}
                options={umbrellaFilterOptions.data}
            />
            <MultiSelectFilter
                displayedCriterion={displayedFilterState.track}
                fieldName={'track'}
                fieldTitle={'Track'}
                handleCriterionChange={handleCriterionChange}
                defaultOptions={['Track 1', 'Track 2', 'Track 3']}
                options={trackFilterOptions.data}
            />
            <MultiSelectFilter
                displayedCriterion={displayedFilterState.status}
                fieldName={'status'}
                fieldTitle={'Status'}
                handleCriterionChange={handleCriterionChange}
                options={[
                    AssessmentStatus.ACTIVE,
                    AssessmentStatus.RETIRED
                ]}
            />
            <MultiSelectFilter
                displayedCriterion={displayedFilterState.royalty}
                fieldName={'royalty'}
                fieldTitle={'Royalty'}
                handleCriterionChange={handleCriterionChange}
                options={['true', 'false']}
            />
            <MultiSelectFilter
                displayedCriterion={displayedFilterState.cadence}
                fieldName={'cadence'}
                fieldTitle={'Cadence'}
                handleCriterionChange={handleCriterionChange}
                options={['Monthly', 'Weekly', 'Bi-weekly']}
            />
        </TableFilterMenu>
    );
}
