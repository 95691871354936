import { DashboardMetrics, DataPeriod } from '../../structures/enums';

export const dbData = [
  {
    type: DashboardMetrics.NEW_OrganizationS,
    history: {
      latest: {
        [DataPeriod.REFERENCE]: 8,
        [DataPeriod.PREVIOUS_MONTH]: 5,
        [DataPeriod.PREVIOUS_WEEK]: 9
      },
      previous: {
        [DataPeriod.REFERENCE]: 5,
        [DataPeriod.PREVIOUS_MONTH]: 7,
        [DataPeriod.PREVIOUS_WEEK]: -2
      }
    }
  },
  {
    type: DashboardMetrics.PERCENT_ASSESSMENTS_COMPLETED,
    history: {
      latest: {
        [DataPeriod.REFERENCE]: 18,
        [DataPeriod.PREVIOUS_MONTH]: -5,
        [DataPeriod.PREVIOUS_WEEK]: 9
      },
      previous: {
        [DataPeriod.REFERENCE]: 43,
        [DataPeriod.PREVIOUS_MONTH]: 7,
        [DataPeriod.PREVIOUS_WEEK]: -2
      }
    }
  }
];
